import { useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { useActions } from 'SHARED/redux/hooks/useActions';
import { PreventLossUnsavedData } from 'SHARED/hooks/usePreventReload';
import { prepareCharacteristics } from 'SHARED/helpers/prepareCharacteristics';
import prepareBuyerGroups from 'SHARED/helpers/prepareBuyerGroups';
import { Helmet } from 'react-helmet-async';
import Preloader from 'SHARED/components/ThePreloader';
import { DevTool } from '@hookform/devtools';
import {
  OnSpecOffer,
  Dictionary,
  OfferTypes,
} from 'SHARED/types/offerTypes';
import preparePreview from 'SHARED/helpers/prepareOfferData';
import routes from 'SHARED/types/routes';
import { clearProxyFields, isDevEnv } from 'SHARED/helpers/common';
import { cloneDeep } from 'lodash';
import useIsForRfp from 'SHARED/hooks/useIsForRfp';
import { volumeUnits } from '../../components/AlreadyProducedOfferData';
import ProductSection from '../../components/ProductSection';
import ShippingSection from '../../components/ShippingSection';
import VolumePriceSection from '../../components/VolumePriceSection';
import ProductFlowSection from '../../components/ProductFlowSection';
import BottomSection from '../../components/BottomSection';

import 'react-datepicker/dist/react-datepicker.css';
import './offer-details.scoped.scss';
import './offer-details.scss';
import useDuplicateAP from './useDuplicateAP';

interface Props { isLoggedIn: boolean }

const AlreadyProducedCreate: React.FC<Props> = ({ isLoggedIn }) => {
  const history = useHistory();
  const { isForRfp, rfpID } = useIsForRfp();

  // ********************* REDUX section *********************
  const { me } = useTypedSelector((state) => state.users);
  const { ON_SPEC } = useTypedSelector((state) => state.offer);
  const { dictionaries } = useTypedSelector((state) => state.dictionaries);
  const { loading } = useTypedSelector((state) => state.specs);

  const {
    product,
    coaUsed,
    params,
    documents,
    departurePeriod,
  } = ON_SPEC;

  const {
    getDictionaries,
    saveOffer,
    resetOffer,
    getProductCategoriesList,
    getLocations,
    getContainers,
  } = useActions();

  // 1 - waiting for org id
  // then get all data for offer creations
  useEffect(() => {
    if (me.orgId) {
      getDictionaries({ offerType: 'ALREADY_PRODUCED' });
      getProductCategoriesList(false);
      getLocations({ type: 'BOTH', orgId: me.orgId });
      getContainers(me.orgId);
    }
  }, [me]);

  const methods = useForm();

  // 3 - watch form values section
  // for dynamic condition rendering and properly date validation
  const { watch, formState: { isDirty }, setValue } = methods;
  const choosedDeparturePeriod = watch('departurePeriod', departurePeriod);
  const choosedCoaUsed = watch('coaUsed', coaUsed);
  const choosedProduct: Dictionary = watch('product', product);

  // ********************* DUPLICATE OFFER section *********************
  // Prefill logic encapsulated into a hook
  const { duplicateMessage, duplicateError, duplicateLoading } = useDuplicateAP(setValue, watch);

  // 2 -   handling user discard offer creation
  // 2.1 - reset offer's state in redux
  // 2.2 - redirect back to offers list page
  const handleDiscard = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    resetOffer();
    history.push(routes.onspec.listDefault);
  };

  if (isForRfp) {
    setValue('parentOfferId', rfpID);
  }

  // const choosedDepartureFromMonth = watch('departureFromMonth');
  // const choosedEndDate            = watch('endDate', endDate);

  // if form changed prevent closing browser tab
  // without prompting user
  PreventLossUnsavedData(isDirty);

  // ********************* SUBMIT sections *********************
  const onSubmit = (data: OnSpecOffer) => {
    const prepared = preparePreview(cloneDeep(clearProxyFields(data)), dictionaries);

    prepared.volumeUnits = volumeUnits;
    prepared.params = { ...params, excludedBuyers: prepared.buyers };
    prepared.documents = documents;
    prepared.productionLocationId = prepared.productionLocation.id;
    prepared.logisticLocationId = prepared.logisticLocation.id;
    prepared.productId = parseInt(prepared.product.value, 10);

    // if buyers groups selected, buyers list must be set from group
    if (prepared.groups?.length > 0) {
      prepared.buyers = prepareBuyerGroups(prepared.groups);
    }

    // if COA document provided
    if (prepared.coaUsed) {
      prepared.documents = {
        ...prepared.documents,
        COA: prepared.coaDocument,
      };

      delete prepared.coaDocument;
    }

    // if Sales terms document provided
    if (prepared.salesTerms?.value === 'OWN') {
      prepared.documents = {
        ...prepared.documents,
        SALES_TERMS: prepared.termsDocument,
      };

      delete prepared.termsDocument;
    } else {
      prepared.documents = {
        ...prepared.documents,
        SALES_TERMS: null,
      };
    }

    // map characteristics values if COA option selected
    if (choosedCoaUsed) {
      prepared.productCharacteristics = prepareCharacteristics(prepared.productCharacteristics);
      prepared.productCharacteristics = prepared.productCharacteristics.map((ch: any) => {
        const cleaned = ch;
        cleaned.range = false;
        delete cleaned.valueTo;
        return cleaned;
      });
    }

    // ? add logic if COA not selected but we got characteristics and COA-field
    // ? they should be removed from offer
    if (!prepared.coaUsed && prepared.productCharacteristics?.length > 0) {
      delete prepared.coaDocument;
      delete prepared.productCharacteristics;

      // prepared.documents.COA = null;
      prepared.documents = {
        ...prepared.documents,
        COA: null,
      };
    }

    // preparing UI field values for backend model
    if (choosedDeparturePeriod === 'MONTH') {
      prepared.departureFrom = prepared.departureFromMonth.value;
      prepared.departureTo = new Date().toISOString();
    }

    // additional validation https://brain-agency.atlassian.net/browse/OP-708
    // if (parseInt(moment(prepared.departureFrom).format('x'), 10) < prepared.endDate) {
    //   setError('endDate', { type: 'required', message: texts.offerTexts.common.departureDateLess });
    //   return false;
    // }

    // single currency (if currency is disabled -- set corresponding price to null)
    // but at least one price should be set
    if (prepared.priceUsdDisabled && prepared.priceEurDisabled) { return false; }
    if (prepared.priceEurDisabled && !prepared.priceUsdDisabled) { prepared.priceEur = null; }
    if (prepared.priceUsdDisabled && !prepared.priceEurDisabled) { prepared.priceUsd = null; }

    // after time format migration backend expects only timestamps
    // prepared.productionDate = moment(prepared.productionDate).valueOf();
    // prepared.endDate = moment(prepared.endDate).valueOf();
    // prepared.expirationDate = moment(prepared.expirationDate).valueOf();
    // prepared.departureFrom = moment(prepared.departureFrom).valueOf();
    // prepared.departureTo = moment(prepared.departureTo).valueOf();

    saveOffer({ offer: prepared, offerType: 'ON_SPEC' });

    // console.log('data', data);
    // console.log('prepared', prepared);
    // return true;

    // RFP should have 'isForRfp' param, in case of 'Edit after preview'
    if (isForRfp && rfpID) {
      history.push(routes.onspec.previewAP_rfp(rfpID as string));
    } else {
      history.push(routes.onspec.previewAP);
    }

    return true;
  };

  return (
    <>
      <div className="breadcrumbs">
        <a href={routes.onspec.listDefault}>&lt; All offers </a>
      </div>

      <div className="page-body is-logged-in">
        <Helmet>
          <title>Create already produced offer</title>
        </Helmet>

        <Preloader isLoading={loading || duplicateLoading} />

        {isDevEnv && <DevTool control={methods.control} placement="top-right" /> }

        <div>
          <h1 className="page-title with-separator">Offer for already produced product</h1>

          {duplicateMessage && !duplicateError && <div className="attention-message">{duplicateMessage}</div>}
          {duplicateError && <div className="attention-message alert">{duplicateError}</div>}

          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div className="offer-wrapper">

                <div className="offer-left-side">
                  <h2 className="page-section-title">
                    Product
                  </h2>

                  <ProductFlowSection />

                  {choosedProduct && (
                    <>
                      <ProductSection offerType={OfferTypes.alreadyProduced} choosedProduct={choosedProduct} />
                      <ShippingSection offerType={OfferTypes.alreadyProduced} />
                      <VolumePriceSection isForRfp={isForRfp} offerType={OfferTypes.alreadyProduced} />
                      <BottomSection offerType={OfferTypes.alreadyProduced} isForRfp={isForRfp} />
                    </>
                  )}

                  <button type="submit" disabled={!choosedProduct} className="btn-primary">Preview offer</button>
                  <button type="button" onClick={(e) => handleDiscard(e)} className="btn-secondary">Discard</button>

                </div>

              </div>
            </form>
          </FormProvider>

        </div>
      </div>
    </>
  );
};

export default AlreadyProducedCreate;
