import React from 'react';
import { MenuItem } from 'SHARED/types/offerTypes';
// import { NavLink } from 'react-router-dom';
// import clsx from 'clsx';

type IMenuProps = {
  items: MenuItem[],
  view?: string,
  activeLink?: string,
  username?: string,
  notExact?: boolean,
  changeTabAction: any, // because type already defined in parent
};

const Menu: React.FC<IMenuProps> = ({
  items, view, activeLink, changeTabAction, username, notExact = false,
}) => {
  const CheckIsActive = (link:string):string | undefined => {
    if (notExact) {
      return link.split('/')[1] === activeLink?.split('/')[1] ? 'active' : undefined;
    }
    return link === activeLink ? 'active' : undefined;
  };

  const handleClick = (e:React.MouseEvent, link:string):void => {
    e.preventDefault();
    changeTabAction(link);
  };

  return (
    <ul className={`tabs ${view}`}>
      {items.map(({ label, link, disabled }) => (
        <li key={label} className={CheckIsActive(link)}>
          <a
            href={link}
            className={disabled ? 'disabled' : ''}
            type="button"
            onClick={(e) => { handleClick(e, link); }}
          >
            {label}
          </a>
          {/* <NavLink
            to={link}
            className={clsx(disabled && 'disabled', 'header-nav-link')}
            type="button"
            onClick={(e) => { changeTabAction(link); }}
          >
            {label}
          </NavLink> */}
        </li>
      ))}
      <li className="hide-mobile" title={username}>
        <span className="user-name">{username}</span>
      </li>
    </ul>
  );
};

export default Menu;
