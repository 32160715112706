import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useParams } from 'react-router-dom';
import _ from 'lodash';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import Preloader from 'SHARED/components/ThePreloader';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { useActions } from 'SHARED/redux/hooks/useActions';
import routes from 'SHARED/types/routes';
import texts from 'SHARED/helpers/texts';
import FormattedPrices from 'SHARED/components/Prices';
import CharacteristicPreview from './ProductSpecificationPreview';

export interface RouteParams {
  id: string,
}

interface IProps {
  isForBuyer?: boolean,
}

const ProductSpecificationsPage: React.FC<IProps> = ({ isForBuyer = false }) => {
  useEffect(() => { getProduct(id); }, []);
  const { id } = useParams<RouteParams>();

  const { loading, product } = useTypedSelector((state) => state.specs);
  const { me } = useTypedSelector((state) => state.users);
  const { getProduct } = useActions();

  const characteristics = product ? product.characteristics : [];
  const sortedCharacteristics = _.sortBy(characteristics, 'displayGroup.order');
  const groupedCharacteristics = _.groupBy(sortedCharacteristics, 'displayGroup.value');

  if (!product) return (<Preloader isLoading />);

  const isBuyer = me.type === 'BUYER';

  const {
    number, productType, created, title, productCategory, premiumPriceEur = 10, premiumPriceUsd = 10, packaging, requiredDocuments, customLabelRequired,
  } = product;

  return (
    <>
      <Helmet>
        <title>{`Product Specification ${product?.title}`}</title>
      </Helmet>

      <Preloader isLoading={loading} />

      <div className="breadcrumbs">
        <Link to={routes.common.specifications}>
          &lt;
          {' '}
          {texts.specsTexts.common.allSpecs}
        </Link>
      </div>

      <div className="page-body is-logged-in preview-offer-page">

        <h1 className="page-title">
          Product specifications
          <div className="buttons">
            <Link
              to={routes.common.duplicateProduct(productCategory.value.toLowerCase(), id)}
              className="btn-icon"
              title="Duplicate specification"
            >
              <i className="icon-duplicate-2" />
            </Link>
          </div>
        </h1>

        <div className="page-columns">
          <div className="page-column">
            <DataItemPreview item={number} title="UID" />
            <DataItemPreview item={productType} title="PRODUCT TYPE" />
            {isBuyer && <DataItemPreview item={packaging} title="PACKAGING" />}
          </div>
          <div className="page-column">
            <DataItemPreview item={created} isDate title="CREATED" />
            <DataItemPreview item={title} title="PRODUCT SPECS TITLE" />
            {isBuyer && <DataItemPreview item={requiredDocuments.map((p) => (p.label)).join(', ')} title="REQUIRED DOCUMENTS" />}
          </div>
          <div className="page-column">
            <DataItemPreview item={productCategory} title="PRODUCT CATEGORY" />
            {isBuyer && <DataItemPreview item={customLabelRequired ? 'Yes' : 'No'} title="CUSTOM LABEL" />}
          </div>
        </div>

        <div className="page-columns">

          {Object.keys(groupedCharacteristics).map((key) => (
            <div className="page-column">

              <div className="page-section-title">
                {groupedCharacteristics[key][0].displayGroup.label}
              </div>

              {groupedCharacteristics[key].map((ch) => (
                <CharacteristicPreview
                  characteristic={ch}
                  isForBuyer={isForBuyer}
                />
              ))}
              <br />
              <br />
            </div>
          ))}
        </div>

        {me.type === 'SELLER' && (
          <>
            <div className="page-section-title">Premium for To-Be-Produced offers</div>
            <div className="page-columns">
              <div className="page-column">
                <FormattedPrices values={[premiumPriceEur, premiumPriceUsd]} title="PREMIUM PRICE" />
              </div>
            </div>
          </>
        )}

      </div>
    </>
  );
};

export default ProductSpecificationsPage;
