import { DevTool } from '@hookform/devtools';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import Checkboxes from 'SHARED/components/Checkboxes';
import FormInput from 'SHARED/components/FormInput';
import RadioGroup from 'SHARED/components/RadioGroup';
import FormSelect from 'SHARED/components/Select';
import Preloader from 'SHARED/components/ThePreloader';
import { MpcDocLink, clearProxyFields, isDevEnv } from 'SHARED/helpers/common';
import { tooltips } from 'SHARED/helpers/texts';
import PreventLossUnsavedData from 'SHARED/hooks/usePreventReload';
import { useActions } from 'SHARED/redux/hooks/useActions';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import routes from 'SHARED/types/routes';
import { ProductCategory } from 'SHARED/types/specsTypes';
import { buyersFetchType, departurePeriod as departurePeriods } from 'SHARED/helpers/OfferData';
import useUserTimezone from 'SHARED/hooks/useUserTimezone';
import ZonedDatePicker from 'SHARED/components/ZonedDatePicker/ZonedDatePicker';
import { isMobile } from 'react-device-detect';
import { zonedMonthsDictionary } from 'SHARED/helpers/dates';
import validationRules from 'SHARED/helpers/validation';
import { SingleCheckbox } from 'SHARED/components/Checkbox';
import SingleCurrencyInput from 'SHARED/components/SingleCurrencyInput';
import { BuyerPaymentTerm, BuyersFetchTypes } from 'SHARED/types/offerTypes';
import Radio from 'SHARED/components/Radio';
import FormMultiSelect from 'SHARED/components/MultiSelect';
import FormFileInput from 'SHARED/components/FileInput/FormFileInput';
import preparePreview from 'SHARED/helpers/prepareOfferData';
import { cloneDeep } from 'lodash';
import prepareBuyerGroups from 'SHARED/helpers/prepareBuyerGroups';

//----------------------------------

const PAGE_STYLES: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  gap: '40px',
};

const SECTION_STYLES: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
};

//----------------------------------

export const SimpleOfferCreate = () => {
  // hooks
  const history = useHistory();
  const { userTimezone } = useUserTimezone();
  // hooks === END

  // hook form
  const methods = useForm();
  const {
    watch,
    setValue,
    formState: { isDirty },
  } = methods;
  // hook form === END

  // redux hooks
  const { me } = useTypedSelector((state) => state.users);
  const {
    dictionaries,
    loading: isDictionariesLoading,
  } = useTypedSelector((state) => state.dictionaries);
  const {
    categories,
    loading: isSpecsLoading,
  } = useTypedSelector((state) => state.specs);
  const {
    ON_SPEC,
    loading: isOfferLoading,
  } = useTypedSelector((state) => state.offer);
  // redux hooks === END

  // redux actions
  const {
    resetOffer,
    getProductCategoriesList,
    getDictionaries,
    saveOffer,
  } = useActions();
  // redux actions === END

  // form values
  const productCategoryValue: ProductCategory = watch('productCategory', ON_SPEC.productCategory);
  const departurePeriodValue = watch('departurePeriod', ON_SPEC.departurePeriod);
  const departureFromValue = watch('departureFrom', ON_SPEC.departureFrom);
  const paymentTermsValue: BuyerPaymentTerm = watch('paymentTerms');
  const departureFromMonthValue = watch('departureFromMonth');
  const departureToValue = watch('departureTo', ON_SPEC.departureTo);

  const currentDepartureDate = (
    departurePeriodValue === 'MONTH'
      ? departureFromMonthValue?.value
      : departureToValue
  );

  const buyersFetchTypeValue: BuyersFetchTypes = watch(
    'buyersFetchType',
    ON_SPEC.buyersFetchType?.value || BuyersFetchTypes.includeAll,
  );

  const salesTermsTypeValue = watch('salesTerms', ON_SPEC.salesTerms);
  const isOwnTermsChoosed = salesTermsTypeValue === 'OWN';
  // form values === END

  // form
  const departureMonthPrefill = (
    (departurePeriodValue === 'MONTH' && ON_SPEC.departureFromMonth)
      ? ON_SPEC.departureFromMonth
      : zonedMonthsDictionary(userTimezone)[0]
  );

  const buyersValidation = {
    required: (
      buyersFetchTypeValue === 'EXCLUDE'
        ? 'Select at least one buyer'
        : false
    ),
    validate: validationRules.notAllSelected(dictionaries.BUYERS_LIST?.length),
  };
  // form === END

  // flags
  const isLoading = (
    isDictionariesLoading
    || isSpecsLoading
    || isOfferLoading
  );
  const isIncludeBuyerGroups = buyersFetchTypeValue === BuyersFetchTypes.includeGroup;
  const isExcludeBuyers = buyersFetchTypeValue === BuyersFetchTypes.exclude;
  const isHaveBuyerGroups = !!dictionaries.BUYERS_GROUPS_LIST?.length;
  // flags === END

  // handlers
  const onSubmit: SubmitHandler<any> = (data) => {
    const prepared = preparePreview(cloneDeep(clearProxyFields(data)), dictionaries);

    // add missing fields
    prepared.volumeUnits = ON_SPEC.volumeUnits;
    prepared.params = { ...ON_SPEC.params, excludedBuyers: prepared.buyers };
    prepared.documents = ON_SPEC.documents;

    // if buyers groups selected, buyers list must be set from group
    if (prepared.groups?.length > 0) {
      prepared.buyers = prepareBuyerGroups(prepared.groups);
    }

    // if Sales terms document provided
    if (prepared.salesTerms?.value === 'OWN') {
      prepared.documents = {
        ...prepared.documents,
        SALES_TERMS: prepared.termsDocument,
      };

      delete prepared.termsDocument;
    } else {
      prepared.documents = {
        ...prepared.documents,
        SALES_TERMS: null,
      };
    }

    // preparing UI field values for backend model
    if (departurePeriodValue === 'MONTH') {
      prepared.departureFrom = prepared.departureFromMonth.value;
      prepared.departureTo = new Date().toISOString();
    }

    // single currency (if currency is disabled -- set corresponding price to null)
    // but at least one price should be set
    if (prepared.priceUsdDisabled && prepared.priceEurDisabled) { return false; }
    if (prepared.priceEurDisabled && !prepared.priceUsdDisabled) { prepared.priceEur = null; }
    if (prepared.priceUsdDisabled && !prepared.priceEurDisabled) { prepared.priceUsd = null; }

    // remove prices from packages (they're in labels and should not be shown in the preview)
    prepared.packages = prepared.packages.map((p: any) => {
      const match = data
        .productCategory
        ?.packagingOptions
        .find((o: any) => o.value === p.value);

      return { ...p, label: match?.label || p.label };
    });

    saveOffer({ offer: prepared, offerType: 'ON_SPEC' });

    history.push(routes.onspec.preview_simple_offer);

    return true;
  };

  const handleDiscard = () => {
    resetOffer();
    history.push(routes.onspec.listDefault);
  };

  function handleProductCategoryChange(value: ProductCategory | null) {
    // reset dependent fields
    setValue('productType', null);
    setValue('packages', null);
  }
  // handlers === END

  // effects
  PreventLossUnsavedData(isDirty);

  useEffect(() => {
    // load all necessary data for offer creation
    if (me.orgId) {
      getDictionaries({ offerType: 'ALREADY_PRODUCED' });
      getProductCategoriesList(false);
    }
  }, [me]);
  // effects === END

  return (
    <>
      <Helmet>
        <title>Create a simple offer</title>
      </Helmet>

      <Preloader isLoading={isLoading} />

      {isDevEnv && <DevTool control={methods.control} placement="top-right" /> }

      <div className="breadcrumbs">
        <a href={routes.onspec.listDefault}>&lt; All offers </a>
      </div>

      <main className="page-body is-logged-in">
        <h1 className="page-title">Create a simple offer</h1>

        <p className="wrapper-md">
          Simple offers don&apos;t include matching on product specifications and allow you to offer products without disclosing your identity until a deal is made.
          <br />
          You can offer any type of product, both Already Produced and To Be Produced.
        </p>

        <div className="separator" />

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <input type="hidden" {...methods.register('type')} value="SIMPLE_ALREADY_PRODUCED" />

            <div className="wrapper-sm manual-form-layout" style={PAGE_STYLES}>

              <section style={SECTION_STYLES}>
                <h2 className="page-section-title no-margin">Product</h2>

                <div className="wrapper-xxs">
                  <FormSelect
                    label="PRODUCT CATEGORY"
                    name="productCategory"
                    options={categories}
                    selected={ON_SPEC.productCategory}
                    onChange={handleProductCategoryChange}
                  />
                </div>

                <div className="wrapper-xxs">
                  <FormSelect
                    label="PRODUCT TYPE"
                    name="productType"
                    options={productCategoryValue?.productTypes || []}
                    selected={ON_SPEC.productType}
                    disabled={!productCategoryValue}
                  />
                </div>

                <FormInput
                  name="description"
                  textarea
                  label="REMARKS FOR THE BUYER"
                  value={ON_SPEC.description}
                  tooltip={tooltips.simple_offer_description}
                />

                {/* TODO: "Product documents" FileUpload will be added later (multiple attachment support will be required) */}

                <Checkboxes
                  label="DOCUMENT CAPABILITIES "
                  name="documentCapabilities"
                  tooltip={tooltips.capabilities}
                  values={dictionaries.DOCUMENT_TYPE}
                  selected={ON_SPEC.documentCapabilities}
                  className="two-columns"
                />

                {!!productCategoryValue && (
                  <Checkboxes
                    label="PACKAGING"
                    name="packages"
                    tooltip={tooltips.packaging}
                    values={productCategoryValue?.packagingOptions || []}
                    selected={ON_SPEC.packages}
                    className="two-columns"
                  />
                )}

                <RadioGroup
                  label="SALES TERMS"
                  name="salesTerms"
                  defaultValue={ON_SPEC.salesTerms}
                  values={dictionaries.SALES_TERMS}
                />

                <div className="form-input">
                  <div className="label-text">SALES TERMS DOCUMENT *</div>

                  {!isOwnTermsChoosed && (
                    <a href={MpcDocLink} target="_blank" rel="noreferrer" className="document-link">
                      <i className="icon-flag" />
                      <span>MPC sales terms</span>
                    </a>
                  )}

                </div>

                {isOwnTermsChoosed && (
                  <div>
                    <FormFileInput
                      name="termsDocument"
                      defaultFiles={ON_SPEC.documents.SALES_TERMS}
                      acceptedFileTypes={['application/pdf']}
                      required={isOwnTermsChoosed}
                    />
                  </div>
                )}

              </section>

              <section style={SECTION_STYLES}>
                <h2 className="page-section-title no-margin">Shipping</h2>

                <div className="wrapper-xxs">
                  <FormSelect
                    label="INCOTERMS"
                    name="incoterms"
                    options={dictionaries.INCOTERM_SELLER}
                    selected={ON_SPEC.incoterms}
                  />
                </div>

                <FormInput
                  name="logisticLocationNote"
                  textarea
                  label="INCOTERMS LOCATION DESCRIPTION (WILL BE SHOWN TO BUYER)"
                  value={ON_SPEC.logisticLocationNote}
                  rules={validationRules.required}
                  tooltip={tooltips.simple_offer_location_note}
                />

                <Checkboxes
                  label="LOADING DETAILS"
                  name="loadOptions"
                  values={dictionaries.LOADING_DETAILS}
                  selected={ON_SPEC.loadOptions}
                />

                <div style={SECTION_STYLES}>
                  <RadioGroup
                    label="DEPARTURE"
                    name="departurePeriod"
                    defaultValue={ON_SPEC.departurePeriod}
                    values={departurePeriods}
                  />

                  {departurePeriodValue === 'MONTH' && (
                    <div className="wrapper-xxs">
                      <FormSelect
                        label="Month"
                        name="departureFromMonth"
                        options={zonedMonthsDictionary(userTimezone)}
                        selected={departureMonthPrefill}
                      />
                    </div>
                  )}

                  {departurePeriodValue === 'PERIOD' && (
                    <div className="date-range">
                      <ZonedDatePicker
                        inline={isMobile}
                        name="departureFrom"
                        value={ON_SPEC.departureFrom}
                        showTimezone={false}
                      />
                      <ZonedDatePicker
                        inline={isMobile}
                        name="departureTo"
                        minDate={departureFromValue}
                        value={ON_SPEC.departureTo}
                        showTimezone={false}
                      />
                    </div>
                  )}
                </div>
              </section>

              <section style={SECTION_STYLES}>
                <h2 className="page-section-title no-margin">Volume</h2>

                <div className="wrapper-xxs">
                  <FormInput
                    name="totalVolume"
                    label="VOLUME"
                    value={ON_SPEC.totalVolume}
                    className="short"
                    type="number"
                    suffix="MT"
                    tooltip="Please offer volumes that equal one or more container volumes."
                    rules={validationRules.required}
                  />
                </div>

                <SingleCheckbox
                  title="SPLITTABLE"
                  label="Splittable per container / full truck load"
                  name="splittable"
                  defaultChecked={ON_SPEC.splittable}
                />
              </section>

              <section style={SECTION_STYLES}>
                <h2 className="page-section-title no-margin">Price</h2>

                <div className="wrapper-xxs">
                  <SingleCurrencyInput
                    name="priceEur"
                    label="ASKING PRICE"
                    value={ON_SPEC.priceEur}
                    tooltip={tooltips.askingPriceEur}
                    currencyName="EUR"
                    currencyDisabledDefault={ON_SPEC.priceEurDisabled}
                  />
                </div>
                <div className="wrapper-xxs">
                  <SingleCurrencyInput
                    name="priceUsd"
                    currency="$"
                    label="ASKING PRICE"
                    value={ON_SPEC.priceUsd}
                    tooltip={tooltips.askingPriceUsd}
                    currencyName="USD"
                    currencyDisabledDefault={ON_SPEC.priceUsdDisabled}
                  />
                </div>

                <div
                  className={paymentTermsValue?.hasDayOffset ? 'financing-row' : ''}
                  style={{ marginBottom: 0 }}
                >
                  <FormSelect
                    label="PAYMENT TERMS"
                    name="paymentTerms"
                    options={dictionaries.PAYMENT_TERMS}
                    selected={ON_SPEC.paymentTerms}
                  />

                  {paymentTermsValue?.hasDayOffset && (
                    <div className="days-offset">
                      <FormSelect
                        label="NUMBER OF DAYS"
                        name="paymentOffset"
                        options={paymentTermsValue.offsetDays.map((d) => ({ value: `${d}`, label: `${d} days` }))}
                        selected={ON_SPEC.paymentOffset || null}
                      />
                    </div>
                  )}
                </div>
              </section>

              <section style={SECTION_STYLES}>
                <h2 className="page-section-title no-margin">Buyers</h2>

                <>
                  <div className="form-input"><label htmlFor="">BUYERS WHO SEE THIS OFFER *</label></div>

                  <Radio
                    selected={buyersFetchTypeValue}
                    name="buyersFetchType"
                    value={BuyersFetchTypes.includeAll}
                    label={buyersFetchType[0].label}
                  />

                  <Radio
                    selected={buyersFetchTypeValue}
                    name="buyersFetchType"
                    value={BuyersFetchTypes.includeGroup}
                    label={buyersFetchType[1].label}
                  />

                  {isIncludeBuyerGroups && isHaveBuyerGroups && (
                    <FormMultiSelect
                      name="groups"
                      options={dictionaries.BUYERS_GROUPS_LIST}
                      value={ON_SPEC.groups}
                    />
                  )}
                  {isIncludeBuyerGroups && !isHaveBuyerGroups && (
                    <div className="attention-message">
                      {'You have no Buyer Groups yet, '}
                      <Link to="/create-buyers-group">
                        create
                      </Link>
                      {' one now.'}
                    </div>
                  )}

                  <Radio
                    selected={buyersFetchTypeValue}
                    name="buyersFetchType"
                    value={BuyersFetchTypes.exclude}
                    label={buyersFetchType[2].label}
                  />

                  {isExcludeBuyers && (
                    <FormMultiSelect
                      name="buyers"
                      rules={buyersValidation}
                      options={dictionaries.BUYERS_LIST}
                      value={ON_SPEC.buyers}
                    />
                  )}
                </>
              </section>

              <section style={SECTION_STYLES}>
                <h2 className="page-section-title no-margin">Trade anonymously</h2>

                <SingleCheckbox
                  name="anonymous"
                  label="Post this offer without disclosing our identity"
                  caption="Your name and company will not be shown to buyers until a deal is made. Also, you will not be able to view the buyers' identities until a deal is made."
                  defaultChecked={ON_SPEC.anonymous}
                />
              </section>

              <section style={SECTION_STYLES}>
                <h2 className="page-section-title no-margin">Offer expiration</h2>

                <div className="wrapper-xs">
                  <ZonedDatePicker
                    inline={isMobile}
                    name="endDate"
                    showTimeInput
                    rules={validationRules.startDate(currentDepartureDate, 'Must be earlier than departure end date')}
                    value={ON_SPEC.endDate}
                    dateFormat="dd MMMM yyyy HH:mm"
                    showTimezone
                  />
                </div>
              </section>

              <div className="buttons-wrapper m-full-width">
                <button
                  type="submit"
                  className="btn-primary"
                >
                  Preview offer
                </button>
                <button
                  type="button"
                  className="btn-secondary"
                  onClick={handleDiscard}
                >
                  Discard
                </button>
              </div>

            </div>

          </form>
        </FormProvider>

      </main>
    </>
  );
};
