import React, { useState } from 'react';
import { Link, useLocation as UseLocation, useHistory } from 'react-router-dom';
import { MenuItem } from 'SHARED/types/offerTypes';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import Menu from '../Menu';
import HeaderDropdown from './HeaderDropdown';
import './header-styles.scss';

interface ITheHeaderProps {
  orgName: string | undefined,
  userName: string | undefined,
}

const menuItems: MenuItem[] = [
  { label: 'Offers', link: '/onspec/offers/' },
  { label: 'Auctions', link: '/offspec/offers' },
  { label: 'RFP', link: '/rfp/offers' },
];

const TheHeader: React.FC<ITheHeaderProps> = ({ orgName, userName }) => {
  const history = useHistory();

  const { me } = useTypedSelector((state) => state.users);
  const PORTAL = me.type;

  const [opened, setOpened] = useState<boolean>(false);
  const path = `/${UseLocation().pathname.substring(1)}`;

  const ChangePage = (link: string) => {
    history.push(link);
    if (opened) {
      document.body.classList.remove('modal-opened');
      setOpened(false);
    }
  };

  const handleClose = () => { setOpened(false); };

  const handleMenuOpen = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    document.body.classList.toggle('modal-opened');
    setOpened(!opened);
  };

  return (
    <div className="app-header">
      <div className="container app-header__wrapper">
        <div className="app-header__left">
          <Link to="/" className="the-logo the-logo--desktop hide-mobile white">
            <img src="/images/logo-white.svg" alt="Logo with text" />
          </Link>
          <Link to="/" className="the-logo the-logo--mobile hide-desktop white">
            <img src="/logo-without-text-white.svg" alt="Logo" />
          </Link>
          <span
            className="app-header__company hide-mobile"
            title={orgName}
          >
            for
            {' '}
            {orgName}
          </span>
        </div>

        <div className="app-header__right">
          <Menu
            items={menuItems}
            view="header-menu"
            activeLink={path}
            changeTabAction={ChangePage}
            username={userName}
            notExact
          />
          <div className="dropdown hide-mobile">
            <i className="icon-account" />
            <ul className="dropdown-menu">
              <HeaderDropdown handleClose={handleClose} portal={PORTAL} />
            </ul>
          </div>
        </div>

        <div className="hide-desktop mobile-menu">
          <a href="##" onClick={(e) => handleMenuOpen(e)}>
            <i className="icon-menu" />
          </a>

          <ul className={opened ? 'menu-overlay opened' : 'menu-overlay'}>
            <HeaderDropdown portal={PORTAL} handleClose={handleClose} />
          </ul>
        </div>

      </div>
    </div>

  );
};

export default TheHeader;
