import React, { FC, useState } from 'react';
import ConfirmAuctionBidModal from 'modals/ConfirmAuctionBidModal';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import FormattedPrice from 'SHARED/components/Price';
import useTimezoneDate from 'SHARED/hooks/useTimezoneDate';
import { Bid } from 'SHARED/types/offerTypes';

interface IBidForAcceptRowProps {
  bid: Bid
  handleAcceptBid: (bidId: string | number) => void
  waitDecisionUntil: number | null | undefined
}

export const BidForAcceptRow: FC<IBidForAcceptRowProps> = ({
  bid,
  handleAcceptBid,
  waitDecisionUntil,
}) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const handleConfirmModalOpen = () => { setConfirmModalOpen(true); };
  const handleConfirmModalClose = () => { setConfirmModalOpen(false); };
  const handleAcceptBidClick = () => {
    handleAcceptBid(bid.id);
    handleConfirmModalClose();
  };

  const timezoneDate = useTimezoneDate();

  return (
    <>
      <div className="bid-for-accept-row row">
        <div className="col"><DataItemPreview item={bid.created} isDate title="BID DATE" /></div>
        <div className="col"><FormattedPrice value={bid.value} title="BID" prefix={bid.currency} suffix="/mt" /></div>
        <div className="col"><DataItemPreview item={bid.bidVolume} title="BID VOLUME" suffix="mt" /></div>
        <div className="col"><FormattedPrice value={bid.offerTotalPrice} title="Total" prefix={bid.currency} boldPrice /></div>
        <div className="col">
          <button
            type="button"
            className="btn-primary"
            onClick={handleConfirmModalOpen}
            disabled={bid.hasDeal}
          >
            Accept
          </button>
        </div>
        <div className="col">
          <span>can accept till </span>
          <span>
            {timezoneDate({ date: waitDecisionUntil })}
          </span>
        </div>
      </div>

      {confirmModalOpen && (
        <ConfirmAuctionBidModal
          bid={bid}
          handleClose={handleConfirmModalClose}
          handleAcceptBid={handleAcceptBidClick}
        />
      )}
    </>
  );
};

export default BidForAcceptRow;
