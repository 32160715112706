import React, { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';
import DataItemPreview from './DataItemPreview';

interface IProps {
  name: string,
  label: string,
  displayValue: string | number | null | undefined,
  fieldValue: string | number | object | null | undefined,
  className?: string,
  requiredMessage?: string,
}

const FormOutput: FC<IProps> = ({
  name,
  label,
  displayValue,
  fieldValue,
  className,
  requiredMessage,
}) => {
  const { register, setValue, formState } = useFormContext();
  const { errors } = formState;

  useEffect(() => {
    setValue(name, fieldValue);
  }, [fieldValue]);

  return (
    <>
      <div className={clsx('form-output form-input', className)}>
        <output
          {...register(name, { required: requiredMessage || 'Required' })}
          className="visually-hidden"
        />

        <DataItemPreview title={label} item={displayValue} />

        {errors[name]?.message && <div className="error-message">{errors[name]?.message}some error</div>}
      </div>
    </>
  );
};

export default FormOutput;
