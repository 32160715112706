import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { store } from 'SHARED/redux/store';
import App from './App';
import 'SHARED/assets/styles/index.scss';

// Tanstack Query Client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      // staleTime: Infinity,
    },
  },
});

const jsx = (
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </Provider>
  </QueryClientProvider>
);

ReactDOM.render(jsx, document.querySelector('#root'));
