import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { useActions } from 'SHARED/redux/hooks/useActions';
import Preloader from 'SHARED/components/ThePreloader';
import FormInput from 'SHARED/components/FormInput';
import SingleCheckbox from 'SHARED/components/Checkbox';
import validationRules from 'SHARED/helpers/validation';
import { FormProvider, useForm } from 'react-hook-form';
import { tooltips } from 'SHARED/helpers/texts';
import { OnspecOfferTypes } from 'SHARED/types/offerTypes';
import notification from 'SHARED/helpers/notifications';
import axios from 'SHARED/helpers/axios';
import { isMobile } from 'react-device-detect';
import PriceInput from 'SHARED/components/PriceInput';
import ZonedDatePicker from 'SHARED/components/ZonedDatePicker/ZonedDatePicker';

interface RouteParams {
  id: string,
  type: string
}

interface EditForm {
  priceEurDisabled?: boolean,
  priceEur: string | null,
  priceUsdDisabled?: boolean,
  priceUsd: string | null,
  remainingVolume: string,
  splittable: boolean,
  type: OnspecOfferTypes,
  endDate: Date,
}

interface Payload {
  priceEur: number | null,
  priceUsd: number | null,
  remainingVolume: number,
  splittable: boolean,
  type: OnspecOfferTypes,
  id: string | number,
  endDate: number,
}

const OnSpecEdit: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<RouteParams>();

  const { ON_SPEC, error: offerError } = useTypedSelector((state) => state.offer);
  const { getOfferDetails, resetOffer } = useActions();

  const {
    type: offerType,
    priceEur,
    priceUsd,
    splittable,
    endDate,
    id: offerId,
    productCategory,
    productType,
    remainingVolume,
  } = ON_SPEC;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getOfferDetails({ id, offerType: 'ON_SPEC' });

    // when leaving detail page
    // set default state for offer details
    return () => {
      resetOffer();
    };
  }, [id]);

  useEffect(() => {
    if (offerError) {
      history.push('/onspec/offers/');
    }
  }, [offerError]);

  const methods = useForm<EditForm>();
  const { register } = methods;
  const { formState: { isDirty } } = methods;

  const makeNumber = (val: string) => parseFloat(val.replace(',', '.'));
  // previous makeNumber func wasn't ready for different types of values
  const preparePrice = (price: string | number | null) => {
    if (price === null) {
      return null;
    }
    if (typeof price === 'string') {
      return makeNumber(price);
    }
    return price;
  };

  const onSubmit = async (data: EditForm) => {
    const editFormData = { ...data };

    // ? single currency guard
    // if (editFormData.priceUsdDisabled && editFormData.priceEurDisabled) { return false; }
    // if (editFormData.priceEurDisabled && !editFormData.priceUsdDisabled) { editFormData.priceEur = null; }
    // if (editFormData.priceUsdDisabled && !editFormData.priceEurDisabled) { editFormData.priceUsd = null; }

    const payload: Payload = {
      ...editFormData,
      priceEur: preparePrice(editFormData.priceEur),
      priceUsd: preparePrice(editFormData.priceUsd),
      remainingVolume: makeNumber(editFormData.remainingVolume),
      id: offerId,
      endDate: new Date(editFormData.endDate).getTime(),
    };

    setLoading(true);

    try {
      await axios.put('/v1/offers', payload);

      notification({
        type: 'success',
        title: 'Offer Update',
        message: 'Offer has been updated successfully',
      });
      history.goBack();
    } catch (error: any) {
      notification({
        type: 'warning',
        title: 'Error',
        message: 'Error during updating offer',
      });
    } finally {
      setLoading(false);
    }

    return false;
  };

  const handleDiscard = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    history.goBack();
  };

  if (!offerId) {
    return <Preloader isLoading />;
  }

  const isAlreadyProduced = offerType?.value === 'ALREADY_PRODUCED';
  const offerTitle = `${offerType?.label}: ${productCategory?.label} ${isAlreadyProduced ? productType?.label : ''}, ${remainingVolume} mt remaining `;

  return (
    <>
      <Helmet>
        <title>{`Edit offer ${offerTitle}`}</title>
      </Helmet>

      <Preloader isLoading={loading} />

      <div className="breadcrumbs">
        <a href="#move-back" onClick={(e) => handleDiscard(e)}>&lt; Back </a>
      </div>

      <div className="page-body is-logged-in preview-offer-page">
        <h1 className="page-title">
          {`Edit ${offerTitle}`}
        </h1>
        <p>
          If you need to change any other specifics, we advise you to cancel this offer and create a new one.
        </p>
        <p>Changing volume, the splittable option and/or pricing does not affect existing deals made within this offer.</p>

        <h2 className="page-section-title">
          Volume and price
        </h2>

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>

            <input type="hidden" {...methods.register('type')} value={offerType?.value} />

            <FormInput
              name={register('remainingVolume').name}
              label="REMAINING VOLUME"
              value={remainingVolume?.toString()}
              className="short"
              type="number"
              suffix="MT"
              tooltip="Please offer volumes that equal one or more container volumes."
              rules={validationRules.required}
            />

            <SingleCheckbox
              className={splittable ? 'disabled' : ''}
              title="SPLITTABLE"
              label="Splittable per container / full truck load"
              name={register('splittable').name}
              defaultChecked={splittable}
            />

            <div className="inputs-range-wrapper">
              {/* Single currency block --> backend not ready for it */}
              {/* // ? Offer will break if we change currency while having negotiation */}
              {/* <SingleCurrencyInput
                name="priceEur"
                label="ASKING PRICE"
                value={priceEur}
                tooltip={tooltips.askingPriceEur}
                currencyName="EUR"
                currencyDisabledDefault={!priceEur}
              />
              <SingleCurrencyInput
                name="priceUsd"
                currency="$"
                label="ASKING PRICE"
                value={priceUsd}
                tooltip={tooltips.askingPriceUsd}
                currencyName="USD"
                currencyDisabledDefault={!priceUsd}
              /> */}

              {priceEur && (
                <PriceInput
                  name={register('priceEur').name}
                  label="ASKING PRICE"
                  value={priceEur?.toString()}
                  tooltip={tooltips.askingPriceEur}
                />
              )}

              {priceUsd && (
                <PriceInput
                  name={register('priceUsd').name}
                  currency="$"
                  label="ASKING PRICE"
                  value={priceUsd?.toString()}
                  tooltip={tooltips.askingPriceUsd}
                />
              )}
            </div>

            <h2 className="page-section-title">
              Offer expiration
            </h2>

            {/* <FormDatePicker
              name={register('endDate').name}
              showTimeInput
              inline={isMobile}
              // maxDate={new Date(expirationDate)}
              value={endDate}
              dateFormat="dd MMMM yyyy HH:mm"
              timezone="CET"
            /> */}

            <ZonedDatePicker
              name={register('endDate').name}
              showTimeInput
              inline={isMobile}
              // maxDate={new Date(expirationDate)}
              value={endDate}
              dateFormat="dd MMMM yyyy HH:mm"
            />

            <button type="submit" disabled={!isDirty} className="btn-primary">Update offer</button>
            <button type="button" onClick={(e) => handleDiscard(e)} className="btn-secondary">Discard</button>
            <br />
            <br />

          </form>
        </FormProvider>

      </div>

    </>
  );
};

export default OnSpecEdit;
