/* eslint-disable import/prefer-default-export */
import { CharacteristicItem, PayloadCharacteristicItem } from 'SHARED/types/specsTypes';

type PreparedProduct = (chs:CharacteristicItem[]) => (PayloadCharacteristicItem | null)[]; // TODO: check this clearly

export const prepareCharacteristics:PreparedProduct = (chs) => {
  // console.log(chs);

  const handledCharacteristics = chs.map((ch) => { // TODO -> refactor me
    if (!ch) return null;
    let value;
    if (ch.value === (null || undefined)) {
      value = null;
    } else if (typeof ch.value !== 'string') {
      // ? next two rows are original by Pavel, currently they don't work
      // // @ts-expect-error:
      // value = ch.value.value;
      // @ts-expect-error:
      value = ch.value.value ? ch.value.value : ch.value;
    } else {
      value = ch.value ? parseFloat(ch.value.replace(',', '.')) : null;
    }

    const characteristicSpecId = parseInt(ch.characteristicSpecId, 10);

    // valueTo logic -- duplicate functional breaks with old range characteristics
    let valueTo = null;
    if (typeof ch.valueTo === 'string') {
      valueTo = parseFloat(ch.valueTo.replace(',', '.'));
    }
    if (typeof ch.valueTo === 'number') {
      valueTo = ch.valueTo;
    }
    // const valueTo = ch.valueTo ? parseFloat(ch.valueTo.replace(',', '.')) : null; // ? old logic, not compatible now

    const newElement:PayloadCharacteristicItem = {
      ...ch,
      characteristicSpecId,
      // // @ts-expect-error:
      value,
      valueTo,
    };
    return newElement;
  });

  return handledCharacteristics.filter((el) => el != null);
};
