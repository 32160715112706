import React, { useEffect } from 'react';
import { tooltips } from 'SHARED/helpers/texts';
import FormInput from 'SHARED/components/FormInput';
import SingleCheckbox from 'SHARED/components/Checkbox';
import Select from 'SHARED/components/Select';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import validationRules from 'SHARED/helpers/validation';
import { BuyerPaymentTerm, OnspecOfferTypes } from 'SHARED/types/offerTypes';
import { useFormContext } from 'react-hook-form';
import SingleCurrencyInput from 'SHARED/components/SingleCurrencyInput';

interface Props {
  offerType: OnspecOfferTypes,
  isForRfp: boolean,
}

const VolumePriceSection: React.FC<Props> = ({ offerType, isForRfp }) => {
  // ********************* REDUX section *********************
  const offer = useTypedSelector((state) => state.offer.ON_SPEC);
  const { dictionaries } = useTypedSelector((state) => state.dictionaries);
  const { PAYMENT_TERMS } = dictionaries;

  const methods = useFormContext();

  const { watch, setValue, formState: { dirtyFields } } = methods;
  const choosedPaymentTerms: BuyerPaymentTerm = watch('paymentTerms');
  // console.log(choosedPaymentTerms?.offsetDays);

  useEffect(() => {
    // console.log(choosedPaymentTerms);
    if (dirtyFields.paymentTerms) {
      setValue('paymentOffset', null);
    }
  }, [choosedPaymentTerms]);

  const {
    splittable,
    priceEur,
    priceUsd,
    totalVolume,
    paymentTerms,
    paymentOffset,
    priceEurDisabled,
    priceUsdDisabled,
  } = offer;

  return (
    <>

      {/* ********************************* Volume and price *********************************** */}

      <h2 className="page-section-title">
        Volume and price
      </h2>

      <FormInput
        name="totalVolume"
        label="VOLUME"
        value={totalVolume}
        className="short"
        type="number"
        suffix="MT"
        tooltip="Please offer volumes that equal one or more container volumes."
        rules={validationRules.required}
      />

      {!isForRfp && (
        <SingleCheckbox
          title="SPLITTABLE"
          label="Splittable per container / full truck load"
          name="splittable"
          defaultChecked={splittable}
        />
      )}

      {/* <div>Please  add the base price of your offer. Premiums are added to your offer automatically and buyers will only see the total price.</div> */}

      <div className="inputs-range-wrapper onspec">
        <SingleCurrencyInput
          name="priceEur"
          label="ASKING PRICE"
          value={priceEur}
          tooltip={tooltips.askingPriceEur}
          currencyName="EUR"
          currencyDisabledDefault={priceEurDisabled}
        />
        <SingleCurrencyInput
          name="priceUsd"
          currency="$"
          label="ASKING PRICE"
          value={priceUsd}
          tooltip={tooltips.askingPriceUsd}
          currencyName="USD"
          currencyDisabledDefault={priceUsdDisabled}
        />
      </div>

      <div className={choosedPaymentTerms?.hasDayOffset ? 'financing-row' : ''}>
        <div className="payment-term">
          <Select
            label="PAYMENT TERMS"
            name="paymentTerms"
            options={PAYMENT_TERMS}
            selected={paymentTerms}
          />
        </div>

        {choosedPaymentTerms?.hasDayOffset && (
          <div className="days-offset">
            <Select
              label="NUMBER OF DAYS"
              name="paymentOffset"
              options={choosedPaymentTerms.offsetDays.map((d) => ({ value: `${d}`, label: `${d} days` }))}
              selected={paymentOffset || null}
            />
          </div>
        )}
      </div>

    </>
  );
};

export default VolumePriceSection;
