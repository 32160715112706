import React, { useState, useEffect } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { useActions } from 'SHARED/redux/hooks/useActions';
import Preloader from 'SHARED/components/ThePreloader';
import Menu from 'SHARED/components/Menu';
import { Helmet } from 'react-helmet-async';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import FormattedPrices from 'SHARED/components/Prices';
import routes from 'SHARED/types/routes';
import OffspecOfferSummary from './OffspecOfferSummary';
import SellerBidTab from './SellerBidTab';

interface RouteParams {
  id: string,
  type: string
}

interface Props {
  isLoggedIn: boolean,
}

const menuItems = [
  { label: 'Bids', link: 'bids' },
  { label: 'Details', link: 'details' },
];

const OffspecOfferPage: React.FC<Props> = ({ isLoggedIn }) => {
  const history = useHistory();

  const { id, type } = useParams<RouteParams>();

  const { OFF_SPEC, loading } = useTypedSelector((state) => state.offer);
  const { getOfferDetails, resetOffer } = useActions();

  useEffect(() => {
    fetchOfferDetails();
    return () => {
      resetOffer();
    };
  }, []);

  const fetchOfferDetails = async () => {
    try {
      getOfferDetails({ id, offerType: 'OFF_SPEC' });
    } catch (err) {
      console.log(err);
      history.push(`/offspec/${type}`);
    }
  };

  const [activeTab, setActiveTab] = useState<string>('bids');

  const changeTabAction = (tab: string) => {
    setActiveTab(tab);
  };

  // useEffect(() => {
  //   if (!isLoggedIn) {
  //     history.push('/login');
  //   }
  // }, [isLoggedIn, history]);

  const {
    statusLabel,
    title,
    totalVolume,
    priceEur,
    priceUsd,
    bidders,
    endDate,
    created,
    type: offerType,
  } = OFF_SPEC;

  return (
    <>
      <Helmet>
        <title>{OFF_SPEC?.title}</title>
      </Helmet>

      <Preloader isLoading={loading} />

      <div className="breadcrumbs">
        <Link to={`/offspec/${type}`}>&lt; All auctions </Link>
      </div>

      <div className="page-body is-logged-in preview-offer-page">
        <h1 className="page-title">
          {`${offerType?.label ?? 'Offer'}: ${title}, ${totalVolume} mt`}

          {offerType?.value === 'OFF_SPEC' && (
          <div className="buttons">
            <a
              href={routes.offspec.duplicate(id)}
              className="btn-icon"
              title={`Duplicate "${offerType?.label}"`}
            >
              <i className="icon-duplicate-2" />
            </a>
          </div>
          )}
        </h1>

        {/* *********************** offer auction details ***********************  */}

        <div className="offer-auction-details">
          <div>
            <DataItemPreview item={statusLabel} title="AUCTION STATUS" />
            <DataItemPreview datesRange={[created, endDate]} title="AUCTION DATES" />
          </div>

          <div>
            <FormattedPrices title="ASK PRICE" values={[priceEur, priceUsd]} />
            <DataItemPreview item={bidders} title="BIDDERS" />
          </div>

        </div>

        <Menu
          items={menuItems}
          view="in-page-tabs"
          activeLink={activeTab}
          changeTabAction={changeTabAction}
        />

        {activeTab === 'bids' && <SellerBidTab />}
        {activeTab === 'details' && <OffspecOfferSummary />}
      </div>
    </>
  );
};

export default OffspecOfferPage;
