import { OfferListItem, OfferTableItem } from 'SHARED/types/offerTypes';
import { OfferListTypes } from 'SHARED/pages/OffersList';

// to iterate trough it in table and cards
export const proposalsBuyer = (offer: OfferListItem, activeTab: OfferListTypes) => {
  const {
    currency,
    isApproved,
    totalVolume,
    price,
    statusLabel,
    agreedOnDelivery,
    sellerOrg,
    sellerOrgCountry,
  } = offer;

  // const departure = departurePeriod === 'MONTH' ? handleDate({ date: departureFrom, format: 'MMM YYYY' }) : handleDateRange({ from: departureFrom, to: departureTo });
  const delivery = agreedOnDelivery ? 'As requested' : 'Other location';

  // creating offer list item data
  // for table and cards
  const activeOffers: OfferTableItem[] = [
    { key: 'price', value: price, prefix: currency, label: 'PRICE' },
    { key: 'volume', value: `${totalVolume} mt`, label: 'VOLUME' },
    { key: 'seller', value: sellerOrg || '-', label: 'SELLER', isApproved },
    { key: 'country', value: sellerOrgCountry, label: 'COUNTRY' },
    { key: 'delivery', value: delivery, label: 'DELIVERY' },
    { key: 'status', value: statusLabel, label: 'STATUS' },
  ];

  return activeOffers;
};
