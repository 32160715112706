import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import DropDownMenu from 'SHARED/components/DropDownMenu';
import routes from 'SHARED/types/routes';

interface ITitlesProps {
  isOffspec: boolean
  isOnspec: boolean
  isRfp: boolean
  isBuyer: boolean
  isSeller: boolean
  activeTab: 'offers' | 'archived-offers' | 'deals'
}

export const Titles: FC<ITitlesProps> = ({ isOffspec, isOnspec, isRfp, isBuyer, isSeller, activeTab }) => {
  let PageTitle = 'Offers';

  const TYPE = isOffspec ? 'auctions' : 'offers';

  switch (activeTab) {
    case 'archived-offers':
      PageTitle = `Archived ${TYPE}`;
      break;

    case 'deals':
      PageTitle = 'Deals made by you';
      break;

    default:
      PageTitle = `Active ${TYPE}`;
      break;
  }

  // if (isRfp) PageTitle = 'RFPs';
  if (isRfp) {
    switch (activeTab) {
      case 'archived-offers':
        PageTitle = 'Expired RFPs';
        break;

      default:
        PageTitle = 'Active RFPs';
        break;
    }
  }

  return (
    <div className="page-top">
      <Helmet>
        <title>{`${PageTitle} - OpenDairy`}</title>
      </Helmet>
      {/* **************** SELLER section ****************  */}
      {isOffspec && isSeller && (
      <>
        <h1 className="page-title">Auctions by our company</h1>
        <Link type="button" to={routes.offspec.create} className="btn-primary">Create auction</Link>
      </>
      )}

      {isOnspec && isSeller && (
      <>
        <h1 className="page-title">
          Offers and deals by our company
        </h1>
        <DropDownMenu />
      </>
      )}

      {isOffspec && isBuyer && (
      <h1 className="page-title">Auctions and deals</h1>
      )}

      {isOnspec && isBuyer && (
      <h1 className="page-title">
        Offers and deals
      </h1>
      )}

      {isRfp && isBuyer && (
      <>
        <h1 className="page-title">
          Our Requests For Proposals (RFP)
        </h1>
        <Link type="button" to={routes.rfp.create} className="btn-primary">Create RFP</Link>
      </>
      )}

      {isRfp && isSeller && (
        <h1 className="page-title">
          Requests For Proposals (RFP)
        </h1>
      )}
    </div>
  );
};

export default Titles;
