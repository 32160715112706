import _ from 'lodash';
import React, { Fragment } from 'react';
import CharacteristicInput from 'SHARED/components/CharacteristicInput';
import { CharacteristicItem } from 'SHARED/types/specsTypes';

export interface CharacteristicsProps {
  characteristics: CharacteristicItem[],
  duplicateOf?: CharacteristicItem[] | null
  isForBuyer: boolean,
}

const CharacteristicsList: React.FC<CharacteristicsProps> = ({
  characteristics,
  isForBuyer = false,
  duplicateOf = null,
}) => {
  // TODO: this should be either global helper or moved into characteristic input
  // ? because this logic might be needed in `Create offer form` - when we returning from preview to form
  const initValueToInputValue = (initValue: number | string | null | undefined) => {
    if (initValue === (undefined || null)) {
      return null;
    }
    return initValue;
  };

  const charsWithPrefill = characteristics.map((char) => {
    const charWithPrefill: any = { ...char };
    const prefillMatch = duplicateOf?.find((duplicate) => duplicate.characteristicType.value === char.characteristicType.value);

    if (prefillMatch) {
      charWithPrefill.prefilledItem = {
        characteristicSpecId: prefillMatch.characteristicSpecId,
        notTested: prefillMatch.notTested,
        value: initValueToInputValue(prefillMatch.value),
        valueTo: initValueToInputValue(prefillMatch.valueTo),
        range: prefillMatch.range,
      };
    }

    return charWithPrefill;
  });

  // console.log('charsWithPrefill', charsWithPrefill);

  // sort by display order field
  // const sortedCharacteristics = _.sortBy(characteristics, 'displayGroup.order'); // ? original, with pure characteristics
  const sortedCharacteristics = _.sortBy(charsWithPrefill, 'displayGroup.order');
  // grouping characteristics list by displayGroup field
  const groupedCharacteristics = _.groupBy(sortedCharacteristics, 'displayGroup.value');

  return (

    <>
      {Object.keys(groupedCharacteristics).map((key) => (
        <Fragment key={key}>
          <h2 className="page-section-title">
            {groupedCharacteristics[key][0].displayGroup.label}
          </h2>

          {groupedCharacteristics[key].map((ch) => (
            <CharacteristicInput
              key={ch.id}
              characteristic={ch}
              prefilledItem={ch.prefilledItem}
              isForBuyer={isForBuyer}
            />
          ))}
        </Fragment>
      ))}
    </>

  );
};

export default CharacteristicsList;
