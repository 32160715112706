import { useHistory, Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import moment from 'moment-timezone';
import FormInput from 'SHARED/components/FormInput';
import Checkbox from 'SHARED/components/Checkboxes';
import RadioGroup from 'SHARED/components/RadioGroup';
import FormSelect from 'SHARED/components/Select';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { useActions } from 'SHARED/redux/hooks/useActions';
import FormMultiSelect from 'SHARED/components/MultiSelect';
import { PreventLossUnsavedData } from 'SHARED/hooks/usePreventReload';
import validationRules from 'SHARED/helpers/validation';
import { preparePreview } from 'SHARED/helpers/prepareOfferData';
import { Helmet } from 'react-helmet-async';
import Preloader from 'SHARED/components/ThePreloader';
import texts, { tooltips } from 'SHARED/helpers/texts';
import {
  BuyersFetchType,
  BuyerPaymentTerm,
  Dictionary,
} from 'SHARED/types/offerTypes';
import routes from 'SHARED/types/routes';
import cloneDeep from 'lodash.clonedeep';
import {
  buyersFetchType,
  volumeUnits,
} from 'SHARED/helpers/OfferData';
import 'react-datepicker/dist/react-datepicker.css';
import { isMobile } from 'react-device-detect';
import { clearProxyFields, isDevEnv, MpcDocLink } from 'SHARED/helpers/common';
import { DevTool } from '@hookform/devtools';
import { groupBy } from 'lodash';
import prepareBuyerGroups from 'SHARED/helpers/prepareBuyerGroups';
import clsx from 'clsx';
import ZonedDatePicker from 'SHARED/components/ZonedDatePicker/ZonedDatePicker';
import { SingleCheckbox } from 'SHARED/components/Checkbox';
import SingleCurrencyInput from 'SHARED/components/SingleCurrencyInput';
import FormFileInput from 'SHARED/components/FileInput/FormFileInput';
import useDuplicateAuction from './useDuplicateAuction';

// TODO: create full interface for form values
interface FormValues {
  paymentTerms?: BuyerPaymentTerm | null;
  paymentOffset?: Dictionary | null;

  [key: string]: any,
}

interface Props {
  isLoggedIn: boolean,
}

const OffSpecOfferCreate: React.FC<Props> = ({ isLoggedIn }) => {
  const history = useHistory();

  // ********************* REDUX selectors *********************
  const { me } = useTypedSelector((state) => state.users);
  const { OFF_SPEC, isFilesUploading } = useTypedSelector((state) => state.offer);
  const { dictionaries, locations } = useTypedSelector((state) => state.dictionaries);
  const {
    getDictionaries,
    saveOffer,
    resetOffer,
    getLocations,
  } = useActions();

  useEffect(() => {
    if (me?.orgId) {
      getDictionaries({ offerType: 'OFF_SPEC' });
      getLocations({ type: 'LOGISTIC', orgId: me.orgId });
    }
  }, [me]);

  const methods = useForm<FormValues>();
  const { formState } = methods;

  // ********************* DATA section *********************
  const groupedLocations = groupBy(locations.LOGISTIC, 'type');
  // ********************* DATA section *********************

  // ********************* SUBMIT sections *********************
  const onSubmit = (data: FormValues) => {
    // makes some data converting such default radios or checkboxes
    // which needs to be converted to objects
    const clearedData = clearProxyFields(data);
    const prepared = preparePreview(cloneDeep(clearedData), dictionaries);

    // Temp solution
    // additional data needed for offer creation
    prepared.volumeUnits = volumeUnits;
    prepared.currency = 'EUR';
    prepared.documents = OFF_SPEC.documents;
    prepared.params = {
      salesTerms: prepared.salesTerms,
    };

    prepared.logisticLocationId = prepared.logisticLocation.id;

    // single currency (if currency is disabled -- set corresponding price to null)
    // but at least one price should be set
    if (prepared.priceUsdDisabled && prepared.priceEurDisabled) { return false; }
    if (prepared.priceEurDisabled && !prepared.priceUsdDisabled) { prepared.priceEur = null; }
    if (prepared.priceUsdDisabled && !prepared.priceEurDisabled) { prepared.priceUsd = null; }

    prepared.paymentTerms = data.paymentTerms;

    // manually update documents
    // because they are stored in the wrong fields
    prepared.documents = {
      COA: prepared.coaDocument,
      SALES_TERMS: prepared.termsDocument,
    };

    // in case if term has day offset
    // if (data.paymentTerms?.hasDayOffset) {
    //   prepared.paymentOffset = data.paymentOffset?.value;
    // }

    // if buyers groups selected, buyers list must be set from group
    if (prepared.groups?.length > 0) {
      prepared.buyers = prepareBuyerGroups(prepared.groups);
    }

    console.log('prepared auction', prepared);
    saveOffer({ offer: prepared, offerType: 'OFF_SPEC' });
    // return true;
    history.push(routes.offspec.preview);

    return true;
  };

  // handling user discard offer creation
  // 1 - reset offer's state in redux
  // 2 - redirect back to offers list page
  const handleDiscard = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    resetOffer();
    history.push(routes.offspec.defaultList);
  };

  // watch form values section
  // for dynamic condition rendering and properly date validation
  const { watch, setValue, formState: { isDirty, dirtyFields } } = methods;
  const termsType = watch('salesTerms');
  const isOwnTermsChoosed = termsType === 'OWN';
  const BuyersChoosedType: BuyersFetchType = watch('buyersFetchType', OFF_SPEC.buyersFetchType.value);
  const startDate = watch('loadDateFrom') || OFF_SPEC.loadDateFrom;
  const totalVolume = watch('totalVolume');
  const splittable = watch('splittable');
  const incotermsValue = watch('incoterms');
  const paymentTermsValue = watch('paymentTerms');

  // *************** AUCTION DUPLICATION **********************************************************
  const { duplicateLoading, duplicateMessage, duplicateError } = useDuplicateAuction();
  // *************** AUCTION DUPLICATION === END **************************************************
  // if form changed prevent closing browser tab
  // without prompting user
  PreventLossUnsavedData(isDirty);

  useEffect(() => {
    if (formState.dirtyFields?.incoterms) {
      setValue('logisticLocation', null);
    }
  }, [incotermsValue]);

  // in case user changed radio button back to include all
  // buyers list must be cleared
  useEffect(() => {
    if (BuyersChoosedType === 'INCLUDE_ALL') {
      setValue('buyers', []);
    }
  }, [BuyersChoosedType]);

  useEffect(() => {
    if (dirtyFields.paymentTerms) {
      setValue('paymentOffset', null);
    }
  }, [paymentTermsValue]);

  // wait until dictionaries will be fetched
  if (dictionaries.INCOTERM_SELLER.length === 0) { // TODO: investigate strange behaviour of loading redux state
    return <Preloader isLoading />;
  }

  // const buyersValidation = { required: BuyersChoosedType === 'INCLUDE_GROUP' ? "Select at least one buyer's group" : false, validate: validationRules.notAllSelected(BUYERS_LIST.length) };

  return (
    <>
      <Preloader isLoading={isFilesUploading || duplicateLoading} />

      {isDevEnv && <DevTool control={methods.control} placement="top-right" />}

      <div className="breadcrumbs">
        <Link to="/offspec/offers/">&lt; All auctions </Link>
      </div>

      <div className="page-body is-logged-in">
        <Helmet>
          <title>Create an auction</title>
        </Helmet>

        <div>
          <h1 className="page-title">Create an auction</h1>

          {/* this might work even better than ReactHookForm devtools */}
          {/* <pre className="rhf-devtools">{JSON.stringify(methods.getValues(), null, 2)}</pre> */}

          {duplicateMessage && !duplicateError && <div className="attention-message">{duplicateMessage}</div>}
          {duplicateError && <div className="attention-message alert">{duplicateError}</div>}

          <h2 className="page-section-title with-separator">
            Product
          </h2>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>

              <input type="hidden" {...methods.register('type')} value="OFF_SPEC" />

              <FormInput
                name="title"
                label="AUCTION TITLE"
                value={OFF_SPEC.title}
                tooltip={tooltips.offer_title}
                rules={validationRules.required}
              />

              <FormInput
                name="description"
                textarea
                label="DESCRIPTION"
                value={OFF_SPEC.description}
                tooltip={tooltips.offer_desc}
              />

              <FormFileInput
                name="coaDocument"
                label="PRODUCT COA DOCUMENT"
                defaultFiles={OFF_SPEC.documents.COA || null}
                acceptedFileTypes={['application/pdf']}
              />

              {/* ***************** checkboxes array ***************** */}
              <Checkbox
                label="DOCUMENT CAPABILITIES"
                name="documentCapabilities"
                values={dictionaries.DOCUMENT_TYPE}
                selected={OFF_SPEC.documentCapabilities}
                className="two-columns"
              />

              <FormSelect
                label="PACKAGING"
                name="packaging"
                options={dictionaries.PACKAGING_OPTIONS}
                selected={OFF_SPEC.packaging}
                defaultValue="25_kg_bags"
              />

              <RadioGroup
                label="SALES TERMS"
                name="salesTerms"
                defaultValue={OFF_SPEC.params.salesTerms}
                values={dictionaries.SALES_TERMS}
              />

              <div className="form-input">
                <div className="label-text">SALES TERMS DOCUMENT *</div>

                {!isOwnTermsChoosed && (
                  <a href={MpcDocLink} target="_blank" rel="noreferrer" className="document-link">
                    <i className="icon-flag" />
                    <span>MPC sales terms</span>
                  </a>
                )}

              </div>
              <div>
                <FormFileInput
                  className={clsx((termsType === 'MPC') && 'visually-hidden')}
                  name="termsDocument"
                  defaultFiles={OFF_SPEC.documents.SALES_TERMS}
                  acceptedFileTypes={['application/pdf']}
                />
              </div>

              {/* ********************************* Shipping *********************************** */}

              <h2 className="page-section-title">
                Shipping
              </h2>

              <FormSelect
                label="INCOTERMS"
                name="incoterms"
                options={dictionaries.INCOTERM_SELLER}
                selected={OFF_SPEC.incoterms}
                defaultValue="FCA"
              />

              <FormSelect
                label="SELLER INCOTERMS LOCATION"
                name="logisticLocation"
                options={groupedLocations[incotermsValue?.locationType] || []}
                selected={OFF_SPEC.logisticLocation}
                disabled={!incotermsValue?.locationType}
              />

              <Checkbox
                label="LOADING DETAILS"
                name="loadOptions"
                values={dictionaries.LOADING_DETAILS}
                selected={OFF_SPEC.loadOptions}
              />

              <div className="dates-range">
                <div className="label-text">PICK-UP DATE RANGE *</div>
                <div className="dates-range-wrapper">
                  <ZonedDatePicker
                    inline={isMobile}
                    name="loadDateFrom"
                    minDate={new Date()}
                    value={OFF_SPEC.loadDateFrom}
                    showTimezone={false}
                  />
                  <ZonedDatePicker
                    inline={isMobile}
                    name="loadDateTo"
                    minDate={moment(startDate).toDate()}
                    value={OFF_SPEC.loadDateTo}
                    rules={validationRules.endDate(startDate)}
                    showTimezone={false}
                  />
                </div>
              </div>

              <h2 className="page-section-title with-separator">
                Volume and price
              </h2>

              <FormInput
                name="totalVolume"
                label="VOLUME"
                value={OFF_SPEC.totalVolume}
                className="short"
                type="number"
                suffix="MT"
                rules={validationRules.required}
              />

              <SingleCheckbox
                title="SPLITTABLE"
                label="Splittable per container / full truck load"
                name="splittable"
                defaultChecked={OFF_SPEC.splittable}
              />

              {splittable && (
                <FormInput
                  name="minBidVolume"
                  label="MINIMUM BID VOLUME"
                  value={OFF_SPEC.minBidVolume}
                  className="short"
                  type="number"
                  suffix="MT"
                  tooltip={texts.tooltips.auctionMinBidVolume}
                  rules={validationRules.maxBidVolume(totalVolume)}
                />
              )}

              <SingleCurrencyInput
                name="priceEur"
                label="ASKING PRICE"
                value={OFF_SPEC.priceEur}
                tooltip={tooltips.auctionAskPriceEur}
                currencyName="EUR"
                currencyDisabledDefault={OFF_SPEC.priceEurDisabled}
                offerType="OFF_SPEC"
              />
              <SingleCurrencyInput
                name="priceUsd"
                currency="$"
                label="ASKING PRICE"
                value={OFF_SPEC.priceUsd}
                tooltip={tooltips.auctionAskPriceUsd}
                currencyName="USD"
                currencyDisabledDefault={OFF_SPEC.priceUsdDisabled}
                offerType="OFF_SPEC"
              />

              <>
                {/* payment terms */}
                <div className={clsx(paymentTermsValue?.hasDayOffset && 'financing-row', 'mb-1')}>
                  <div className="payment-term">
                    <FormSelect
                      label="PAYMENT TERMS"
                      name="paymentTerms"
                      options={dictionaries.PAYMENT_TERMS}
                      selected={OFF_SPEC.paymentTerms}
                    />
                  </div>

                  {paymentTermsValue?.hasDayOffset && (
                    <div className="days-offset">
                      <FormSelect
                        className="one-line-label"
                        label="NUMBER OF DAYS"
                        name="paymentOffset"
                        options={paymentTermsValue.offsetDays.map((d) => ({ value: `${d}`, label: `${d} days` }))}
                        selected={OFF_SPEC.paymentOffset || null}
                      />
                    </div>
                  )}
                </div>
              </>

              <h2 className="page-section-title with-separator">
                Buyers
              </h2>

              <RadioGroup
                name="buyersFetchType"
                defaultValue={OFF_SPEC.buyersFetchType}
                // values={[buyersFetchType[0], buyersFetchType[2]]}
                values={buyersFetchType}
              />

              {BuyersChoosedType === 'INCLUDE_GROUP' && dictionaries.BUYERS_GROUPS_LIST.length > 0 && (
                <FormMultiSelect
                  name="groups"
                  label="PICK SOME BUYER GROUPS FOR THIS AUCTION"
                  options={dictionaries.BUYERS_GROUPS_LIST}
                  value={OFF_SPEC.groups}
                  rules={validationRules.required}
                />
              )}
              {BuyersChoosedType === 'INCLUDE_GROUP' && dictionaries.BUYERS_GROUPS_LIST.length === 0 && (
                <div className="attention-message">Please create at least one Buyer&apos;s Group</div>
              )}

              <FormMultiSelect
                name="buyers"
                className={BuyersChoosedType !== 'EXCLUDE' ? 'hidden' : ''}
                rules={{ required: BuyersChoosedType === 'EXCLUDE' ? 'Select at least one buyer' : false, validate: validationRules.notAllSelected(dictionaries.BUYERS_LIST.length) }}
                label="EXCLUDE SOME BUYERS FROM SEEING THIS AUCTION"
                options={dictionaries.BUYERS_LIST}
                value={OFF_SPEC.buyers}
              />

              <h2 className="page-section-title with-separator">
                Auction end date
              </h2>

              <ZonedDatePicker
                name="endDate"
                showTimeInput
                inline={isMobile}
                minDate={new Date()}
                value={OFF_SPEC.endDate}
                dateFormat="dd MMMM yyyy HH:mm"
              />

              <button type="submit" className="btn-primary">Preview offer</button>
              <button type="button" onClick={(e) => handleDiscard(e)} className="btn-secondary">Discard</button>

            </form>
          </FormProvider>

        </div>
      </div>
    </>
  );
};

export default OffSpecOfferCreate;
