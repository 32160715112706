import React     from 'react';
import { Link } from 'react-router-dom';

const Logo: React.FC = () => (
  <div className="container">
    <Link to="/" className="the-logo">
      <img src="/images/logo-black.svg" alt="" />
    </Link>

  </div>
);

export default Logo;
