import React from 'react';
import { Link } from 'react-router-dom';

interface PropsSuccessAccept {
  handleClose: (value: React.SetStateAction<boolean>) => void,
  dealId: number | null,
  isRfp?: boolean
}

const SuccessAcceptModal: React.FC<PropsSuccessAccept> = ({
  handleClose,
  dealId,
  isRfp = false,
}) => {
  const dealUrl = isRfp ? `/rfp/deals/${dealId}` : `/onspec/deals/${dealId}`;

  return (
    <div className="popup">
      <div className="popup-overlay" />
      <div className="popup-body">
        <a href="##" className="popup-close" onClick={(e) => { handleClose(false); }}>
          close
        </a>

        <div>
          <h1>Congratulations!</h1>

          <div>
            <img src="/images/confetti.gif" height="300" alt="" />
          </div>

          <div>
            You can now visit the deal page to chat with the buyer and start exchanging documents. You can also find the buyer’s contact details on this page.
          </div>

          <div>
            <br />
            <Link to={dealUrl}>View deal page</Link>
            <br />
          </div>

        </div>
      </div>
    </div>
  );
};

export default SuccessAcceptModal;
