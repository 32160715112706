import React from 'react';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import { Link } from 'react-router-dom';
import OfferDisclaimer from 'SHARED/components/OfferDisclaimer';
import { getFullName } from 'SHARED/helpers/common';

export interface IOffspecOfferSummaryProps { }

const texts = {
  nextSteps: {
    seller: 'Please contact the buyer as soon as possible to arrange the fulfillment of the deal. If you need any assistance from the OpenDairy team please don\'t hesitate to contact us.',
    buyer: '',
  },
  common: {
    price: 'All prices are excl, VAT, excl. logistics and the OpenDairy fees',
  },
};

const OffspecDealSummary: React.FC<IOffspecOfferSummaryProps> = () => {
  const { OFF_SPEC } = useTypedSelector((state) => state.offer);

  const {
    title,
    description,
    documentCapabilities,
    packaging,
    incoterms,
    loadAddress,
    logisticLocation,
    loadOptions,
    loadDateFrom,
    loadDateTo,
    documents,
    winningBid,
    deal,
    params,
  } = OFF_SPEC;

  return (
    <>
      <div className="page-columns">
        <div className="page-column product-info">
          <div className="page-section-title">Product</div>
          <DataItemPreview item={title} title="OFFER TITLE" />
          <DataItemPreview item={description} title="DESCRIPTION" />
          {documents && documents.COA && <DataItemPreview file={documents.COA[0]} title="PRODUCT COA" />}
          <DataItemPreview item={documentCapabilities} title="DOCUMENTS INCLUDED" />
          <DataItemPreview item={packaging} title="PACKAGING" />
          <DataItemPreview item={params.salesTerms} title="SALES TERMS" />
          {documents && documents.SALES_TERMS && <DataItemPreview file={documents.SALES_TERMS[0]} title="SALES TERMS DOCUMENT" />}
          <br />
          <br />
          <Link type="button" to={`/offspec/offer/${OFF_SPEC.id}`}>Show offer details</Link>
        </div>

        <div className="page-column shipping-info">
          <div className="page-section-title">Shipping</div>
          <DataItemPreview item={incoterms} title="INCOTERMS" />
          <DataItemPreview datesRange={[loadDateFrom, loadDateTo]} title="PICK-UP DATE RANGE" />
          {!!loadAddress?.value && <DataItemPreview item={loadAddress} title="LOADING ADDRESS" />}
          {!!logisticLocation && <DataItemPreview item={logisticLocation?.fullAddress} title="SELLER INCOTERMS LOCATION" />}
          <DataItemPreview item={loadOptions} title="LOADING DETAILS" />

          <br />
          <br />
          <div className="page-section-title deal-info">Deal</div>
          <DataItemPreview item={deal?.number} title="DEAL ID" />
          <DataItemPreview item={deal?.created} isDate title="DEAL DONE ON" />
        </div>

        <div className="page-column buyer-info">
          <div className="page-section-title">Buyer</div>
          <DataItemPreview item={deal?.buyerOrg?.orgName} title="BUYER COMPANY" />
          <DataItemPreview item={deal?.buyerOrg?.country} title="COUNTRY" />
          <DataItemPreview item={winningBid?.actionTime} isDate title="LATEST BID" />
          <DataItemPreview item={getFullName(deal?.buyer?.profile)} title="BUYER NAME" />
          <DataItemPreview item={deal?.buyer?.profile?.email} title="EMAIL" isMailLink />
          <DataItemPreview item={deal?.buyer?.profile?.phoneNumber} title="TELEPHONE" />

        </div>
        <div className="page-column seller-info">
          <div className="page-section-title">Seller</div>
          <DataItemPreview item={getFullName(deal?.seller?.profile)} title="SELLER NAME" />
          <DataItemPreview item={deal?.seller?.profile?.email} title="EMAIL" isMailLink />
          <DataItemPreview item={deal?.seller?.profile?.phoneNumber} title="TELEPHONE" />
        </div>
        <div className="description-text">
          <div className="page-section-title">This is what will happen next:</div>

          <div>{texts.nextSteps.seller}</div>

          <br />

          <OfferDisclaimer userType="seller" />
        </div>
      </div>
    </>
  );
};

export default OffspecDealSummary;
