import React           from 'react';
import { Link } from 'react-router-dom';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import FormattedPrice  from 'SHARED/components/Price';
import { HistoryAction, OfferHistoryItem }  from 'SHARED/types/offerTypes';

interface Props {
  history: OfferHistoryItem[] | null
}

interface OfferProps {
  created: number | null,
  volume: number | undefined,
  action: HistoryAction
}

interface DealProps {
  created: number | undefined,
  volume: number | undefined,
  price: number | undefined,
  buyer: string | undefined,
  curency: string | undefined,
  dealId: number | undefined,
}

const Deal: React.FC<DealProps> = ({
  created, volume, buyer, curency, price, dealId,
}) => (
  <div className="page-columns bid-item one-line">
    <DataItemPreview className="page-column" isDate item={created} title="DEAL DONE" />
    <DataItemPreview className="page-column" item={buyer} title="BUYER" />
    <FormattedPrice className="page-column" value={price} prefix={curency} suffix="/ mt" title="DEAL PRICE" />
    <DataItemPreview className="page-column" item={volume} title="DEAL VOLUME" />
    <div className="page-column"><Link to={`/onspec/deals/${dealId}`}> View this deal</Link></div>
  </div>
);

const Offer: React.FC<OfferProps> = ({ created, volume, action }) => (
  <div className="page-columns bid-item one-line">
    <DataItemPreview className="page-column" isDate item={created} title={action === 'CREATE' ? 'OFFER CREATED' : 'OFFER UPDATED'} />
    <DataItemPreview className="page-column" item={volume} title={action === 'CREATE' ? 'OFFER VOLUME' : 'REMAINING VOLUME'} />
  </div>
);

const OfferDealsTab: React.FC<Props> = ({ history }) => (
  <>
    <div className="bid-items deals">
      {history && history.map(({ actionTime, params, action }) => {
        if (action === 'MAKE_DEAL') {
          return (
            <Deal
              key={params.dealId}
              created={actionTime}
              volume={params.dealVolume}
              price={params.dealPrice}
              curency={params.dealCurrency}
              buyer={params.buyerOrg}
              dealId={params.dealId}
            />
          );
        }
        return (
          <Offer
            created={actionTime}
            volume={params.remainingVolume}
            action={action}
            key={actionTime}
          />
        );
      })}

    </div>
  </>
);

export default OfferDealsTab;
