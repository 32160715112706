import React, { Fragment } from 'react';
import { CounterBid } from 'SHARED/types/offerTypes';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import CommonBidFields from 'SHARED/components/Offer/CommonBidFields';
import OfferFields from 'SHARED/components/Offer/OfferFields';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { OfferBid } from 'SHARED/types/bidTypes';
import './styles/bid-history.scss';
import OfferCancellationFields from 'SHARED/components/Offer/OfferCancellationFields';
import { cloneDeep, sortBy } from 'lodash';
import moment from 'moment-timezone';

interface Props {
  handleClose: (value: React.SetStateAction<OfferBid[] | null>) => void,
  history: OfferBid[]
  buyer: string,
}

const BidHistory: React.FC<Props> = ({
  handleClose,
  history,
  buyer,
}) => {
  const { ON_SPEC } = useTypedSelector((state) => state.offer);
  const {
    created,
    totalVolume,
    description,
    priceUsd,
    priceEur,
    ownerOrg,
    currency,
    price,
  } = ON_SPEC;
  const { me } = useTypedSelector((state) => state.users);

  // flags
  const isSeller = me.type === 'SELLER';
  const isSimpleOffer = ON_SPEC.type?.value === 'SIMPLE_ALREADY_PRODUCED';
  // flags - end

  const originalOffer: CounterBid = { // TODO: refactor temp solution
    created: created || 0,
    volume: totalVolume || 0,
    message: '', // ? not needed for original offer
    description,
    sellerName: ownerOrg.orgName,
    validTo: null,
    netPrice: isSeller ? undefined : (price || 0),
    acceptable: true, // TODO: fix it
  };

  const prices = isSeller ? [priceEur, priceUsd] : undefined;

  // bids to render
  const unsortedHistory = cloneDeep(history);
  const historyWithParsedDates = unsortedHistory
    .map((bid) => {
      bid.created = moment(bid.created).valueOf();
      return bid;
    });
  const sortedHistory = sortBy(historyWithParsedDates, 'created');
  // bids to render - end

  return (
    <div className="popup">
      <div className="popup-overlay" />
      <div className="popup-body bid-history">
        <button
          type="button"
          className="popup-close"
          onClick={(e) => { handleClose(null); }}
        >
          close
        </button>

        <div className="bid-history-top">
          {/* <div className="page-section-title">BUYER</div> */}
          <DataItemPreview item={buyer} title="BUYER" isHeading />

        </div>
        <div className="bid-items">
          <div className="bid-item">
            <OfferFields
              offer={originalOffer}
              prices={prices}
              title="Original offer"
              currency={currency}
            />
          </div>

          {sortedHistory.map((bid) => (
            <Fragment key={bid.id}>
              <div className="bid-item">
                <CommonBidFields bidFields={bid} isSimpleOffer={isSimpleOffer} />
              </div>
              {bid.counterBid && (
                <div className="bid-item">
                  <OfferFields currency={bid.currency} offer={bid.counterBid} />
                </div>
              )}
            </Fragment>
          ))}

          {ON_SPEC.status === 'CANCELLED' && (
            <div className="bid-item">
              <OfferCancellationFields offer={ON_SPEC} />
            </div>
          )}
        </div>

      </div>
    </div>
  );
};

export default BidHistory;
