import React from 'react';
import MatchedProducts from 'SHARED/components/Offer/MatchedProducts';
import FormattedPrice from 'SHARED/components/Price';
import { OfferTableItem } from 'SHARED/types/offerTypes';

interface ICarfFieldProps {
  fieldData: OfferTableItem;
}

const CardField: React.FC<ICarfFieldProps> = ({ fieldData }) => {
  const { key, label, isSplittable, prefix, value, matchedProducts = [] } = fieldData;

  let displayValue: any = value;

  switch (key) {
    case 'type':
      displayValue = (
        <>
          {fieldData.isAnonymous && <i className="icon-eye-slash inline-icon" />}
          {value}
        </>
      );
      break;

    case 'volume':
      displayValue = (
        <>
          {isSplittable && <i className="icon-split" />}
          <span>{value}</span>
        </>
      );
      break;

    case 'price':
      displayValue = <FormattedPrice suffix="/mt" prefix={prefix} value={value} />;
      break;

    case 'ourLastBid':
      displayValue = <FormattedPrice suffix="/mt" prefix={prefix} value={value} />;
      break;

    case 'product':
      displayValue = (
        <>
          {value}
          {
            matchedProducts.length > 0
              ? <MatchedProducts products={matchedProducts} />
              : '-'
          }
        </>
      );
      break;

    default:
      displayValue = value || '-';
  }

  return (
    <div key={key} className={`column ${key}`}>
      <div className="label">{label}</div>
      <div className="value">
        {displayValue}
      </div>
    </div>
  );
};

export default CardField;
