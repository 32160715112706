import React from 'react';
import '../groups.scoped.scss';

export interface ITopSectionProps {
  isEditMode: boolean,
  removeGroup: () => void
}

const TopSection: React.FC<ITopSectionProps> = ({ isEditMode, removeGroup }) => (
  <div>
    {isEditMode
      ? (
        <h1>
          <span>Edit buyers group</span>
          <button type="button" onClick={(e) => removeGroup()} className="btn-icon">
            <i className="icon-delete" />
          </button>
        </h1>
      )
      : <h1>Add buyer group</h1>}

    <div className="form-input">
      A buyer group is a predefined list of buyers. It allows you to easily create selections of buyers to use in your offers and auctions.
    </div>
  </div>
);

export default TopSection;
