import React from 'react';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';

const RfpDealFinancingAgreed = () => {
  const { ON_SPEC } = useTypedSelector((state) => state.offer);

  const {
    id,
    parentOffer,
    agreedOnPayment,
    paymentTerms,
  } = ON_SPEC;

  // flags
  const isRfp = parentOffer?.type?.value === 'RFP';
  // flags === END

  if (!id || !isRfp || !agreedOnPayment) return null;

  return (
    <>
      <div className="attention-message no-margin">
        Seller agreed with requested payment terms.
      </div>
      <div className="mb-2" />

      <DataItemPreview
        title="Buyer payment terms"
        item={paymentTerms}
      />
    </>
  );
};

export default RfpDealFinancingAgreed;
