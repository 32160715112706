import React from 'react';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';

const OfferDealLogisticsNoHandler = () => {
  const { ON_SPEC } = useTypedSelector((state) => state.offer);

  const {
    id,
    agreedOnDelivery,
    deal,
    departureFrom,
    departureTo,
    incoterms,
    logisticLocation,
  } = ON_SPEC;

  const {
    shipping,
  } = deal || {};

  const {
    logisticHandler,
  } = shipping || {};

  if (!id || agreedOnDelivery || logisticHandler?.value) return null;

  return (
    <>
      <DataItemPreview
        title="Departure"
        datesRange={[departureFrom, departureTo]}
      />

      <DataItemPreview
        title="Seller incoterms"
        item={incoterms}
      />

      <DataItemPreview
        title="Seller location"
        item={logisticLocation?.fullAddress}
      />
    </>
  );
};

export default OfferDealLogisticsNoHandler;
