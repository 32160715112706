import React from 'react';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import FormattedPrice from 'SHARED/components/Price';
import { CounterBid } from 'SHARED/types/offerTypes';
import FormattedPrices from '../Prices';

interface Props {
  offer: CounterBid,
  title?: string,
  currency?: string
  prices?: (number | null)[] | undefined
}

const OfferFields: React.FC<Props> = ({
  offer,
  title = 'Counter offer',
  currency,
  prices,
}) =>

  (
    <>
      <div className="page-section-title">{title}</div>
      <div className="page-columns">
        <DataItemPreview className="page-column" item={offer.created} dateFormat="DD MMM YYYY HH:mm z" isDate title="OFFER PLACED" />
        <DataItemPreview className="page-column" item={offer.sellerName} title="SELLER" />
      </div>
      <div className="page-columns">
        {prices
          ? <FormattedPrices className="page-column" title="OFFER PRICE" values={prices} />
          : offer.netPrice && <FormattedPrice className="page-column" value={offer.netPrice} prefix={currency} suffix="/mt" title="OFFER PRICE" />}

        <DataItemPreview className="page-column" item={offer.volume} suffix="mt" title="OFFER VOLUME" />
      </div>

      {offer.message && offer.message.length > 0 && <DataItemPreview item={offer.message} title="MESSAGE" />}
      {offer.description && offer.description.length > 0 && <DataItemPreview item={offer.description} title="REMARKS FOR THE BUYER" />}
    </>
  );
export default OfferFields;
