import React from 'react';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { OfferListItem, PagesTypes } from 'SHARED/types/offerTypes';

interface ITextsProps {
  pageType: PagesTypes
  activeTab: 'offers' | 'archived-offers' | 'deals'
  offersList: OfferListItem[]
  isLoading: boolean
}

const NO_OFFERS_TEXTS = {
  SELLER: {
    onspec: {
      active: 'You have no active offers at this moment. Click on the Create Offer button to create your an offer!',
      archived: 'This is where your offers will be shown as soon as they are archived. Go ahead and create an offer by clicking on the Create Offer button!',
      deals: 'Any deals you have made will be shown here. Go ahead and create an offer by clicking on the Create Offer button and you will have your first deal soon!',
    },
  },
  BUYER: {
    onspec: {
      active: 'There are currently no active offers available. You can choose to send out a Request for Proposal to sellers. Please use the RFP tab to create one!',
      archived: 'This is the place where offers will appear once they have expired or have turned into a deal. You can always view the offers you have been negotiating on, regardless of whether you got the deal or not.',
      deals: 'As soon as you close a deal with a seller it will be visible here. So go ahead and close you first deal! ',
    },
  },
};

const Texts: React.FC<ITextsProps> = ({
  pageType,
  activeTab,
  offersList,
  isLoading,
}) => {
  const { me } = useTypedSelector((state) => state.users);

  const NO_OFFERS = offersList.length === 0 && !isLoading;

  if (!me.type) {
    return <div>-</div>;
  }

  if (me.type === 'ADMIN') {
    return <div>Please add Admin related texts...</div>;
  }

  return (
    <>
      {NO_OFFERS && (
        <div className="page-body">
          {pageType === 'onspec' && (
            <>
              {activeTab === 'offers' && (
                <>{NO_OFFERS_TEXTS[me.type][pageType].active}</>
              )}

              {activeTab === 'archived-offers' && (
                <>{NO_OFFERS_TEXTS[me.type][pageType].archived}</>
              )}

              {activeTab === 'deals' && (
                <>{NO_OFFERS_TEXTS[me.type][pageType].deals}</>
              )}
            </>
          )}

          {pageType === 'offspec' && (
            <>
              {me.type === 'SELLER' && (
              <div>
                <p>
                  This module allows you to initiate an auction to sell your products.
                  {' '}
                  <br />
                  After creating an auction, invited buyers have the opportunity to submit a bid, at the end of the auction the lot will be assigned to the highest bidder.
                </p>
                <p>Click on the Create Auction button to create your first auction!</p>

              </div>
              )}

              {me.type === 'BUYER' && (
              <div>
                There are currently no offers available for you to bid on.
              </div>
              )}
            </>
          )}

          {pageType === 'rfp' && (
            <>
              {me.type === 'SELLER' && (
                <div>
                  There are currently no RFPs for you to respond to. If a buyer places an RFP and invites you, you will receive an email notification.
                </div>
              )}

              {me.type === 'BUYER' && (
                <div>
                  <div>You have no active RFPs.</div>
                  <br />
                  <div>RFPs allow you to ask platform sellers to submit offers based on the specifications you include in your RFP.</div>
                  <br />
                  <div>Create an RFP now and start receiving offers within days.</div>
                  <br />
                </div>
              )}
            </>
          )}

        </div>
      )}

    </>
  );
};

export default Texts;
