/* eslint-disable react/no-danger */
import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { Helmet }                     from 'react-helmet-async';
import { Link, useHistory }                       from 'react-router-dom';
import Preloader                      from 'SHARED/components/ThePreloader';
import axios                          from 'SHARED/helpers/axios';
import routes                         from 'SHARED/types/routes';

export interface OrgItem {
  label: string, value: number | string
}

export interface Group {
  id: number,
  name: string,
  description: string,
  organizations: OrgItem[]
}

const BuyerGroups: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [groups, setGroups]   = useState<Group[]>([]);

  useEffect(() => { getGroups(); }, []);

  const getGroups = async () => {
    try {
      const responce:AxiosResponse<Group[]> = await axios.get('/v1/buyer-group');
      setGroups(responce.data);
    } catch (error:any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const viewDetails = (id: number) => {
    history.push(routes.common.groupDetails(id.toString()));
    console.log(id);
  };

  return (
    <>
      <Helmet>
        <title>BuyerGroups</title>
      </Helmet>

      <Preloader isLoading={loading} />

      <div className="page-container">
        <div className="page-top">
          <h1 className="page-title">Buyer Groups</h1>
          <Link type="button" to={routes.common.createGroup} className="btn-primary">Add buyer group</Link>
        </div>

        {groups.length > 0 ? (
          <table className="table-component always-visible">

            <thead>
              <tr>
                <td>GROUP NAME</td>
                <td>DESCRIPTION</td>
                <td>BUYERS</td>
              </tr>
            </thead>

            <tbody>
              {groups && groups.map(({
                id, name, description, organizations,
              }) =>
                (
                  <tr key={id} onClick={() => { viewDetails(id); }}>
                    <td>{name}</td>
                    <td>{description}</td>
                    <td>{organizations.map((o) => (o.label)).join(',')}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        ) : <div className="attention-message">There are no groups yet.</div> }
      </div>
    </>
  );
};

export default BuyerGroups;
