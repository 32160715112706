import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import texts from 'SHARED/helpers/texts';
import Select from 'SHARED/components/Select';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { useActions } from 'SHARED/redux/hooks/useActions';
import { Dictionary } from 'SHARED/types/offerTypes';
import { ProductCategory } from 'SHARED/types/specsTypes';
import { Link } from 'react-router-dom';
import routes from 'SHARED/types/routes';

const ProductFlowSection: React.FC = () => {
  const { ON_SPEC } = useTypedSelector((state) => state.offer);
  const { containers } = useTypedSelector((state) => state.dictionaries);
  const { categories, products } = useTypedSelector((state) => state.specs);

  const isHasProducts = products && products.length !== 0;
  const isHaveActiveProducts = isHasProducts && products?.some((p) => p.active);

  const {
    productCategory,
    productType,
    product,
  } = ON_SPEC;

  const { getProducts } = useActions();

  const methods = useFormContext();

  // 3 - watch form values section
  // for dynamic condition rendering and properly date validation
  const { watch, setValue } = methods;
  const choosedProductCategory: ProductCategory = watch('productCategory', productCategory);
  const choosedProductType: Dictionary = watch('productType', productType);
  const [noContainers, setNoContainers] = useState<boolean>(false);

  // clear relative dropdowns if product category has been changed
  useEffect(() => {
    // if there is no defined container for choosed category STOP
    // and show appropriate message
    choosedProductCategory && !containers[choosedProductCategory.value] ? setNoContainers(true) : setNoContainers(false);

    if (choosedProductCategory !== productCategory) {
      setValue('productType', null);
      setValue('product', null);
    }
  }, [choosedProductCategory]);

  // get products list according choosed category and type
  // clear already choosed product details
  useEffect(() => {
    if (choosedProductType && choosedProductType !== productType) {
      getProducts({
        type: choosedProductType.value,
        category: choosedProductCategory.value,
      });
      setValue('product', null);
    }
  }, [choosedProductType]);

  return (
    <>

      <input type="hidden" {...methods.register('type')} value="ALREADY_PRODUCED" />

      <Select
        label="PRODUCT CATEGORY"
        name="productCategory"
        options={categories}
        selected={productCategory}
      />

      {noContainers && <div className="attention-message alert">{texts.offerTexts.common.noContainers}</div>}

      {choosedProductCategory && !noContainers && (
        <Select
          label="PRODUCT TYPE"
          name="productType"
          options={choosedProductCategory.productTypes}
          selected={productType}
        />
      )}

      {choosedProductType && isHaveActiveProducts && (
        <Select
          label="OUR PRODUCT SPECS"
          name="product"
          options={products.filter((p) => p.active).map((p) => ({ label: p.title, value: p.id.toString() }))}
          selected={product}
        />
      )}

      {choosedProductType && !isHaveActiveProducts && (
        <div className="attention-message form-input">
          You have no <Link to={routes.common.specifications}>Product Specification</Link> for this product type. You can create a new specification in your <Link to={routes.common.specifications}>organisational profile</Link>
        </div>
      )}

    </>
  );
};

export default ProductFlowSection;
