import moment, { Moment } from 'moment';
import { buyersFetchType } from 'SHARED/helpers/OfferData';
import { IDataCollection, Dictionary } from 'SHARED/types/offerTypes';

export const salesTerms:Dictionary[] = [
  { label: 'MPC', value: 'MPC' },
  { label: 'Seller own terms', value: 'OWN' },
];

// export const buyersFetchType:Dictionary[] = [
//   { label: 'All buyers', value: BuyersFetchType.includeAll, disabled: false },
//   { label: 'Buyers  in one of my Buyers’ Group', value: BuyersFetchType.includeGroup, disabled: false },
//   { label: 'All buyers, excluding', value: BuyersFetchType.exclude, disabled: false },
// ];

export const volumeUnits:Dictionary = {
  label: 'MT',
  value: 'MT',
};

export const departurePeriod = [{ label: 'Month', value: 'month' }, { value: 'specific', label: 'Specific dates' }];

const OfferData: { [key: string]: IDataCollection[] } = {
  salesTerms,
  BuyersFetchType: buyersFetchType,
};

// ? all this logic should probably be rewritten with `Europe/Amsterdam` timezone
// ? or in user's timezone
const getLastDayOfMonth = (momentDate: Moment): Moment => {
  const lastDay = momentDate.format(`YYYY-MM-${momentDate.daysInMonth()}`);
  return moment(lastDay).add(23, 'hours').add(59, 'minutes').add(59, 'seconds');
};

const nextMonths: Moment[] = [];
nextMonths.push(getLastDayOfMonth(moment()));

for (let i = 0; i < 11; i += 1) {
  nextMonths.push(getLastDayOfMonth(moment(nextMonths[i]).add(1, 'months')));
}

export const monthsDictionary:Dictionary[] = nextMonths.map((m) => ({ label: moment(m).format('MMMM YYYY'), value: m.toISOString() }));

export default OfferData;
