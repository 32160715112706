import { CORE_ENDPOINTS } from 'SHARED/api/endpoints';
import { axios_Core } from 'SHARED/helpers/axios';
import notification, { showNotification } from 'SHARED/helpers/notifications';

/**
 * Updates a product specification in the database.
 * @param {object} payload - The data to update the product with.
 * @returns {Promise<boolean>} A boolean indicating whether the update was successful or not.
 */
export async function putUpdateProduct(payload: object) {
  try {
    await axios_Core.put(CORE_ENDPOINTS.product, payload);

    showNotification('PRODUCT_UPDATED');

    return false;
  } catch (error) {
    console.log('updateProduct error: ', error);

    notification({
      type: 'danger',
      title: 'Error',
      message: 'Could not update product...',
    });

    return true;
  }
}
