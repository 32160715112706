import React from 'react';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';

const OfferDealFinancingNoHandler = () => {
  const { ON_SPEC } = useTypedSelector((state) => state.offer);

  const {
    id,
    agreedOnPayment,
    deal,
  } = ON_SPEC;

  const {
    financing,
  } = deal || {};

  const {
    sellerPaymentTerm,
    buyerPaymentTerm,
    financingProvider,
    sellerPaymentOffset,
    buyerPaymentOffset,
  } = financing || {};

  // FLAGS
  const isHasDaysOffsetGap = sellerPaymentOffset !== buyerPaymentOffset;
  const isHasFinancingProvider = !!financingProvider?.value;
  const isSamePaymentTerms = sellerPaymentTerm?.value === buyerPaymentTerm?.value;
  // FLAGS === END

  if (!id || agreedOnPayment || isHasFinancingProvider) return null;

  return (
    <>
      {/* Should be always present */}
      <DataItemPreview
        title="Seller payment terms"
        item={sellerPaymentTerm}
      />

      {/* Edge case - Buyer selected payment terms that has not days in between */}
      {/* and we're treating those as "no financing needed" */}
      {!isHasDaysOffsetGap && (
        <>
          {/* We should only show payment terms that are different */}
          {!isSamePaymentTerms && (
            <DataItemPreview
              title="Buyer payment terms"
              item={buyerPaymentTerm}
            />
          )}

          <DataItemPreview
            title="Financing provider"
            item="No financing needed"
          />
        </>
      )}

    </>
  );
};

export default OfferDealFinancingNoHandler;
