import React, { useEffect, useState } from 'react';
import RadioGroup from 'SHARED/components/RadioGroup/RadioGroupV2';
import FormSelect from 'SHARED/components/Select';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { useActions } from 'SHARED/redux/hooks/useActions';
import { FormProvider, useForm } from 'react-hook-form';
import Preloader from 'SHARED/components/ThePreloader';
import { SingleCheckbox } from 'SHARED/components/Checkbox';
import {
  MpcDocLink,
  clearProxyFieldsTypeSafe,
  filterLocationsByIncotermsLocationType,
  handleDateRange,
  isDevEnv,
  minMaxVolumeToString,
  preparePriceInputValue,
} from 'SHARED/helpers/common';
import FormInput from 'SHARED/components/FormInput';
import validationRules, { rfpProposalEndDate } from 'SHARED/helpers/validation';
import FormOutput from 'SHARED/components/FormOutput';
import PriceInput from 'SHARED/components/PriceInput';
import ZonedDatePicker from 'SHARED/components/ZonedDatePicker/ZonedDatePicker';
import { isMobile } from 'react-device-detect';
import { DevTool } from '@hookform/devtools';
import { tooltips } from 'SHARED/helpers/texts';
import clsx from 'clsx';
import { BuyerPaymentTerm, Dictionary, File, IProposalPayload, InputOption } from 'SHARED/types/offerTypes';
import useUserTimezone from 'SHARED/hooks/useUserTimezone';
import { zonedMonthsDictionary } from 'SHARED/helpers/dates';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import moment from 'moment-timezone';
import FormFileInput from 'SHARED/components/FileInput/FormFileInput';
import notification from 'SHARED/helpers/notifications';
import { uploadCoreFiles } from 'SHARED/api/common/post/uploadFiles';
import { deleteCoreFile } from 'SHARED/api/common/delete/deleteFile';
import RfpSubmitPopup from './RfpSubmitPopup';

const salesTermsTypesOptions: InputOption[] = [
  { value: 'MPC', label: 'MPC' },
  { value: 'OWN', label: 'Seller own terms' },
];

type SalesTermsType = typeof salesTermsTypesOptions[number];

const pickUpOptions = [
  { value: 'MONTH', label: 'Month' },
  { value: 'PERIOD', label: 'Specific dates' },
];
interface IFormValues {
  productionLocation: Dictionary;

  salesTerms: SalesTermsType;
  termsDocument?: File[] | null;

  packaging: Dictionary;
  customLabelPossible: boolean;
  totalVolume: number;
  price: number | string;

  agreedOnPayment: boolean;
  paymentTerms?: BuyerPaymentTerm | null;
  paymentOffset?: Dictionary | null;

  agreedOnDelivery: boolean;
  incoterms?: Dictionary | null;
  logisticLocation?: Dictionary | null;
  departurePeriod?: Dictionary | null;
  departureFromMonth?: Dictionary | null;
  departureFrom?: string | null;
  departureTo?: string | null;

  description: string;
  endDate: string;
}

const RfpProposalForm = () => {
  const { dictionaries, loading, locations, containers } = useTypedSelector((state) => state.dictionaries);
  const { RFP } = useTypedSelector((state) => state.offer);
  const { me } = useTypedSelector((state) => state.users);

  const {
    getContainers,
    getDictionaries,
    getLocations,
    getOfferDetails,
  } = useActions();

  const { PAYMENT_TERMS, INCOTERM_SELLER } = dictionaries;

  const { userTimezone } = useUserTimezone();

  // temporary `any` type
  const [proposalPayload, setProposalPayload] = useState<any>(null);
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState<boolean>(false);

  const {
    id,
    packages,
    minVolume,
    maxVolume,
    statusLabel,
    endDate,
    currency,
    paymentTerms,
    deliveryLocation,
    departureTo,
    departureFrom,
    volumeUnits,
    incoterms,
  } = RFP;

  const methods = useForm<IFormValues>();
  const { handleSubmit, watch, setValue } = methods;

  // form values
  const salesTermsTypeValue = watch('salesTerms');
  const agreedOnPaymentValue = watch('agreedOnPayment');
  const paymentTermsValue = watch('paymentTerms');
  const agreedOnDeliveryValue = watch('agreedOnDelivery');
  const incotermsValue = watch('incoterms');
  const departurePeriodValue = watch('departurePeriod');
  const departureFromMonthValue = watch('departureFromMonth');
  const departureFromValue = watch('departureFrom');
  const departureToValue = watch('departureTo');
  // form values === END

  // encapsulated data
  const matchingProductContainers = containers[RFP?.productCategory?.value ?? ''];
  const volume = minMaxVolumeToString(minVolume, maxVolume);
  const filteredLogisticsLocations = filterLocationsByIncotermsLocationType(locations.LOGISTIC, incotermsValue);
  const deliveryRange = handleDateRange({ from: departureFrom, to: departureTo });
  const deliveryDescription = `${deliveryRange}, ${incoterms?.label}, ${deliveryLocation?.fullAddress}`;
  // encapsulated data === END

  // flags
  const isLoading = loading;
  const isSellerHasMatchingContainers = (
    matchingProductContainers?.some((c) => c.containerCategory === 'SEA')
    && matchingProductContainers?.some((c) => c.containerCategory === 'LAND')
  );
  const isDisabled = isLoading || !RFP.id || isSubmitModalOpen || !isSellerHasMatchingContainers;
  const isSingleProductionLocation = locations.PRODUCTION?.length === 1;
  const isOwnSalesTerms = salesTermsTypeValue?.value === 'OWN';
  const isSinglePackage = packages?.length === 1;
  const isVolumeRange = minVolume !== maxVolume;
  // flags === END

  function onSubmit(data: IFormValues) {
    const clearedData = clearProxyFieldsTypeSafe<IFormValues>(data);

    const payload: IProposalPayload = {
      type: 'RFP_PROPOSAL',
      parentOfferId: RFP.id,
      volumeUnits,
      productionLocationId: clearedData.productionLocation?.value,
      salesTerms: clearedData.salesTerms,
      packaging: clearedData.packaging,
      customLabelPossible: clearedData.customLabelPossible,
      totalVolume: clearedData.totalVolume,
      agreedOnPayment: clearedData.agreedOnPayment,
      agreedOnDelivery: clearedData.agreedOnDelivery,
      description: clearedData.description,
      endDate: moment(clearedData.endDate).valueOf(),
    };

    const preparedPrice = preparePriceInputValue(clearedData.price);
    switch (currency) {
      case 'USD':
        payload.priceUsd = preparedPrice;
        break;

      // default case is for EUR (default currency)
      default:
        payload.priceEur = preparedPrice;
    }

    if (data.salesTerms?.value === 'OWN') {
      payload.documents = {
        SALES_TERMS: data.termsDocument,
      };
    }

    // if user did not accept payment terms - payload will include 2-3 more fields
    if (!data.agreedOnPayment) {
      payload.paymentTerms = data.paymentTerms;
    }
    // in case if term has day offset
    if (data.paymentTerms?.hasDayOffset) {
      payload.paymentOffset = data.paymentOffset?.value;
    }

    // if user did not accept delivery terms
    // we need to add logisticLocationId
    if (!data.agreedOnDelivery) {
      payload.incoterms = data.incoterms;
      // add logisticLocationId
      payload.logisticLocationId = data.logisticLocation?.id;

      payload.departurePeriod = data.departurePeriod;

      // preparing UI field values for backend model
      if (data.departurePeriod?.value === 'MONTH') {
        payload.departureFromMonth = data.departureFromMonth;
        payload.departureFrom = moment(data.departureFromMonth?.value).toISOString();
      }

      // if user selected date period
      if (data.departurePeriod?.value === 'PERIOD') {
        payload.departureFrom = moment(data.departureFrom).toISOString();
        payload.departureTo = moment(data.departureTo).toISOString();
      }
    }

    setProposalPayload(payload);
    setIsSubmitModalOpen(true);
  }

  // TODO: handle error from server infinite preloader
  // ? axios interceptors and redux are not passing error to the consumer
  function fetchOffer() {
    try {
      id && getOfferDetails({ id, offerType: 'RFP' });
    } catch (error) {
      console.error(error);
      notification({
        type: 'danger',
        title: 'Error',
        content: 'Could not get RFP offer details. Please try again later.',
      });
    }
  }

  function handleCloseSubmitPopup(isSuccess: boolean) {
    if (isSuccess) {
      fetchOffer();
      setProposalPayload(null);
      // reset(defaultValues);
    }

    setIsSubmitModalOpen(false);
  }

  useEffect(() => {
    if (me.orgId) {
      getDictionaries({ offerType: 'RFP_PROPOSAL' });
      getLocations({ type: 'BOTH', orgId: me.orgId });
      getContainers(me.orgId);
    }
  }, [me]);

  useEffect(() => {
    setValue('paymentOffset', null);
  }, [paymentTermsValue]);

  useEffect(() => {
    setValue('logisticLocation', null);
  }, [incotermsValue]);

  return (
    <>
      <Preloader isLoading={isLoading} />

      {isDevEnv && <DevTool control={methods.control} placement="top-right" />}

      {!isSellerHasMatchingContainers && (
        <div className="attention-message">
          You have not setup a logistic type for this product category. Please <a href="https://www.opendairy.com/support" target="_blank" rel="noreferrer">contact OpenDairy</a> to add container type and volume.
        </div>
      )}

      <section className="proposal-form pb-4">
        <h2 className="page-section-title">Create proposal to this RFP</h2>
        <p>
          During the validity of the RFP you are able to create one or more proposals that fit the RFP requirements. The buyer can only accept one of the proposals.
        </p>

        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>

            {/* PRODUCTION LOCATION SECTION */}
            {isSingleProductionLocation && (
              <FormOutput
                name="productionLocation"
                label="PRODUCTION LOCATION"
                displayValue={locations.PRODUCTION[0]?.label}
                fieldValue={locations.PRODUCTION[0]}
              />
            )}

            {!isSingleProductionLocation && (
              <FormSelect
                name="productionLocation"
                label="Production location"
                options={locations.PRODUCTION || []}
                selected={null}
              />
            )}
            {/* PRODUCTION LOCATION SECTION === END */}

            {/* SALES TERMS SECTION */}
            <RadioGroup
              label="SALES TERMS"
              name="salesTerms"
              values={salesTermsTypesOptions}
              defaultValue={salesTermsTypesOptions[0]}
            />

            {!isOwnSalesTerms && (
              <div className="form-input">
                <DataItemPreview
                  title="SALES TERMS DOCUMENT"
                  link={{
                    url: MpcDocLink,
                    displayName: 'MPC Sales Terms',
                  }}
                />
              </div>
            )}

            {isOwnSalesTerms && (
              <FormFileInput
                name="termsDocument"
                label="Sales terms document"
                handleUploadFiles={uploadCoreFiles}
                handleDeleteFile={deleteCoreFile}
                required
                disabled={isDisabled}
              />
            )}
            {/* SALES TERMS SECTION === END */}

            {isSinglePackage && (
              <FormOutput
                name="packaging"
                label="Packaging"
                className="short"
                displayValue={packages[0].label}
                fieldValue={packages[0]}
              />
            )}

            {!isSinglePackage && (
              <FormSelect
                name="packaging"
                label="Packaging"
                className="short"
                options={packages || []}
                selected={null}
              />
            )}

            <SingleCheckbox
              title="CUSTOM LABEL"
              label="Custom label possible"
              name="customLabelPossible"
            />

            {isVolumeRange && (
              <FormInput
                name="totalVolume"
                label="VOLUME"
                className="short"
                type="number"
                suffix="MT"
                rules={validationRules.withinRange(
                  minVolume,
                  maxVolume,
                  'Volume must be within requested range',
                )}
                caption={`${volume} requested`}
              />
            )}

            {!isVolumeRange && (
              <FormOutput
                name="totalVolume"
                label="VOLUME"
                className="short"
                displayValue={`${minVolume} MT`}
                fieldValue={minVolume}
              />
            )}

            <PriceInput
              name="price"
              label="PRICE"
              rules={validationRules.required}
              // currency={(currency === 'USD') && '$'}
              currency={(currency === 'EUR') ? '€' : '$'}
              tooltip={tooltips.rfp.price}
            />

            {/* PAYMENT TERMS SECTION */}
            <h2 className="page-section-subtitle mt-0">
              Payment terms
            </h2>
            <SingleCheckbox
              label="I agree with the payment terms of the buyer"
              name="agreedOnPayment"
              caption={agreedOnPaymentValue ? paymentTerms?.label : null}
              defaultChecked
            />
            {!agreedOnPaymentValue && (
            <div className={clsx(paymentTermsValue?.hasDayOffset && 'financing-row', 'mb-1')}>
              <div className="payment-term">
                <FormSelect
                  label="PAYMENT TERMS"
                  name="paymentTerms"
                  options={PAYMENT_TERMS}
                  selected={null}
                />
              </div>

              {paymentTermsValue?.hasDayOffset && (
              <div className="days-offset">
                <FormSelect
                  label="NUMBER OF DAYS"
                  name="paymentOffset"
                  options={paymentTermsValue.offsetDays.map((d) => ({ value: `${d}`, label: `${d} days` }))}
                  selected={null}
                  className="one-line-label"
                />
              </div>
              )}
            </div>
            )}
            {/* PAYMENT TERMS SECTION === END */}

            {/* INCOTERMS SECTION */}
            <h2 className="page-section-subtitle mt-0">
              Delivery
            </h2>
            <SingleCheckbox
              label="I agree to deliver to the address and timeline requested by the buyer"
              name="agreedOnDelivery"
              caption={agreedOnDeliveryValue ? deliveryDescription : null}
              defaultChecked
            />

            {!agreedOnDeliveryValue && (
              <>
                <FormSelect
                  label="SELLER INCOTERMS"
                  name="incoterms"
                  options={INCOTERM_SELLER}
                  selected={null}
                  className="short"
                />

                <FormSelect
                  label="LOCATION"
                  name="logisticLocation"
                  options={filteredLogisticsLocations || []}
                  selected={null}
                  disabled={!incotermsValue}
                />

                <RadioGroup
                  label="DEPARTURE"
                  name="departurePeriod"
                  values={pickUpOptions}
                  defaultValue={pickUpOptions[0]}
                />

                {(departurePeriodValue?.value === 'MONTH') && (
                  <FormSelect
                    label="MONTH"
                    name="departureFromMonth"
                    options={zonedMonthsDictionary(userTimezone)}
                    selected={null}
                    className="short"
                  />
                )}

                {(departurePeriodValue?.value === 'PERIOD') && (
                  <div className="dates-range">
                    <div className="dates-range-wrapper">
                      <ZonedDatePicker
                        inline={isMobile}
                        name="departureFrom"
                        value={new Date()}
                        showTimezone={false}
                      />
                      <ZonedDatePicker
                        inline={isMobile}
                        name="departureTo"
                        minDate={departureFromValue}
                        value={moment(new Date()).add(3, 'month').toDate()}
                        showTimezone={false}
                        rules={validationRules.endDate(moment(departureFromValue).toDate())}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
            {/* INCOTERMS SECTION === END */}

            <FormInput
              name="description"
              textarea
              label="REMARKS FOR THE BUYER"
            />

            <ZonedDatePicker
              name="endDate"
              label="PROPOSAL VALID UNTIL"
              showTimeInput
              inline={isMobile}
              value={moment(endDate).toDate()}
              minDate={new Date()}
              maxDate={moment(endDate).toDate()}
              dateFormat="dd MMMM yyyy HH:mm"
              rules={
                rfpProposalEndDate({
                  rfpEndDate: endDate,
                  agreedOnDelivery: !!agreedOnDeliveryValue,
                  departurePeriodType: departurePeriodValue?.value,
                  departureFromMonth: departureFromMonthValue?.value,
                  departureTo: departureToValue,
                })
              }
              caption={statusLabel.replace('Active until', 'RFP expires on')}
            />

            <div className="btn-wrapper buttons-wrapper m-full-width mt-2">
              <button
                type="submit"
                className="btn-primary"
                disabled={isDisabled}
              >
                Preview proposal
              </button>
            </div>

          </form>
        </FormProvider>
      </section>

      {isSubmitModalOpen && !!proposalPayload && (
        <RfpSubmitPopup
          payload={proposalPayload}
          handleClose={handleCloseSubmitPopup}
          currency={currency}
        />
      )}
    </>
  );
};

export default RfpProposalForm;
