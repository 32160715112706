type ParamId = number | string;

export const routes = {
  home: '/onspec/offers',

  auth: {
    login: '/login',
    oauth: window?.APP_CONFIG?.oauth_url,
    oauthLogin: '/oauth2/code',
  },

  // :offer_type = onspec | offspec | rfp
  // :offer_status = offers | archived-offers | deals
  offersList: '/:offer_type/:offer_status',

  onspec: {
    create_simple_offer: '/onspec/offers/create-simple',
    preview_simple_offer: '/onspec/offers/preview-simple',
    createAP: '/onspec/offers/create-already-produced',
    createAP_rfp: (rfpId: string | number) => `/onspec/offers/create-already-produced?isForRfp=${rfpId}`,
    previewAP: '/onspec/offers/preview-already-produced',
    previewAP_rfp: (rfpId: string | number) => `/onspec/offers/preview-already-produced?isForRfp=${rfpId}`,
    duplicate_already_produced: (offerId: string) => `/onspec/offers/create-already-produced?duplicate=${offerId}`,
    createTP: '/onspec/offers/create-tobe-produced',
    createTP_rfp: (rfpId: number | string) => `/onspec/offers/create-tobe-produced?isForRfp=${rfpId}`,
    previewTP: '/onspec/offers/preview-tobe-produced',
    previewTP_rfp: (rfpId: number | string) => `/onspec/offers/preview-tobe-produced?isForRfp=${rfpId}`,
    duplicate_to_be_produced_bundle: (offerId: string) => `/onspec/offers/create-tobe-produced?duplicate=${offerId}`,
    list: '/onspec/:type/',
    listDefault: '/onspec/offers/',
    details: '/onspec/:type/:id',
    offerDetails: (offerId: string | number) => `/onspec/offers/${offerId}`,
    edit: '/onspec-edit/:id',

    // ***** deals and document exchange process *****
    deals: '/onspec/deals',
    dealDetail: '/onspec/deals/:dealId',
    goto_dealDetail: (dealId: string | number) => `/onspec/deals/${dealId}`,
    dealDetail_documents: '/onspec/deals/:dealId/documents',
    goto_dealDetail_documents: (dealId: string | number) => `/onspec/deals/${dealId}/documents`,
    requestDocument: '/onspec/documents/request/:dealId',
    goto_requestDocument: (dealId: string | number) => `/onspec/documents/request/${dealId}`,
    provideDocument: '/onspec/documents/provide/:dealId',
    goto_provideDocument: (dealId: string | number) => `/onspec/documents/provide/${dealId}`,
    goto_provideDocument_thread: (dealId: string | number, threadId: string | number) => `/onspec/documents/provide/${dealId}?threadId=${threadId}`,
  },

  offspec: {
    create: '/offspec/offers/create',
    duplicate: (auctionId: string) => `/offspec/offers/create?duplicate=${auctionId}`,
    offerDetail: '/offspec/:type/:id',
    dealDetail: '/offspec/deals/:id',
    list: '/offspec/:type',
    preview: '/offspec-preview/',
    defaultList: '/offspec/offers',
  },

  rfp: {
    create: '/rfp/offers/create',
    preview: '/preview-rfp',
    details: '/rfp/offers/:id',
    proposalDetails: '/rfp/proposals/:id',
    list: '/rfp/:type',
    defaultList: '/rfp/offers',
    archivedList: '/rfp/archived-offers',
    archivedDetails: '/rfp/archived-offers/:id',

    // deals
    deals: '/rfp/deals',
    dealDetail: '/rfp/deals/:dealId',
    goto_dealDetail: (dealId: string | number) => `/rfp/deals/${dealId}`,
    dealDetail_documents: '/rfp/deals/:dealId/documents',
    goto_dealDetail_documents: (dealId: string | number) => `/rfp/deals/${dealId}/documents`,
    requestDocument: '/rfp/documents/request/:dealId',
    goto_requestDocument: (dealId: string | number) => `/rfp/documents/request/${dealId}`,
    provideDocument: '/rfp/documents/provide/:dealId',
    goto_provideDocument: (dealId: string | number) => `/rfp/documents/provide/${dealId}`,
    goto_provideDocument_thread: (dealId: string | number, threadId: string | number) => `/rfp/documents/provide/${dealId}?threadId=${threadId}`,
  },

  common: {
    profile: '/company-profile',
    specifications: '/product-specifications',
    buyerGroups: '/buyers-groups',
    groupDetails: (id = ':id') => `/group-details/${id}`,
    createGroup: '/create-buyers-group',
    createProduct: (specType = ':specType') => `/create-product-specification/${specType}`,
    duplicateProduct: (specType = ':specType', specId: string) => `/create-product-specification/${specType}?duplicate=${specId}`,
    productDetails: (id = ':id') => `/product-specifications-details/${id}`,
  },
} as const;

export enum OfferEndpoints {
  getDetails = '/v1/offers/off-spec/',
  getOffspecList = '/v1/offers/off-spec/',
  getList = '/v1/offers/',
  getDeals = '/v1/offers/off-spec?statuses=DEAL',
  createOffspec = '/v1/offers/off-spec',
  create = '/v1/offers',
  getLogisticInfo = '/v1/logistic/offer-prices',
  bidHistory = 'v1/negotiation/bid/history',
  uploadFiles = '/v1/file-upload',
  offerPrices = '/v1/offers/prices',
}

export const admin_routes = {
  dashboard: '/',
  login: '/login',
  seller: '/seller',
  buyer: '/buyer',
  admin: '/admin',

  catalogue: '/catalogue',
  catalogue_financing: '/catalogue/financing',
  catalogue_logisticPrices: '/catalogue/logistic-prices',
  catalogue_logisticPrices_add: '/catalogue/logistic-prices/add',

  // OFFERS (ON_SPEC)
  // ? URl for offer page -> `/offers/:offerId` (active and archived), same way auctions and rfp should be refactored/remade since those pages basically are the same
  // ? URl for deal page -> `/offers/deals/:dealId` (separate page)
  offers: '/offers',
  offerPage: '/offers/:offerId',
  offerEdit: '/offers/:offerId/edit',
  // tabs
  offerPage_bids: '/offers/:offerId/bids',
  offerPage_history: '/offers/:offerId/history',
  offerPage_matches: '/offers/:offerId/matches',
  offerPage_analytics: '/offers/:offerId/analytics',

  offers_archived: '/offers/archived',

  offers_deals: '/offers/deals',
  offerDeal: '/offers/deals/:dealId',
  offerDeal_documents: '/offers/deals/:dealId/documents',

  offerDocumentsProvide: '/offers/documents/provide/:dealId',
  // goto
  goto_offerPage: (offerId: ParamId) => `/offers/${offerId}`,
  goto_offerPage_bids: (offerId: ParamId) => `/offers/${offerId}/bids`,
  goto_offerPage_history: (offerId: ParamId) => `/offers/${offerId}/history`,
  goto_offerPage_matches: (offerId: ParamId) => `/offers/${offerId}/matches`,
  goto_offerPage_analytics: (offerId: ParamId) => `/offers/${offerId}/analytics`,

  goto_offerEdit: (offerId: ParamId) => `/offers/${offerId}/edit`,

  goto_offerDealPage: (dealId: ParamId) => `/offers/deals/${dealId}`,
  goto_offerDealPage_documents: (dealId: ParamId) => `/offers/deals/${dealId}/documents`,
  goto_offerDealCreate: (offerId: ParamId) => `/offers/deal/create/${offerId}`,
  offer_dealCreateUrl: '/offers/deal/create/:offerId',

  goto_offerDocumentsProvide: (dealId: ParamId) => `/offers/documents/provide/${dealId}`,
  // OFFERS (ON_SPEC) === END

  // AUCTIONS
  offSpecOffers: '/offspec/offers',
  offSpecOffers_offerPage: (offerId: number | string) => `/offspec/offers/${offerId}`,

  offSpecOffers_archived: '/offspec/offers/archived',

  offSpecOffers_deals: '/offspec/deals',
  offSpecOffers_dealPage: (dealId: number | string) => `/offspec/deals/${dealId}`,
  // AUCTIONS === END

  // RFP
  rfp: '/rfp', // for rfp/:rfpId
  rfp_archived: '/rfp/archived',
  rfp_deals: '/rfp/deals',

  rfpOffer: '/rfp/:rfpId',
  goto_rfpOffer: (rfpId: ParamId) => `/rfp/${rfpId}`,
  rfpOfferProposals: '/rfp/:rfpId/proposals',
  goto_rfpOfferProposals: (rfpId: ParamId) => `/rfp/${rfpId}/proposals`,
  rfpOfferAnalytics: '/rfp/:rfpId/analytics',
  goto_rfpOfferAnalytics: (rfpId: ParamId) => `/rfp/${rfpId}/analytics`,

  rfpOffer_archived: '/rfp/archived/:rfpId',
  goto_rfpOffer_archived: (rfpId: ParamId) => `/rfp/archived/${rfpId}`,
  rfpOfferProposals_archived: '/rfp/archived/:rfpId/proposals',
  goto_rfpOfferProposals_archived: (rfpId: ParamId) => `/rfp/archived/${rfpId}/proposals`,
  rfpOfferAnalytics_archived: '/rfp/archived/:rfpId/analytics',
  goto_rfpOfferAnalytics_archived: (rfpId: ParamId) => `/rfp/archived/${rfpId}/analytics`,

  rfp_deal: '/rfp/deals/:dealId',
  rfp_deal_documents: '/rfp/deals/:dealId/documents',
  goto_rfp_deal: (dealId: ParamId) => `/rfp/deals/${dealId}`,
  goto_rfp_deal_documents: (dealId: ParamId) => `/rfp/deals/${dealId}/documents`,

  rfpProposal: '/rfp/proposals/:proposalId',
  goto_rfpProposal: (proposalId: ParamId) => `/rfp/proposals/${proposalId}`,
  // RFP === END

  offSpecOffer: '/offspec/offer/:id',
  offSpecBase: '/offspec',
  auctionDeal: '/offspec/deals/:dealId',

  newOffer: '/offer',

  deals: '/offspec/deals',
  deal: '/offspec/deal/:id',

  authHandler: '/oauth2/code',
} as const;

export default routes;
