import { handleDate, handleDateRange } from 'SHARED/helpers/common';
import { OfferListItem, OfferTableItem } from 'SHARED/types/offerTypes';
import { OfferListTypes } from 'SHARED/pages/OffersList';

// to iterate through it in table and cards
export const rfpSeller = (offer: OfferListItem, activeTab: OfferListTypes) => {
  const {
    endDate,
    departureFrom,
    departureTo,
    minVolume,
    maxVolume,
    statusLabel,
    productCategory,
    productType,
    matchedProducts,
    buyerName,
    logisticLocationCountry,
    deal,
    agreedOnDelivery,
  } = offer;

  const departure = handleDateRange({ from: departureFrom, to: departureTo });
  const format = 'DD MMM YYYY';

  const volume = () => {
    if (minVolume === maxVolume) return `${maxVolume} mt`;
    return `${minVolume} - ${maxVolume} mt`;
  };

  // creating offer list item data
  // for table and cards
  const activeOffers: OfferTableItem[] = [
    { key: 'endDate', value: endDate ? handleDate({ date: endDate, format }) : '-', label: 'VALID UNTIL' },
    { key: 'product', value: `${productCategory} ${productType}`, matchedProducts, label: 'PRODUCT' },
    { key: 'volume', value: volume(), label: 'VOLUME' },
    { key: 'departure', value: departure, label: 'Delivery range' },
    { key: 'country', value: logisticLocationCountry, label: 'Delivery country' },
    { key: 'buyer', value: buyerName, label: 'BUYER' },
  ];

  const archivedOffers: OfferTableItem[] = [
    { key: 'endDate', value: endDate ? handleDate({ date: endDate, format }) : '-', label: 'VALID UNTIL' },
    { key: 'product', value: `${productCategory} ${productType}`, matchedProducts, label: 'PRODUCT ' },
    { key: 'volume', value: volume(), label: 'VOLUME' },
    { key: 'departure', value: departure, label: 'REQUESTED DEPARTURE' },
    { key: 'buyer', value: buyerName, label: 'BUYER' },
    { key: 'status', value: statusLabel, label: 'STATUS' },
  ];

  const deals: OfferTableItem[] = [
    { key: 'endDate', value: deal ? handleDate({ date: deal?.created, format }) : '-', label: 'DEAL DONE ON' },
    { key: 'product', value: `${productCategory} ${productType}`, matchedProducts, label: 'PRODUCT' },
    { key: 'price', value: deal?.netPrice, label: 'DEAL PRICE' },
    { key: 'volume', value: `${deal?.volume} ${deal?.volumeUnits}`, label: 'VOLUME' },
    { key: 'buyer', value: deal?.buyerOrg?.orgName, label: 'BUYER' },
    { key: 'departure', value: agreedOnDelivery ? departure : null, label: 'DELIVERY' },
    { key: 'departure', value: deal?.shipping?.destinationLocation?.country, label: 'DELIVERY COUNTRY' },
  ];

  if (activeTab === 'archived-offers') {
    return archivedOffers;
  }

  if (activeTab === 'deals') {
    return deals;
  }

  return activeOffers;
};
