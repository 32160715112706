import React                 from 'react';
import { useHistory }        from 'react-router-dom';
import routes                from 'SHARED/types/routes';
import { ProductListItem }   from 'SHARED/types/specsTypes';
import                      './categories.scoped.scss';

interface SpecsTableProps {
  products: ProductListItem[],
  openPopup: any,
}

const SpecsTable: React.FC<SpecsTableProps> = ({ products, openPopup }) => {
  const history = useHistory();

  const handleRedirectToEdit = (id:number) => {
    history.push(routes.common.productDetails(id.toString()));
  };

  const handleEditTitle = (e:React.MouseEvent<HTMLAnchorElement, MouseEvent>, id:number) => {
    e.stopPropagation();
    openPopup(id);
    console.log('handleEditTitle');
  };

  return (
    <table className="specs-table">
      <thead>
        <tr>
          <th className="type">type</th>
          <th className="title">title</th>
          <th className="status">status</th>
          <th className="status">{' '}</th>
        </tr>
      </thead>

      <tbody>
        {products && products.map(({
          productType, title, active, id,
        }) => (
          <tr key={id} onClick={() => handleRedirectToEdit(id)}>
            <td width="30%">{productType.label}</td>
            <td width="30%">{title}</td>
            <td width="30%">
              {active && <div>Active</div> }
              {!active && <div>Inactive</div> }
            </td>
            <td align="right"><a href="##" onClick={(e) => { handleEditTitle(e, id); }}>Edit</a></td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SpecsTable;
