import { handleDateRange } from 'SHARED/helpers/common';
import { IOffspecOffer, OfferStatuses, OfferTableItem } from 'SHARED/types/offerTypes';
import { OfferListTypes } from 'SHARED/pages/OffersList';
import { dateWithTimezone } from 'SHARED/helpers/dates';

// to iterate through it in table and cards
export const offspecSeller = (
  offer: IOffspecOffer,
  activeTab: OfferListTypes,
  userTimezone: string = 'Europe/Amsterdam',
) => {
  const {
    created,
    title,
    totalVolume,
    price,
    loadDateFrom,
    loadDateTo,
    bidders,
    status,
    buyer,
    deal,
    deals: auctionDeals,
    currency,
    splittable,
  } = offer;

  const dateRange = handleDateRange({ from: loadDateFrom, to: loadDateTo });
  const volume = `${totalVolume?.toString().replace('.', ',')} mt`;
  const dealVolume = `${deal?.volume?.toString().replace('.', ',')} mt`;
  const buyerOrgName = deal?.buyerOrg?.orgName;
  const statusLabel = OfferStatuses[status];

  const isHasMultipleBuyers = auctionDeals && (auctionDeals?.length > 1);
  const archivedOfferBuyerValue = isHasMultipleBuyers ? 'Multiple buyers' : buyer;

  // creating offer list item data
  // for table and cards
  const activeOffers:OfferTableItem[] = [
    { key: 'placed', value: dateWithTimezone(created, userTimezone, 'DD MMM YYYY'), label: 'PLACED' },
    { key: 'title', value: title, label: 'OFFER TITLE' },
    { key: 'volume', value: volume, label: 'VOLUME', isSplittable: splittable },
    { key: 'price', value: price, prefix: currency, label: 'ASK PRICE' },
    { key: 'dateRange', value: dateRange, label: 'PICK-UP DATE RANGE' },
    { key: 'bidders', value: bidders, label: 'BIDDERS' },
    { key: 'status', value: statusLabel, label: 'STATUS' },
  ];
  const archivedOffers:OfferTableItem[] = [
    { key: 'placed', value: dateWithTimezone(created, userTimezone, 'DD MMM YYYY'), label: 'PLACED' },
    { key: 'title', value: title, label: 'OFFER TITLE' },
    { key: 'volume', value: volume, label: 'VOLUME', isSplittable: splittable },
    { key: 'dateRange', value: dateRange, label: 'PICK-UP DATE RANGE' },
    { key: 'price', value: price, prefix: currency, label: 'ASK PRICE' },
    { key: 'buyer', value: archivedOfferBuyerValue, label: 'BUYER' },
    { key: 'status', value: statusLabel, label: 'STATUS' },
  ];
  const deals:OfferTableItem[] = [
    { key: 'placed', value: dateWithTimezone(created, userTimezone, 'DD MMM YYYY'), label: 'DEAL DONE ON' },
    { key: 'dateRange', value: dateRange, label: 'PICK-UP DATE RANGE' },
    { key: 'title', value: title, label: 'PRODUCT' },
    { key: 'price', value: deal?.price || 0, prefix: currency, label: 'DEAL PRICE' },
    { key: 'volume', value: dealVolume, label: 'VOLUME' },
    { key: 'buyer', value: buyerOrgName, label: 'BUYER' },
  ];

  if (activeTab === 'archived-offers') {
    return archivedOffers;
  }

  if (activeTab === 'deals') {
    return deals;
  }

  return activeOffers;
};
