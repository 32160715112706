import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import Preloader from 'SHARED/components/ThePreloader';
import DataItemPreview from 'SHARED/components/DataItemPreview';

const ProfilePage: React.FC = () => {
  const { me, loading } = useTypedSelector((state) => state.users);

  const CP = me?.organization?.contactPerson;

  const UserName = me?.organization?.contactPerson ? `${CP?.salutation || ''} ${CP?.firstName || ''} ${CP?.middleName || ''} ${CP?.lastName || ''}` : '-';

  return (
    <>
      <Helmet>
        <title>Company profile page</title>
      </Helmet>

      <Preloader isLoading={loading} />

      <div className="page-container">
        <div className="page-top">
          <h1 className="page-title">Company profile</h1>
        </div>

        <div className="page-body">
          <div className="page-columns">
            <div className="page-column">
              <div className="page-section-title">Company information</div>
              <DataItemPreview item={me.organization?.orgName} title="TRADING NAME" />
              <DataItemPreview item={me.organization?.country} title="COUNTRY" />
              <DataItemPreview item={me.organization?.legalName} title="LEGAL COMPANY NAME FOR CONTRACTS" />
              <DataItemPreview item={me.organization?.coc} title="CHAMBER OF COMMERCE NUMBER" />
              <DataItemPreview item={me.organization?.vat} title="VAT NUMBER" />
            </div>
            <div className="page-column">
              <div className="page-section-title">General contact info</div>
              <DataItemPreview item={UserName} title="CONTACT PERSON" />
              <DataItemPreview item={me.organization?.contactPerson?.jobTitle} title="JOB TITLE" />
              <DataItemPreview item={me.organization?.contactPerson?.phoneNumber} title="TELEPHONE NUMBER" />
              <DataItemPreview item={me.organization?.contactPerson?.email} title="GENERAL CONTACT EMAIL" />
            </div>
            {/* only for SELLER */}
            {me.organization && me.type === 'SELLER' && (
              <div className="page-column">
                <div className="page-section-title">Logistic locations for auctions</div>
                {me.organization.offSpecLocations && me.organization.offSpecLocations.length === 0 && <div>-</div>}
                {me.organization.offSpecLocations && me.organization.offSpecLocations.length > 0
                  && me.organization.offSpecLocations.map((location, index) => <DataItemPreview item={location.label} title={`PICK-UP LOCATION ${index + 1}`} />)}
              </div>
            )}

            {/* Fees description */}
            {me.organization?.feesDescription && (
              <div className="page-column">
                <div className="page-section-title">Fees description</div>
                <DataItemPreview title="AGREED UPON PLATFORM TERMS" multiline multilineText={me.organization?.feesDescription} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfilePage;
