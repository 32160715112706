import React, { useState } from 'react';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import ProductSpecsDetailsModal from 'SHARED/modals/ProductSpecsDetailsModal';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import { getFullName, MpcDocLink } from 'SHARED/helpers/common';
import OfferDisclaimer from 'SHARED/components/OfferDisclaimer';
import { Link } from 'react-router-dom';
import RfpDealLogisticsAgreed from './fields/logistics/RfpDealLogisticsAgreed';
import OfferDealLogisticsWithHandler from './fields/logistics/OfferDealLogisticsWithHandler';
import OfferDealLogisticsNoHandler from './fields/logistics/OfferDealLogisticsNoHandler';
import RfpDealFinancingAgreed from './fields/financing/RfpDealFinancingAgreed';
import OfferDealFinancingWithHandler from './fields/financing/OfferDealFinancingWithHandler';
import OfferDealFinancingNoHandler from './fields/financing/OfferDealFinancingNoHandler';
import { OfferDealLogisticsSimple } from './fields/logistics/OfferDealLogisticsSimple';

const AlreadyProducedDealSummary: React.FC = () => {
  const { ON_SPEC } = useTypedSelector((state) => state.offer);

  const {
    productType,
    productCategory,
    documentCapabilities,
    coaUsed,
    customLabelPossible,
    productionLocation,
    productionDate,
    expirationDate,
    salesTerms,
    documents,
    productCharacteristics,
    deal,
    id,
    type,
    bundleId,
    parentOffer,
    paymentTerms,
  } = ON_SPEC;

  const openSpecsPopup = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    setIsPopup(true);
  };

  const [isPopup, setIsPopup] = useState(false);

  const isToBeProduced = type?.value === 'TO_BE_PRODUCED';
  const isCreatedByAdmin = deal?.createdBy?.type === 'ADMIN';
  const isRfp = parentOffer?.type?.value === 'RFP';
  const isSimpleOffer = type?.value === 'SIMPLE_ALREADY_PRODUCED';

  const offerTypeTitle = isRfp ? 'Proposal' : 'Offer';

  return (
    <>
      <div className="page-columns">
        <div className="page-column">
          <div className="page-section-title">Deal</div>
          {deal && (
            <>
              <DataItemPreview item={deal?.number} title="DEAL ID" />
              <DataItemPreview item={deal?.created} dateFormat="DD MMM YYYY" isDate title="DEAL DONE ON" />

              {isCreatedByAdmin && (
              <DataItemPreview item="OpenDairy" title="Added by" />
              )}
            </>
          )}

          <div className="page-section-title">Product</div>
          <DataItemPreview item={type?.label} title="OFFER TYPE" />
          <DataItemPreview item={productCategory} title="PRODUCT CATEGORY" />
          <DataItemPreview item={productType} title="PRODUCT TYPE" />
          {!isSimpleOffer && (
            <>
              <div className="data-preview-row">
                <div className="label-text">{isRfp ? 'BUYER' : 'SELLER'} PRODUCT SPECIFICATION</div>
                <div><a href="##" onClick={(e) => openSpecsPopup(e)}>Specifications details</a></div>
              </div>
              {documents && documents.COA && <DataItemPreview file={documents.COA[0]} title="PRODUCT COA" />}
            </>
          )}
          <DataItemPreview
            item={documentCapabilities?.length ? documentCapabilities : '-'}
            title="DOCUMENTS INCLUDED"
          />
          {!isSimpleOffer && (
            <>
              <DataItemPreview item={productionLocation?.fullAddress} title="PRODUCTION LOCATION" />
              <DataItemPreview item={deal?.shipping?.packaging} title="PACKAGING" />
              <DataItemPreview item={customLabelPossible ? 'Custom label possible' : 'Custom label not possible'} title="CUSTOM LABEL" />
              {!isToBeProduced && !isRfp && (
                <>
                  <DataItemPreview item={productionDate} dateFormat="DD MMM YYYY" isDate title="production date" />
                  <DataItemPreview item={expirationDate} dateFormat="DD MMM YYYY" isDate title="expiration date" />
                </>
              )}
            </>
          )}
          <DataItemPreview item={salesTerms} title="SALES TERMS" />

          {salesTerms?.value === 'MPC' && (
            <a href={MpcDocLink} target="_blank" rel="noreferrer" className="document-link">
              <i className="icon-flag" />
              <span>MPC sales terms</span>
            </a>
          )}

          {
            !!documents
            && !!documents.SALES_TERMS
            && !!documents.SALES_TERMS.length
            && <DataItemPreview file={documents.SALES_TERMS[0]} title="SALES TERMS DOCUMENT" />
          }
        </div>

        {/* VAS section */}
        <div className="page-column">

          <>
            <div className="page-section-title">Shipping & Logistics</div>
            {!isSimpleOffer && (
              <>
                {/* RFP, when Seller agreed on delivery terms */}
                <RfpDealLogisticsAgreed />
                {/* RFP and Offer, with handler */}
                <OfferDealLogisticsWithHandler />
                {/* RFP and Offer, no handler */}
                <OfferDealLogisticsNoHandler />
              </>
            )}

            {/* Simple offer */}
            <OfferDealLogisticsSimple />
          </>

          <>
            <div className="page-section-title">Financing</div>
            {!isSimpleOffer && (
              <>
                {/* RFP, agreed on payment */}
                <RfpDealFinancingAgreed />
                {/* RFP and Offer, with handler */}
                <OfferDealFinancingWithHandler />
                {/* RFP and Offer, no handler */}
                <OfferDealFinancingNoHandler />
              </>
            )}

            {isSimpleOffer && (
              <DataItemPreview
                title="Seller payment terms"
                item={paymentTerms}
              />
            )}
          </>

        </div>
        {/* VAS section === END */}

        {deal && (
          <div className="page-column">
            <div className="page-section-title">Buyer</div>
            <DataItemPreview item={deal.buyerOrg.orgName} title="BUYER COMPANY" />
            <DataItemPreview item={deal.buyerOrg.country} title="COUNTRY" />
            <DataItemPreview item={getFullName(deal.buyer.profile)} title="BUYER NAME" />
            <DataItemPreview item={deal.buyer.profile.email} title="EMAIL" isMailLink />
            <DataItemPreview item={deal.buyer.profile.phoneNumber} title="TELEPHONE" />

            <div className="page-section-title">Seller</div>
            <DataItemPreview item={getFullName(deal.seller.profile)} title="SELLER NAME" />
            <DataItemPreview item={deal.seller.profile.email} title="EMAIL" isMailLink />
            <DataItemPreview item={deal.seller.profile.phoneNumber} title="TELEPHONE" />
          </div>
        )}

      </div>

      <Link
        to={`/${isRfp ? 'rfp/proposals' : 'onspec/offers'}/${isToBeProduced ? bundleId : id}`}
      >
        Show {offerTypeTitle.toLowerCase()} details
      </Link>

      <div>
        <div className="page-section-title">This is what will happen next:</div>

        <div>
          Please contact the buyer as soon as possible to arrange the fulfillment of the deal.
          {' '}
          <br />
          If you need any assistance from the OpenDairy team please don&apos;t hesitate to contact us.

          <br />
          <br />
          <br />
          <OfferDisclaimer userType="seller" />
        </div>
      </div>

      {/* ************************** product's characteristics details **************************  */}
      {isPopup && productCharacteristics && (
        <ProductSpecsDetailsModal
          handleClose={setIsPopup}
          characteristics={productCharacteristics}
          coaUsed={coaUsed}
        />
      )}

    </>
  );
};

export default AlreadyProducedDealSummary;
