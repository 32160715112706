/* eslint-disable react/jsx-props-no-spreading */
import React           from 'react';
import FormattedPrice  from 'SHARED/components/Price';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import                 './styles/modal-styles.scss';

interface PropsAcceptOffer {
  extraCosts: number
  offerVolume: number,
  offerPrice: number,
  currency: string,
  handleClose: (value: React.SetStateAction<boolean>) => void,
  acceptBid: () => void,
}

const AcceptBidSummaryModal: React.FC<PropsAcceptOffer> = ({
  handleClose, offerVolume, offerPrice, extraCosts, acceptBid, currency,
}) => {
  const offerTotal = offerVolume * offerPrice + extraCosts;

  return (
    <div className="popup">
      <div className="popup-overlay" />
      <div className="popup-body">
        <a href="##" className="popup-close" onClick={(e) => { handleClose(false); }}>
          close
        </a>

        <div>
          <h1>You are about to close the deal!</h1>
          <div>The deal will be made with the bid volume. For splittable offers, you will need to create a new offer for possible rest volume.</div>

          <br />

          <DataItemPreview item={offerVolume} title="VOLUME" suffix="mt" />
          <FormattedPrice suffix="/mt" prefix={currency} value={offerPrice} title="TOTAL PRICE" />
          <FormattedPrice suffix="/mt" prefix={currency} value={offerTotal} title="TOTAL DEAL PRICE" />

          <div>
            <div>
              <button type="submit" className="btn-primary" onClick={acceptBid}>Accept the bid</button>
              <button type="button" className="btn-secondary" onClick={(e) => { handleClose(false); }}>Back to offer</button>
            </div>

          </div>

        </div>
      </div>
    </div>
  );
};
export default AcceptBidSummaryModal;
