import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import routes from 'SHARED/types/routes';
import validationRules from 'SHARED/helpers/validation';
import FormInput from 'SHARED/components/FormInput';
import { FormProvider, useForm } from 'react-hook-form';
import Preloader from 'SHARED/components/ThePreloader';
import { NewProductForm } from 'SHARED/types/specsTypes';
import axios from 'SHARED/helpers/axios';
import { AxiosResponse } from 'axios';
import FormMultiSelect from 'SHARED/components/MultiSelect';
import notification from 'SHARED/helpers/notifications';
import { IBuyerResponseType } from 'SHARED/redux/models/IBuyer';
import { Group, OrgItem } from '../List';
import TopSection from './TopSection';
import SelectedBuyers from './SelectedBuyers';
import '../groups.scoped.scss';

export interface RouteParams {
  specType: string,
}

interface BuyerItem { value: number, label: string }

interface GroupDedails extends Omit<Group, 'id'> {
  id: number | null
}

const initialGroupData: GroupDedails = {
  id: null,
  name: '',
  description: '',
  organizations: [],
};

const CreateBuyersGroup: React.FC = () => {
  const methods = useForm<GroupDedails>({ defaultValues: initialGroupData });
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);
  const [buyers, setBuyers] = useState<BuyerItem[]>([]);
  const isEditMode = id !== undefined;
  const history = useHistory();

  // ******** form section
  const { formState: { isDirty }, watch, reset } = methods;
  const selectedBuyers: OrgItem[] = watch('organizations', []);

  useEffect(() => {
    getBuers();
    if (isEditMode) { getGroupDetails(); }
  }, []);

  // **************** getting groups' details ****************
  const getGroupDetails = async () => {
    setLoading(true);

    try {
      const responce: AxiosResponse<any> = await axios.get(`v1/buyer-group/${id}`);
      reset(responce.data);
    } finally {
      setLoading(false);
    }
  };

  // **************** getting buyers list for dropdown ****************
  const getBuers = async () => {
    setLoading(true);

    try {
      const responce: AxiosResponse<IBuyerResponseType> = await axios.get('/v1/organizations/buyer?offSpecTrader=true');
      const buyersList: BuyerItem[] = responce.data.rows.map((b) => ({ value: b.id, label: `${b.orgName} (${b.country})` }));
      setBuyers(buyersList);
    } finally {
      setLoading(false);
    }
  };

  // **************** Submitting new or edited FORM ****************
  const onSubmit = async (payload: NewProductForm) => {
    setLoading(true);

    try {
      if (isEditMode) {
        await axios.put(`/v1/buyer-group/${id}`, payload);
      } else {
        await axios.post('/v1/buyer-group', payload);
      }

      const message = isEditMode ? 'Group has been updated' : 'Group has been created';

      notification({
        title: 'Success',
        message,
      });
      history.goBack();
    } finally {
      setLoading(false);
    }
  };

  // **************** DELETE group ****************
  const removeGroup = async () => {
    setLoading(true);

    try {
      await axios.delete(`/v1/buyer-group/${id}`);
      notification({
        title: 'Success',
        message: 'Group has been deleted',
      });
      history.goBack();
    } finally {
      setLoading(false);
    }
  };

  const handleDiscard = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    history.goBack();
  };

  return (
    <>
      <Preloader isLoading={loading} />

      {/* <DevTool control={methods.control} placement="top-right" /> */}

      <div className="breadcrumbs">
        <Link to={routes.common.buyerGroups}>
          &lt;
          {' '}
          All groups
        </Link>
      </div>

      <div className="page-body">

        <TopSection isEditMode={isEditMode} removeGroup={removeGroup} />

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>

            <FormInput
              name="name"
              label="INTERNAL GROUP TITLE"
              rules={validationRules.required}
            />

            <FormInput
              name="description"
              textarea
              label="DESCRIPTION"
            />

            <FormMultiSelect
              name="organizations"
              label="BUYERS IN THIS GROUP"
              // @ts-expect-error: // TODO: fix it asap
              options={buyers}
              value={[{ label: 'U.K.M. Int’l Enterprise', value: '5' }]}
              rules={{ required: 'Select at least one buyer' }}
            />

            <SelectedBuyers buyers={selectedBuyers} />

            <button type="submit" className="btn-primary" disabled={!isDirty}>Apply</button>
            <button type="button" onClick={(e) => handleDiscard(e)} className="btn-secondary">Cancel</button>
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default CreateBuyersGroup;
