import React from 'react';
import { OrgItem } from '../List';

export interface ISelectedBuyersProps {
  buyers: OrgItem[]
}

const SelectedBuyers: React.FC<ISelectedBuyersProps> = ({ buyers }) => (
  <>
    <div className="label-text">SELECTED</div>
    <ul>
      {buyers.map((b) => (<li key={b.value}>{b.label}</li>))}
    </ul>
  </>
);

export default SelectedBuyers;
