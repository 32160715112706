import { axios_Core } from 'SHARED/helpers/axios';
import { ICounterOffer } from 'SHARED/types/models/ICounterOffer';

export const postSubmitBid = async (bid: ICounterOffer, type: 'VALIDATE' | 'SUBMIT') => {
  const isCommit = type === 'SUBMIT';

  try {
    const response = await axios_Core.post(`/v1/negotiation/bid?commit=${isCommit}`, bid);

    return response;
  } catch (error: unknown) {
    console.log('submitBid error', error);
    return error;
  }
};
