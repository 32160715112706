import React, { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import texts from 'SHARED/helpers/texts';
import Select from 'SHARED/components/Select';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { ProductCategory } from 'SHARED/types/specsTypes';
import { Dictionary } from 'SHARED/types/offerTypes';
import PackageItem, { FormPackageItem } from './PackageItem';
import './styles/packages.scoped.scss';

interface FormPackage {
  productType: Dictionary | null,
  product: Dictionary | null,
  fullfilled: boolean
}

const getUniqueId = () => new Date().getTime();

export const newPackage: () => FormPackageItem = () => ({
  productType: null, product: null, fullfilled: false, id: getUniqueId(),
});

const PackagesFlowSection: React.FC<{ isForRfp: boolean, ignoreNoContainers?: boolean }> = ({ isForRfp, ignoreNoContainers }) => {
  const { ON_SPEC } = useTypedSelector((state) => state.offer);
  const { containers } = useTypedSelector((state) => state.dictionaries);
  const { categories } = useTypedSelector((state) => state.specs);

  const methods = useFormContext();
  const { watch, setValue } = methods;
  const choosedProductCategory: ProductCategory = watch('productCategory');
  const formPackages: FormPackage[] | undefined = watch('products');

  const { products, productCategory } = ON_SPEC;
  const [noContainers, setNoContainers] = useState<boolean>(false);
  const { fields: packages, append, remove } = useFieldArray({ name: 'products' });

  useEffect(() => {
    if (products && products.length === 0) {
      append(newPackage());
    } else {
      setValue('products', products);
    }
  }, [products]);

  useEffect(() => {
    // if there is no defined container for choosed category STOP
    // and show appropriate message
    choosedProductCategory && !containers[choosedProductCategory.value] ? setNoContainers(true) : setNoContainers(false);

    if (choosedProductCategory && choosedProductCategory.value !== productCategory?.value) {
      setValue('products', [newPackage()]);
    }
  }, [choosedProductCategory]);

  const handleAddPackage = () => {
    append(newPackage());
  };

  const removePackage = (index: number) => {
    remove(index);
  };

  const canAddNew = formPackages ? formPackages[formPackages.length - 1]?.fullfilled : false;

  return (
    <>
      <input type="hidden" {...methods.register('type')} value="TO_BE_PRODUCED" />

      <Select
        label="PRODUCT CATEGORY"
        name="productCategory"
        options={categories}
        selected={productCategory}
      />

      {noContainers && !ignoreNoContainers && <div className="attention-message alert">{texts.offerTexts.common.noContainers}</div>}

      {choosedProductCategory && (
        <div className="packages-list">
          {packages.map((pack, index) => (
            <PackageItem
              key={pack.id}
              index={index}
              id={1}
              removePackage={removePackage}
              count={packages.length}
              choosedProductCategory={choosedProductCategory}
            />
          ))}
          {!isForRfp && canAddNew && (
            <button
              type="button"
              className="btn-primary"
              onClick={handleAddPackage}
            >
              Add another product package
            </button>
          )}

        </div>
      )}

    </>
  );
};

export default PackagesFlowSection;
