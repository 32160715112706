import { handleDate, handleDateRange } from 'SHARED/helpers/common';
import { OfferListItem, OfferTableItem } from 'SHARED/types/offerTypes';
import { OfferListTypes } from 'SHARED/pages/OffersList';

// to iterate trough it in table and cards
export const onspecBuyer = (offer: OfferListItem, activeTab: OfferListTypes) => {
  const {
    ourLastBidVolume,
    endDate,
    price,
    currency,
    departurePeriod,
    departureFrom,
    departureTo,
    productCategory,
    productType,
    splittable,
    statusLabel,
    seller,
    ourLastBid,
    country,
    isApproved,
    deal,
    matchedProducts,
    type,
    remainingVolume,
  } = offer;

  const departure = departurePeriod === 'MONTH' ? handleDate({ date: departureFrom, format: 'MMM YYYY' }) : handleDateRange({ from: departureFrom, to: departureTo });
  const logistics = activeTab === 'deals' ? deal?.shipping?.logisticHandler?.label || deal?.shipping?.logisticHandlerType.label : '';
  const format = 'DD MMM YYYY';

  const productTitle = [productCategory, productType]
    .filter((part) => part)
    .join(' ');

  // creating offer list item data
  // for table and cards
  const activeOffers: OfferTableItem[] = [
    { key: 'endDate', value: endDate ? handleDate({ date: endDate, format }) : '-', label: 'OFFER VALID UNTIL' },
    { key: 'type', value: type, label: 'TYPE' },
    { key: 'product', value: productTitle, matchedProducts, label: 'PRODUCT & MATCH' },
    { key: 'volume', value: `${remainingVolume} mt`, label: 'REMAINING VOLUME', isSplittable: splittable },
    { key: 'seller', value: seller, label: 'SELLER', isApproved },
    { key: 'country', value: country, label: 'COUNTRY' },
    { key: 'departure', value: departure, label: 'DEPARTURE' },
    { key: 'price', value: price, prefix: currency, label: 'OFFER PRICE EXCL. SERVICES' },
    { key: 'ourLastBid', value: ourLastBid, ourLastBidVolume, prefix: currency, label: 'OUR BID | VOLUME' },
  ];
  const archivedOffers: OfferTableItem[] = [
    { key: 'endDate', value: handleDate({ date: endDate, format }), label: 'OFFER VALID UNTIL' },
    { key: 'type', value: type, label: 'TYPE' },
    { key: 'product', value: `${productCategory} ${productType}`, matchedProducts, label: 'PRODUCT & MATCH' },
    { key: 'volume', value: `${remainingVolume} mt`, label: 'REMAINING VOLUME', isSplittable: splittable },
    { key: 'seller', value: seller, label: 'SELLER', isApproved },
    { key: 'departure', value: departure, label: 'DEPARTURE' },
    { key: 'ourLastBid', value: ourLastBid, ourLastBidVolume, prefix: currency, label: 'OUR BID | VOLUME' },
    { key: 'status', value: statusLabel, label: 'STATUS' },
  ];
  const deals: OfferTableItem[] = [
    { key: 'placed', value: handleDate({ date: deal?.created, format }), label: 'DEAL DONE ON' },
    { key: 'type', value: type, label: 'TYPE' },
    { key: 'product', value: `${productCategory} ${productType}`, matchedProducts, label: 'PRODUCT' },
    { key: 'volume', value: `${deal?.volume} mt`, label: 'VOLUME' },
    { key: 'seller', value: deal?.sellerOrg.orgName, label: 'SELLER', isApproved },
    { key: 'country', value: deal?.sellerOrg.country, label: 'COUNTRY' },
    { key: 'departure', value: departure, label: 'DEPARTURE' },
    { key: 'logistics', value: logistics, label: 'LOGISTICS' },
    { key: 'price', value: deal?.price, prefix: deal?.currency, label: 'DEAL PRICE' },
  ];

  if (activeTab === 'archived-offers') {
    return archivedOffers;
  }
  if (activeTab === 'deals') {
    return deals;
  }

  return activeOffers;
};
