import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Preloader from 'SHARED/components/ThePreloader';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { useActions } from 'SHARED/redux/hooks/useActions';

const OauthPage: React.FC = () => {
  const { search } = useLocation();
  const query = qs.parse(search, { ignoreQueryPrefix: true });

  const { isLoggedIn } = useTypedSelector((state) => state.oauth);
  const { getAccessCode } = useActions();

  useEffect(() => {
    console.log('isLoggedIn', isLoggedIn);

    if (query.code && !isLoggedIn) {
      console.log('make request to OAUTH');

      getAccessCode(query.code as string);
    } else {
      console.log('query code not provided');
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Oauth login</title>
      </Helmet>

      <Preloader isLoading />

      <div className="page-container">
        <div className="page-top">
          <h1 className="page-title">Oauth login</h1>
        </div>

        <div className="page-body" />
      </div>
    </>
  );
};

export default OauthPage;
