import { useEffect, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import useQueryItem from 'SHARED/hooks/useQueryItem';
import axios from 'SHARED/helpers/axios';
import { AxiosResponse } from 'axios';
import texts from 'SHARED/helpers/texts';
import { defaultOffspecOffer } from 'SHARED/initialState/offer';
import { useActions } from 'SHARED/redux/hooks/useActions';
import { IOffspecOffer } from 'SHARED/types/offerTypes';

const useDuplicateAuction = (setValue?: UseFormSetValue<any>) => {
  const duplicateId = useQueryItem('duplicate');

  const { saveOffer, resetOffer } = useActions();

  const [duplicateLoading, setDuplicateLoading] = useState(false);
  const [duplicateMessage, setDuplicateMessage] = useState<any>(null);
  const [duplicateError, setDuplicateError] = useState<any>(null);

  // now we need to get auction data for duplication, but modify it before saving to redux
  const getDuplicatedOffer = async (offerId: number | string) => axios.get(`/v1/offers/${offerId}`);

  useEffect(() => {
    if (duplicateId) {
      resetOffer();
      setDuplicateLoading(true);
      getDuplicatedOffer(duplicateId)
        .then((res: AxiosResponse) => res.data)
        .then((offerData: any) => {
          // check if offer is of type auction
          if (offerData.type.value !== 'OFF_SPEC') {
            // need to set error message here as well
            setDuplicateError(texts.offerDuplicationTexts.auctionTypeError);
            // stop loading
            setDuplicateLoading(false);
            // break out of the function
            return;
          }

          const {
            // fields that can cause problems when duplicating
            status,
            remainingVolume,
            // ====================
            ...offerFieldsToDuplicate
          } = offerData as IOffspecOffer;

          // ? clean up some fields from original auction
          const cleanedData = {
            ...defaultOffspecOffer,
            ...offerFieldsToDuplicate,
            buyersFetchType: defaultOffspecOffer.buyersFetchType,
            incoterms: null,
            loadAddress: null,
            buyers: [],
            groups: [],
            loadDateFrom: defaultOffspecOffer.loadDateFrom,
            loadDateTo: defaultOffspecOffer.loadDateTo,
            endDate: defaultOffspecOffer.endDate,
            title: defaultOffspecOffer.title,
            params: {
              ...defaultOffspecOffer.params,
              salesTerms: offerData.params.salesTerms,
            },
            documents: {
              COA: offerData.documents?.COA?.map((doc: File) => ({ ...doc, duplicate: true })),
              SALES_TERMS: offerData.documents?.SALES_TERMS?.map((doc: File) => ({ ...doc, duplicate: true })),
            },
          };

          // ? then put cleaned up data into `offerState`
          saveOffer({ offer: cleanedData, offerType: 'OFF_SPEC' });

          setDuplicateMessage(texts.offerDuplicationTexts.auction);
          setDuplicateLoading(false);
        })
        .catch(() => {
          setDuplicateError(texts.offerDuplicationTexts.auctionNotFound);
          setDuplicateLoading(false);
        });
    }
  }, [duplicateId]);

  return { duplicateLoading, duplicateId, duplicateMessage, duplicateError };
};

export default useDuplicateAuction;
