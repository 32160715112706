import React from 'react';
import { handleDate } from 'SHARED/helpers/common';
import { Message } from 'twilio-chat';

export interface MessageProps {
  message: Message,
  isIncome: boolean,
  userName: string,
  onEdit?: (message: Message) => void,
}

export default function MessageItem(props: MessageProps) {
  const {
    message, isIncome, userName,
  } = props;

  return (
    <div className={isIncome ? 'message-left' : 'message-right'}>
      <div className="message-header">{userName}</div>
      <div className="message-body">{message.body}</div>
      <div className="message-footer">{handleDate({ date: message.dateCreated, format: 'DD MMM YYYY hh:mm' })}</div>
    </div>
  );
}
