import React, { useEffect, useState } from 'react';
import ProductSpecsDetailsModal from 'SHARED/modals/ProductSpecsDetailsModal';
import { useFormContext } from 'react-hook-form';
import Select from 'SHARED/components/Select';
import { Dictionary } from 'SHARED/types/offerTypes';
import { ProductCategory, ProductListItem } from 'SHARED/types/specsTypes';
import FormattedPrices from 'SHARED/components/Prices';
import './styles/packages.scoped.scss';
import { Link } from 'react-router-dom';
import routes from 'SHARED/types/routes';
import { getProducts } from 'SHARED/api/common/get/getProducts';
import Preloader from 'SHARED/components/ThePreloader';

interface Props {
  index: number,
  count: number,
  id: number,
  choosedProductCategory: ProductCategory,
  removePackage: (index: number) => void
}

export interface FormPackageItem {
  productType: Dictionary | null
  product: Dictionary | null
  fullfilled: boolean,
  id: number,
}

interface PackageItemFields {
  productType: string
  product: string
  fullfilled: string
  title: string,
  premiumPriceUsd?: any
  premiumPriceEur?: any
}

const PackageItem: React.FC<Props> = ({
  index, choosedProductCategory, count, removePackage, id,
}) => {
  const methods = useFormContext();
  const { watch, setValue, register, formState: { dirtyFields } } = methods;

  const packageName = `products[${index}]`;
  const names: PackageItemFields = {
    productType: `${packageName}.productType`,
    product: `${packageName}.product`,
    fullfilled: `${packageName}.fullfilled`,
    title: `${packageName}.productTitle`,
    premiumPriceUsd: `${packageName}.premiumPriceUsd`,
    premiumPriceEur: `${packageName}.premiumPriceEur`,
  };

  const [products, setProducts] = useState<ProductListItem[] | null>(null);
  const [isPopup, setIsPopup] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const selectedType: Dictionary | null = watch(names.productType);
  const selectedProduct: Dictionary | null = watch(names.product);
  const fullfilled: boolean | null = watch(names.fullfilled);

  const selectOptions = products ? products.filter((p) => p.active).map((p) => ({ label: p.title, value: p.id.toString(), premiumPriceUsd: p.premiumPriceUsd, premiumPriceEur: p.premiumPriceEur })) : [];

  const productsExists = products && products.length && products.some((p) => p.active);

  useEffect(() => {
    // ? this doesn't seem to work, because `dirtyFields[packageName]` is always `undefined`
    // if (dirtyFields[packageName]) {
    //   setValue(names.product, null);
    //   setProducts(null);
    // }

    if (dirtyFields.products) {
      setValue(names.product, null);
      setProducts(null);
    }

    if (selectedType) {
      (async () => {
        setLoading(true);

        const response = await getProducts({
          category: choosedProductCategory.value,
          type: selectedType.value,
        });

        if (!response.isError) {
          setProducts(response.data);
        }

        setLoading(false);
      })();
    }
  }, [selectedType]);

  const handleRemove = () => {
    removePackage(index);
  };

  const openSpecsPopup = () => {
    setIsPopup(true);
  };

  const choosedProduct = products?.find((p) => p.id.toString() === selectedProduct?.value) || null;
  // const premiumPrices = choosedProduct ? [choosedProduct.premiumPriceEur, choosedProduct.premiumPriceUsd] : [null, null]; // ? old variant, does not work correctly with duplicate logic, since state is local and there is no way to change it globally
  const premiumPrices = [(selectedProduct as any)?.premiumPriceEur || null, (selectedProduct as any)?.premiumPriceUsd || null];

  useEffect(() => {
    const isFullfilled = !!selectedType && !!selectedProduct;
    setValue(names.fullfilled, isFullfilled);
  }, [selectedType, selectedProduct]);

  useEffect(() => {
    setValue(names.title, selectedProduct?.label);
  }, [selectedProduct]);

  return (
    <>
      <Preloader isLoading={loading} />

      <div className="package-item">
        <div className="package-item-wrapper">
          <h2>{`Package ${index + 1}`}</h2>

          {count > 1 && (
            <button
              type="button"
              className="remove-control btn-icon"
              onClick={handleRemove}
            >
              {/* <i className="icon-trash" /> */}
              <i className="icon-delete" />
            </button>
          )}

          <input type="hidden" {...register(names.fullfilled)} />
          <input type="hidden" {...register(names.title)} />

          {choosedProductCategory.productTypes && (
            <Select
              label="PRODUCT TYPE"
              name={names.productType}
              options={choosedProductCategory.productTypes}
              selected={selectedType}
            />
          )}

          {!!(selectedType && productsExists) && (
            <Select
              label="OUR PRODUCT SPECS"
              name={names.product}
              options={selectOptions}
              selected={selectedProduct}
            />
          )}

          {/* {selectedProduct && productsExists && ( */}
          {selectedProduct && fullfilled && (
            <>
              {/* // ? temporary logs */}
              {/* <pre>
                {JSON.stringify(selectedProduct, null, 2)}
              </pre> */}
              {(premiumPrices[0] || premiumPrices[1]) && (
                <div>
                  Premium for this package
                  <FormattedPrices values={premiumPrices} className="inline-block" suffixes={['', '/mt']} />
                </div>
              )}
              <button
                type="button"
                onClick={openSpecsPopup}
                className="link-button"
              >
                View product specifications
              </button>
            </>
          )}

          {selectedType && !productsExists && !loading && (
            <div className="attention-message">
              You have no <Link to={routes.common.specifications}>Product Specification</Link> for this product type. You can create a new specification in your <Link to={routes.common.specifications}>organisational profile</Link>
            </div>
          )}

        </div>
      </div>

      {isPopup && productsExists && (
        <ProductSpecsDetailsModal
          handleClose={setIsPopup}
          characteristics={choosedProduct?.characteristics}
        />
      )}

    </>
  );
};

export default PackageItem;
