import { useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { useActions } from 'SHARED/redux/hooks/useActions';
import { PreventLossUnsavedData } from 'SHARED/hooks/usePreventReload';
import { Helmet } from 'react-helmet-async';
import Preloader from 'SHARED/components/ThePreloader';
import { FormPackageItem } from 'pages/Onspec/components/PackageItem';
import {
  OnSpecOffer,
  Dictionary,
  OfferTypes,
} from 'SHARED/types/offerTypes';
import preparePreview from 'SHARED/helpers/prepareOfferData';
import prepareBuyerGroups from 'SHARED/helpers/prepareBuyerGroups';
import routes from 'SHARED/types/routes';
import { clearProxyFields } from 'SHARED/helpers/common';
import { cloneDeep } from 'lodash';
import useIsForRfp from 'SHARED/hooks/useIsForRfp';
import PackagesFlowSection from 'pages/Onspec/components/PackagesFlowSection';
import {
  volumeUnits,
} from '../../components/AlreadyProducedOfferData';
import ProductSection from '../../components/ProductSection';
import ShippingSection from '../../components/ShippingSection';
import VolumePriceSection from '../../components/VolumePriceSection';
import BottomSection from '../../components/BottomSection';
import 'react-datepicker/dist/react-datepicker.css';
import useDuplicateTBP from './useDuplicateTBP';

const ToBeProducedCreate: React.FC = () => {
  const history = useHistory();

  // ********************* REDUX section *********************
  const { me } = useTypedSelector((state) => state.users);
  const { ON_SPEC, offerDuplicateLoading } = useTypedSelector((state) => state.offer);
  const { dictionaries } = useTypedSelector((state) => state.dictionaries);
  const { loading } = useTypedSelector((state) => state.specs);

  const {
    getDictionaries,
    saveOffer,
    resetOffer,
    getProductCategoriesList,
    getLocations,
    getContainers,
  } = useActions();

  // 1 - waiting for org id
  // then get all data for offer creations
  useEffect(() => {
    if (me.orgId) {
      getDictionaries({ offerType: 'TO_BE_PRODUCED' });
      getProductCategoriesList(false);
      getLocations({ type: 'BOTH', orgId: me.orgId });
      getContainers(me.orgId);
    }
  }, [me]);

  const methods = useForm({
    shouldUnregister: true,
  });

  const { isForRfp, rfpID } = useIsForRfp();

  const {
    params,
    documents,
    departurePeriod,
    products,
  } = ON_SPEC;

  // 3 - watch form values section
  // for dynamic condition rendering and properly date validation
  const { watch, formState: { isDirty }, setValue } = methods;

  const choosedDeparturePeriod: string = watch('departurePeriod', departurePeriod);
  const choosedProductCategory: Dictionary = watch('productCategory');
  const packages: FormPackageItem[] = watch('products', products);

  const fullfilledExists: boolean = packages.filter((p: any) => p.fullfilled).length > 0;

  // ********************* DUPLICATE OFFER section *********************
  // Prefill logic encapsulated into a hook
  const { duplicateMessage, duplicateError } = useDuplicateTBP(setValue, methods.getValues);

  // 2 -   handling user discard offer creation
  // 2.1 - reset offer's state in redux
  // 2.2 - redirect back to offers list page
  const handleDiscard = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    resetOffer();
    history.push(routes.onspec.listDefault);
  };

  if (isForRfp) {
    setValue('parentOfferId', rfpID);
  }

  useEffect(() => {
    getDictionaries({
      offerType: 'TO_BE_PRODUCED',
      productCategory: choosedProductCategory?.value || '',
    });
  }, [choosedProductCategory]);

  // if form changed prevent closing browser tab
  // without prompting user
  PreventLossUnsavedData(isDirty);

  // ********************* SUBMIT sections *********************
  const onSubmit = (data: OnSpecOffer) => {
    const prepared = preparePreview(cloneDeep(clearProxyFields(data)), dictionaries);

    if (data.products?.length === 0) {
      return false;
    }

    prepared.volumeUnits = volumeUnits;
    prepared.params = {
      ...params,
      excludedBuyers: prepared.buyers,
    };
    prepared.documents = documents;
    prepared.productionLocationId = prepared.productionLocation.id;
    prepared.logisticLocationId = prepared.logisticLocation.id;

    if (prepared.groups?.length > 0) {
      prepared.buyers = prepareBuyerGroups(prepared.groups);
    }

    // if COA document provided
    if (prepared.coaUsed) {
      prepared.documents = {
        ...prepared.documents,
        COA: prepared.coaDocument,
      };

      delete prepared.coaDocument;
    }

    // if Sales terms document provided
    if (prepared.salesTerms?.value === 'OWN') {
      prepared.documents = {
        ...prepared.documents,
        SALES_TERMS: prepared.termsDocument,
      };

      delete prepared.termsDocument;
    }

    // preparing UI field values for backend model
    if (choosedDeparturePeriod === 'MONTH') {
      prepared.departureFrom = prepared.departureFromMonth.value;
      prepared.departureTo = new Date().toISOString();
    }

    // single currency (if currency is disabled -- set corresponding price to null)
    // but at least one price should be set
    if (prepared.priceUsdDisabled && prepared.priceEurDisabled) { return false; }
    if (prepared.priceEurDisabled && !prepared.priceUsdDisabled) { prepared.priceEur = null; }
    if (prepared.priceUsdDisabled && !prepared.priceEurDisabled) { prepared.priceUsd = null; }

    saveOffer({ offer: prepared, offerType: 'ON_SPEC' });

    // console.log('data', data);
    // console.log('prepared', prepared);
    // return true;

    if (isForRfp && rfpID) {
      history.push(routes.onspec.previewTP_rfp(rfpID as string));
    } else {
      history.push(routes.onspec.previewTP);
    }

    return true;
  };

  return (
    <>
      <div className="breadcrumbs">
        <a href={routes.onspec.listDefault}>&lt; All offers </a>
      </div>

      <div className="page-body is-logged-in">
        <Helmet>
          <title>Create to be produced offer</title>
        </Helmet>

        <Preloader isLoading={loading || offerDuplicateLoading} />

        <div>
          <h1 className="page-title with-separator">Offer for To be produced product</h1>

          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div className="offer-wrapper">

                <div className="offer-left-side">
                  <h2 className="page-section-title">
                    Offer product
                  </h2>

                  {duplicateMessage && !duplicateError && <div className="attention-message">{duplicateMessage}</div>}
                  {duplicateError && <div className="attention-message alert">{duplicateError}</div>}

                  <input type="hidden" {...methods.register('type')} value={OfferTypes.toBeProduced} />

                  <PackagesFlowSection isForRfp={isForRfp} ignoreNoContainers={duplicateMessage} />

                  {fullfilledExists && (
                    <>
                      <ProductSection offerType={OfferTypes.toBeProduced} />
                      <VolumePriceSection offerType={OfferTypes.toBeProduced} isForRfp={isForRfp} />
                      <ShippingSection offerType={OfferTypes.toBeProduced} />
                      <BottomSection offerType={OfferTypes.toBeProduced} isForRfp={isForRfp} />
                    </>
                  )}

                  <button type="submit" disabled={!choosedProductCategory} className="btn-primary">Preview offer</button>
                  <button type="button" onClick={(e) => handleDiscard(e)} className="btn-secondary">Discard</button>

                </div>
              </div>
            </form>
          </FormProvider>

        </div>
      </div>
    </>
  );
};

export default ToBeProducedCreate;
