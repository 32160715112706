import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import FormattedPrice from 'SHARED/components/Price';
import { Deal } from 'SHARED/types/offerTypes';
// import { Bid } from 'SHARED/types/bidTypes';

interface IDealInfoRowProps {
  deal: Deal
  askPrice?: number | null
}

export const DealInfoRow: FC<IDealInfoRowProps> = ({
  deal,
  askPrice = 0,
}) => (
  <>
    <div className="bid-info-row row">
      <div className="col"><DataItemPreview item={deal.created} isDate title="DATE" /></div>
      <div className="col"><DataItemPreview item={deal.buyerOrg.orgName} title="BUYER" /></div>
      <div className="col"><DataItemPreview item={deal.buyerOrg.country} title="COUNTRY" /></div>
      <div className="col"><FormattedPrice className="bid-price" value={deal.price} title="BID" prefix={deal.currency} suffix="/mt" /></div>
      <div className="col"><DataItemPreview item={deal.volume} title="BID VOLUME" suffix="mt" /></div>
      <div className="col"><FormattedPrice className="bid-price" value={deal.amount} title="TOTAL" prefix={deal.currency} /></div>

      {/* if it's winner row */}
      <div className="deal-link col">
        <Link to={`/offspec/deals/${deal.id}`}>View deal details</Link>
      </div>
    </div>
  </>
);

export default DealInfoRow;
