import React, { FC, ReactNode } from 'react';

interface IProps {
  children: ReactNode;
}

const InfoBlock: FC<IProps> = ({ children }) => (
  <div className="attention-message inset info-bar">
    {children}
  </div>
);

export default InfoBlock;
