import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Preloader from 'SHARED/components/ThePreloader';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { useActions } from 'SHARED/redux/hooks/useActions';
import EditProductTitleModal from 'SHARED/modals/EditProductTitleModal';
import renderMultiline from 'SHARED/helpers/renderMultiline';
import SpecificationItem from './SpecificationItem';
import './categories.scoped.scss';

interface SpecsPageProps {
  title: string,
  description: string,
}

const ProductSpecificationsPage: React.FC<SpecsPageProps> = ({ title, description }) => {
  const { specifications, loading } = useTypedSelector((state) => state.specs);
  const { getSpecificationsList, clearProduct } = useActions();

  const [isPopup, setIsPopup] = useState(false);
  const [productId, setProductId] = useState<number | string>();

  const openPopup = (id: number | null) => {
    if (id) {
      setProductId(id);
      setIsPopup(true);
    } else {
      setIsPopup(false);
    }
  };

  const handleClose = () => {
    clearProduct();
    setIsPopup(false);
  };

  useEffect(() => {
    getSpecificationsList();
  }, []);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      {isPopup && productId && (
        <EditProductTitleModal id={productId} handleClose={() => handleClose()} />
      )}

      <Preloader isLoading={loading} />

      <div className="page-container">
        <div className="specs-top">
          <h1 className="specs-title">{title}</h1>
          <div className="description">{renderMultiline(description)}</div>
        </div>

        <div>
          <div className="specs-list">
            {specifications.map(({ productCategory, products }) => (
              <SpecificationItem
                key={productCategory.value}
                name={productCategory.label}
                products={products}
                category={productCategory.value.toLowerCase()}
                openPopup={openPopup}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductSpecificationsPage;
