import React, { useEffect, useState } from 'react';
import { Channel, Member, Message } from 'twilio-chat';
// import useToggle from '../../hooks/useToggle';
// import Controls from '../controls/Controls';
import MessageItem from './MessageItem';

export interface ChatProps {
  channel: Channel,
  identity: string,
}
export default function Chat(props: ChatProps) {
  // const classes = useStyles();
  const { channel, identity } = props;

  const [members, setMembers] = useState<any>([]);
  const [descriptors, setDescriptors] = useState<any>([]);
  // const [users, setUsers] = useState<any>([]);
  const [messages, setMessages] = useState<Message[]>();
  const [message, setMessage] = useState('');
  const [messageEdit, setMessageEdit] = useState<Message>();
  const [typingMember, setTypingMember] = useState('');
  // const [anchorEl, setAnchorEl] = useState(null);
  // const { toggle, setToggle } = useToggle();

  const [error, setError] = useState('');

  // cleanup client listeners on unmount
  useEffect(() => () => { channel?.removeAllListeners(); }, []);

  // load messages and channel members
  useEffect(() => {
    if (channel) {
      loadMessages(channel);
      channel.on(Channel.messageAdded, () => loadMessages(channel));
      channel.on(Channel.messageUpdated, () => loadMessages(channel));
      channel.on(Channel.messageRemoved, () => loadMessages(channel));

      channel.on(Channel.typingStarted, (member) => updateTypingMember(member, true));
      channel.on(Channel.typingEnded, (member) => updateTypingMember(member, false));

      channel.getMembers()
        .then((res) => res.map(mapMember))
        .then(setMembers)
        .catch(setError);
      channel.getUserDescriptors()
        .then((res) => res.items.map((it) => ({ identity: it.identity, online: it.online })))
        .then((res) => setDescriptors(res))
        .catch(setError);
    }
  }, [channel]);

  // check member status by linked user descriptor
  useEffect(() => {
    if (members.length > 0 && descriptors.length > 0) {
      // const result = members.map(
      //   (member: any) => ({
      //     ...member,
      //     online: descriptors.find((desc: any) => member.identity === desc.identity)?.online,
      //   }),
      // );
      // setUsers(result);
    }
  }, [members, descriptors]);

  const loadMessages = (_channel: Channel) => {
    _channel.getMessages()
      .then((res) => setMessages(res.items))
      .catch(setError);
  };

  const mapMessage = (_message: Message) => (
    <MessageItem
      message={_message}
      isIncome={isIncomeMessage(_message.author)}
      userName={getUserName(_message.author)}
      onEdit={handleEdit}
    />
  );

  const getUserName = (_identity: string) => {
    const member = members.find((it: any) => it.identity === _identity);
    return member?.name || identity;
  };

  const isIncomeMessage = (author: string) => author !== identity;

  // const getOnlineUserCount = () => (
  //   users.length > 0 && users.filter((it: any) => it.online).length
  // );

  // @ts-ignore
  const mapMember = (item: Member) => ({ identity: item.identity, name: item.attributes.name });

  const handleSend = () => {
    const text = message?.trim();
    if (channel && text) {
      if (messageEdit) {
        messageEdit.updateBody(text)
          .then(() => {
            setMessage('');
            setMessageEdit(undefined);
          });
      } else {
        channel.sendMessage(message)
          .then(() => setMessage(''))
          .catch(setError);
      }
    }
  };

  const handleEdit = (_message: Message) => {
    setMessageEdit(_message);
    setMessage(_message.body);
  };

  // const sendButton = () => (
  //   <button type="button" disabled={!message.trim()} onClick={handleSend}>Send</button>
  // );

  const updateTypingMember = (member: Member, isTyping: boolean) => {
    if (isTyping) {
      const item = members.find((it: any) => it.identity === member.identity);
      setTypingMember(item?.name);
    } else {
      setTypingMember('');
    }
  };

  // const renderPopover = () => (
  //   <Popover
  //     id={anchorEl ? 'simple-popover' : undefined}
  //     open={!!anchorEl}
  //     anchorEl={anchorEl}
  //     onClose={() => setAnchorEl(null)}
  //     anchorOrigin={{
  //       vertical: 'bottom',
  //       horizontal: 'center',
  //     }}
  //     transformOrigin={{
  //       vertical: 'top',
  //       horizontal: 'center',
  //     }}
  //   >
  //     {renderUserList()}
  //   </Popover>
  // );
  //
  // const renderUserList = () => (
  //   <List>
  //     {users.map((it: any) => (
  //       <ListItem>
  //         <ListItemText primary={`${it.name} ${(it.online && '- Online') || ''}`} />
  //       </ListItem>
  //     ))}
  //   </List>
  // );

  return (
    error
      ? <div className="error">{`Failed to initialize chat: ${error}`}</div>
      : (
        <div className="chatContainer">
          <div className="chatHeader">
            <div className="chat-deal-id">{channel?.friendlyName || 'Loading conversation...'}</div>
            <div className="online-user-counter" />
            <div className="user-list">
              {/* { renderPopover() } */}
            </div>
          </div>
          <div className="collapse-block">
            <div className="chatContent">
              {
                messages && messages.length > 0
                  ? messages.map(mapMessage)
                  : <span color="textSecondary">No messages available</span>
              }
            </div>
            {
              typingMember && (
              <div className="typingIndicator">
                <span>{`${typingMember} is typing...`}</span>
              </div>
              )
            }
            <div className="chatFooter">
              <textarea
                style={{ width: '100%' }}
                value={message}
                onChange={(e) => {
                  channel.typing();
                  setMessage(e.target.value);
                }}
                placeholder="Enter your message"
              />
              <button type="button" className="btn-primary" onClick={handleSend}>Send</button>
            </div>
          </div>
        </div>
      )
  );
}
