import { createAsyncThunk } from '@reduxjs/toolkit';
import { CORE_ENDPOINTS } from 'SHARED/api/endpoints';
import { axios_Core } from 'SHARED/helpers/axios';
import notification from 'SHARED/helpers/notifications';
import { IBuyerResponseType } from 'SHARED/redux/models/IBuyer';
import { IGroup } from 'SHARED/redux/models/IGroup';
import { ILocationsResponseType } from 'SHARED/redux/models/ILocationsResponseType';
import { DictionariesList, DictionaryEntry } from 'SHARED/types/offerTypes';
import { IContainersResponse } from 'SHARED/redux/models/IContainers';
import { offerActions } from '../offer/OfferSlice';

// ===== DICTIONARIES =====
type PayloadOfferType = 'ALREADY_PRODUCED' | 'TO_BE_PRODUCED' | 'OFF_SPEC' | 'ON_SPEC' | 'RFP' | 'RFP_PROPOSAL' | 'ALL';

interface IGetDictionaryArgs {
  offerType: PayloadOfferType
  productCategory?: string
  isBuyer?: boolean
}
const getDictionaries = createAsyncThunk(
  'dictionaries/getDictionaries',
  async (args: IGetDictionaryArgs, { rejectWithValue }) => {
    const { offerType, productCategory = '', isBuyer = false } = args;

    const offerTypeValue = offerType === 'ALL' ? '' : offerType;

    const dictionaryTypesArr: DictionaryEntry[] = [
      'INCOTERM_SELLER',
      'INCOTERM_BUYER',
      'LOADING_DETAILS',
      'PACKAGING_OPTIONS',
      'PAYMENT_TERMS',
      'DOCUMENT_TYPE',
    ];
    const typesStr = dictionaryTypesArr.join(',');

    try {
      const dictionaries = await axios_Core.get<DictionariesList>(
        CORE_ENDPOINTS.getDictionaries({
          types: typesStr,
          offerType: offerTypeValue,
          productCategory,
        }),
      );

      let buyersList: any;
      let buyersGroupsList: any;

      if (!isBuyer) {
        // ? For some unknown reason this redux-action returns 2 responses
        // ? and `buyers` are seems to be not in it's place
        const buyers = await axios_Core.get<IBuyerResponseType>(CORE_ENDPOINTS.buyers);
        const buyersGroups = await axios_Core.get<IGroup[]>(CORE_ENDPOINTS.buyerGroups);

        // adapt buyers list foe multiselect
        // TODO: remove any, it should be `Dictionary[]` for proper typing
        // ? at this point it's two years old code and I don't want to touch it
        buyersList = buyers.data.rows.map((b) => ({ ...b, value: b.id, label: b.orgName })) as any;
        buyersGroupsList = buyersGroups.data.map((b) => ({ ...b, value: b.id, label: b.name })) as any;
      }

      return {
        dictionaries: dictionaries.data,
        buyers: buyersList || [],
        buyersGroup: buyersGroupsList || [],
      };
    } catch (error) {
      console.log('getDictionaries error', error);

      notification({
        type: 'danger',
        title: 'Error',
        message: 'Could not get dictionaries...',
      });

      return rejectWithValue('Could not get dictionaries...');
    }
  },
);
// ===== DICTIONARIES ===== END

// ===== BUYERS =====
const getBuyers = createAsyncThunk(
  'dictionaries/getBuyers',
  async (_, { rejectWithValue }) => {
    try {
      const buyers = await axios_Core.get<IBuyerResponseType>(CORE_ENDPOINTS.buyers);

      // adapt buyers list foe multiselect
      const buyersList = buyers.data.rows.map((b) => ({ ...b, value: b.id, label: b.orgName })) as any;

      return buyersList;
    } catch (error) {
      console.log('getBuyers error', error);

      notification({
        type: 'danger',
        title: 'Error',
        message: 'Could not get buyers...',
      });

      return rejectWithValue('Could not get buyers...');
    }
  },
);
// ===== BUYERS ===== END

// ===== LOCATIONS =====
interface ILocationsArgs {
  type: string
  orgId: number
}
const getLocations = createAsyncThunk(
  'dictionaries/getLocations',
  async (args: ILocationsArgs, { rejectWithValue, dispatch }) => {
    const { type, orgId } = args;

    try {
      const response = await axios_Core.get<ILocationsResponseType>(CORE_ENDPOINTS.getLocations({ type, orgId }));

      const onlyActive = response.data.rows.filter((l) => l.active);
      const locationsList = onlyActive.map((b) => ({
        ...b,
        value: b.id.toString(),
        label: b.type === 'NON_PORT' ? b.fullAddress : b.seaPortName,
      }));

      const mainLocations = locationsList.filter((l) => l.main);

      if (mainLocations.length > 0) {
        const MAIN_LOCATIONS = {
          productionLocation: mainLocations.find((l) => l.category === 'PRODUCTION') || null,
          logisticLocation: null,
        };

        dispatch(offerActions.setOfferLocations({
          productionLocation: MAIN_LOCATIONS.productionLocation,
          logisticLocation: MAIN_LOCATIONS.logisticLocation,
        }));
      }

      return locationsList;
    } catch (error) {
      console.log('getLocations error', error);

      notification({
        type: 'danger',
        title: 'Error',
        message: 'Could not get locations...',
      });

      return rejectWithValue('Could not get locations...');
    }
  },
);
// ===== LOCATIONS ===== END

// ===== CONTAINERS =====
const getContainers = createAsyncThunk(
  'dictionaries/getContainers',
  async (orgId: number, { rejectWithValue }) => {
    try {
      const response = await axios_Core.get<IContainersResponse>(CORE_ENDPOINTS.getContainers(orgId));
      const containers = response.data;

      return containers;
    } catch (error) {
      console.log('getContainers error', error);

      notification({
        type: 'danger',
        title: 'Error',
        message: 'Could not get containers...',
      });

      return rejectWithValue('Could not get containers...');
    }
  },
);
// ===== CONTAINERS ===== END

export const asyncDictionariesActions = {
  getDictionaries,
  getBuyers,
  getLocations,
  getContainers,
};
