import { DOCUMENT_ENDPOINTS } from 'SHARED/api/endpoints';
import { axios_Documents } from 'SHARED/helpers/axios';
import { createAndDownloadFile } from 'SHARED/helpers/common';
import notification from 'SHARED/helpers/notifications';
import { AxiosResponse } from 'axios';

interface IParams {
  dealId: string | number;
  threadId: string | number;
  originalFileName: string;
}

async function getDownloadDocumentFile(params: IParams) {
  const { dealId, threadId, originalFileName } = params;

  try {
    const response: AxiosResponse<Blob> = await axios_Documents.get(
      DOCUMENT_ENDPOINTS.getDownloadDocumentFile(dealId, threadId),
      { responseType: 'blob' },
    );

    console.log(response);

    createAndDownloadFile(response.data, originalFileName);

    return response.data;
  } catch (error) {
    notification({
      type: 'danger',
      title: 'Error',
      message: 'Cannot download document',
    });

    console.error('Error getting document file:', error);

    return null;
  }
}

export default getDownloadDocumentFile;
