import clsx from 'clsx';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { INavTabItem } from 'SHARED/types/common';

interface IProps {
  items: INavTabItem[],
  className?: string,
  pageTabs?: boolean,
  navTabs?: boolean,
  spread?: boolean,
  headerMenu?: boolean,
}

const NavTabs: React.FC<IProps> = ({
  items,
  className,
  pageTabs = true,
  navTabs = true,
  spread = true,
  headerMenu = false,
}) => {
  const handleClick = (disabled: boolean | undefined) => (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (disabled) {
      e.preventDefault();
    }
  };

  return (
    <ul className={clsx(
      'tabs',
      pageTabs && 'page-tabs',
      navTabs && 'nav-tabs',
      spread && 'spread',
      headerMenu && 'header-menu',
      className,
    )}
    >
      {items.map(({ label, link, disabled, hidden, title, disabledTitle }) => (
        <li key={link}>
          <NavLink
            to={link}
            exact
            className={clsx({ 'disabled-link': disabled, disabled: hidden })}
            title={disabled ? disabledTitle : title}
            onClick={handleClick(disabled)}
          >
            {label}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};

export default NavTabs;
