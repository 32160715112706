import React from 'react';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';

const RfpDealLogisticsAgreed = () => {
  const { ON_SPEC } = useTypedSelector((state) => state.offer);

  const {
    id,
    parentOffer,
    agreedOnDelivery,
    deal,
    departureFrom,
    departureTo,
    incoterms,
  } = ON_SPEC;

  const {
    shipping,
  } = deal || {};

  const {
    destinationLocation,
  } = shipping || {};

  // flags
  const isRfp = parentOffer?.type?.value === 'RFP';
  // flags === END

  if (!id || !isRfp || !agreedOnDelivery) return null;

  return (
    <>
      <div className="attention-message no-margin">
        Seller agreed with requested delivery location and timeline.
      </div>
      <div className="mb-2" />

      <DataItemPreview
        title="Delivery"
        datesRange={[departureFrom, departureTo]}
      />

      <DataItemPreview
        title="Buyer incoterms"
        item={incoterms}
      />

      <DataItemPreview
        title="Buyer location"
        item={destinationLocation?.address}
      />
    </>
  );
};

export default RfpDealLogisticsAgreed;
