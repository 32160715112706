/* eslint-disable import/no-cycle */
import { IProfile } from 'SHARED/redux/models/IUser';
import { IContainer } from 'SHARED/redux/models/IContainers';
import { OfferBid } from './bidTypes';

export interface MenuItem {
  label: string, link: string, disabled?: boolean
}

export type PagesTypes = 'onspec' | 'offspec' | 'rfp' | 'proposals';

export type PortalType = 'SELLER' | 'BUYER' | 'ADMIN' | null | undefined;

export interface IDataCollection {
  label: string | undefined,
  value: string | number | BuyersFetchType | undefined,
}

export interface Dictionary {
  value: string,
  label: string,
  id?: number,
  type?: string,
  offerTypes?: string[],
  disabled?: boolean,
  payload?: any,
  main?: boolean,

  // incoterms only
  locationType?: 'PORT' | 'NON_PORT',
}

export interface InputOption extends Dictionary {
  isDisabled?: boolean,
}

export interface OwnerUser {
  createdAt: string
  id: number
  login: string
  orgId: number
  profile: IProfile
  roleId: number
  roleName: string
  status: string
  type: string
}

export enum Dictionaries {
  incoterms = 'INCOTERM_SELLER',
  incotermsBuyer = 'INCOTERM_BUYER',
  loadOptions = 'LOADING_DETAILS',
  packaging = 'PACKAGING_OPTIONS',
  packages = 'PACKAGING_OPTIONS',
  paymentTerms = 'PAYMENT_TERMS',
  documentCapabilities = 'DOCUMENT_TYPE',
  salesTerms = 'SALES_TERMS',
  productLocation = 'PRODUCT_LOCATION',
  loadAddress = 'LOADING_ADDRESS',
  showTo = 'BUYERS_LIST',
  buyersGroups = 'BUYERS_GROUPS_LIST',
}

export type DictionaryEntry = `${Dictionaries}`;

export type DictionariesList = {
  [key in Dictionaries]: Dictionary[];
};

export enum BuyersFetchTypes {
  includeGroup = 'INCLUDE_GROUP',
  include = 'INCLUDE',
  exclude = 'EXCLUDE',
  includeAll = 'INCLUDE_ALL',
}

export type BuyersFetchType = `${BuyersFetchTypes}`;

export enum OfferTypes {
  offspec = 'OFF_SPEC',
  alreadyProduced = 'ALREADY_PRODUCED',
  toBeProduced = 'TO_BE_PRODUCED_BUNDLE',
  rfpProposal = 'RFP_PROPOSAL',
  simpleAlreadyProduced = 'SIMPLE_ALREADY_PRODUCED',
}
export type BaseOfferType = 'OFF_SPEC' | 'ON_SPEC' | 'RFP';
export type OfferType = (
  'OFF_SPEC' |
  'ALREADY_PRODUCED' |
  'TO_BE_PRODUCED_BUNDLE' |
  'TO_BE_PRODUCED' |
  'SIMPLE_ALREADY_PRODUCED' |
  'RFP' |
  'RFP_PROPOSAL'
);
export type OnspecOfferTypes = 'ALREADY_PRODUCED' | 'TO_BE_PRODUCED_BUNDLE';
export type DocType = 'COA' | 'SALES_TERMS';

export interface OauthResponce {
  access_token: string,
  refresh_token: string,
  token_type: string,
  expires_in: number,
  scope: string,
}

export enum OfferStatuses {
  'NEW' = 'New',
  'ACTIVE' = 'Active',
  'ARCHIVED' = 'Archived',
  'CANCELLED' = 'Cancelled',
  'ENDED' = 'Ended',
  'FINALIZED' = 'Finalized',
  'OFFLINE' = 'Offline',
}

export type OfferStatus = keyof typeof OfferStatuses;

export interface Person {
  id: number
  firstName: string
  middleName: string
  lastName: string
  jobTitle: string
  email: string
  phoneNumber: string
  salutation: string
  language:string | null
  timeZone: string
  timeZoneAbbr: string
  operationLimit:string | null
}

export interface Organisation {
  approvalDate: null | string,
  coc: string,
  contactPerson: Person | null,
  country: string,
  id: number,
  feesDescription?: string | null,
  notes: string,
  offSpecLocations: Dictionary[] | null,
  orgName: string,
  legalName: string,
  registrationDate: string,
  status: string,
  tradingName: string,
  type: string,
  vat: string,
  currency: 'USD' | 'EUR',
  documents?: []
}

export type File = {
  contentLength: number,
  contentType: string,
  fileName: string,
  id: number,
  originalFileName: string,
  url?: string,
  duplicate?: boolean,
};

export interface FilesResponce {
  data: File[]
}

export interface Deal {
  amount: number,
  number: string,
  buyer: { profile: Person },
  price: number,
  buyerOrg: Organisation,
  created: any,
  createdBy?: OwnerUser | null,
  seller: { profile: Person },
  sellerOrg: Organisation,
  volume: number,
  volumeUnits: string,
  id: number
  currency: string,
}

export interface OnspecDeal extends Deal {
  id: number,
  shipping: {
    departureLocation: {
      id: number,
      type: LocationType,
      address: string,
      incoterms: Dictionary
      country?: string
    }
    destinationLocation: {
      id: number,
      type: LocationType,
      address: string,
      incoterms: Dictionary
      country?: string
    }
    loading: any
    logisticAmount: number
    logisticHandler: null | Dictionary
    logisticHandlerType: Dictionary
    containerType?: Dictionary
    logisticPrice: number
    packaging: Dictionary
    buyerIncoterms: Dictionary | null
  },
  financing: {
    buyerPaymentOffset: number
    buyerPaymentTerm: Dictionary
    commissionPerDeal: number
    commissionPerUnit: number
    creditAmount: number
    creditInterestRate: number
    creditPrice: number
    creditTermDays: number
    costOfFinancing?: number
    totalCostOfFinancing?: number
    financingProvider: Dictionary | null
    financingProviderType: Dictionary | null
    sellerPaymentOffset: number
    sellerPaymentTerm: Dictionary
    type: Dictionary
    financingType: Dictionary
    financingNeeded: boolean
  }
  departurePeriod: Dictionary
  departureFrom: 'string'
  departureTo: 'string'
  netPrice: number
  packagingPrice?: number
  packagingAmount?: number
  netAmount: number
  amount: number
  currency: string
  latestBid: Bid
}

export interface OffspecDeal extends Deal {
  id: number,
  currency: string,
}

export type OfferTypeDictionary = {
  label: string,
  value: OfferType,
};

interface BaseOffer {
  description?: string,
  documentCapabilities: Dictionary[] | null,
  id: string | number,
  incoterms: Dictionary | null,
  paymentTerms: Dictionary | null,
  paymentOffset?: Dictionary,
  salesTerms: Dictionary | null,
  status: OfferStatus,
  statusLabel?: string,
  title: string,
  totalVolume: number | undefined,
  type: OfferTypeDictionary | null,

  price: number | null,
  priceEurDisabled?: boolean,
  priceEur: number | null,
  priceUsdDisabled?: boolean,
  priceUsd: number | null,

  bidders: number,
  buyer: string,
  buyers: Dictionary[] | null,
  // buyersFetchType: Dictionary,
  buyersFetchType: { label:string, value: BuyersFetchType },
  cancellationReason: string,
  created: number | null,
  currency: string,
  volumeUnits: Dictionary,
  cancellationInfo: {
    date: string | number,
    fullName: string,
  }
  country: string,
  winningBid: Bid | null,
  seller: '',
  ourLastBid: number | null,
  params: {
    excludedBuyers: [],
    buyersFetchType: Dictionary | null,
    salesTerms: Dictionary | null,
  },
  ownerOrg: Organisation,
  deal: Deal | null,
}

export interface IOffspecOffer extends BaseOffer {
  productLocation: Dictionary | null,
  hasDeals?: boolean,
  hasDeal?: boolean,
  deals?: OffspecDeal[] | null,
  documents: {
    COA: File[] | null,
    SALES_TERMS: File[] | null,
  },
  offerBids?: Bid[] | null
  waitDecisionUntil?: number | null
  loadAddress?: Dictionary | null,
  loadOptions: Dictionary[] | null,
  loadDateFrom: Date | number,
  loadDateTo: Date | number,
  endDate: Date | number,
  latestBid: Bid | null,
  packaging: Dictionary | null,
  groups: Dictionary[],
  splittable: boolean,
  minBidVolume?: number
  logisticLocation: Location | null,
  remainingVolume?: number,
}

export interface PackageItem extends Dictionary {
  params?: {
    price?: number,
  }
}

export interface BuyerPaymentTerm {
  id: number,
  type: string,
  value: string,
  label: string,
  displayOrder: number,
  hasDayOffset: boolean,
  offsetDays: number[]
}

export interface FinancingOption {
  id: number,
  partner: {
    label: string,
    value: string,
    order: number
  },
  price: number,
  interestRate: number,
  creditTermDays: number,
  commissionPerDeal: number,
  commissionPerUnit: number
}
export interface FinancingPayload {
  paymentTerm: any,
  paymentOffset: number,
  creditConditionId: number,
}

export interface OnSpecOffer extends BaseOffer {
  bundleId?: number,
  productCategory: Dictionary | null,
  productType: Dictionary | null,
  productTitle?: string
  product: Dictionary | null,
  productId: number,
  products?: OfferPackage[], // to be produced offer
  productIds?: number[], // to be produced offer
  productionLocation: Location | null,
  logisticLocation: Location | null,
  logisticLocationId: number | null,
  documents: {
    COA: File[] | null,
    SALES_TERMS: File[] | null,
  },
  multiDeal: boolean,
  matchedProducts?: Dictionary[] | null,
  loadOptions: Dictionary[] | null,
  productCharacteristics: any[] | null,
  coaUsed: boolean,
  customLabelPossible: boolean,
  splittable: boolean,
  productionDate: string | number,
  expirationDate: string | number,
  endDate: number,
  departurePeriod: any,
  departureFrom: string | number,
  departureFromMonth: Dictionary | null,
  departureTo: string | number | null,
  isApproved?: boolean,
  offerBids?: OfferBid[],
  containerVolume?: number,
  vasLogistics?: VasLogistic | null,
  version: string,
  deal: OnspecDeal | null,
  deals?: OnspecDeal[] | null,
  hasDeals: boolean
  remainingVolume?: number,
  isCanAddBid?: boolean,
  isCanOverBid?: boolean,
  isCanAccept?: boolean,
  latestBid: OfferBid | null,
  personalOffer?: CounterBid | null
  packaging: Dictionary | null,
  packages?: PackageItem[] | null,
  productContainers?: IContainer[],
  groups: Dictionary[],
  vasFinancing: VasFinancing | null,
  priceDetails: PricesResponce | null,
  parentOffer? : {
    id: number,
    status: Dictionary,
    type: Dictionary,
  }
  createdBy?: OwnerUser | null,

  // for RFP_PROPOSAL
  agreedOnDelivery?: boolean
  agreedOnPayment?: boolean
  feedback?: {
    created: number,
    message: string,
  }

  // simple offer
  logisticLocationNote?: string,
  anonymous?: boolean,
}

export interface ISimpleOffer extends BaseOffer {
  bundleId?: number,
  productCategory: Dictionary | null,
  productType: Dictionary | null,
  productTitle?: string
  product: Dictionary | null,
  productId: number,
  // products?: OfferPackage[], // to be produced offer
  // productIds?: number[], // to be produced offer
  // documents: unknown[], // TODO: TBD, not yet implemented
  multiDeal: boolean,
  matchedProducts?: Dictionary[] | null,
  loadOptions: Dictionary[] | null,
  splittable: boolean,
  endDate: number,
  departurePeriod: any,
  departureFrom: string | number,
  departureFromMonth: Dictionary | null,
  departureTo: string | number | null,
  isApproved?: boolean,
  offerBids?: OfferBid[],
  containerVolume?: number,
  version: string,
  deal: OnspecDeal | null,
  deals?: OnspecDeal[] | null,
  hasDeals: boolean
  remainingVolume?: number,
  isCanAddBid?: boolean,
  isCanOverBid?: boolean,
  isCanAccept?: boolean,
  latestBid: OfferBid | null,
  personalOffer?: CounterBid | null
  packaging: Dictionary | null,
  packages?: PackageItem[] | null,
  productContainers?: IContainer[],
  groups: Dictionary[],
  createdBy?: OwnerUser | null,

  // simple offer
  location?: string | null,
  isAnonymous?: boolean,
}

export interface RFP {
  id?: undefined,
  created?: number,
  productTitle?: string,
  ownerOrg?: Organisation | null,
  createdBy?: {
    createdAt: string
    id: number
    login: string
    orgId: number
    profile: IProfile
    roleId: number
    roleName: string
    status: string
    type: string
  },
  productCharacteristics? : any[];
  productLocation: Dictionary | null,
  loadAddress: Dictionary | null,
  description?: string,
  packages: Dictionary[] | null,
  productCategory: Dictionary | null,
  productType: Dictionary | null,
  product: Dictionary | null,
  deliveryLocation: Location | null,
  documents: {
    COA: File[] | null,
    SALES_TERMS: File[] | null,
  },
  loadOptions: Dictionary[] | null,
  endDate: number,
  departureFrom: string | number | null,
  departureTo: string | number | null,
  version: string,
  status: string | null,
  statusLabel: string,
  volumeUnits: Dictionary,
  documentCapabilities: Dictionary[] | null,
  minVolume: number | undefined,
  maxVolume: number | undefined,
  deliveryLocationId?: number | string
  productId?: number | string
  proposals: OfferListItem[]
  isCanAddProposal: boolean
  incoterms: Dictionary | null,
  paymentTerms: Dictionary | null,
  paymentOffset?: string | number | null,
  currency: string | null,

  params?: {
    excludedBuyers: [],
    buyersFetchType: Dictionary | null,
    salesTerms: Dictionary | null,
  },

  // ? these two fields are needed only for RFP which is based on ON_SPEC offer
  agreedOnDelivery?: boolean
  agreedOnPayment?: boolean
}

export interface VasFinancing {
  financingNeeded: boolean,
  financingAvailable: boolean,
  financingExist: boolean,
  financingTerm: number,
  sellerPaymentTerms: Dictionary,
  sellerPaymentOffset: number,
  buyerPaymentTerms: BuyerPaymentTerm,
  buyerPaymentOffset: number,
  availableBuyerPaymentTerms: BuyerPaymentTerm[],
  paymentTerms: Dictionary[],
  options: FinancingOption[],
}

export interface PricesResponce {
  amounts: {
    totalAmount: number,
    netAmount: number,
    packagingAmount: number,
    logisticAmount: number,
    creditAmount: number,
  },
  creditAmount:number,
  logisticAmount:number,
  netAmount:number,
  packagingAmount:number,
  totalAmount:number,
  commissionPerDeal:number,
  commissionPerUnit:number,
  creditConditionId: null,
  creditPrice: number,
  creditTermDays: number,
  currency: 'EUR' | 'USD',
  interestRate: number,
  logisticPrice: number,
  logisticPriceId: number,
  netPrice: number,
  packagingPrice: number,
  totalPrice: number,
  volume: number,
  logisticProvider: Dictionary | null,
  financingProvider: Dictionary | null,
  vasFinancing?: VasFinancing,
  vasLogistics?: VasLogistic,
  destinationLocation: Location,
}

// export interface Price {
//   laneId?: number,
//   priceId: number,
//   partner: Dictionary,
//   type?: LocationType,
//   currency?: 'USD' | 'EUR',
//   price: number,
//   validFrom?: string,
//   validTo?: string,
//   incoterms?: Dictionary,
//   logisticLocationId: number | null,
// }

interface IOfferProduct extends Dictionary{
  premiumPriceEur? : number
  premiumPriceUsd? : number
}

export interface OfferPackage {
  productType: Dictionary,
  product: IOfferProduct,
  fullfilled: boolean, // TODO: fix this typo, but check usages first
  productTitle: string,
  id?: number,
  premiumPriceEur? : number
  premiumPriceUsd? : number
  productId?: number,
}

export interface OfferListItem {
  title: string,
  loadDateFrom: Date | number,
  loadDateTo: Date | number,
  bidders: number
  buyer: null | string
  country: string
  created: number
  currency: string
  buyerName: string
  deal?: OnspecDeal
  departureFrom: string
  departurePeriod: string
  departureTo: string
  endDate: number
  id: number
  isApproved: false
  isMatched: false
  matchedProducts: string[]
  ourLastBid: null | number | string
  price: number
  productCategory: string
  productTitle?: string
  productType: string
  seller: string
  splittable: boolean
  status: OfferStatus
  statusLabel: string
  type: string
  typeCode: OfferType
  totalVolume: number
  ourLastBidVolume: number | null
  volumeUnits: string
  remainingVolume: number
  dealsVolume: number
  minVolume: number
  maxVolume: number
  proposals: number
  proposalsCount: number
  agreedOnDelivery?: boolean
  agreedOnPayment?: boolean
  incoterms?: string

  createdBy?: string | null
  description?: string
  productionLocation?: string | null
  logisticLocation?: string | null
  customLabel?: string
  packaging?: Dictionary[] | null
  salesTerms?: string | null
  paymentTerms: Dictionary | null
  documents: {
    SALES_TERMS: File[] | null,
  }
  logisticLocationCountry?: string | null
  sellerOrg?: string | null
  sellerOrgCountry?: string | null
  // ? proposal only
  feedback?: {
    created: number | string
    message: string
  }

  // ? simple offer only
  logisticLocationNote?: string
  anonymous?: boolean
}

// TODO: brand new interface for Proposal entity only (will replace the old one OfferListItem)
// TODO: remove all the fields that needed only for Proposal

// ************************************ LIST PAGE related types ************************************
export interface ListResponse {
  rows: OfferListItem[],
  totalPageCount: number,
  totalRowCount: number,
}

export type LocationType = 'NON_PORT' | 'PORT';

export interface Location {
  value: string,
  label: string,
  active: boolean
  address1: string
  address2: string
  category: 'LOGISTIC' | 'PRODUCTION'
  city: string
  contactPerson: Person
  countryId: number
  id: number
  incoterms: string
  main: boolean
  name: string
  organizationId: number
  region: string
  seaPortId: number
  seaPortName: string
  subType: string
  type: 'NON_PORT' | 'PORT'
  zip: string,
  fullAddress: string
}

export interface ListParams {
  params: {
    page?: number,
    size?: number,
    sort?: string,
    active?: boolean
    types?: OfferType
    statuses?: string | null
    matched?: boolean
    rfpProposal?: boolean
  }
}

export interface Bid {
  id: number,
  userName: string,
  value: number,
  actionTime: number,
  action: string,
  active: boolean,
  orgName?: string,
  netPrice?: string,
  totalPrice?: string,
  buyerName?: string,
  created?: number,
  currency?: string,
  volume?: number,
  bidVolume: number,
  counterBid?: CounterBid,
  offerTotalPrice: number
  hasDeal: boolean
  // deletable: boolean
}

export type BidStatuses = 'ACTIVE' | 'EXPIRED' | 'DELETED' | 'SUSPENDED';

export interface CounterBid {
  created: number
  id?: number
  logisticPrice?: number
  message: string
  description?: string
  netPrice?: number
  sellerName: string
  sellerOrg?: string
  status?: BidStatuses
  totalOfferPrice?: number
  totalPrice?: number
  validTo: null | number
  volume: number,
  acceptable: boolean,
  commissionPerDeal?: number | null
  commissionPerUnit?: number | null
  creditPrice?: number | null
  creditTermDays?: number
  logisticPriceId?: number
  logisticHandler?: IDataCollection
  financingProvider?: IDataCollection
  packagingPrice?: number,
}

export interface AcceptOfferPayload {
  offerId: number | string,
  volume: number | string,
  offerVersion: string,
  logisticLocationId?: string | number | null,
  logisticPriceId?: string | number | null
  packaging?: string | null,
  paymentOffset?: string | number | null,
  paymentTerm: string | number | null,
  creditConditionId?: string | number | null,
}

export interface OfferTableItem {
  key: string,
  value: string | number | null | undefined,
  isSplittable?: boolean,
  isApproved?: boolean,
  isAnonymous?: boolean,
  label: string,
  prefix?: string,
  matchedProducts?: string[],
  currency?: string,
  ourLastBidVolume?: number | null,
}

export type HandleRowClick = (
  e:React.MouseEvent<HTMLTableRowElement | HTMLAnchorElement, MouseEvent>, id:string | number) => void;

//  ************************* bid info *************************

export interface Price {
  laneId?: number,
  priceId: number | string,
  id?: number,
  partner: Dictionary,
  // type?: LocationType,
  type?: {
    labe: string,
    value: 'SEA' | 'LAND'
  },
  currency?: 'USD' | 'EUR',
  price: number, // price per 1mt
  validFrom?: string,
  validTo?: string,
  incoterms?: Dictionary,
  logisticLocationId: number | null,

  // prices for the full container/truck
  transportPriceEur: number,
  transportPriceUsd: number,

  caption?: string,
}

export interface DestinationLocation {
  type: LocationType,
  address: string,
  prices: Price[],
  id: number,
  incoterms: Dictionary,
  main: boolean,
}

export interface VasLogistic {
  logisticNeeded: boolean,
  availableBuyerIncoterms: Dictionary[],
  sellerIncoterms: Dictionary,
  buyerIncoterms: Dictionary,
  departmentLocation: {
    type: string,
    address: string,
  },
  destinationLocations: DestinationLocation[]
}

interface DealParams {
  dealId?: number,
  dealPrice?: number,
  dealCurrency?: string,
  dealVolume?: number,
  remainingVolume?: number,
  buyerOrg?: string
}

export type HistoryAction = 'MAKE_DEAL' | 'FINALIZE_WITH_DEAL' | 'UPDATE' | 'CREATE';

export interface OfferHistoryItem {
  action: HistoryAction,
  actionTime: number,
  statusBefore: 'ACTIVE',
  statusAfter: 'ACTIVE',
  params: DealParams
}

export interface IProposalPayload {
  type: 'RFP_PROPOSAL'
  parentOfferId: string | number | undefined
  volumeUnits: Dictionary
  productionLocationId: string | number
  salesTerms: Dictionary
  documents?: {
    SALES_TERMS?: File[] | null
  }
  packaging: Dictionary
  customLabelPossible: boolean
  totalVolume: number | string

  // ? even though price is single, BE expects it to be with a correct name
  priceUsd?: number | string
  priceEur?: number | string

  agreedOnPayment: boolean
  paymentTerms?: BuyerPaymentTerm | null
  paymentOffset?: number | string

  agreedOnDelivery: boolean
  incoterms?: Dictionary | null
  logisticLocationId?: string | number | null
  departurePeriod?: Dictionary | null
  departureFromMonth?: Dictionary | null
  departureFrom?: number | string
  departureTo?: number | string

  description?: string
  endDate: number
}
