import { CORE_ENDPOINTS } from 'SHARED/api/endpoints';
import { axios_Core } from 'SHARED/helpers/axios';
import notification from 'SHARED/helpers/notifications';
import { IGetProductsArgs } from 'SHARED/redux/models/IGetProductsArgs';
import { ProductListItem } from 'SHARED/types/specsTypes';

export const getProducts = async (args: IGetProductsArgs) => {
  const { category, type } = args;

  try {
    const response = await axios_Core.get<ProductListItem[]>(CORE_ENDPOINTS.getProducts({ category, type }));

    return {
      data: response.data,
      isError: false,
    } as const;
  } catch (error) {
    console.log('getProducts error: ', error);

    notification({
      type: 'danger',
      title: 'Error',
      message: 'Could not get products list...',
    });

    return {
      message: 'Could not get products list...',
      isError: true,
    } as const;
  }
};
