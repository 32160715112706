import React, { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import FormInput from 'SHARED/components/FormInput';
import Preloader from 'SHARED/components/ThePreloader';
import validationRules from 'SHARED/helpers/validation';
import routes from 'SHARED/types/routes';

interface ICancelOfferModalProps {
  offerId: number | string;
  handleClose: () => void;
  cancelOffer: (id: string | number, cancellationReason: string) => any;
  setOfferError?: (error: string) => any;
}

interface IFromValues {
  cancellationReason: string;
}

const CancelOfferModal: FC<ICancelOfferModalProps> = ({
  offerId,
  handleClose,
  cancelOffer,
  setOfferError = (error: unknown) => console.log(error),
}) => {
  const history = useHistory();
  const methods = useForm<IFromValues>();
  const { handleSubmit } = methods;

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: IFromValues) => {
    setLoading(true);

    try {
      await cancelOffer(offerId, data.cancellationReason);
      history.push(routes.onspec.listDefault);
    } catch (error) {
      console.log(error);
      setOfferError('Could not cancel offer...');
      setLoading(false);
    }
  };

  return (
    <>
      <Preloader isLoading={loading} />

      <div className="popup">
        <div className="popup-overlay" aria-hidden onClick={handleClose} onKeyDown={(e) => console.log(e)} />

        <div className="popup-body">
          <a href="##" className="popup-close" onClick={handleClose}>
            close
          </a>

          <div className="popup-content">
            <h1>Are you sure you want to cancel this offer?</h1>
            <p>The reason for cancellation will be shared with buyers who have submitted a bid or whom you are in negotiation with</p>

            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormInput
                  name="cancellationReason"
                  placeholder="Cancellation reason"
                  label="PLEASE ENTER THE REASON FOR CANCELLATION BELOW"
                  rules={validationRules.cancellation}
                  textarea
                  maxLength={250}
                />

                <div>
                  <button type="submit" className="btn-primary">Cancel offer</button>
                  <button type="button" className="btn-secondary" onClick={handleClose}>Keep the offer</button>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </>
  );
};

export default CancelOfferModal;
