import { cloneDeep } from 'lodash';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import FormInput from 'SHARED/components/FormInput';
import SingleCheckbox from 'SHARED/components/Checkbox';
import validationRules from 'SHARED/helpers/validation';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { useActions } from 'SHARED/redux/hooks/useActions';
import PriceInput from 'SHARED/components/PriceInput';
import Preloader from 'SHARED/components/ThePreloader';
import { tooltips } from 'SHARED/helpers/texts';
import { putUpdateProduct } from 'SHARED/api/common/put/putUpdateProduct';

interface Props {
  handleClose: (value: React.SetStateAction<boolean>) => void,
  id: string | number,
}

const EditProductTitle: React.FC<Props> = ({ handleClose, id }) => {
  const { product } = useTypedSelector((state) => state.specs);
  const { me } = useTypedSelector((state) => state.users);
  const { getProduct, getSpecificationsList } = useActions();

  useEffect(() => {
    getProduct(id);
  }, []);

  const methods = useForm();
  const { formState: { isDirty } } = methods;

  const onSubmit = async (form: any) => {
    if (product) {
      const payload = { ...cloneDeep(product), ...form };

      if (me.type === 'SELLER') {
        payload.premiumPriceEur = typeof form.premiumPriceEur === 'string' ? parseFloat(form.premiumPriceEur.replace(',', '.')) : form.premiumPriceEur;
        payload.premiumPriceUsd = typeof form.premiumPriceUsd === 'string' ? parseFloat(form.premiumPriceUsd.replace(',', '.')) : form.premiumPriceUsd;
      }

      const isError = await putUpdateProduct(payload);

      if (isError) {
        // @ts-expect-error:
        methods.setError('title', { message: isError, type: 'required' });
      } else {
        getSpecificationsList();
        handleClose(false);
      }
    }
  };

  if (!product) {
    return <Preloader isLoading />;
  }

  return (
    <div className="popup">
      <div className="popup-overlay" />
      <div className="popup-body">
        <a href="##" className="popup-close" onClick={(e) => { handleClose(false); }}>
          close
        </a>

        <div>
          <h1>Edit product</h1>

          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>

              <>
                <FormInput
                  name="title"
                  label="PRODUCT SPECS TITLE"
                  rules={validationRules.required}
                  value={product.title}
                  tooltip="Must be unique in its category"
                />

                <SingleCheckbox
                  title="STATUS"
                  label="Active"
                  name="active"
                  defaultChecked={product.active}
                  rules={{ reqired: false }}
                />
              </>

              {me.type === 'SELLER'
                && (
                  <>
                    <div className="page-section-title">Premium for To-Be-Produced offers</div>
                    <div className="inputs-range-wrapper">
                      <PriceInput
                        name="premiumPriceEur"
                        label="PREMIUM PRICE"
                        value={product.premiumPriceEur}
                        tooltip={tooltips.premiumPriceEur}
                        rules={validationRules.required}
                      />

                      <PriceInput
                        name="premiumPriceUsd"
                        currency="$"
                        label="PREMIUM PRICE"
                        value={product.premiumPriceUsd}
                        tooltip={tooltips.premiumPriceUsd}
                        rules={validationRules.required}
                      />
                    </div>
                  </>
                )}

              <div>
                <button type="submit" className="btn-primary" disabled={!isDirty}>Submit</button>
                <button type="button" className="btn-secondary" onClick={(e) => { handleClose(false); }}>Cancel</button>
              </div>
            </form>
          </FormProvider>

        </div>
      </div>
    </div>
  );
};
export default EditProductTitle;
