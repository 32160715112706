import React, { useState } from 'react';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { useActions } from 'SHARED/redux/hooks/useActions';
import ProductSpecsDetailsModal from 'SHARED/modals/ProductSpecsDetailsModal';
import { Link } from 'react-router-dom';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import { MpcDocLink } from 'SHARED/helpers/common';
import FormattedPrices from 'SHARED/components/Prices';
import OfferDisclaimer from 'SHARED/components/OfferDisclaimer';
import { offerLabels } from 'SHARED/helpers/texts';
import useUserTimezone from 'SHARED/hooks/useUserTimezone';
import { readablePaymentTerms } from 'SHARED/helpers/readablePaymentTerms';
import { dateRangeWithTimezone, dateWithTimezone } from 'SHARED/helpers/dates';
import PackagePreviewItem from '../components/PackagePreviewItem';

export interface IToBeProducedOfferSummaryProps {
  mode: 'preview' | 'detail'
}

const ToBeProducedOfferSummary: React.FC<IToBeProducedOfferSummaryProps> = ({ mode }) => {
  const { ON_SPEC } = useTypedSelector((state) => state.offer);
  const { product, loading } = useTypedSelector((state) => state.specs);

  const { getProduct } = useActions();

  const isPreview = mode === 'preview';

  const { userTimezone } = useUserTimezone();

  const {
    products: offerProducts,
    productCategory,
    description,
    documentCapabilities,
    packages,
    customLabelPossible,
    productionLocation,
    logisticLocation,
    salesTerms,
    paymentTerms,
    incoterms,
    loadOptions,
    priceEur,
    priceUsd,
    totalVolume = 0,
    remainingVolume = 0,
    params,
    documents,
    buyersFetchType,
    buyers,
    splittable,
    departureFrom,
    departureTo,
    departurePeriod,
    endDate,
    hasDeals,
    deals,
    parentOffer,
    createdBy,
    status,
    paymentOffset,
  } = ON_SPEC;

  const isForRfp = parentOffer?.type.value === 'RFP';
  const isCreatedByAdmin = createdBy?.type === 'ADMIN';
  const isOffline = status === 'OFFLINE';

  const totalUsd = priceUsd ? priceUsd * remainingVolume : 0;
  const totalEur = priceEur ? priceEur * remainingVolume : 0;

  const [isPopup, setIsPopup] = useState(false);

  const handleClose = () => {
    setIsPopup(false);
  };

  const showSpecifications = (id: string) => {
    setIsPopup(true);
    getProduct(id);
  };

  const departure = departurePeriod.value === 'MONTH'
    ? dateWithTimezone(departureFrom, userTimezone, 'MMMM YYYY')
    : dateRangeWithTimezone({ from: departureFrom, to: departureTo, timezone: userTimezone });

  return (
    <>
      <div className="page-columns">
        <div className="page-column">
          <div className="page-section-title">Offer product</div>
          <DataItemPreview item={productCategory} title="PRODUCT CATEGORY" />

          {isCreatedByAdmin && (
            <DataItemPreview item="OpenDairy" title="Added by" />
          )}

          {offerProducts && offerProducts.map((p, index) => (
            <PackagePreviewItem
              isPreview={mode === 'preview'}
              showSpecifications={showSpecifications}
              p={p}
              index={index}
              key={`${p.id}-${p.productTitle}`}
            />
          ))}

          <div className="page-section-title">Product offer details</div>

          {description && <DataItemPreview multiline multilineText={description} title="REMARKS FOR THE BUYER" />}
          <DataItemPreview item={documentCapabilities} title={offerLabels.toBeProduced.documents} />
          <DataItemPreview item={productionLocation?.fullAddress} title="PRODUCTION LOCATION" />
          <DataItemPreview item={packages} title={offerLabels.toBeProduced.packaging} />
          <DataItemPreview item={customLabelPossible ? 'Custom label possible' : 'Custom label not possible'} title="CUSTOM LABEL" />
          <DataItemPreview item={salesTerms} title="SALES TERMS" />
          {salesTerms?.value === 'MPC' && (
            <a href={MpcDocLink} target="_blank" rel="noreferrer" className="document-link">
              <i className="icon-flag" />
              <span>MPC sales terms</span>
            </a>
          )}
          {documents && documents.SALES_TERMS && <DataItemPreview file={documents.SALES_TERMS[0]} title="SALES TERMS DOCUMENT" />}
        </div>

        <div className="page-column">
          <div className="page-section-title">Shipping</div>

          <DataItemPreview item={incoterms} title="SELLER INCOTERMS" />
          <DataItemPreview item={logisticLocation?.fullAddress} title="SELLER INCOTERMS LOCATION" />
          <DataItemPreview item={loadOptions} title="LOADING DETAILS" />
          <DataItemPreview item={departure} title="DEPARTURE" />

          <br />
          <br />
          <div className="page-section-title">Offer expiration</div>
          <DataItemPreview item={endDate} isDate title="Offer end date" />
        </div>

        <div className="page-column">
          <div className="page-section-title">Volume and price</div>

          <DataItemPreview
            icon={splittable ? 'icon-splittable' : ''}
            item={isPreview ? totalVolume : remainingVolume}
            title={isPreview ? 'VOLUME' : 'REMAINING VOLUME'}
            suffix="mt"
          />

          {!isForRfp && <DataItemPreview item={splittable ? 'Yes' : 'No'} title="SPLITTABLE" />}
          <FormattedPrices title={offerLabels.toBeProduced.price} values={[priceEur, priceUsd]} />
          <DataItemPreview item={readablePaymentTerms({ paymentTerms, paymentOffset, isPreview })} title="PAYMENT TERMS" />
          <FormattedPrices title="TOTAL BASE PRICE" disableSuffix values={[totalEur, totalUsd]} />
        </div>

        {!isForRfp && !isOffline && (
          <div className="page-column">
            <div className="page-section-title">Buyers</div>
            {buyersFetchType?.value === 'INCLUDE_ALL' && (<div>All buyers have been included</div>)}
            {buyersFetchType?.value === 'EXCLUDE' && (<DataItemPreview item={params.excludedBuyers || buyers} title="EXCLUDED BUYERS" />)}
            {buyersFetchType?.value === 'INCLUDE_GROUP' && (<DataItemPreview item={buyers} title="INCLUDED BUYERS" />)}
          </div>
        )}
      </div>

      <OfferDisclaimer userType="seller" />

      {hasDeals
        && (
          <>
            {deals?.map((deal) => (<div><Link type="button" to={`/onspec/deals/${deal.id}`}>{`Show deal (${deal.number}) details`}</Link></div>))}
            <br />
            <br />
          </>
        )}

      {isPopup && (
        <ProductSpecsDetailsModal
          handleClose={() => handleClose()}
          characteristics={loading ? null : product?.characteristics}
        />
      )}

    </>
  );
};

export default ToBeProducedOfferSummary;
