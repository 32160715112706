import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, Link, useParams } from 'react-router-dom';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { useActions } from 'SHARED/redux/hooks/useActions';
import Preloader from 'SHARED/components/ThePreloader';
import Menu from 'SHARED/components/Menu';
import FormattedPrices from 'SHARED/components/Prices';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import OfferBids from 'pages/Onspec/OfferDetails/partials/OfferBids';
import { MenuItem } from 'SHARED/types/offerTypes';
import { offerLabels } from 'SHARED/helpers/texts';
import routes from 'SHARED/types/routes';
import CancelOfferModal from 'SHARED/modals/CancelOfferModal';
import { cancelOffer } from 'SHARED/api/offers/put/putCancelOffer';
import { SimpleOfferSummary } from 'SHARED/pages/simple-offer/simple-offer-summary';
import OfferCancellationInfo from 'SHARED/components/Offer/OfferCancellationInfo';
import AlreadyProducedOfferSummary from '../AlreadyProduced/OfferSummary';
import ToBeProducedOfferSummary from '../ToBeProduced/OfferSummary';
import OfferDealsTab from './partials/OfferDealsTab';
import ProposalFields from './partials/ProposalFields';

interface RouteParams {
  id: string,
  type: string
}

const defaultMenuItems: MenuItem[] = [
  { label: 'Bids', link: 'bids' },
  { label: 'Details', link: 'details' },
];

const dealTab: MenuItem = { label: 'Deal(s)', link: 'deals' };

const OnspecOfferPage: React.FC = () => {
  const { ON_SPEC, loading } = useTypedSelector((state) => state.offer);
  const { offerHistory, isLoading } = useTypedSelector((state) => state.offers);

  const {
    getOfferDetails,
    resetOffer,
    getOfferHistory,
    clearHistory,
    setOfferError,
  } = useActions();

  const history = useHistory();
  const { id, type } = useParams<RouteParams>();
  const [menuItems, setMenuItems] = useState<MenuItem[]>(defaultMenuItems);

  const {
    status,
    priceEur,
    priceUsd,
    productCategory,
    productType,
    statusLabel,
    offerBids,
    paymentTerms,
    remainingVolume,
    totalVolume,
    splittable,
    hasDeals,
    type: offerType,
    products,
    productTitle,
    version,
    parentOffer,
    cancellationReason,
    cancellationInfo,
  } = ON_SPEC;

  const isRfp = parentOffer?.type?.value === 'RFP';
  const isAlreadyProduced = offerType?.value === 'ALREADY_PRODUCED';
  const isSimpleOffer = offerType?.value === 'SIMPLE_ALREADY_PRODUCED';

  const rfpPrefix = isRfp ? 'RFP Proposal - ' : '';

  const offerTitle = `${rfpPrefix} ${offerType?.label}: ${productCategory?.label} ${isAlreadyProduced ? productType?.label : ''}, ${totalVolume} mt`;

  const [activeTab, setActiveTab] = useState<string>('bids');

  // CANCEL OFFER
  const [showCancelOfferModal, setShowCancelOfferModal] = useState<boolean>(false);
  const handleOpenCancelOfferModal = () => setShowCancelOfferModal(true);
  const handleCloseCancelOfferModal = () => setShowCancelOfferModal(false);
  // CANCEL OFFER === END

  useEffect(() => () => {
    clearHistory();
  }, []);

  useEffect(() => {
    if (isRfp) {
      setActiveTab('details');
    }
  }, [parentOffer]);

  // check if the deals tab must be addet to the menu items
  useEffect(() => {
    if (hasDeals) {
      getOfferHistory(id);
      setMenuItems([dealTab, ...defaultMenuItems]);
    }
  }, [hasDeals]);

  useEffect(() => {
    fetchOfferDetails();
    return () => {
      resetOffer();
    };
  }, []);

  const fetchOfferDetails = async () => {
    try {
      getOfferDetails({ id, offerType: 'ON_SPEC' });
    } catch (err) {
      console.log(err);
      history.push(`/offspec/${type}`);
    }
  };

  const changeTabAction = (tab: string) => {
    getOfferDetails({ id, offerType: 'ON_SPEC' });
    setActiveTab(tab);
  };

  const bidsList: any = (isAlreadyProduced || isSimpleOffer) ? [{
    productTitle, offerBids, offerId: id, offerVersion: version,
  }] : products || []; // temp urgent solution for to be produced offer

  return (
    <>
      <Helmet>
        <title>{`${offerType?.label}: ${productCategory?.value}`}</title>
      </Helmet>

      <Preloader isLoading={loading || isLoading} />

      {/* *********************** offer main details ***********************  */}
      <div className="breadcrumbs">
        {isRfp
          ? <Link to={`/rfp/offers/${parentOffer?.id}`}>&lt; RFP </Link>
          : <Link to={`/onspec/${type}`}>&lt; All offers </Link>}
      </div>

      <div className="page-body is-logged-in preview-offer-page">
        <h1 className="page-title">
          {offerTitle}

          {!isRfp && (
            <div className="buttons">
              {status === 'ACTIVE' && (
                <Link to={`/onspec-edit/${id}`} className="btn-icon">
                  <i className="icon-edit" />
                </Link>
              )}

              {/* DUPLICATE 'ALREADY_PRODUCED' or 'TO_BE_PRODUCED_BUNDLE' link */}
              {ON_SPEC.type?.value === 'ALREADY_PRODUCED' && (
                <a
                  href={routes.onspec.duplicate_already_produced(id)}
                  className="btn-icon"
                  title={`Duplicate "${ON_SPEC.type?.label}"`}
                >
                  <i className="icon-duplicate-2" />
                </a>
              )}
              {ON_SPEC.type?.value === 'TO_BE_PRODUCED_BUNDLE' && (
                <a
                  href={routes.onspec.duplicate_to_be_produced_bundle(id)}
                  className="btn-icon"
                  title={`Duplicate "${ON_SPEC.type?.label}"`}
                >
                  <i className="icon-duplicate-2" />
                </a>
              )}
              {/* DUPLICATE 'ALREADY_PRODUCED' or 'TO_BE_PRODUCED_BUNDLE' link === END */}

              {/* CANCEL (soft delete) OFFER (AP or TBP) */}
              {status === 'ACTIVE' && (
                <button
                  type="button"
                  className="btn-icon"
                  title="Cancel offer"
                  onClick={handleOpenCancelOfferModal}
                >
                  <i className="icon-delete" />
                </button>
              )}
              {/* CANCEL (soft delete) OFFER (AP or TBP) === END */}
            </div>
          )}
        </h1>

        {/* *********************** offer auction details ***********************  */}
        <div className="offer-auction-details">
          <div>
            <DataItemPreview item={statusLabel} title="OFFER STATUS" />
            <DataItemPreview item={paymentTerms} title="SELLER PAYMENT TERMS" />
          </div>

          <div>
            <FormattedPrices title={offerLabels.alreadyProduced.price} values={[priceEur, priceUsd]} />
            <DataItemPreview
              item={isRfp ? totalVolume : remainingVolume}
              title={isRfp ? 'TOTAL VOLUME' : 'REMAINING VOLUME'}
              suffix="mt"
            />
          </div>

          {!isRfp && (
            <div>
              <DataItemPreview item={splittable ? 'Yes' : 'No'} title="SPLITTABLE" />
            </div>
          )}
        </div>

        {!isRfp && status === 'CANCELLED' && (
          <OfferCancellationInfo
            cancellationDate={cancellationInfo?.date}
            cancellationReason={cancellationReason}
          />
        )}

        {!isRfp && !!version && (
          <Menu
            items={menuItems}
            view="in-page-tabs"
            activeLink={activeTab}
            changeTabAction={changeTabAction}
          />
        )}

        {!isRfp && status === 'ACTIVE' && showCancelOfferModal && (
          <CancelOfferModal
            offerId={id}
            handleClose={handleCloseCancelOfferModal}
            cancelOffer={cancelOffer}
            setOfferError={setOfferError}
          />
        )}

        {activeTab === 'bids' && <OfferBids products={bidsList} bids={offerBids || []} />}

        {(activeTab === 'details' && offerType?.value === 'ALREADY_PRODUCED')
          && <AlreadyProducedOfferSummary mode="detail" />}

        {(activeTab === 'details' && offerType?.value === 'TO_BE_PRODUCED_BUNDLE')
          && <ToBeProducedOfferSummary mode="detail" />}

        {(activeTab === 'details' && offerType?.value === 'SIMPLE_ALREADY_PRODUCED')
          && <SimpleOfferSummary offer={ON_SPEC} />}

        {(activeTab === 'deals')
          && <OfferDealsTab history={offerHistory} />}

        {isRfp && <ProposalFields />}
      </div>
    </>
  );
};

export default OnspecOfferPage;
