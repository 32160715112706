/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import validationRules from 'SHARED/helpers/validation';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import Tooltip from '../Tooltip';

interface Props {
  name: `${string}` | `${string}.${string}` | `${string}.${number}`,
  rules?: any,
  value?: any,
  label?: string,
  timezone?: string | null,
  defaultValue?: Date | null | undefined,
  tooltip?: string,
  placeholder?: string,
  dateFormat?: string,
  disabled?: boolean,
  showTimeInput?: boolean,
  minDate?: Date | null | undefined,
  maxDate?: Date | null | undefined,
  inline?: boolean,
}

const FormDatePicker: React.FC<Props> = (props) => {
  const { formState: { errors }, control, watch } = useFormContext();

  // moment.tz.setDefault('Greenwich');
  // const now = moment();
  // console.log('NOW: ', now.format('YYYY-MM-DD HH:mm:ss z Z'));

  const {
    name,
    rules = validationRules.required,
    label,
    tooltip,
    disabled = false,
    showTimeInput = false,
    defaultValue = null,
    dateFormat = 'dd MMMM yyyy',
    placeholder = 'Select a date',
    minDate = new Date(),
    maxDate,
    value,
    timezone = null,
    inline = false,
  } = props;

  // defining min hours, minutes for preventing shoosing time before current time
  const val = watch(name);
  const isSelectedDateToday = moment().isSame(val, 'day');
  let minTimeHour = new Date().getHours();
  let minTimeMinutes = new Date().getMinutes();
  if (!isSelectedDateToday) {
    minTimeHour = 0;
    minTimeMinutes = 0;
  }
  const minTime = new Date(new Date().setHours(minTimeHour, minTimeMinutes, 0, 0));
  const maxTime = new Date(new Date().setHours(23, 59, 0, 0));

  const formattedValue = value ? new Date(value) : undefined;
  // console.log(formattedValue);

  return (
    <div className={`form-input ${errors[name] ? 'invalid' : 'valid'}`}>
      {label && (
        <label htmlFor={name}>
          {rules && rules.required ? `${label} *` : label}

          {
            tooltip && tooltip.length > 0 && <Tooltip text={tooltip} />
          }
        </label>
      )}

      <Controller
        name={name}
        rules={rules}
        control={control}
        defaultValue={formattedValue}
        render={({ field, fieldState }) => (
          <>
            <DatePicker
              inline={inline}
              placeholderText={placeholder}
              disabled={disabled}
              {...field}
              minDate={minDate}
              maxDate={maxDate}
              showTimeSelect={showTimeInput}
              minTime={minTime}
              maxTime={maxTime}
              selected={field.value === null ? defaultValue : field.value}
              dateFormat={dateFormat}
              timeFormat="HH:mm"
              timeIntervals={15}
              ref={(elem) => {
                // @ts-expect-error
                elem && field.ref(elem.input);
              }}
              autoComplete="off"
            />
            <div className="error-message">{fieldState.error?.message}</div>
          </>
        )}
      />

      {
        timezone
        && <div className="suffix timezone">{timezone}</div>
      }

    </div>
  );
};

export default FormDatePicker;
