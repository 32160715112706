import React from 'react';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import {
  MpcDocLink, handleDateRange,
} from 'SHARED/helpers/common';
import { Link } from 'react-router-dom';

const ProposalFields = () => {
  const { ON_SPEC } = useTypedSelector((state) => state.offer);

  const {
    description,
    packaging,
    customLabelPossible,
    productionLocation,
    salesTerms,
    price,
    priceEur,
    priceUsd,
    currency,
    documents,
    endDate,
    ownerOrg,
    isApproved,
    totalVolume,
    statusLabel,
    volumeUnits,
    status,
    incoterms,
    logisticLocation,
    departureFrom,
    departureTo,
    agreedOnDelivery,
    agreedOnPayment,
    paymentTerms,
    hasDeals,
    deals,
  } = ON_SPEC;

  const isActive = status === 'ACTIVE';

  const delivery = handleDateRange({ from: departureFrom, to: departureTo });
  const proposalPrice = price || priceEur || priceUsd;

  return (
    <>
      <section className="summary-grid">

        <div>
          <div className="page-section-title">Seller</div>
          <DataItemPreview icon={isApproved ? 'icon-approved' : ''} item={ownerOrg?.orgName} title="SELLER COMPANY" />
          <DataItemPreview item={ownerOrg?.country} title="COUNTRY" />
          <DataItemPreview item={productionLocation?.fullAddress} title="PRODUCTION LOCATION" />
          <DataItemPreview item={salesTerms} title="Sales terms" />
          {salesTerms?.value === 'MPC' && (
          <DataItemPreview title="Sales terms document" link={{ url: MpcDocLink, displayName: 'MPC sales terms' }} />
          )}
          {documents && documents.SALES_TERMS && <DataItemPreview file={documents.SALES_TERMS[0]} title="SALES TERMS DOCUMENT" />}
        </div>

        <div>
          <div className="page-section-title">Proposal</div>
          <DataItemPreview item={statusLabel} title="STATUS" />
          <DataItemPreview item={packaging} title="Proposed packaging" />
          <DataItemPreview item={customLabelPossible ? 'Custom label possible' : 'Custom label not possible'} title="CUSTOM LABEL" />
          <DataItemPreview item={totalVolume} title="Proposed volume" suffix={volumeUnits?.label} />
          <DataItemPreview
            item={proposalPrice}
            title="Proposed price"
            prefix={currency}
            suffix={`/${volumeUnits?.label}`}
          />
          <DataItemPreview item={endDate} title="Proposed valid until" isDate />
          <DataItemPreview multiline multilineText={description} title="Message" />
        </div>

        {!isActive && (
        <div>
          {/* LOGISTICS */}
          <div className="page-section-title">Logistics</div>
          {agreedOnDelivery && (
            <>
              <div className="attention-message no-margin">
                This proposal complies with requested delivery location and timeline.
              </div>
              <div className="mb-2" />
            </>
          )}
          <DataItemPreview
            title="Incoterms"
            item={incoterms}
          />
          <DataItemPreview
            title={agreedOnDelivery ? 'Delivery location' : 'Departure location'}
            item={logisticLocation?.fullAddress}
          />
          <DataItemPreview
            title={agreedOnDelivery ? 'Requested delivery' : 'Departure range'}
            item={delivery}
          />
          <br />

          {/* FINANCING */}
          <div className="page-section-title">Financing</div>
          {agreedOnPayment && (
            <>
              <div className="attention-message no-margin">
                This proposal complies with requested payment terms.
              </div>
              <div className="mb-2" />
            </>
          )}

          <DataItemPreview
            title={agreedOnPayment ? 'Requested payment terms' : 'Payment terms'}
            item={paymentTerms?.label}
          />
        </div>
        )}
      </section>

      {hasDeals
        && (
          <>
            {deals?.map((deal) => (
              <div>
                <Link
                  type="button"
                  to={`/rfp/deals/${deal.id}`}
                >
                  {`Show deal (${deal.number}) details`}
                </Link>
              </div>
            ))}
            <br />
            <br />
          </>
        )}
    </>
  );
};

export default ProposalFields;
