import React           from 'react';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import FormattedPrice  from 'SHARED/components/Price';
import { CounterBid }  from 'SHARED/types/offerTypes';

interface Props {
  counterBid: CounterBid
  currency: string
}

const CounterBidFields: React.FC<Props> = ({ counterBid, currency }) => (
  <div>
    <div className="page-section-title with-separator">You counter offer</div>
    <div className="page-columns">
      <DataItemPreview
        className="page-column"
        item={counterBid.created}
        dateFormat="DD MMM YYYY HH:mm z"
        isDate
        title="COUNTER OFFER PLACED"
      />
      <DataItemPreview
        className="page-column"
        item={counterBid.sellerName}
        title="SELLER"
      />
    </div>
    <div className="section-title">Volume & price</div>
    <div className="page-columns">
      <FormattedPrice
        className="page-column"
        value={counterBid.netPrice}
        prefix={currency}
        suffix="/mt"
        title="BID PRICE"
      />
      <DataItemPreview
        className="page-column"
        item={counterBid.volume}
        suffix="mt"
        title="BID VOLUME"
      />
    </div>

    <DataItemPreview
      title="MESSAGE"
      multiline
      multilineText={counterBid.message}
    />
  </div>
);

export default CounterBidFields;
