import React, { ReactNode, FC, useEffect } from 'react';

interface IProps {
  handleClose: () => void;
  children: ReactNode;
  isLoading?: boolean;
}

const Popup: FC<IProps> = ({
  handleClose,
  children,
  isLoading = false,
}) => {
  function handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    if (isLoading) return;

    // close on Enter key or Space key
    if (e.key === 'Enter' || e.key === ' ') {
      handleClose();
    }
  }

  // add ESC key handler (event listener that will be removed on unmount)
  function handleEscKey(e: KeyboardEvent) {
    if (isLoading) return;

    if (e.key === 'Escape' || e.key === 'Esc') {
      handleClose();
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleEscKey);

    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, []);

  return (
    <div className="popup">
      <div
        className="popup-overlay"
        onClick={() => handleClose()}
        data-testing="popup-overlay"
        role="button"
        tabIndex={0}
        onKeyDown={handleKeyDown}
      >
        Popup overlay
      </div>

      <div className="popup-body">

        <button
          type="button"
          className="popup-close"
          onClick={() => handleClose()}
          data-testing="popup-close-button"
        >
          close
        </button>

        {children}

      </div>
    </div>
  );
};

export default Popup;
