import React               from 'react';
import { Link }            from 'react-router-dom';
import routes              from 'SHARED/types/routes';
import { oauthLogoutForm } from 'SHARED/helpers/axios';
import { PortalType }      from 'SHARED/types/offerTypes';

const handleLogout = (e:React.MouseEvent) => {
  e.preventDefault();
  oauthLogoutForm();
};

interface MenuItem {
  label: string, link: string
}

interface IProps {
  portal: PortalType,
  mainMenu?: MenuItem[],
  handleClose: () => void
}

const links = routes.common;
const BUYERS_GROUPS_LINK = { label: 'Buyer groups', link: links.buyerGroups };

const mobileMenu: MenuItem[] = [
  { label: 'Product specifications', link: links.specifications },
  { label: 'Company profile', link: links.profile },
];

const HeaderDropdown: React.FC<IProps> = ({ portal, mainMenu = [], handleClose }) => {
  let dropdownList = [...mainMenu, ...mobileMenu];

  if (portal === 'SELLER') {
    dropdownList = [...mainMenu, ...mobileMenu, BUYERS_GROUPS_LINK];
  }

  // const [menuItems, setMenuItems] = useState<MenuItem[]>(dropdownList);

  return (
    <>
      {dropdownList.map(({ link, label }) => (
        <li key={link}>
          <Link onClick={handleClose} to={link}>{label}</Link>
        </li>
      ))}

      <li>
        <a href="##" onClick={(e) => handleLogout(e)}>Logout</a>
      </li>
    </>

  );
};

export default HeaderDropdown;
