import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import FormDatePicker from 'SHARED/components/DatePicker';
import Select from 'SHARED/components/Select';
import SingleCheckbox from 'SHARED/components/Checkbox';
import { handleDate } from 'SHARED/helpers/common';
import './filters.scss';
import { DevTool } from '@hookform/devtools';
import { Dictionary } from 'SHARED/types/offerTypes';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { useActions } from 'SHARED/redux/hooks/useActions';
import { axios_Core } from 'SHARED/helpers/axios';
import { useUserType } from 'SHARED/hooks/useUserType';
import { IBuyer } from 'SHARED/redux/models/IBuyer';

export interface IFiltersProps {
  applyFilter: (payload?: IFiltersPayload) => void
}

export interface IFiltersForm {
  createdFrom: Date | null,
  createdTo: Date | null,
  matched: boolean,
  buyer: IBuyer | null
  product: Dictionary | null
}

export interface IFiltersPayload {
  createdFrom: string | null,
  createdTo: string | null,
  matched: boolean,
  buyerId: number | null
  productTypes: string | null
}

const DefaultFormState: IFiltersForm = {
  createdFrom: null,
  createdTo: null,
  matched: false,
  buyer: null,
  product: null,
};

const Filters: React.FC<IFiltersProps> = ({ applyFilter }) => {
  const { dictionaries } = useTypedSelector((state) => state.dictionaries);
  const { getDictionaries } = useActions();

  const { isBuyer } = useUserType();

  const methods = useForm<IFiltersForm>({ defaultValues: DefaultFormState });

  const [products, setProducts] = useState<Dictionary[]>();

  useEffect(() => {
    if (isBuyer) {
      getDictionaries({ offerType: 'OFF_SPEC', isBuyer: true });
    } else {
      getDictionaries({ offerType: 'OFF_SPEC' });
    }
  }, []);

  useEffect(() => {
    fetchProducts();
  }, []);

  // ******************** ACCEPT offer  ********************
  const fetchProducts = async () => {
    try {
      const response = await axios_Core.get<Dictionary[]>('/v1/product/types/');
      setProducts(response.data);
    } catch (error: any) {
      console.log('fetchProducts error', error);
    }
  };

  const { watch, formState: { isDirty }, reset } = methods;
  const dateFrom = watch('createdFrom');

  const resetForm = () => {
    reset(DefaultFormState);
    applyFilter();
  };

  const onSubmit = (form: IFiltersForm) => {
    const payload: IFiltersPayload = {
      createdFrom: form.createdFrom ? handleDate({ date: form.createdFrom, format: 'YYYY-MM-DD' }) : null,
      createdTo: form.createdTo ? handleDate({ date: form.createdTo, format: 'YYYY-MM-DD' }) : null,
      matched: form.matched,
      buyerId: form?.buyer?.id || null,
      productTypes: form.product ? form.product?.value : null,
    };

    applyFilter(payload);
  };

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="filters-form">
            {(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && <DevTool control={methods.control} placement="top-right" />}

            <div className="filter-item text">
              Deals done from
            </div>

            <div className="filter-item">
              <FormDatePicker
                name="createdFrom"
                minDate={null}
                rules={{ required: false }}
                maxDate={new Date()}
                placeholder="Select date from"
              />
            </div>

            <div className="filter-item text">
              to
            </div>

            <div className="filter-item">
              <FormDatePicker
                name="createdTo"
                minDate={dateFrom || null}
                rules={{ required: false }}
                maxDate={new Date()}
                placeholder="Select date to"
              />
            </div>

            <div className="filter-item">
              <Select
                name="buyer"
                options={dictionaries.BUYERS_LIST}
                selected={null}
                rules={{ required: false }}
                placeholder="Select buyer"
              />
            </div>

            <div className="filter-item">
              <Select
                name="product"
                options={products || []}
                selected={null}
                rules={{ required: false }}
                placeholder="Select product"
              />
            </div>

            <div className="filter-item">
              <SingleCheckbox
                label="Only show matching offers"
                name="matched"
                rules={{ reqired: false }}
              />
            </div>

            {isDirty && <button className="btn-secondary" onClick={resetForm} type="button">Reset</button>}
            <button className="btn-primary" disabled={!isDirty} type="submit">Apply</button>

          </div>
        </form>

      </FormProvider>

    </div>
  );
};

export default Filters;
