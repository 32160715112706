import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import Preloader from 'SHARED/components/ThePreloader';
import PreventLossUnsavedData from 'SHARED/hooks/usePreventReload';
import { useActions } from 'SHARED/redux/hooks/useActions';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import routes from 'SHARED/types/routes';
import { scrollToTop } from 'SHARED/helpers/common';
import toast from 'react-hot-toast';
import { SimpleOfferSummary } from '../simple-offer-summary';

export const SimpleOfferPreview = () => {
  const history = useHistory();
  const { ON_SPEC, loading } = useTypedSelector((state) => state.offer);
  const { createOffer } = useActions();

  PreventLossUnsavedData(true);

  function handleChangeOffer() {
    history.push(routes.onspec.create_simple_offer);
  }

  async function handlePlaceOrder() {
    const res: any = await createOffer('SIMPLE_ALREADY_PRODUCED');
    console.log('res', res);
    console.log('res.payload', res.payload);

    if (res.payload === true) {
      history.push('/');
    } else {
      toast.error(res.payload);
    }
  }

  useEffect(() => {
    if (!ON_SPEC.productCategory) {
      history.push(routes.onspec.create_simple_offer);
    }

    scrollToTop();
  }, []);

  return (
    <>
      <Helmet>
        <title>Simple Offer Preview</title>
        <meta name="description" content="Simple Offer Preview" />
      </Helmet>

      <Preloader isLoading={loading} />

      <div className="breadcrumbs">
        <a href={routes.onspec.listDefault}>&lt; All offers </a>
      </div>

      <main className="page-body" style={{ paddingBottom: 0 }}>
        <h1 className="page-title">Create a simple offer</h1>

        <div className="attention-message inset">
          Great, you&apos;re almost done! Please review your offer before you continue.
        </div>

        <SimpleOfferSummary offer={ON_SPEC} />

        <div className="attention-message success inset no-vertical-margin">
          <div className="page-section-title">Place this offer</div>
          <div>
            By placing this offer you agree to the OpenDairy platform terms.
            {' '}
            <br />
            If a buyer confirms your offer you have the legal obligation to sell.
            {' '}
            <br />
            Offers can be changed/cancelled at any time.
          </div>
          <div className="actions-section">
            <button
              type="button"
              className="btn-primary"
              onClick={handlePlaceOrder}
            >
              Place offer
            </button>

            <button
              onClick={handleChangeOffer}
              type="button"
              className="btn-secondary"
            >
              Change offer
            </button>

            <a href="/" className="btn-secondary">Discard</a>
          </div>
        </div>
      </main>
    </>
  );
};
