import React              from 'react';
import { HandleRowClick } from 'SHARED/types/offerTypes';
import { OfferListTypes } from 'SHARED/pages/OffersList';
import { FieldsList }     from 'SHARED/pages/OffersList/OfferTable';
import useUserTimezone from 'SHARED/hooks/useUserTimezone';
import CardField from './CardField';

export interface IProps {
  handleRowClick: HandleRowClick,
  fields?: FieldsList,
  offers: any[],
  activeTab: OfferListTypes,
}

const CardsView: React.FC<IProps> = ({ offers, handleRowClick, fields, activeTab }) => {
  const { userTimezone } = useUserTimezone();

  if (!fields) return <div>-</div>;

  const cardClassNames = (offer: any) => `card-view count-${fields(offer, activeTab).length}`;

  const isDealsTab = activeTab === 'deals';

  const handleCardClick = (e:React.MouseEvent<HTMLAnchorElement, MouseEvent>, offer: any) => {
    handleRowClick(e, isDealsTab && offer.deal ? offer.deal.id : offer.id);
  };

  const getId = (offer:any) => (isDealsTab ? offer?.deal?.id : offer.id);

  return (
    <>
      <div className="cards-layout">
        { offers.map((offer) => (
          <a
            href="##"
            className={cardClassNames(offer)}
            key={getId(offer)}
            onClick={(e) => handleCardClick(e, offer)}
            role="button"
          >
            <div className="card-body">
              {
                fields(offer, activeTab, userTimezone)
                  .slice(0, -2)
                  .map((field) => (
                    <CardField
                      key={`card-${getId(offer)}-${field.label}-${field.value}-${field.key}`}
                      fieldData={field}
                    />
                  ))
              }

              {/* Footer */}
              <div className="card-body__footer">
                {
                  fields(offer, activeTab, userTimezone)
                    .slice(-2)
                    .map((field) => (
                      <CardField
                        key={`card-${getId(offer)}-${field.label}-${field.value}-${field.key}`}
                        fieldData={field}
                      />
                    ))
                }
              </div>
            </div>
          </a>
        ))}
      </div>

    </>
  );
};

export default CardsView;
