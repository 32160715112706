import { AxiosResponse } from 'axios';
import { DOCUMENT_ENDPOINTS } from 'SHARED/api/endpoints';
import { axios_Documents } from 'SHARED/helpers/axios';
import notification from 'SHARED/helpers/notifications';

interface IParams {
  dealId: string | number;
  threadId: string | number;
}

async function deleteFileFromDocument(params: IParams) {
  const { dealId, threadId } = params;

  try {
    const response: AxiosResponse<any> = await axios_Documents.delete(
      DOCUMENT_ENDPOINTS.deleteFileFromDocument(dealId, threadId),
    );

    if (response.status === 200) {
      notification({
        type: 'success',
        title: 'Success',
        message: 'File removed successfully',
      });

      return 'success';
    }

    throw new Error('Document delete file error');
  } catch (error) {
    console.error(error);

    return 'error';
  }
}

export default deleteFileFromDocument;
