import React from 'react';
import { OfferTableItem, HandleRowClick, OfferListItem } from 'SHARED/types/offerTypes';
import FormattedPrice from 'SHARED/components/Price';
import { OfferListTypes } from 'SHARED/pages/OffersList';
import { FieldsList } from 'SHARED/pages/OffersList/OfferTable';
import MatchedProducts from '../../components/Offer/MatchedProducts';

interface IProps {
  fields?: FieldsList,
  offers: OfferListItem[],
  handleRowClick: HandleRowClick,
  activeTab: OfferListTypes,
}

const renderField = (item: OfferTableItem) => {
  const {
    key, value, isSplittable, prefix, isApproved, matchedProducts = [], ourLastBidVolume,
  } = item;

  switch (key) {
    default: return value || '-';

    case 'type':
      return (
        <>
          {item.isAnonymous && <i className="icon-eye-slash inline-icon" />}
          {value}
        </>
      );

    case 'volume':
      return (
        <>
          {isSplittable && <i className="icon-split" />}
          <span>{value}</span>
        </>
      );

    case 'seller':
      return (
        <>
          {isApproved && <i className="icon-approved" title="Approved seller" />}
          <span>{value}</span>
        </>
      );

    case 'ourLastBid':
      return (
        <>
          {value ? (
            <>
              <FormattedPrice suffix="/mt" prefix={prefix} value={value} className="inline" />
              {ourLastBidVolume && (
                <>
                  <span>| </span>
                  {`${ourLastBidVolume} mt`}
                </>
              )}

            </>
          ) : '-'}
        </>
      );

    case 'product':
      return (
        <>
          <span>{value}</span>
          {matchedProducts.length > 0 && <MatchedProducts products={matchedProducts} />}
        </>
      );

    case 'price':
      return <FormattedPrice suffix="/mt" prefix={prefix} value={value} />;
  }
};

const TableView: React.FC<IProps> = ({
  offers, handleRowClick, fields, activeTab,
}) => {
  const isDealsTab = activeTab === 'deals';

  if (!fields) return <div>-</div>;

  const handleItemClick = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, offer: any) => {
    handleRowClick(e, isDealsTab && offer.deal ? offer.deal.id : offer.id);
  };

  const getTitle = (offer: any) => (isDealsTab ? `Deal id: ${offer?.deal?.id}` : `Offer id: ${offer.id}`);
  const getId = (offer: any) => (isDealsTab ? offer?.deal?.id : offer.id);

  return (
    <>
      <table className="table-component">

        <thead>
          <tr>
            {fields(offers[0], activeTab).map((field: any) => (<th key={field.label}>{field.label}</th>))}
          </tr>
        </thead>

        <tbody>
          {offers.map((offer) => (
            <tr key={getId(offer)} onClick={(e) => handleItemClick(e, offer)} title={getTitle(offer)}>
              {fields(offer, activeTab).map((item) => (
                <td key={item.key} className={item.key}>
                  {renderField(item)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>

      </table>

    </>
  );
};
export default TableView;
