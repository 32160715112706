import qs from 'qs';
import { useLocation } from 'react-router-dom';

interface IUseIsForRfpReturn {
  isForRfp: boolean,
  rfpID: string | number | null | undefined
}

const useIsForRfp = () => {
  const { search } = useLocation();
  const query = qs.parse(search, { ignoreQueryPrefix: true });
  const isForRfpValue = query.isForRfp;

  const obj: IUseIsForRfpReturn = {
    isForRfp: false,
    rfpID: null,
  };

  if ((typeof isForRfpValue === 'string') && isForRfpValue.length > 0) {
    obj.isForRfp = true;
    obj.rfpID = isForRfpValue;
  }

  return obj;
};

export default useIsForRfp;
