import React, { useEffect, useState } from 'react';
import {
  Link, useHistory, useLocation, useParams,
} from 'react-router-dom';
import Select from 'SHARED/components/Select';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import routes from 'SHARED/types/routes';
import { Dictionary } from 'SHARED/types/offerTypes';
import validationRules from 'SHARED/helpers/validation';
import FormInput from 'SHARED/components/FormInput';
import Checkboxes from 'SHARED/components/Checkboxes';
import { FormProvider, useForm } from 'react-hook-form';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { useActions } from 'SHARED/redux/hooks/useActions';
import Preloader from 'SHARED/components/ThePreloader';
import { cloneDeep } from 'lodash';
import { prepareCharacteristics } from 'SHARED/helpers/prepareCharacteristics';
import SingleCheckbox from 'SHARED/components/Checkbox';
import texts, { tooltips } from 'SHARED/helpers/texts';
import PriceInput from 'SHARED/components/PriceInput';
import { CharacteristicItem, NewProduct, NewProductForm } from 'SHARED/types/specsTypes';
import qs from 'qs';
import { useSubmitProductMutation } from 'SHARED/api/_mutations/useSubmitProductMutation';
import findCategoryPackaging from 'SHARED/helpers/findCategoryPackaging';
import CharacteristicsList from './ProductCharacteristics';

export interface RouteParams {
  specType: string,
}

interface FormInputs {
  productType: Dictionary,
  title: string,
  characteristics: [{ value: number, valueTo: number, notRequired: boolean }]

  // Buyer only
  packaging?: Dictionary,
  customLabelRequired?: boolean,

  // Seller only
  premiumPriceEur?: number | string,
  premiumPriceUsd?: number | string,
}

interface IProps {
  isForBuyer?: boolean,
}

const SpecificationPage: React.FC<IProps> = ({ isForBuyer = false }) => {
  const { specType } = useParams<RouteParams>();
  const { search } = useLocation();
  const query = qs.parse(search, { ignoreQueryPrefix: true });

  const {
    categories,
    characteristics,
    documents,
    loading,
    product,
  } = useTypedSelector((state) => state.specs);
  const {
    getCharacteristicsList,
    getProductCategoriesList,
    getProduct,
    clearProduct,
  } = useActions();

  const { isLoading, mutateAsync: submitProduct } = useSubmitProductMutation();

  const choosedSpecType = specType.toUpperCase();

  const productCategoryMatch = categories?.find((item) => item.value.toLowerCase() === specType.toLowerCase());

  // spec duplication
  const duplicateId: any = query.duplicate;
  const isDuplicate = duplicateId && duplicateId?.length > 0;
  const isValidDuplicate = specType.toLowerCase() === product?.productCategory?.value.toLowerCase();

  const methods = useForm<FormInputs>({
    shouldUnregister: true,
    shouldFocusError: true,
  });

  const { watch, setValue } = methods;
  const productType = watch('productType');
  const [types, setTypes] = useState<any>([]);

  //  *********************** get categories with types ***************************
  useEffect(() => {
    getProductCategoriesList(true);
  }, []);

  //  *********************** get characteristics list for choosed type ***************************
  useEffect(() => {
    if (productType) {
      getCharacteristicsList(productType.value);
    }
  }, [productType]);

  //  *************************** setting types for types select ***************************
  useEffect(() => {
    const type = categories.find((cat) => cat.value.toLowerCase() === specType)?.productTypes;

    setTypes(type);
  }, [categories]);

  // preparing data for API request
  // some fields need to map
  // some fields need to add
  const prepareData = (data: NewProductForm) => {
    const documentsList = data.requiredDocuments ? documents.filter((el) => data.requiredDocuments.includes(el.value)) : null;

    // const form: NewProduct = {
    const form: any = {
      ...cloneDeep(data),
      productCategory: { label: choosedSpecType, value: choosedSpecType },
      // productCategory: 'some wrong value to break validation',
      requiredDocuments: documentsList,
      premiumPriceEur: data.premiumPriceEur ? parseFloat(data.premiumPriceEur) : undefined,
      premiumPriceUsd: data.premiumPriceUsd ? parseFloat(data.premiumPriceUsd) : undefined,
      characteristics: prepareCharacteristics(data.characteristics),
    };

    return form;
  };

  // TODO: typing of this form is flawed
  // ? form got one interface, but `prepareData` got another
  // ? hereby `prepareData` should be updated to match form interface
  // const onSubmit = async (form: NewProductForm) => {
  const onSubmit = async (form: any) => {
    const preparedData: NewProduct = prepareData(form);

    const isError = await submitProduct(preparedData);

    // it can be `false`, array of errors or unknown
    // in case of validated array we should set errors to form
    // so user knows what is wrong
    if (isError) {
      isError?.forEach((error: any) => {
        methods.setError(error?.field, { message: error?.message, type: 'required' }, { shouldFocus: true });
      });
    } else {
      history.push(routes.common.specifications);
    }
  };

  const history = useHistory();
  const handleDiscard = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (isDuplicate) {
      history.push(routes.common.productDetails(duplicateId));
      return;
    }
    // console.log(routes.common.specifications);
    history.push(routes.common.specifications);
  };

  // * duplicate product ************************************************************************
  const [characteristicsDuplicate, setCharacteristicsDuplicate] = useState<CharacteristicItem[] | null>(null);
  const [buyerRequiredDocumentsPrefill, setBuyerRequiredDocumentsPrefill] = useState<Dictionary[] | null>(null);

  const prefillWithDuplicatedData = () => {
    if (!product) { return; }
    // console.log('product: ', product);

    setValue('title', `Copy of ${product?.title}`);
    setValue('productType', product?.productType);

    setCharacteristicsDuplicate(product?.characteristics);

    // Seller
    if (!isForBuyer) {
      setValue('premiumPriceEur', product?.premiumPriceEur);
      setValue('premiumPriceUsd', product?.premiumPriceUsd);
    }

    // Buyer
    if (isForBuyer) {
      setValue('packaging', product?.packaging);
      setValue('customLabelRequired', product?.customLabelRequired);
      setBuyerRequiredDocumentsPrefill(product?.requiredDocuments);
    }
  };

  useEffect(() => {
    // get product by id (donor for duplication)
    if (isDuplicate) {
      getProduct(duplicateId);
    }

    // clear product data (remove duplicated product)
    return () => {
      clearProduct();
    };
  }, [specType]);
  useEffect(() => {
    if (product && isDuplicate && isValidDuplicate) {
      prefillWithDuplicatedData();
    }
  }, [product]);
  // * duplicate product  === END ***************************************************************

  return (
    <>
      <Preloader isLoading={loading || isLoading} />

      {/* <DevTool control={methods.control} placement="top-right" /> */}

      <div className="breadcrumbs">
        <Link to={routes.common.specifications}>
          &lt;
          {' '}
          {texts.specsTexts.common.allSpecs}
        </Link>
      </div>
      <div className="page-body product-creation">
        <h1>
          Product Specifications
        </h1>

        {/* Simple notice, that user is about to duplicate product spec */}
        {duplicateId && product && isValidDuplicate && (
          <div className="attention-message">
            You are creating product specification from your existing product specification. You can keep all specification, but need to change the title.
          </div>
        )}

        {/* Warning message, that duplication spec is not valid */}
        {duplicateId && product && !isValidDuplicate && (
          <div className="attention-message">
            You want to duplicate product specification, but it is not valid. Please, check the product specification and try again.
          </div>
        )}

        <DataItemPreview item={{ label: productCategoryMatch?.label || specType.toUpperCase(), value: specType }} title="PRODUCT CATEGORY" />
        <br />

        <FormProvider {...methods}>
          {/* TODO: typing of this form is flawed,  */}
          <form onSubmit={methods.handleSubmit(onSubmit)}>

            {categories && (
              <Select
                label="PRODUCT TYPE "
                name="productType"
                options={types}
                selected={null}
                defaultValue=""
                rules={validationRules.required}
              />
            )}

            <FormInput
              name="title"
              label="PRODUCT SPECS TITLE"
              value=""
              rules={validationRules.required}
            />

            {productType && (
              <CharacteristicsList
                characteristics={characteristics}
                duplicateOf={characteristicsDuplicate}
                isForBuyer={isForBuyer}
              />
            )}

            {isForBuyer && (
              <>
                {/* ************************** packaging and documents SECTION ******************** */}
                <h2 className="page-section-title with-separator">Required packaging and documents</h2>

                <Select
                  label="PACKAGING"
                  name="packaging"
                  options={findCategoryPackaging(categories, choosedSpecType)}
                  selected={null}
                  defaultValue="25_kg_bags"
                  rules={validationRules.required}
                />

                <Checkboxes
                  label="REQUIRED DOCUMENTS"
                  name="requiredDocuments"
                  values={documents}
                  selected={buyerRequiredDocumentsPrefill}
                  className="three-columns"
                  key={`${buyerRequiredDocumentsPrefill?.length}-${documents?.length}`}
                />

                <SingleCheckbox
                  title="CUSTOM LABEL"
                  label="Custom label required"
                  name="customLabelRequired"
                  rules={{ reqired: false }}
                />
              </>
            )}

            {!isForBuyer && (
              <>
                <h2 className="page-section-title with-separator">Premium for To-Be-Produced offers</h2>

                <div className="inputs-range-wrapper">
                  <PriceInput
                    name="premiumPriceEur"
                    currency="€"
                    label="PREMIUM PRICE"
                    value="0"
                    tooltip={tooltips.premiumPriceEur}
                    rules={validationRules.required}
                  />

                  <PriceInput
                    name="premiumPriceUsd"
                    currency="$"
                    label="PREMIUM PRICE"
                    value="0"
                    tooltip={tooltips.premiumPriceUsd}
                    rules={validationRules.required}
                  />
                </div>
              </>

            )}

            <h2 className="page-section-title with-separator">Product specs status</h2>

            <SingleCheckbox
              title="STATUS"
              label="Active"
              name="active"
              defaultChecked
              rules={{ reqired: false }}
            />

            <button type="submit" className="btn-primary" disabled={!productType}>Apply</button>
            <button type="button" onClick={(e) => handleDiscard(e)} className="btn-secondary">Cancel</button>
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default SpecificationPage;
