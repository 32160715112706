import React from 'react';
import FormattedPrice from 'SHARED/components/Price';
import { Bid } from 'SHARED/types/offerTypes';

import './styles/modal-styles.scss';

interface IProps {
  bid: Bid;
  handleClose: () => void;
  handleAcceptBid: () => void;
}

const ConfirmAuctionBidModal: React.FC<IProps> = ({
  bid,
  handleClose,
  handleAcceptBid,
}) => (
  <div className="popup">
    <div className="popup-overlay" aria-hidden onClick={handleClose} onKeyDown={(e) => console.log(e)} />
    <div className="popup-body">
      <a href="##" className="popup-close" onClick={handleClose}>
        close
      </a>

      <div className="popup-content">
        <h1>You are about to close the deal!</h1>
        <p>The deal will be made with the following volume and price:</p>

        <FormattedPrice value={bid.bidVolume} title="DEAL VOLUME" suffix="mt" />
        <FormattedPrice value={bid.value} title="BID PRICE" prefix={bid.currency} suffix="/mt" />
        {!!bid.bidVolume && (
          <FormattedPrice
            value={bid.value * bid.bidVolume}
            title="TOTAL DEAL PRICE"
            prefix={bid.currency}
            boldPrice
          />
        )}

        <div>
          <button type="button" className="btn-primary" onClick={handleAcceptBid}>Accept the bid</button>
          <button type="button" className="btn-secondary" onClick={handleClose}>Back to auction</button>
        </div>
      </div>
    </div>
  </div>
);

export default ConfirmAuctionBidModal;
