import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import FormInput from 'SHARED/components/FormInput';
import validationRules from 'SHARED/helpers/validation';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { useActions } from 'SHARED/redux/hooks/useActions';
import PriceInput from 'SHARED/components/PriceInput';
import { OfferBid } from 'SHARED/types/bidTypes';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import CommonBidFields from 'SHARED/components/Offer/CommonBidFields';
import FormattedPrice from 'SHARED/components/Price';
import Preloader from 'SHARED/components/ThePreloader';
import errorCodes from 'SHARED/helpers/codes';
import { ICounterOffer, ICounterOfferForm } from 'SHARED/types/models/ICounterOffer';
import { postSubmitBid } from 'SHARED/api/offers/post/postSubmitBid';
import VolumeInput from 'SHARED/components/VolumeInput';
import { NonValidStatuses } from './BidNotValidModal';
import './styles/counter-offer.scoped.scss';

interface Props {
  handleClose: (value: React.SetStateAction<boolean>) => void,
  setIsNotValidPopup: (value: React.SetStateAction<NonValidStatuses | null>) => void,
  bid: OfferBid
}

const CounterOfferModal: React.FC<Props> = ({ handleClose, bid, setIsNotValidPopup }) => {
  const { ON_SPEC } = useTypedSelector((state) => state.offer);
  const { id, splittable, type, remainingVolume } = ON_SPEC;

  // flags
  const isToBeProduced = type?.value === 'TO_BE_PRODUCED';
  const isSimpleOffer = type?.value === 'SIMPLE_ALREADY_PRODUCED';
  // flags - end

  const { getOfferDetails } = useActions();

  const methods = useForm();
  const { formState: { isDirty } } = methods;
  const [validatedBid, setValidatedBid] = useState<ICounterOffer | null>();
  const [loading, setLoading] = useState(false);

  const handleValidate = async (form: ICounterOfferForm) => {
    setLoading(true);
    const bidForSubmit: ICounterOffer = {
      volume: parseFloat(form.volume),
      price: parseFloat(form.price),
      message: form.message,
      bidId: bid.id,
      offerId: bid.offerId,
      offerVersion: bid.offerVersion || null,
    };

    (async () => {
      const result: any = await postSubmitBid(bidForSubmit, 'VALIDATE');
      setLoading(false);

      if (result?.status === 200) {
        console.log('show submit popup');
        setValidatedBid(bidForSubmit);
      }

      if (result?.status === 400) {
        setValidatedBid(null);

        if (result?.data?.message === errorCodes?.expiredOffer || result?.data?.message === errorCodes.outdatedOffer) {
          handleClose(false);
          setIsNotValidPopup('EXPIRED');
        }

        if (result?.data?.message === errorCodes?.notValidBid) {
          handleClose(false);
          setIsNotValidPopup('NOT_VALID');
        }
      }
    })();
  };

  const handleSubmit = () => {
    setLoading(true);

    if (validatedBid) {
      (async () => {
        await postSubmitBid(validatedBid, 'SUBMIT');
        setLoading(false);
        setValidatedBid(null);
        handleClose(false);
        getOfferDetails({ id, offerType: 'ON_SPEC' });
      })();
    }
  };

  return (
    <div className="popup">
      <div className="popup-overlay" />
      <Preloader isLoading={loading} />
      <div className="popup-body counter-offer">
        <button
          type="button"
          className="popup-close"
          onClick={(e) => { handleClose(false); }}
        >
          close
        </button>

        {/* ***************** VALIDATION part ******************** */}
        {!validatedBid && (
          <>
            <div className="counter-offer-top">
              <div className="page-section-title">Buyer</div>
              <DataItemPreview className="page-column" item={bid.buyerOrg} title="BUYER" />
            </div>

            <div className="counter-offer-body">
              <div className="counter-offer-left">
                <CommonBidFields bidFields={bid} isSimpleOffer={isSimpleOffer} />
              </div>

              <div className="counter-offer-right">

                <div className="page-section-title">Create a counter offer</div>

                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(handleValidate)}>

                    <>
                      <div className="short-inputs">
                        <VolumeInput
                          name="volume"
                          label="VOLUME"
                          className={!splittable && type?.value !== 'TO_BE_PRODUCED_BUNDLE' ? 'disabled' : ''}
                          rules={validationRules.required}
                          value={bid.volume}
                          testingName="counter-offer-volume-input"
                          hasMaxValue={!isToBeProduced}
                          maxValue={remainingVolume}
                          disabled={!isToBeProduced && !splittable}
                        />

                        <PriceInput
                          label="COUNTER OFFER PRICE"
                          name="price"
                          value={bid.netPrice}
                          rules={validationRules.required}
                          tooltip=""
                          currency={bid.currency === 'EUR' ? '€' : '$'}
                        />
                      </div>

                      <FormInput
                        name="message"
                        label="MESSAGE"
                      />
                    </>

                    <div>
                      <button type="submit" className="btn-primary" disabled={!isDirty}>Review your counter offer</button>
                      {/* <button type="button" className="btn-secondary" onClick={(e) => { handleClose(false); }}>Cancel</button> */}
                    </div>
                  </form>
                </FormProvider>
              </div>

            </div>
          </>
        )}

        {/* ***************** SUBMIT part ******************** */}
        {validatedBid && (
          <div className="validate-data">
            <h1>You are about to place a counter offer for one buyer!</h1>

            <div>
              {`Only the buyer ${bid.buyerOrg} will be able to see this counter offer. You could continue negotiation with the others.
            If a buyer will accept your offer in a meanwhile, the deal will be made automatically.`}

            </div>
            <DataItemPreview item={validatedBid.volume} suffix="mt" title="BID VOLUME" />
            <FormattedPrice value={validatedBid.price} prefix={bid.currency} suffix="/mt" title="BID PRICE" />
            <FormattedPrice value={validatedBid.price * validatedBid.volume} prefix={bid.currency} suffix="/mt" title="TOTAL COUNTER OFFER" />
            <div>
              <button type="button" className="btn-primary" onClick={handleSubmit}>Place this counter offer</button>
              <button type="button" className="btn-secondary" onClick={() => { handleClose(false); }}>Back to the offer</button>
            </div>

          </div>
        )}

      </div>
    </div>
  );
};
export default CounterOfferModal;
