import React, { useState, useEffect } from 'react';
import ProductSpecsDetailsModal from 'SHARED/modals/ProductSpecsDetailsModal';
import { Link } from 'react-router-dom';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { useActions } from 'SHARED/redux/hooks/useActions';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import { MpcDocLink } from 'SHARED/helpers/common';
import { CharacteristicItem } from 'SHARED/types/specsTypes';
import FormattedPrices from 'SHARED/components/Prices';
import { offerLabels } from 'SHARED/helpers/texts';
import OfferDisclaimer from 'SHARED/components/OfferDisclaimer';
import useUserTimezone from 'SHARED/hooks/useUserTimezone';
import { dateWithTimezone, dateRangeWithTimezone } from 'SHARED/helpers/dates';
import { readablePaymentTerms } from 'SHARED/helpers/readablePaymentTerms';

export interface IAlreadyProducedOfferSummaryProps {
  mode: 'preview' | 'detail'
}

const AlreadyProducedOfferSummary: React.FC<IAlreadyProducedOfferSummaryProps> = ({ mode }) => {
  const { ON_SPEC } = useTypedSelector((state) => state.offer);
  const { product } = useTypedSelector((state) => state.specs);

  const { getProduct } = useActions();

  const { userTimezone } = useUserTimezone();

  const isPreview = mode === 'preview';

  const {
    description,
    productType,
    productCategory,
    documentCapabilities,
    packaging,
    coaUsed,
    customLabelPossible,
    productionLocation,
    logisticLocation,
    productionDate,
    expirationDate,
    salesTerms,
    paymentTerms,
    incoterms,
    loadOptions,
    priceEur,
    priceUsd,
    totalVolume = 0,
    remainingVolume = 0,
    documents,
    buyersFetchType,
    buyers,
    splittable,
    departureFrom,
    departureTo,
    departurePeriod,
    params,
    endDate,
    productId,
    hasDeals,
    deals,
    productCharacteristics,
    parentOffer,
    paymentOffset,
    createdBy,
    status,
  } = ON_SPEC;

  const isForRfp = parentOffer?.type?.value === 'RFP';

  const totalUsd = priceUsd ? priceUsd * (isPreview ? totalVolume : remainingVolume) : 0;
  const totalEur = priceEur ? priceEur * (isPreview ? totalVolume : remainingVolume) : 0;

  const [isPopup, setIsPopup] = useState(false);
  const [isCoaPopup, setIsCoaPopup] = useState(false);

  const isCreatedByAdmin = createdBy?.type === 'ADMIN';
  const isOffline = status === 'OFFLINE';

  useEffect(() => {
    if (productId) {
      getProduct(productId);
    }
  }, [coaUsed]);

  const handleClose = () => {
    setIsPopup(false);
    setIsCoaPopup(false);
  };

  const getCoaCombined = () => {
    if (!productCharacteristics || !product) {
      return [];
    }

    // if COA checked map edited characteristics
    // add needed fields from original characteristics list
    const characteristics = productCharacteristics.map((ch: CharacteristicItem) => {
      const oridginalItem: any = product.characteristics.find((oi: any) => oi.characteristicSpecId === ch.characteristicSpecId);
      return { ...oridginalItem, ...ch };
    });

    return characteristics;
  };

  const departure =    departurePeriod.value === 'MONTH'
  // ? handleDate({ date: departureFrom, format: 'MMMM YYYY' })
    ? dateWithTimezone(departureFrom, userTimezone, 'MMMM YYYY')
    : dateRangeWithTimezone({ from: departureFrom, to: departureTo, timezone: userTimezone });

  return (
    <>
      <div className="page-columns">
        <div className="page-column">
          <div className="page-section-title">Product</div>

          {description && <DataItemPreview multiline multilineText={description} title="REMARKS FOR THE BUYER" />}
          <DataItemPreview item={productCategory} title="PRODUCT CATEGORY" />
          <DataItemPreview item={productType} title="PRODUCT TYPE" />

          {isCreatedByAdmin && (
            <DataItemPreview item="OpenDairy" title="Added by" />
          )}

          <div className="data-preview-row">
            <div className="label-text">SELLER PRODUCT SPECIFICATION</div>
            <div>{product?.title}</div>
            <div><a href="#open-spec-popup" onClick={(e) => setIsPopup(true)}>Specifications details</a></div>
          </div>

          {documents && documents.COA && (
            <>
              <DataItemPreview file={documents.COA[0]} title="PRODUCT COA" />
              <div className="data-preview-row" style={{ marginTop: '-15px' }}>
                <a href="#open-coa-popup" onClick={(e) => setIsCoaPopup(true)}>Certificate of analysis details</a>
              </div>
            </>
          )}

          <DataItemPreview item={documentCapabilities} title={offerLabels.alreadyProduced.documents} />
          <DataItemPreview item={productionLocation?.fullAddress} title="PRODUCTION LOCATION" />
          <DataItemPreview item={packaging} title={offerLabels.alreadyProduced.packaging} />
          <DataItemPreview item={customLabelPossible ? 'Custom label possible' : 'Custom label not possible'} title="CUSTOM LABEL" />
          <DataItemPreview item={productionDate} dateFormat="DD MMM YYYY" isDate title="production date" />
          <DataItemPreview item={expirationDate} dateFormat="DD MMM YYYY" isDate title="expiration date" />
          <DataItemPreview item={salesTerms} title="SALES TERMS" />

          {salesTerms?.value === 'MPC' && (
            <a href={MpcDocLink} target="_blank" rel="noreferrer" className="document-link">
              <i className="icon-flag" />
              <span>MPC sales terms</span>
            </a>
          )}

          {salesTerms?.value !== 'MPC' && documents && documents.SALES_TERMS && <DataItemPreview file={documents.SALES_TERMS[0]} title="SALES TERMS DOCUMENT" />}
        </div>

        <div className="page-column">
          <div className="page-section-title">Shipping</div>

          <DataItemPreview item={incoterms} title="SELLER INCOTERMS" />
          <DataItemPreview item={logisticLocation?.fullAddress} title="SELLER INCOTERMS LOCATION" />
          <DataItemPreview item={loadOptions} title="LOADING DETAILS" />
          <DataItemPreview item={departure} title="DEPARTURE" />

          <br />
          <br />

          <div className="page-section-title">Offer expiration</div>
          <DataItemPreview item={endDate} isDate title="Offer end date" />
        </div>

        <div className="page-column">
          <div className="page-section-title">Volume and price</div>

          <DataItemPreview
            icon={splittable ? 'icon-splittable' : ''}
            item={isPreview ? totalVolume : remainingVolume}
            title={isPreview ? 'VOLUME' : 'REMAINING VOLUME'}
            suffix="mt"
          />

          <DataItemPreview item={splittable ? 'Yes' : 'No'} title="SPLITTABLE" />
          <FormattedPrices title={offerLabels.alreadyProduced.price} values={[priceEur, priceUsd]} />
          <DataItemPreview item={readablePaymentTerms({ paymentTerms, paymentOffset, isPreview })} title="PAYMENT TERMS" />
          <FormattedPrices title="TOTAL PRICE" disableSuffix values={[totalEur, totalUsd]} />
        </div>

        {!isForRfp && !isOffline && (
          <div className="page-column">
            <div className="page-section-title">Buyers</div>

            {buyersFetchType?.value === 'INCLUDE_ALL' && (<div>All buyers have been included</div>)}
            {buyersFetchType?.value === 'EXCLUDE' && (<DataItemPreview item={params?.excludedBuyers || buyers} title="EXCLUDED BUYERS" />)}
            {/* TODO: logic needs to be updated if `buyers` could be an array OR single object */}
            {buyersFetchType?.value === 'INCLUDE_GROUP' && (<DataItemPreview item={buyers} title="INCLUDED BUYERS" />)}
          </div>
        )}
      </div>

      <OfferDisclaimer userType="seller" newTab={mode === 'preview'} />

      {hasDeals
        && (
          <>
            {deals?.map((deal) => (
              <div>
                <Link
                  type="button"
                  to={`/onspec/deals/${deal.id}`}
                >
                  {`Show deal (${deal.number}) details`}
                </Link>
              </div>
            ))}
            <br />
            <br />
          </>
        )}

      {isPopup && (
        <ProductSpecsDetailsModal
          handleClose={() => handleClose()}
          characteristics={product?.characteristics}
        />
      )}

      {isCoaPopup && (
        <ProductSpecsDetailsModal
          handleClose={() => handleClose()}
          characteristics={mode === 'detail' ? productCharacteristics : getCoaCombined()}
          coaUsed
        />
      )}

    </>
  );
};

export default AlreadyProducedOfferSummary;
