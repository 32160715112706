import { Dictionary } from 'SHARED/types/offerTypes';
import { ProductCategory } from 'SHARED/types/specsTypes';

const findCategoryPackaging = (
  categories: ProductCategory[],
  selectedProductCategory: string | null | undefined,
): Dictionary[] => {
  if (!selectedProductCategory) return [];
  const options = categories.find((category) => (
    category.value === selectedProductCategory
  ))?.packagingOptions;
  return options || [];
};

export default findCategoryPackaging;
