import React from 'react';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import OfferDisclaimer from 'SHARED/components/OfferDisclaimer';
import FormattedPrices from 'SHARED/components/Prices';
import { readablePaymentTerms } from 'SHARED/helpers/readablePaymentTerms';

export interface IOffspecOfferSummaryProps {
  mode?: 'preview' | 'detail'
}

const OffspecOfferSummary: React.FC<IOffspecOfferSummaryProps> = ({
  mode = 'detail',
}) => {
  const { OFF_SPEC } = useTypedSelector((state) => state.offer);

  const isPreview = mode === 'preview';

  const {
    title,
    description,
    documentCapabilities,
    packaging,
    paymentTerms,
    incoterms,
    loadAddress,
    logisticLocation,
    loadOptions,
    loadDateFrom,
    loadDateTo,
    endDate,
    priceEur,
    priceUsd,
    totalVolume,
    documents,
    buyersFetchType,
    params,
    splittable,
    minBidVolume,
    paymentOffset,
  } = OFF_SPEC;

  const preparedVolume = typeof totalVolume === 'string' ? parseInt(totalVolume, 10) : totalVolume || 0;
  const totalUsd = priceUsd ? priceUsd * preparedVolume : 0;
  const totalEur = priceEur ? priceEur * preparedVolume : 0;

  return (
    <>
      <div className="page-columns">
        <div className="page-column">
          <div className="page-section-title">Product</div>
          <DataItemPreview item={title} title="AUCTION TITLE" />
          <DataItemPreview multiline multilineText={description} title="DESCRIPTION" />
          {documents && documents.COA && <DataItemPreview file={documents.COA[0]} title="PRODUCT COA" />}
          <DataItemPreview item={documentCapabilities} title="DOCUMENTS INCLUDED" />
          <DataItemPreview item={packaging} title="PACKAGING" />
          <DataItemPreview item={params?.salesTerms} title="SALES TERMS" />
          {documents && documents.SALES_TERMS && <DataItemPreview file={documents.SALES_TERMS[0]} title="SALES TERMS DOCUMENT" />}
        </div>
        <div className="page-column">
          <div className="page-section-title">Shipping</div>
          <DataItemPreview item={incoterms} title="INCOTERMS" />
          {}
          {!!loadAddress?.value && <DataItemPreview item={loadAddress} title="LOADING ADDRESS" />}
          {!!logisticLocation && <DataItemPreview item={logisticLocation?.fullAddress} title="SELLER INCOTERMS LOCATION" />}
          <DataItemPreview item={loadOptions} title="LOADING DETAILS" />
          <DataItemPreview datesRange={[loadDateFrom, loadDateTo]} title="PICK-UP DATE RANGE" />

          <br />
          <br />
          <div className="page-section-title">Auction end date</div>
          <DataItemPreview item={endDate} isDate title="Auction end date" />

        </div>
        <div className="page-column">
          <div className="page-section-title">Volume and price</div>

          <DataItemPreview icon={splittable ? 'icon-splittable' : ''} item={totalVolume} title="VOLUME" suffix="mt" />
          <DataItemPreview item={splittable ? 'Yes' : 'No'} title="SPLITTABLE" />
          {splittable && <DataItemPreview item={minBidVolume} title="MINIMUM BID VOLUME" suffix="mt" />}

          <FormattedPrices title="ASK PRICE" values={[priceEur, priceUsd]} />
          <DataItemPreview item={readablePaymentTerms({ paymentTerms, paymentOffset, isPreview })} title="PAYMENT TERMS" />
          <FormattedPrices title="TOTAL PRICE" disableSuffix values={[totalEur, totalUsd]} />

        </div>
        <div className="page-column">
          <div className="page-section-title">Buyers</div>
          {buyersFetchType.value === 'INCLUDE_ALL' && (<div>All buyers have been included</div>)}
          {buyersFetchType.value === 'EXCLUDE' && (<DataItemPreview item={params.excludedBuyers || OFF_SPEC.buyers} title="EXCLUDED BUYERS" />)}
          {buyersFetchType.value === 'INCLUDE_GROUP' && (<DataItemPreview item={OFF_SPEC.buyers} title="INCLUDED BUYERS" />)}
        </div>
      </div>

      <OfferDisclaimer userType="seller" newTab={mode === 'preview'} />
    </>
  );
};

export default OffspecOfferSummary;
