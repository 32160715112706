import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { CharacteristicItem, CharacteristicItemShort } from 'SHARED/types/specsTypes';
import { Dictionary } from 'SHARED/types/offerTypes';
import NumericInput from 'SHARED/components/NumericInput';
import validationRules from 'SHARED/helpers/validation';
import texts from 'SHARED/helpers/texts';
import { preparePrice, preparePricePrefill } from 'SHARED/helpers/common';
import FormSelect from '../Select';
import Tooltip from '../Tooltip';
import NotTestedSection from './NotTestedSection';
import './characteristic-input.scoped.scss';

interface CharacteristicInputProps {
  characteristic: CharacteristicItem
  rules?: any,
  placeholder?: string,
  tooltip?: string,
  disabled?: boolean,
  isCoaUsed?: boolean,
  prefilledItem?: CharacteristicItemShort,
  className?: string,
  inputsGroup?: string,
  isForBuyer?: boolean,
}
interface Ch { value: string | undefined, selectValue: Dictionary | null, notTested: boolean }
type PrefilledValues = () => Ch;
// type PrefilledValue = () => string | undefined;
// type PrefilledSelectValue = () => Dictionary | null;

const CharacteristicInput: React.FC<CharacteristicInputProps> = (props) => {
  const {
    register,
    formState: { errors },
    clearErrors,
    watch,
    setValue,
  } = useFormContext();

  const {
    characteristic,
    disabled = false,
    className = '',
    inputsGroup = 'characteristics',
    isForBuyer = false,
    isCoaUsed = false,
    prefilledItem,
  } = props;

  const {
    id,
    characteristicSpecId,
    prefill,
    typicalValue,
    characteristicType,
    prefillValues,
    minValue,
    maxValue,
    measurementValueType,
    measurementUnit,
    range,
    value: originalValue,
    valueTo: originalValueTo,
    notTested: notOriginallyTested,
    displayOrder: index,
  } = characteristic;

  const name = characteristicType.value;

  const defaultRules = {
    ...validationRules.required,
    ...validationRules.minPrice(minValue, `Value must be greater than min value ${minValue}`),
  };

  const [vRules, setVrules] = useState<any>(defaultRules);
  const groupItemName = `${inputsGroup}[${index}]`;
  const isRange = measurementValueType === 'RANGE';

  const names = {
    notTested: `${groupItemName}.notTested`,
    value: `${groupItemName}.value`,
    valueTo: `${groupItemName}.valueTo`,
    characteristicSpecId: `${groupItemName}.characteristicSpecId`,
  };

  const placeholders = {
    value: `${minValue.toString()} - ${maxValue.toString()}`,
    maxValue: `max ${maxValue.toString()}`,
  };

  // define decimals according measurementUnit
  // and for specific iem -> PH
  const DECIMALS = [
    // measurementUnit
    'M_M_PERCENT',
    'PERCENT',
    // characteristicType
    'PH',
    'WPNI',
    'INSOLUBILITY',
  ];
  const isDecimal = DECIMALS.includes(measurementUnit.value) || DECIMALS.includes(characteristicType.value);
  const isSelectbox = characteristicType.value === 'SCORCHED_PARTICLES';

  // watch not tested checkbox
  const notTested = watch(names.notTested);
  const value = watch(names.value);
  const notTestedText = isForBuyer ? 'Not Required' : 'Not tested';

  const prefilledValues: PrefilledValues = () => {
    if (prefilledItem) {
      return {
        value: preparePricePrefill(prefilledItem.value),
        selectValue: { value: prefilledItem.value?.toString(), label: prefilledItem.value?.toString() },
        notTested: prefilledItem.notTested,
      };
    }

    return {
      value: prefill ? preparePrice(typicalValue) : undefined,
      selectValue: null,
      notTested,
    };
  };

  // * prefill fields on mount ********************************************************************
  useEffect(() => {
    // not tested - type of field doesn't matter
    if (!prefilledItem) { return; }
    if (prefilledItem.notTested) {
      setValue(names.notTested, prefilledItem.notTested);
      return;
    }
    // stop prefill if type of field is select
    if (isSelectbox) { return; }

    // prefill first input
    setValue(names.value, prefilledItem.value);
    // prefill second input if type of field is range
    if (isRange) {
      setValue(names.valueTo, prefilledItem.valueTo);
    }
  }, []);
  // * prefill fields on mount === END ************************************************************

  // SELECT PREFILL
  const selectPrefill = (isSelectbox && prefillValues && prefilledItem?.value)
    ? (prefillValues?.find((item) => item.value === prefilledItem?.value.toString()) || null)
    : null;
  // SELECT PREFILL === END

  useEffect(() => {
    // if user checked not tested option
    // remove validation rules
    // clear existing error messages
    if (notTested) {
      setVrules({ required: false, validate: () => true });
      clearErrors(groupItemName);
    } else {
      setVrules(defaultRules);
    }
  }, [notTested]);

  return (
    <div className={`form-input numeric-input ${className} ${isRange ? 'is-range' : ''} ${errors[name] ? 'invalid' : 'valid'}`}>
      <label htmlFor={name}>
        {characteristicType.label}
        {characteristicType.value === 'LACTOSE' && <Tooltip text={texts.specsTexts.common.lactoseTooltip} />}
      </label>

      <input type="hidden" {...register(names.characteristicSpecId)} defaultValue={isCoaUsed ? characteristicSpecId : id} />
      {range && <input type="checkbox" className="hidden" checked={range} {...register(`${groupItemName}.range`, undefined)} />}

      <div className="form-input-wrapper">

        <div className="input-left">
          <NotTestedSection
            notTested={notTested}
            isCoaUsed={isCoaUsed}
            isRange={isRange}
            notOriginallyTested={notOriginallyTested}
            measurementValueType={measurementValueType}
            notTestedText={notTestedText}
            originalValueTo={originalValueTo}
            originalValue={originalValue}
            minValue={minValue}
            maxValue={maxValue}
            typicalValue={typicalValue}
            measurementUnit={measurementUnit}
          />

          <div className={notTested ? 'hidden input-left-wrapper' : 'input-left-wrapper'}>
            <div className={`value-type ${measurementValueType}`}>
              {isRange ? 'MIN' : measurementValueType.replace('ND_IN', 'ND in')}
            </div>

            {isSelectbox && prefillValues
              ? (
                <FormSelect
                  name={names.value}
                  options={prefillValues}
                  selected={selectPrefill}
                  rules={notTested ? { required: false } : validationRules.required}
                />
              )
              : (
                <NumericInput
                  name={names.value}
                  minValue={minValue}
                  maxValue={maxValue}
                  rules={vRules}
                  isDecimal={isDecimal}
                  value={prefilledValues().value}
                  placeholder={placeholders.value}
                />

              )}

            {/* there is no MAX value when COA choosed */}
            {isRange && !isCoaUsed && (
              <>
                <div className="value-type">MAX</div>

                <NumericInput
                  name={names.valueTo}
                  value={prefill ? typicalValue.toString() : undefined}
                  rules={{
                    required: notTested ? false : validationRules.required.required,
                    validate: notTested ? true : validationRules.minPrice(value, 'Max value must be greater than min value'),
                  }}
                  minValue={minValue}
                  maxValue={maxValue}
                  isDecimal={isDecimal}
                  placeholder={placeholders.maxValue}
                />
              </>
            )}

            <div className="measure-units">{measurementUnit.label}</div>
          </div>
        </div>

        <div className="custominput">
          <input
            disabled={disabled}
            type="checkbox"
            // defaultChecked={prefilledValues().notTested}
            id={names.notTested}
            {...register(names.notTested, undefined)}
          />

          <label htmlFor={names.notTested}>
            {isCoaUsed ? 'Not tested for COA' : notTestedText}
          </label>
        </div>
      </div>

    </div>
  );
};

export default CharacteristicInput;
