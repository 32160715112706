import React, { FC, useState } from 'react';
import clsx from 'clsx';
import TruncateMarkup from 'react-truncate-markup';
import renderMultiline from 'SHARED/helpers/renderMultiline';

interface IProps {
  text: string,
  showLines?: number,
  className?: string,
}

const TextCollapse: FC<IProps> = ({ text, showLines = 2, className }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  // ? possible handler to render multiline text from the beginning
  // const renderText = (textString: string) => textString.split('\n')
  //   .filter((item) => item.length > 0)
  //   .map((item, index) => (
  //     <TruncateMarkup.Atom key={index}>
  //       <span key={index} className="paragraph">{item}</span>
  //     </TruncateMarkup.Atom>
  //   ));

  return (
    <div
      className={clsx('text-collapse', !isCollapsed && 'expanded', className)}
      tabIndex={0}
      role="button"
      onClick={() => setIsCollapsed(!isCollapsed)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          setIsCollapsed(!isCollapsed);
        }
      }}
    >
      {isCollapsed ? (
        <TruncateMarkup
          lines={showLines}
        >
          <div>
            {`“${text}”`}
            {/* {renderText(`“${text}”`)} */}
          </div>
        </TruncateMarkup>
      ) : (
        <>{ renderMultiline(`“${text}”`) }</>
      )}
    </div>
  );
};

export default TextCollapse;
