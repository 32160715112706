import React, { FC, useState } from 'react';
import { cancelOffer } from 'SHARED/api/offers/put/putCancelOffer';
import Popup from 'SHARED/components/Popup/Popup';
import Preloader from 'SHARED/components/ThePreloader';

interface IProps {
  handleClose: (isSuccess: boolean) => void;
  proposalId: number | string;
}

const RFPCancelProposalModal: FC<IProps> = ({ handleClose, proposalId }) => {
  const [isLoading, setIsLoading] = useState(false);

  async function handlePutRequest() {
    setIsLoading(true);
    try {
      await cancelOffer(proposalId);
      handleClose(true);
    } catch (error) {
      console.error(error);
      handleClose(false);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <Preloader isLoading={isLoading} />

      <Popup handleClose={() => handleClose(false)} isLoading={isLoading}>
        <h1>Are you sure you want to cancel this proposal?</h1>

        <br />

        <button
          type="button"
          className="btn-primary"
          onClick={() => handlePutRequest()}
        >
          Cancel proposal
        </button>

        <button
          type="button"
          className="btn-secondary"
          onClick={() => handleClose(false)}
          disabled={isLoading}
        >
          Keep the proposal
        </button>
      </Popup>
    </>
  );
};

export default RFPCancelProposalModal;
