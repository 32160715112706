import { useMemo } from 'react';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { FieldsList } from '../OfferTable';
import { offspecSeller } from './seller/offspec';
import { onspecSeller } from './seller/onspec';
import { rfpSeller } from './seller/rfp';
import { proposalsSeller } from './seller/proposals';
import { onspecBuyer } from './buyer/onspec';
import { offspecBuyer } from './buyer/offspec';
import { rfpBuyer } from './buyer/rfp';
import { proposalsBuyer } from './buyer/proposals';

interface Fields {
  offspec: FieldsList,
  onspec: FieldsList,
  rfp: FieldsList,
  proposals?: FieldsList,
}

export const useOfferFields = (): Fields => {
  const { me } = useTypedSelector((state) => state.users);

  const isSeller = me?.type === 'SELLER';
  const isBuyer = me?.type === 'BUYER';
  const isAdmin = me?.type && !isSeller && !isBuyer;

  const fields = useMemo(() => {
    let onspec: FieldsList;
    let offspec: FieldsList;
    let rfp: FieldsList;
    let proposals: FieldsList;

    // set default fields (SELLER ones)
    onspec = onspecSeller;
    offspec = offspecSeller;
    rfp = rfpSeller;
    proposals = proposalsSeller;

    // override fields for BUYER
    if (isBuyer) {
      onspec = onspecBuyer;
      offspec = offspecBuyer;
      rfp = rfpBuyer;
      proposals = proposalsBuyer;
    }

    // override fields for ADMIN
    if (isAdmin) {
      // for now ADMIN has no personal fields
    }

    return { onspec, offspec, rfp, proposals };
  }, [me]);

  return fields;
};
