import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import routes from 'SHARED/types/routes';
import Preloader from 'SHARED/components/ThePreloader';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { useActions } from 'SHARED/redux/hooks/useActions';
import DocumentCard from './DocumentCard';

interface IRouteParams {
  dealId: string;
}

const DocumentsExchange = () => {
  const { dealId } = useParams<IRouteParams>();

  const { ON_SPEC, loading } = useTypedSelector((state) => state.offer);
  const { documents, isDocumentsLoading } = useTypedSelector((state) => state.documents);
  const { getDocuments, resetDocuments } = useActions();

  const isForRfp = ON_SPEC?.parentOffer?.type?.value === 'RFP';
  const provideDocLink = isForRfp
    ? routes.rfp.goto_provideDocument(dealId)
    : routes.onspec.goto_provideDocument(dealId);

  useEffect(() => {
    if (dealId) {
      getDocuments(dealId);
    }

    return () => {
      resetDocuments();
    };
  }, [dealId]);

  return (
    <>
      <Preloader isLoading={isDocumentsLoading || loading} />

      <section className="tab-content">
        <div className="margin-collapse-container">
          <p>You can use our document exchange to provide documents to your counter-party and request document that you need.</p>

          {/* actions */}
          <div className="buttons-wrapper m-full-width">
            {/* going to be implemented in the future version */}
            {/* <Link
              to={routes.onspec.goto_requestDocument(dealId)}
              className="btn-primary disabled"
            >
              Request a document
            </Link> */}

            <Link
              to={provideDocLink}
              className="btn-secondary"
            >
              Provide a document
            </Link>
          </div>
          {/* actions === END */}
        </div>

        <div className="documents-wrapper">

          {(documents.length === 0) && !isDocumentsLoading && (
          <div className="attention-message info-bar">
            There are currently no documents that need your review or approval.
          </div>
          )}

          {/* documents-grid */}
          <div className="documents-grid">

            {documents?.map((document) => (
              <DocumentCard key={document.id} dealId={dealId} document={document} />
            ))}

          </div>
          {/* documents-grid === END */}

        </div>

      </section>
    </>
  );
};

export default DocumentsExchange;
