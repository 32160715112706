import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import Preloader from 'SHARED/components/ThePreloader';
import { scrollToTop } from 'SHARED/helpers/common';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { allActions } from 'SHARED/redux/reducers/all-actions';
import { useActions, useAppDispatch } from 'SHARED/redux/hooks/useActions';
import routes from 'SHARED/types/routes';
import OffspecOfferSummary from './OffspecOfferSummary';

const OffSpecOfferPreview: React.FC = () => {
  const history = useHistory();

  const dispatch = useAppDispatch();

  const { OFF_SPEC, loading } = useTypedSelector((state) => state.offer);
  const {
    setOfferError,
  } = useActions();

  useEffect(() => {
    if (OFF_SPEC.title === '') {
      history.push(routes.offspec.create);
    }

    scrollToTop();

    const onUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = '';
    };

    window.addEventListener('beforeunload', onUnload);
    return () => window.removeEventListener('beforeunload', onUnload);
  }, []);

  const handleChangeOffer = () => {
    // history.goBack();
    setOfferError(null);
    history.push(routes.offspec.create);
  };

  const handlePlaceOrder = async () => {
    dispatch(allActions.createOffer('OFF_SPEC'))
      .then(unwrapResult)
      .then((res) => {
        console.log('success:', res);
        history.push(routes.offspec.defaultList);
      })
      .catch((err) => console.error('ERROR:', err));
  };

  return (
    <>
      <Preloader isLoading={loading} />

      <div className="breadcrumbs">
        <Link to="/offspec/offers/">&lt; All auctions</Link>
      </div>

      <div className="page-body is-logged-in preview-offer-page">

        <h1 className="page-title">Create an auction</h1>

        <div className="attention-message inset">
          Great, you&apos;re almost done! Please review your auction before you continue.
        </div>

        <OffspecOfferSummary mode="preview" />

        <div className="attention-message success inset">
          <div className="page-section-title">Place this auction</div>
          <div>
            By placing this offer you agree to the OpenDairy platform terms.
            <br />
            If bids are made at the end of the auction you
            have the legal obligation to sell to the highest bidder.
            <br />
            Once placed, offers can not be changed.
          </div>
          <div className="actions-section">
            <button type="button" className="btn-primary" onClick={handlePlaceOrder}>Place offer</button>
            <button onClick={() => { handleChangeOffer(); }} type="button" className="btn-secondary">Change offer</button>
            <a href="/" className="btn-secondary">Discard</a>
          </div>
        </div>

      </div>
    </>
  );
};

export default OffSpecOfferPreview;
