import DataItemPreview from 'SHARED/components/DataItemPreview';
import { handleDateRange, minMaxVolumeToString } from 'SHARED/helpers/common';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import ProductSpecsDetailsModal from 'SHARED/modals/ProductSpecsDetailsModal';
import React, { useState } from 'react';

const RfpSummary = () => {
  const { RFP } = useTypedSelector((state) => state.offer);
  const { product: productDetails } = useTypedSelector((state) => state.specs);

  const {
    endDate,
    productType,
    productCategory,
    statusLabel,
    minVolume,
    maxVolume,
    packages,
    description,
    productCharacteristics,
    documentCapabilities,
    deliveryLocation,
    departureFrom,
    departureTo,
    documents,
    incoterms,
    paymentTerms,
  } = RFP;

  const volume = minMaxVolumeToString(minVolume, maxVolume);
  const delivery = handleDateRange({ from: departureFrom, to: departureTo });

  const salesTermsDocument = documents?.SALES_TERMS ? documents?.SALES_TERMS[0] : undefined;

  const [isPopup, setIsPopup] = useState(false);

  const handleClose = () => {
    setIsPopup(false);
  };

  return (
    <>
      <div className="summary-grid">
        <div>
          <DataItemPreview title="INSTRUCTION FOR THE SELLERS" multiline multilineText={description} />
          <DataItemPreview title="DOCUMENT(S)" file={salesTermsDocument} />
          <DataItemPreview title="STATUS" item={statusLabel} />
          <DataItemPreview title="RFP VALID UNTIL" item={endDate} isDate />
        </div>

        <div>
          <DataItemPreview title="PRODUCT CATEGORY" item={productCategory?.label} />
          <DataItemPreview title="PRODUCT TYPE" item={productType?.label} />
          <div className="data-preview-row">
            <div className="label-text">BUYER PRODUCT SPECIFICATION</div>
            <div><button type="button" className="link-button" onClick={(e) => setIsPopup(true)}>Specifications details</button></div>
          </div>
          <DataItemPreview title="REQUESTED VOLUME" item={volume} />
          <DataItemPreview title="PACKAGING OPTION(S)" item={packages} />
        </div>

        <div>
          <DataItemPreview title="INCOTERMS" item={incoterms ?? deliveryLocation?.incoterms} />
          <DataItemPreview title="DELIVERY LOCATION" item={deliveryLocation?.fullAddress} />
          <DataItemPreview title="DELIVERY PERIOD" item={delivery} />
          <DataItemPreview title="PAYMENT TERMS" item={paymentTerms} />
          <DataItemPreview title="DOCUMENT REQUIRED" item={documentCapabilities} />
        </div>
      </div>

      {isPopup && (
      <ProductSpecsDetailsModal
        handleClose={() => handleClose()}
        characteristics={productCharacteristics || productDetails?.characteristics}
      />
      )}
    </>
  );
};

export default RfpSummary;
