import { handleDate, handleDateRange } from 'SHARED/helpers/common';
import { OfferListItem, OfferTableItem } from 'SHARED/types/offerTypes';
import { OfferListTypes } from 'SHARED/pages/OffersList';

// to iterate trough it in table and cards
export const proposalsSeller = (offer: OfferListItem, activeTab: OfferListTypes) => {
  const {
    departureFrom,
    departureTo,
    currency,
    price,
    type,
    departurePeriod,
    statusLabel,
    created,
    productTitle,
    totalVolume,
  } = offer;

  const departure = departurePeriod === 'MONTH' ? handleDate({ date: departureFrom, format: 'MMM YYYY' }) : handleDateRange({ from: departureFrom, to: departureTo });

  // creating offer list item data
  // for table and cards
  const activeOffers: OfferTableItem[] = [
    { key: 'type', value: handleDate({ date: created, format: 'DD MMM YYYY' }), label: 'OFFERED' },
    { key: 'type', value: type, label: 'OFFER TYPE' },
    { key: 'type', value: productTitle, label: 'PRODUCT SPEC' },
    { key: 'volume', value: `${totalVolume} mt`, label: 'VOLUME' },
    { key: 'departure', value: departure, label: 'DEPARTURE' },
    { key: 'price', value: price, prefix: currency, label: 'PRICE FOR THIS BUYER' },
    { key: 'status', value: statusLabel, label: 'STATUS' },
  ];

  return activeOffers;
};
