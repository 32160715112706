import React from 'react';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import FormattedPrice from 'SHARED/components/Price';
import prepareFinancingDetails from 'SHARED/helpers/prepareFinancingDetails';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';

const OfferDealFinancingWithHandler = () => {
  const { ON_SPEC } = useTypedSelector((state) => state.offer);

  const {
    id,
    agreedOnPayment,
    deal,
  } = ON_SPEC;

  const {
    financing,
  } = deal || {};

  const {
    sellerPaymentTerm,
    buyerPaymentTerm,
    financingProvider,
    type,
  } = financing || {};

  // flags
  const isSellerFinance = type?.value === 'SELLER';
  // flags === END

  if (!id || agreedOnPayment || !financingProvider?.value) return null;

  return (
    <>
      <DataItemPreview
        title="Seller payment terms"
        item={sellerPaymentTerm}
      />

      <DataItemPreview
        title="Buyer payment terms"
        item={buyerPaymentTerm}
      />

      <DataItemPreview
        title="Financing provider"
        item={isSellerFinance ? 'Seller' : financingProvider}
      />

      {isSellerFinance && (
        <>
          <DataItemPreview
            item={prepareFinancingDetails({
              currency: deal?.currency,
              creditInterestRate: deal?.financing?.creditInterestRate,
              commissionPerUnit: deal?.financing?.commissionPerUnit,
              commissionPerDeal: deal?.financing?.commissionPerDeal,
            })}
            title="FINANCING TERMS"
          />
          <FormattedPrice
            value={deal?.financing?.costOfFinancing}
            prefix={deal?.currency}
            suffix="/mt"
            title="COST OF FINANCING"
          />
          <FormattedPrice
            value={deal?.financing?.totalCostOfFinancing}
            prefix={deal?.currency}
            title="TOTAL COST OF FINANCING"
          />
        </>
      )}
    </>
  );
};

export default OfferDealFinancingWithHandler;
