import React, { useEffect, useMemo, useState } from 'react';
import qs from 'qs';
import {
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { useActions } from 'SHARED/redux/hooks/useActions';
import Preloader from 'SHARED/components/ThePreloader';
import {
  HandleRowClick,
  ListParams,
  MenuItem,
  PagesTypes,
} from 'SHARED/types/offerTypes';
import Menu from 'SHARED/components/Menu';
import OutOfFormCheckbox from 'SHARED/components/OutOfFormCheckbox';
import OfferTable from 'SHARED/pages/OffersList/OfferTable';
import routes from 'SHARED/types/routes';
import DealFilters, { IFiltersPayload } from './DealFilters';
import Texts from './Texts';
import { Titles } from './Titles';

// *********** TYPES SECTION ***********
export type OfferListTypes = 'offers' | 'archived-offers' | 'deals';
type IChangeTabAction = (tab: OfferListTypes) => void;

interface ListTabs extends Omit<MenuItem, 'link'> {
  link: OfferListTypes,
}

interface IProps {
  isLoggedIn: boolean,
  portal: 'SELLER' | 'BUYER'
}
interface Pagination {
  selected: number
}

// *********** CONST'S SECTION ***********
const OfferListTabs: ListTabs[] = [
  { label: 'Active offers', link: 'offers', disabled: false },
  { label: 'Archived offers', link: 'archived-offers', disabled: false },
  { label: 'Deals', link: 'deals', disabled: false },
];

const AuctionsListTabs: ListTabs[] = [
  { label: 'Active auctions', link: 'offers', disabled: false },
  { label: 'Archived auctions', link: 'archived-offers', disabled: false },
  { label: 'Deals', link: 'deals', disabled: false },
];

const RfpListTabs: ListTabs[] = [
  { label: 'Active RFPs', link: 'offers', disabled: false },
  { label: 'Archived RFPs', link: 'archived-offers', disabled: false },
  { label: 'Deals', link: 'deals', disabled: false },
];

const PAGE_SIZE = 12;

const validPageTypes: PagesTypes[] = ['onspec', 'offspec', 'rfp'];
const validTabs: OfferListTypes[] = ['offers', 'archived-offers', 'deals'];

const OffersList: React.FC<IProps> = ({ isLoggedIn, portal }) => {
  const history = useHistory();

  const { offer_type, offer_status } = useParams<{
    offer_type: PagesTypes,
    offer_status: OfferListTypes
  }>();

  const { offers, auctions, rfps, isLoading } = useTypedSelector((state) => state.offers);
  const { me } = useTypedSelector((state) => state.users);

  const { getOffersListData } = useActions();

  const currentOffersData = useMemo(() => {
    switch (offer_type) {
      case 'onspec':
        return offers;

      case 'offspec':
        return auctions;

      case 'rfp':
        return rfps;

      default:
        return offers;
    }
  }, [offer_type, offers, auctions, rfps]);
  const { list: offersList, totalPageCount } = currentOffersData;

  // 1 - getting type from URL params
  const [currentPage, setCurrentPage] = useState(0);
  const [onlyMatched, setOnlyMatched] = useState(false);

  const changeTabAction: IChangeTabAction = (tab) => {
    history.push(`/${offer_type}/${tab}/`);
  };

  // ***************** handlePagination *****************
  const handlePagination = (page: Pagination, forceFetch: boolean = true) => {
    const { selected } = page;
    setCurrentPage(selected);
    const query = qs.stringify({ page: selected });

    history.push({ search: `?${query}` });

    if (forceFetch) {
      fetchOffers(query);
    }
  };

  // ***************** get Query Params *****************
  const { search, pathname } = useLocation();

  const applyFilter = (payload?: IFiltersPayload) => {
    handlePagination({ selected: 0 }, false);
    fetchOffers(search, payload);
  };

  const isSeller = me.type === 'SELLER';
  const isBuyer = me.type === 'BUYER';
  const isOffspec = offer_type === 'offspec';
  const isOnspec = offer_type === 'onspec';
  const isRfp = offer_type === 'rfp';

  function getTabs() {
    switch (offer_type) {
      case 'onspec':
        return OfferListTabs;

      case 'offspec':
        return AuctionsListTabs;

      case 'rfp':
        return RfpListTabs;

      default:
        return OfferListTabs;
    }
  }

  const fetchOffers = (location: string = search, filters?: IFiltersPayload): void => {
    if (isLoggedIn) {
      const query = qs.parse(location, { ignoreQueryPrefix: true });

      enum Statuses {
        'offers' = 'ACTIVE',
        'archived-offers' = 'CANCELLED',
        'deals' = 'DEAL',
      }
      let isDeals: boolean = false;
      const statuses = Statuses[offer_status];
      const params: ListParams = { params: { ...query, size: PAGE_SIZE, ...filters } };

      switch (offer_status) {
        case 'offers':
          params.params.statuses = statuses;
          params.params.matched = onlyMatched || undefined; // only for BUYER
          params.params.sort = 'created,DESC';
          break;

        case 'archived-offers':
          params.params.active = false;
          params.params.sort = 'created,DESC';
          break;

        case 'deals':
          params.params.statuses = statuses;
          params.params.sort = 'created,DESC';
          isDeals = true;
          break;

        default:
          console.log('unknown offer type');
      }

      // @ts-expect-error:
      setCurrentPage(parseInt(query.page, 10) || 0);
      // set statuses depending on tab

      // this mess below unfortunately impossible to fix without backand part improvements
      let offerTypes = 'OFF_SPEC';
      if (isOnspec && portal === 'SELLER') {
        offerTypes = 'TO_BE_PRODUCED_BUNDLE, ALREADY_PRODUCED, SIMPLE_ALREADY_PRODUCED';
      } else if (isOnspec && portal === 'BUYER') {
        offerTypes = 'TO_BE_PRODUCED, ALREADY_PRODUCED, SIMPLE_ALREADY_PRODUCED';
      }

      if (isOnspec && offer_status === 'deals' && portal === 'SELLER') {
        offerTypes = 'TO_BE_PRODUCED, ALREADY_PRODUCED, SIMPLE_ALREADY_PRODUCED';
      }
      if (isRfp) {
        offerTypes = offer_status === 'deals' ? '' : 'RFP';
        params.params.sort = 'created,DESC';
        if (offer_status === 'deals') {
          params.params.rfpProposal = true;
        }
      }

      getOffersListData({
        params,
        offerTypes,
        isDeals,
        type: offer_type,
      });
    }
  };

  useEffect(() => {
    fetchOffers();
  }, [pathname, onlyMatched]);

  useEffect(() => { // TODO: refactor this
    // 4 - listen for browser back | forward behaviour
    // and fetch with appropriate params
    const listen = history.listen((location, action) => {
      if (action === 'POP') {
        fetchOffers(location.search);
      }
    });

    return listen;
  }, []);

  useEffect(() => { // TODO: refactor this
    if (isLoggedIn) {
      const routeToRestore = localStorage.getItem('route');
      // console.log('routeToRestore');

      if (routeToRestore) {
        history.push(routeToRestore);
        localStorage.removeItem('route');
      }
    }
  }, []);

  const viewOfferDetails: HandleRowClick = (e, id) => {
    e.preventDefault();
    history.push(`/${offer_type}/${offer_status}/${id}`);
  };

  if (!validPageTypes.includes(offer_type) || !validTabs.includes(offer_status)) {
    history.push(routes.home);
  }

  return (
    <>

      <Preloader isLoading={isLoading} />

      <div className={`page-container ${offer_type} ${offer_status}`}>

        <Titles
          activeTab={offer_status}
          isBuyer={isBuyer}
          isSeller={isSeller}
          isOnspec={isOnspec}
          isOffspec={isOffspec}
          isRfp={isRfp}
        />

        <Menu
          items={getTabs()}
          view="page-tabs"
          activeLink={offer_status}
          changeTabAction={changeTabAction}
        />

        {isSeller && offer_status === 'deals' && isOnspec && (
          <DealFilters applyFilter={applyFilter} />
        )}

        {isBuyer && offer_status === 'offers' && isOnspec && (
          <OutOfFormCheckbox
            label="Only show matching offers"
            value={onlyMatched}
            setValue={setOnlyMatched}
          />
        )}

        <Texts pageType={offer_type} activeTab={offer_status} offersList={offersList} isLoading={isLoading} />

        {offersList.length > 0 && !isLoading && (
          <OfferTable
            data={offersList}
            pageType={offer_type}
            handleRowClick={viewOfferDetails}
            activeTab={offer_status}
          />
        )}

        {totalPageCount > 1 && (
          <ReactPaginate
            previousLabel=""
            nextLabel=""
            breakLabel="..."
            breakClassName="break-me"
            forcePage={currentPage}
            disableInitialCallback
            pageCount={totalPageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={10}
            onPageChange={(page: Pagination) => handlePagination(page)}
            containerClassName="pagination"
            activeClassName="active"
          />
        )}

        {totalPageCount === 1 && (
          // FIXME:
          <div>
            <br />
            <br />
          </div>
        )}

      </div>
    </>
  );
};

export default OffersList;
