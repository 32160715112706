import { useEffect, useState } from 'react';
import axios from 'SHARED/helpers/axios';
import { AxiosResponse } from 'axios';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { defaultOnspecOffer } from 'SHARED/initialState/offer';
import texts from 'SHARED/helpers/texts';
import { newPackage } from 'pages/Onspec/components/PackagesFlowSection';
import useQueryItem from 'SHARED/hooks/useQueryItem';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { useActions } from 'SHARED/redux/hooks/useActions';
import { getProducts } from 'SHARED/api/common/get/getProducts';
import { OnSpecOffer } from 'SHARED/types/offerTypes';

const useDuplicateTBP = (setValue: UseFormSetValue<any>, getValues: UseFormGetValues<any>) => {
  const { categories } = useTypedSelector((state) => state.specs);
  const { saveOffer, setOfferDuplicateLoading } = useActions();

  const [offerState, setOfferState] = useState<any>(null);
  const [duplicateMessage, setDuplicateMessage] = useState<any>(null);
  const [duplicateError, setDuplicateError] = useState<any>(null);

  const duplicateId = useQueryItem('duplicate');
  // now we need to get offer data for duplication, but modify it before saving to redux
  const getDuplicatedOffer = async (offerId: number | string) => axios.get(`/v1/offers/${offerId}`);
  useEffect(() => {
    if (duplicateId) {
      setOfferDuplicateLoading(true);
      getDuplicatedOffer(duplicateId)
        .then((res: AxiosResponse) => res.data)
        .then(async (offerData: any) => {
          // console.log(offerData);
          if (offerData.type.value !== 'TO_BE_PRODUCED_BUNDLE') {
            // need to set error message here as well
            setDuplicateError(texts.offerDuplicationTexts.onSpecTypeError);
            setOfferDuplicateLoading(false);
            return;
          }

          const dictionaries = await axios.get('/v1/dictionary/group?types=PAYMENT_TERMS&offerTypes=ALREADY_PRODUCED');
          if (dictionaries.status !== 200) {
            setDuplicateError(texts.offerDuplicationTexts.onSpecNotFound);
            throw new Error(texts.offerDuplicationTexts.onSpecNotFound);
          }

          const offer = {
            ...offerData,
            buyersFetchType: defaultOnspecOffer.buyersFetchType,

            // payment terms should be set to more explicit value
            paymentTerms: dictionaries.data.PAYMENT_TERMS.find((term: any) => term.value === offerData.paymentTerms.value) || null,
            paymentOffset: offerData.paymentOffset ? {
              value: offerData.paymentOffset.toString(),
              label: `${offerData.paymentOffset} days`,
            } : null,

            // reset some fields that are not needed for duplication
            productionLocation: defaultOnspecOffer.productionLocation,
            departureFrom: defaultOnspecOffer.departureFrom,
            departureTo: defaultOnspecOffer.departureTo,
            departurePeriod: defaultOnspecOffer.departurePeriod,
            endDate: defaultOnspecOffer.endDate,
            productionDate: defaultOnspecOffer.productionDate,
            expirationDate: defaultOnspecOffer.expirationDate,
            incoterms: defaultOnspecOffer.incoterms,
            buyers: defaultOnspecOffer.buyers,
            params: defaultOnspecOffer.params,
            documents: {
              COA: offerData.documents.COA?.map((doc: File) => ({ ...doc, duplicate: true })),
              SALES_TERMS: offerData.documents.SALES_TERMS?.map((doc: File) => ({ ...doc, duplicate: true })),
            },
          };

          if (!offer.priceEur && offer.priceUsd) {
            offer.priceEur = null;
            offer.priceEurDisabled = true;
          }
          if (!offer.priceUsd && offer.priceEur) {
            offer.priceUsd = null;
            offer.priceUsdDisabled = true;
          }

          setOfferState(offer);
        })
        .catch(() => {
          setDuplicateError(texts.offerDuplicationTexts.onSpecNotFound);
          setOfferDuplicateLoading(false);
        });
    }
  }, [duplicateId]);

  useEffect(() => {
    if (duplicateId && categories.length > 0 && offerState) {
      (async () => {
        const {
          // fields that can cause problems when duplicating
          status,
          remainingVolume,
          // ====================
          ...offerFieldsToDuplicate
        } = offerState as OnSpecOffer;

        const offerWithoutProducts = {
          ...defaultOnspecOffer,
          ...offerFieldsToDuplicate,
          products: [],
          productIds: [],
          productionLocationId: null,
        };

        saveOffer({ offer: offerWithoutProducts, offerType: 'ON_SPEC' });
        setDuplicateMessage(texts.offerDuplicationTexts.toBeProduced);

        // * PRODUCT CATEGORY => pick from `categories` === WORKS
        const categoryIndex = categories.findIndex((category) => category.value === offerState.productCategory.value);
        categoryIndex >= 0 && setValue('productCategory', categories[categoryIndex]);

        // ? here should add a new guard
        // two keys - one for amount of products
        // one to track if all products are added
        // for example -- productsAmount = 2, productsAdded = 1, and spinner should be shown until two values are equal
        await offerState.products.forEach(async (prod: any, prodIndex: number) => {
          const formProducts = getValues().products;
          if (formProducts.length < prodIndex) {
            setValue('products', [...formProducts, newPackage()]);
          }
          // if we got more products than we should have, remove extra ones
          if (formProducts.length > prodIndex) {
            formProducts.pop();
            setValue('products', [...formProducts]);
          }

          const { productCategory, productType } = prod;
          const prodsResponse = await getProducts({ category: productCategory.value, type: productType.value });
          const { data, isError } = prodsResponse;

          if (isError) {
            setDuplicateError('Could not get products for duplication');
            return;
          }

          const matchedCategoryType = categories[categoryIndex].productTypes.find((t: any) => t.value === productType.value);
          const prodMatch = data.find((p) => p.id === prod.productId);

          const product = {
            label: prodMatch?.title,
            value: prodMatch?.id,
            premiumPriceEur: prodMatch?.premiumPriceEur,
            premiumPriceUsd: prodMatch?.premiumPriceUsd,
          };

          setValue(`products[${prodIndex}].productType`, matchedCategoryType);
          setValue(`products[${prodIndex}].product`, product);
          setValue(`products[${prodIndex}].productTitle`, prodMatch?.title);
          setValue(`products[${prodIndex}].fullfilled`, true);
        });

        setOfferState(null);
        setOfferDuplicateLoading(false);
      })();
    }
  }, [duplicateId, categories, offerState]);

  return { duplicateId, duplicateMessage, duplicateError };
};

export default useDuplicateTBP;
