import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import FormattedPrice from 'SHARED/components/Price';
import { Bid } from 'SHARED/types/offerTypes';
// import { Bid } from 'SHARED/types/bidTypes';

interface IBidInfoRowProps {
  bid: Bid
  dealId?: number | string | null
  country: string
  offerMinimumPrice?: number | null
}

export const BidInfoRow: FC<IBidInfoRowProps> = ({
  bid,
  dealId = null,
  country,
  offerMinimumPrice = 0,
}) => {
  const belowMinimumClass = offerMinimumPrice && bid.value < offerMinimumPrice ? 'below-minimum' : '';

  return (
    <>
      <div className={`bid-info-row ${belowMinimumClass}`}>
        <div className="col"><DataItemPreview item={bid.actionTime} isDate title="BID DATE" /></div>
        <div className="col"><DataItemPreview item={bid?.orgName} title="BUYER" /></div>
        <div className="col"><DataItemPreview item={country} title="COUNTRY" /></div>
        <div className="col"><FormattedPrice className="bid-price" value={bid.value} title="BID" prefix={bid.currency} suffix="/mt" /></div>
        <div className="col"><DataItemPreview item={bid.bidVolume} title="BID VOLUME" suffix="mt" /></div>
        <div className="col"><FormattedPrice className="bid-price" value={bid.offerTotalPrice} title="TOTAL" prefix={bid.currency} /></div>

        {/* if it's winner row */}
        {dealId && (
          <div className="deal-link col">
            <Link to={`/offspec/deals/${dealId}`}>View deal details</Link>
          </div>
        )}
      </div>
    </>
  );
};

export default BidInfoRow;
