import React, { FC } from 'react';
import { OnSpecOffer } from 'SHARED/types/offerTypes';
import DataItemPreview from '../DataItemPreview';

interface IProps {
  offer: OnSpecOffer
}

const OfferCancellationFields: FC<IProps> = ({ offer }) => {
  console.log('OfferCancellationFields', offer);

  return (
    <>
      <div className="page-section-title">Offer cancelled by the seller</div>
      <div className="page-columns">
        <DataItemPreview
          className="page-column"
          item={offer.cancellationInfo?.date}
          dateFormat="DD MMM YYYY HH:mm z"
          isDate
          title="TIME"
        />
        <DataItemPreview
          className="page-column"
          item={offer.cancellationInfo?.fullName}
          title="SELLER"
        />
      </div>
    </>
  );
};

export default OfferCancellationFields;
