import React, { useState } from 'react';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import FormattedPrice from 'SHARED/components/Price';
import { handleDate } from 'SHARED/helpers/common';
import { OfferBid } from 'SHARED/types/bidTypes';
import AnimateHeight from 'react-animate-height';
import prepareFinancingDetails from 'SHARED/helpers/prepareFinancingDetails';

interface Props {
  bid: OfferBid
  isSimpleOffer?: boolean
}

const BidFields: React.FC<Props> = ({
  bid,
  isSimpleOffer = false,
}) => {
  const {
    status,
    validTo,
    created,
    buyerName,
    netPrice,
    currency,
    volume,
    departmentLocation,
    logisticHandler,
    destinationLocation,
    message,
    totalOfferPrice,
    buyerPaymentTerm,
    sellerPaymentTerm,
    deleted,
    deletedBy,
    packaging,
    // financing info
    financingType,
    financingProvider,
    creditInterestRate,
    commissionPerUnit,
    commissionPerDeal,
    costOfFinancing,
    totalCostOfFinancing,
    financingNeeded,
  } = bid;

  const isDeleted = status === 'DELETED';
  const deletedText = `Cancelled ${handleDate({
    date: deleted,
    format: 'DD MMM YYYY HH:mm',
  })} by ${deletedBy?.label}`;

  const incotermsText = [
    departmentLocation?.incoterms?.label,
    departmentLocation?.address,
  ].filter(Boolean).join(', ');

  const [showFinancingDetails, setShowFinancingDetails] = useState(false);

  const isFinancingBySeller = financingType.value === 'SELLER';

  return (
    <>

      <div className="page-section-title with-separator">Latest bid</div>
      {isDeleted && (
        <div className="deleted-bid-info">
          {deletedText}
        </div>
      )}

      {status === 'EXPIRED' && <div className="attention-message inset">Bid expired</div>}

      <div className="page-columns">
        <DataItemPreview
          className="page-column"
          item={validTo}
          dateFormat="DD MMM YYYY HH:mm z"
          isDate
          title="BID VALID"
        />
        <DataItemPreview
          className="page-column"
          item={created}
          dateFormat="DD MMM YYYY HH:mm z"
          isDate
          title="BID PLACED"
        />
        <DataItemPreview className="page-column" item={buyerName} title="BUYER" />
      </div>
      <div className="section-title">Volume & price</div>
      <div className="page-columns">
        <FormattedPrice
          className="page-column"
          value={netPrice}
          prefix={currency}
          suffix="/mt"
          title="BID PRICE"
        />
        <DataItemPreview className="page-column" item={volume} suffix="mt" title="BID VOLUME" />
        {packaging && <DataItemPreview className="page-column" item={packaging?.label} title="PACKAGING" />}
      </div>

      <div className="section-title">Logistics {!isSimpleOffer && 'handling'}</div>
      {!isSimpleOffer && (
        <div>
          <strong>
            <span>{departmentLocation?.address}</span>
            <span> &rarr; </span>
            <span>{destinationLocation?.address}</span>
          </strong>
        </div>
      )}
      <br />
      <div className="page-columns">
        <DataItemPreview
          className="page-column"
          title="SELLER INCOTERMS"
          // item={incotermsText}
          multilineText={incotermsText}
          multiline
        />
        {!isSimpleOffer && (
          <>
            <DataItemPreview
              className="page-column"
              item={logisticHandler?.label}
              title="LOGISTICS"
            />
            <DataItemPreview
              className="page-column"
              item={destinationLocation?.incoterms?.label}
              title="BUYER INCOTERMS"
            />
          </>
        )}
      </div>

      <div className="section-title">Financing</div>
      <div className="page-columns">
        <DataItemPreview
          className="page-column"
          item={sellerPaymentTerm?.label}
          title="SELLER PAYMENT TERMS"
        />

        {!isSimpleOffer && (
          <DataItemPreview className="page-column" title="FINANCING PROVIDER">
            {financingProvider
              ? <div>{financingProvider?.label}</div>
            // in case if buyer did not select any financing provider
              : <span>Buyer arranges financing</span>}

            {isFinancingBySeller && (
            <button
              className="link-button"
              type="button"
              onClick={() => setShowFinancingDetails(!showFinancingDetails)}
            >
              Terms
            </button>
            )}
          </DataItemPreview>
        )}

        {financingNeeded && (
          <DataItemPreview
            className="page-column"
            item={buyerPaymentTerm?.label}
            title="BUYER PAYMENT TERMS"
          />
        )}
      </div>

      {
        isFinancingBySeller && (
          <AnimateHeight
            duration={400}
            height={showFinancingDetails ? 'auto' : 0}
          >
            <div className="bid-financing-details">
              <button
                className="close-btn link-button"
                type="button"
                onClick={() => setShowFinancingDetails(false)}
              >
                x
              </button>

              <div className="page-section-subtitle">You are financing the buyer, these are your terms</div>

              <div className="page-columns">
                <DataItemPreview
                  className="page-column"
                  item={prepareFinancingDetails({
                    currency,
                    creditInterestRate,
                    commissionPerUnit,
                    commissionPerDeal,
                  })}
                  title="FINANCING TERMS"
                />

                <FormattedPrice
                  className="page-column"
                  value={costOfFinancing}
                  prefix={currency}
                  suffix="/mt"
                  title="COST OF FINANCING"
                />
                <FormattedPrice
                  className="page-column"
                  value={totalCostOfFinancing}
                  prefix={currency}
                  title="TOTAL COST OF FINANCING"
                />
              </div>
            </div>
          </AnimateHeight>
        )
      }

      <div className="section-title" />
      <DataItemPreview
        title="MESSAGE"
        multiline
        multilineText={message}
      />

      <div className="section-title total-offer-price">
        <span>Total:</span>
        <FormattedPrice
          className="inline"
          value={totalOfferPrice}
          prefix={currency}
        />
      </div>

    </>
  );
};

export default BidFields;
