import React from 'react';
import { Dictionary } from 'SHARED/types/offerTypes';

export interface INotTestedSectionProps {
  notTested: boolean
  isCoaUsed: boolean
  isRange: boolean
  notOriginallyTested: boolean
  measurementValueType: string
  notTestedText: string
  originalValueTo: string
  originalValue: string | number
  minValue: string | number
  maxValue: string | number
  typicalValue: string | number
  measurementUnit: Dictionary
}

const NotTestedSection: React.FC<INotTestedSectionProps> = ({
  notTested, isCoaUsed, notOriginallyTested, isRange, originalValue, typicalValue, measurementUnit, notTestedText, minValue, maxValue, measurementValueType, originalValueTo,
}) => (
  <div>
    {notTested && !isCoaUsed && <div className="match-text input-left-wrapper">{notTestedText}</div> }

    {/* ***************** COA section if not tested option selected ****************** */}
    {/* details - https://brain-agency.atlassian.net/wiki/spaces/OP/pages/2980216833/Use+case+Seller+creates+and+publishes+the+Already+Produced+offer#Extra-fields-if-the-Seller-wants-to-sell-on-COA */}

    {notTested && isCoaUsed && !notOriginallyTested && !isRange && measurementValueType !== 'ND_IN' && (
      <div className="match-text input-left-wrapper">
        {/* {`${measurementValueType.replace('_', ' ')} ${originalValue || typicalValue} ${measurementUnit.label}`} */}
        {`${measurementValueType.replace('_', ' ')} ${originalValue} ${measurementUnit.label}`}
      </div>
    ) }

    {notTested && isCoaUsed && !notOriginallyTested && isRange && (
    <div className="match-text input-left-wrapper">
      {`${originalValue || minValue} - ${originalValueTo || maxValue} ${measurementUnit.label}`}
    </div>
    ) }

    {((notTested && isCoaUsed && notOriginallyTested) || (notTested && measurementValueType === 'ND_IN')) && (
    <div className="input-left-wrapper">
      Will not match if required by the buyer
    </div>
    ) }
  </div>
);

export default NotTestedSection;
