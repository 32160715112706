import { handleDate, handleDateRange } from 'SHARED/helpers/common';
import { OfferListItem, OfferTableItem } from 'SHARED/types/offerTypes';
import { OfferListTypes } from 'SHARED/pages/OffersList';

// to iterate trough it in table and cards
export const rfpBuyer = (offer: OfferListItem, activeTab: OfferListTypes) => {
  const {
    endDate,
    departureFrom,
    departureTo,
    minVolume,
    maxVolume,
    proposalsCount,
    statusLabel,
    productTitle,

    productCategory,
    productType,
    matchedProducts,
    buyerName,
    deal,
    agreedOnDelivery,
  } = offer;

  const departure = handleDateRange({ from: departureFrom, to: departureTo });
  const format = 'DD MMM YYYY';
  const fullProductTitle = `${productCategory} ${productType} (${productTitle})`;

  const volume = () => {
    if (minVolume === maxVolume) return `${maxVolume} mt`;
    return `${minVolume} - ${maxVolume} mt`;
  };

  // creating offer list item data
  // for table and cards
  const activeOffers: OfferTableItem[] = [
    { key: 'endDate', value: endDate ? handleDate({ date: endDate, format }) : '-', label: 'VALID UNTIL' },
    { key: 'product', value: fullProductTitle, label: 'PRODUCT SPECIFICATION' },
    { key: 'volume', value: volume(), label: 'VOLUME' },
    { key: 'delivery', value: departure, label: 'delivery' },
    { key: 'proposals', value: proposalsCount?.toString(), label: 'PROPOSALS' },
    { key: 'status', value: statusLabel, label: 'STATUS' },
  ];

  const archivedOffers: OfferTableItem[] = [
    { key: 'endDate', value: endDate ? handleDate({ date: endDate, format }) : '-', label: 'VALID UNTIL' },
    { key: 'product', value: fullProductTitle, matchedProducts, label: 'PRODUCT ' },
    { key: 'volume', value: volume(), label: 'VOLUME' },
    { key: 'departure', value: departure, label: 'REQUESTED DEPARTURE' },
    { key: 'buyer', value: buyerName, label: 'BUYER' },
    { key: 'status', value: statusLabel, label: 'STATUS' },
  ];

  const deals: OfferTableItem[] = [
    { key: 'endDate', value: deal ? handleDate({ date: deal?.created, format }) : '-', label: 'DEAL DONE ON' },
    { key: 'product', value: fullProductTitle, matchedProducts, label: 'PRODUCT' },
    { key: 'price', value: deal?.price, label: 'DEAL PRICE' },
    { key: 'volume', value: `${deal?.volume} ${deal?.volumeUnits}`, label: 'VOLUME' },
    { key: 'seller', value: deal?.sellerOrg?.orgName, label: 'SELLER' },
    { key: 'delivery', value: agreedOnDelivery ? departure : null, label: 'DELIVERY' },
  ];

  if (activeTab === 'archived-offers') {
    return archivedOffers;
  }

  if (activeTab === 'deals') {
    return deals;
  }

  return activeOffers;
};
