import { axios_Core } from 'SHARED/helpers/axios';
import { showNotification } from 'SHARED/helpers/notifications';
import { z } from 'zod';

export const postSubmitProduct = async (payload:any) => {
  try {
    await axios_Core.post('/v1/product', payload);

    showNotification('PRODUCT_CREATED');

    return false;
  } catch (error:any) {
    // validate error with zod
    const schema = z.object({
      data: z.object({
        errors: z.array(z.object({
          message: z.string(),
          field: z.string(),
        })),
      }),
    });

    const validate = schema.safeParse(error);

    if (!validate.success) {
      return false;
    }

    return validate.data.data.errors;
  }
};
