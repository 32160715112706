import React, { useState } from 'react';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import FormattedPrice from 'SHARED/components/Price';
import useTimezoneDate from 'SHARED/hooks/useTimezoneDate';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { OfferBid } from 'SHARED/types/bidTypes';
import AnimateHeight from 'react-animate-height';
import prepareFinancingDetails from 'SHARED/helpers/prepareFinancingDetails';

interface Props {
  bidFields: OfferBid,
  isSimpleOffer?: boolean,
}

const CommonBidFields: React.FC<Props> = ({ bidFields, isSimpleOffer = false }) => {
  const {
    validTo,
    created,
    buyerName,
    netPrice,
    totalPrice,
    currency,
    volume,
    message,
    departmentLocation,
    destinationLocation,
    deleted,
    deletedBy,
    deletionReason,
    packaging,
    buyerPaymentTerm,
    sellerPaymentTerm,

    // financing info
    financingProvider,
    financingType,
    creditInterestRate,
    commissionPerUnit,
    commissionPerDeal,
    costOfFinancing,
    totalCostOfFinancing,
    financingNeeded,
  } = bidFields;
  const timezoneDate = useTimezoneDate();
  const { me } = useTypedSelector((state) => state.users);

  const isSeller = me.type === 'SELLER';

  const [showFinancingDetails, setShowFinancingDetails] = useState(false);
  const isFinancingBySeller = financingType.value === 'SELLER';
  const financingDetailsTitle = (isSeller && isFinancingBySeller) ? 'You are financing the buyer, these are your terms' : 'Financing terms';

  const isDeleted = bidFields.status === 'DELETED';
  const bidDeletionMessage = deletionReason ? `: ${deletionReason}` : '';
  const deletedText = `Cancelled ${timezoneDate({ date: deleted })} by ${deletedBy?.label}${bidDeletionMessage}`;
  const incotermsText = [
    departmentLocation?.incoterms?.label,
    departmentLocation?.address,
  ].filter(Boolean).join(', ');

  // seller should see net price, buyer - total price (including VAS)
  const bidPrice = isSeller ? netPrice : totalPrice;

  return (
    <>
      <div className="page-section-title">
        <span>Bid</span>
        {isDeleted && (
          <span className="deleted-bid-info">
            {deletedText}
          </span>
        )}
      </div>

      <div className="page-columns">
        <DataItemPreview className="page-column" item={validTo} isDate title="BID VALID" />
        <DataItemPreview className="page-column" item={created} isDate title="BID PLACED" />
        <DataItemPreview className="page-column" item={buyerName} dateFormat="DD MMM YYYY" title="BUYER" />
      </div>

      <div className="page-columns">
        <FormattedPrice className="page-column" value={bidPrice} prefix={currency} suffix="/mt" title="BID PRICE" />
        <DataItemPreview className="page-column" item={volume} suffix="mt" title="BID VOLUME" />
        {packaging && <DataItemPreview className="page-column" item={packaging?.label} title="PACKAGING" />}
      </div>

      {message && (
        <DataItemPreview
          title="MESSAGE"
          multilineText={message}
          multiline
        />
      )}

      {/* {deletionReason && <DataItemPreview className="page-column" item={deletionReason} title="CANCELLATION REASON" />} */}

      {/* LOGISTICS */}
      <div className="page-section-title">
        <span>Logistics {!isSimpleOffer && 'handling'}</span>
      </div>
      {!isSimpleOffer && (
        <>
          <div>
            <strong>
              <span>{departmentLocation?.address}</span>
              <span> &rarr; </span>
              <span>{destinationLocation?.address}</span>
            </strong>
          </div>
          <br />
        </>
      )}

      <div className="page-columns">
        <DataItemPreview
          className="page-column"
          title="SELLER INCOTERMS"
          multilineText={incotermsText}
          multiline
        />
        {!isSimpleOffer && (
          <>
            <div className="page-column">{' '}</div>
            <DataItemPreview className="page-column" item={destinationLocation?.incoterms?.label} title="BUYER INCOTERMS" />
          </>
        )}
      </div>
      {/* LOGISTICS === END */}

      {/* FINANCING */}
      <div className="page-section-title">
        <span>Financing</span>
      </div>
      <div className="page-columns">
        <DataItemPreview className="page-column" item={sellerPaymentTerm} title="SELLER PAYMENT TERM" />

        {!isSimpleOffer && (
          <>
            <DataItemPreview className="page-column" title="FINANCING PROVIDER">
              {financingProvider
                ? <div>{financingProvider?.label}</div>
              // in case if buyer did not select any financing provider
                : <span>Buyer arranges financing</span>}

              {/* seller should see only details about financing provided by him */}
              {/* buyer should see financing details always (if there is any) */}
              {
            (isSeller ? isFinancingBySeller : financingProvider) && (
            <button
              className="link-button"
              type="button"
              onClick={() => setShowFinancingDetails(!showFinancingDetails)}
            >
              Terms
            </button>
            )
          }
            </DataItemPreview>

            {financingNeeded && (
              <DataItemPreview
                className="page-column"
                item={buyerPaymentTerm}
                title="BUYER PAYMENT TERM"
              />
            )}
          </>
        )}
      </div>

      {
        (isSeller ? isFinancingBySeller : true) && (
          <AnimateHeight
            duration={400}
            height={showFinancingDetails ? 'auto' : 0}
          >
            <div className="bid-financing-details">
              <button
                className="close-btn link-button"
                type="button"
                onClick={() => setShowFinancingDetails(false)}
              >
                x
              </button>

              <div className="page-section-subtitle">{financingDetailsTitle}</div>

              <div className="page-columns">
                <DataItemPreview
                  className="page-column"
                  item={prepareFinancingDetails({
                    currency,
                    creditInterestRate,
                    commissionPerUnit,
                    commissionPerDeal,
                  })}
                  title="FINANCING TERMS"
                />
                <FormattedPrice className="page-column" value={costOfFinancing} prefix={currency} suffix="/mt" title="COST OF FINANCING" />
                <FormattedPrice className="page-column" value={totalCostOfFinancing} prefix={currency} title="TOTAL COST OF FINANCING" />
              </div>
            </div>
          </AnimateHeight>
        )
      }
      {/* FINANCING === END */}

    </>
  );
};

export default CommonBidFields;
