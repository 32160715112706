import React          from 'react';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import { OfferStatuses } from 'SHARED/types/offerTypes';
import { OfferBid } from 'SHARED/types/bidTypes';
import CounterBidFields from './CounterBidFields';
import BidFields from './BidFields';

interface Props {
  bid: OfferBid,
  offerStatus: keyof typeof OfferStatuses,
  history: OfferBid[] | null,
  offerVersion: string,
  validateAcceptBid: (bid: OfferBid) => void,
  handleCounterOffer: (bid: OfferBid) => void,
  viewBidHistory: (offerId:number, buyerId: number) => void,
  setHistory: React.Dispatch<React.SetStateAction<OfferBid[] | null>>,
  isSimpleOffer?: boolean,
}

const OfferBidItem: React.FC<Props> = ({
  bid,
  offerStatus,
  handleCounterOffer,
  validateAcceptBid,
  viewBidHistory,
  offerVersion,
  isSimpleOffer = false,
}) => (

  <div className="offer-bid" key={bid.id}>
    {bid.isMatchedProduct && <div className="attention-message inset">Offer is a match for this buyer</div>}
    <div>
      <DataItemPreview item={bid.buyerOrg} title="BUYER" isHeading />
    </div>

    {/* show bid details only for active offer
      in other cases show only buyer name and negotiation history */}

    {offerStatus === 'ACTIVE' && (
      <>
        {bid.counterBid && <CounterBidFields counterBid={bid.counterBid} currency={bid.currency} />}

        <BidFields bid={bid} isSimpleOffer={isSimpleOffer} />

        {!bid.counterBid && bid.acceptable && ( // TODO: later use only acceptable flag (remove counteroffer)
          <div className="bid-actions  success inset attention-message buttons-wrapper m-full-width">
            <button type="button" className="btn-primary" onClick={() => validateAcceptBid({ ...bid, offerVersion })}>Accept</button>
            <button type="button" className="btn-secondary" onClick={() => handleCounterOffer({ ...bid, offerVersion })}>
              Counter offer
            </button>
          </div>
        )}
      </>
    )}

    <button
      type="button"
      className="link-button"
      onClick={() => viewBidHistory(bid.offerId, bid.buyerId)}
    >
      View negotiation history
    </button>

  </div>

);

export default OfferBidItem;
