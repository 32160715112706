import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import CurrencyInput from 'react-currency-input-field';
import validationRules from 'SHARED/helpers/validation';
import texts from 'SHARED/helpers/texts';
import prepareDataAttr from 'SHARED/helpers/prepareDataAttr';
import Tooltip from '../Tooltip';

export interface IPriceInputProps {
  name: `${string}` | `${string}.${string}` | `${string}.${number}`,
  label?: string,
  tooltip?: string,
  disabled?: boolean,
  value?: number | string | null,
  rules?: any,
  currency?: any,
  testingName?: string,
}

const PriceInput: React.FC<IPriceInputProps> = (props) => {
  const { formState: { errors }, control } = useFormContext();

  const {
    name,
    label,
    currency = '€',
    tooltip = texts.tooltips.price,
    disabled = false,
    value,
    rules = validationRules.price,
    testingName,
  } = props;

  return (
    <div
      className={`form-input price-input short ${errors[name] ? 'invalid' : 'valid'}`}
      data-testing={prepareDataAttr(testingName || name)}
    >
      {label && (
        <label htmlFor={name}>
          {label}
          <span>{' *'}</span>

          {tooltip && (tooltip.length > 0) && <Tooltip text={tooltip} />}
        </label>
      )}

      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={value}
        render={({ field, fieldState }) => (
          <>
            <div className="form-input-wrapper">
              <div className="prefix">{currency}</div>

              <CurrencyInput
                allowDecimals
                disabled={disabled}
                disableGroupSeparators
                decimalScale={2}
                decimalSeparator=","
                allowNegativeValue={false}
                groupSeparator=" "
                value={field.value === null ? '' : field.value}
                onValueChange={(e) => {
                  field.onChange(e || null);
                }}
              />

              <div className="suffix">/MT</div>
            </div>
            {fieldState?.error && <div className="error-message">{fieldState?.error?.message}</div>}
          </>
        )}
      />
    </div>
  );
};

export default PriceInput;
