import React, { FC, useState } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import FormattedPrice from 'SHARED/components/Price';
import { OfferListItem, RFP } from 'SHARED/types/offerTypes';
import { useActions } from 'SHARED/redux/hooks/useActions';
import useTimezoneDate from 'SHARED/hooks/useTimezoneDate';
import { MpcDocLink, handleDateRange } from 'SHARED/helpers/common';
import renderMultiline from 'SHARED/helpers/renderMultiline';
import RFPCancelProposalModal from './RFPCancelProposalModal';

interface IProps {
  proposal: OfferListItem
  rfp: RFP;
  order: number;
  rfpStatus: string | null;
}

const RfpProposalCard: FC<IProps> = ({
  proposal,
  rfp,
  order,
  rfpStatus,
}) => {
  const {
    id,
    status,
    created,
    endDate,
    totalVolume,
    price,
    currency,
    volumeUnits,
    incoterms,
    createdBy,
    description,
    productionLocation,
    customLabel,
    packaging,
    salesTerms,
    logisticLocation,
    paymentTerms,
    agreedOnDelivery,
    agreedOnPayment,
    documents,
    deal,
    departureFrom,
    departureTo,
    feedback,
  } = proposal;
  const { id: rfpId, ownerOrg } = rfp;

  const SALES_TERMS = documents?.SALES_TERMS;

  const { getOfferDetails } = useActions();
  const timezoneDate = useTimezoneDate();

  const isActiveRfp = rfpStatus === 'ACTIVE';
  const isRfpEnded = rfpStatus !== 'ACTIVE';
  const isActive = (status === 'ACTIVE');
  const isArchived = (status === 'ARCHIVED');
  const isCancelled = status === 'CANCELLED';
  const isMPC = salesTerms === 'MPC';
  const isDeal = !!deal;
  const shouldShowStatus = (
    isRfpEnded
    || isCancelled
    || (isActiveRfp && isArchived)
  );

  const delivery = handleDateRange({ from: departureFrom, to: departureTo });
  const asRequested = '(as requested by buyer)';
  const incotermsText = agreedOnDelivery
    ? `${incoterms} ${asRequested}`
    : incoterms;
  const locationText = agreedOnDelivery
    ? `${logisticLocation} ${asRequested}`
    : logisticLocation;
  const paymentTermsText = agreedOnPayment
    ? `${paymentTerms?.label} ${asRequested}`
    : paymentTerms?.label;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const statusText = () => {
    if (isDeal) { return 'You got the deal'; }
    if (isCancelled) { return 'Cancelled'; }
    if (isActiveRfp && isArchived) { return 'Expired'; }

    return 'You did not get the deal';
  };

  function handleCancelProposal(isSuccess: boolean) {
    setIsModalOpen(false);

    if (!isSuccess) {
      return;
    }

    if (rfpId) {
      getOfferDetails({ id: rfpId, offerType: 'RFP' });
    }
  }

  return (
    <>
      <div
        className={clsx(
          'attention-message inset-section proposal-card',
          isRfpEnded && isDeal && 'deal',
          isRfpEnded && !isDeal && 'no-deal',
          isActiveRfp && isArchived && 'expired',
          isCancelled && 'cancelled',
        )}
      >

        <div className="title-with-controls mb-1">
          <h2 className="proposal-card__title">
            <span>Proposal {order}</span>
            {shouldShowStatus && <span className="status">{statusText()}</span>}
            {isDeal && (
              <Link
                to={`/rfp/deals/${deal?.id}`}
              >
                Deal details
              </Link>
            )}
          </h2>

          {isActive && isActiveRfp && (
          <div className="controls">
            <button
              type="button"
              className="btn-icon"
              title="Cancel proposal"
              onClick={() => setIsModalOpen(true)}
            >
              <i className="icon-delete" />
            </button>
          </div>
          )}
        </div>

        <div className="mb-1_5"><strong>{timezoneDate({ date: created })}, by {createdBy}</strong></div>

        <div className="summary-grid">
          <div>
            <DataItemPreview title="Message" multiline multilineText={description} />
            <DataItemPreview title="Production address" item={productionLocation} />
            <DataItemPreview title="Custom label" item={customLabel} />
            <DataItemPreview title="Proposal valid until" item={endDate} isDate />
          </div>

          <div>
            <DataItemPreview title="Proposed volume" item={totalVolume} suffix="mt" />
            <FormattedPrice title="Proposed price" value={price} prefix={currency} suffix={`/${volumeUnits}`} />
            <DataItemPreview title="Proposed packaging" item={packaging && packaging[0]} />

            <DataItemPreview title="Sales terms" item={salesTerms} />

            {/* SALES TERMS */}
            {isMPC && (
              <DataItemPreview
                title="Sales terms document"
                link={{ url: MpcDocLink, displayName: 'MPC sales terms' }}
              />
            )}
            {SALES_TERMS && SALES_TERMS.length > 0 && (
              <DataItemPreview title="Sales terms document" file={SALES_TERMS[0]} />
            )}
          </div>

          <div>
            {/* logistics */}
            <DataItemPreview
              title="Incoterms"
              item={incotermsText}
            />
            <DataItemPreview
              title="Incoterms location"
              item={locationText}
            />
            {!agreedOnDelivery && (
              <DataItemPreview
                title="Departure range"
                item={delivery}
              />
            )}
            {/* logistics === END */}

            {/* payment */}
            <DataItemPreview
              title="Payment terms"
              item={paymentTermsText}
            />
            {/* payment === END */}
          </div>

        </div>

        {/* conditional feedback message from the buyer */}
        {!!feedback && (
          <div className="attention-message info no-margin">
            <strong className="mb-1" style={{ display: 'block' }}>
              Feedback from {ownerOrg?.orgName} on {timezoneDate({ date: feedback?.created })}:
            </strong>

            {renderMultiline(feedback?.message)}
          </div>
        )}
      </div>

      {isModalOpen && (
        <RFPCancelProposalModal
          proposalId={id}
          handleClose={handleCancelProposal}
        />
      )}
    </>
  );
};

export default RfpProposalCard;
