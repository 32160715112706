import React from 'react';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { useActions } from 'SHARED/redux/hooks/useActions';
import { postAcceptBid } from 'SHARED/api/offers/post/postAcceptBid';
import { BidInfoRow } from './BidInfoRow';
import { BidForAcceptRow } from './BidForAcceptRow';
import { DealInfoRow } from './DealInfoRow';

const bidTexts = {
  active: 'You will be able to see all the bidders and their bids once the auction has ended.',
  // noDeal: 'No bids were made on your offer. You can choose to recreate the offer at a lower price or invite more buyers to increase the chances of selling your product.',
  noDeal: [
    'No bids equal of higher than your minimum price were made on your offer.',
    'You can choose to recreate the offer at a lower price or invite more buyers to increase the chances of selling your product.',
  ],
  cancelled: 'The auction has been cancelled on your request. Reason: ',
  endedWithLowBids: 'Your auction has ended with one or more bids below your minimum price. ',
  needChooseBid: 'No bid is automatically or manually selected',
  noBidSelectedFinish: 'No bid was selected to become a deal',
};

const SellerBidTab: React.FC = () => {
  const { OFF_SPEC } = useTypedSelector((state) => state.offer);
  const { getOfferDetails } = useActions();

  const {
    status,
    ownerOrg,
    cancellationReason,
    id,
    hasDeal,
    bidders,
    offerBids,
    deals,
    waitDecisionUntil,
    price: offerMinimumPrice,
  } = OFF_SPEC;

  const bidsToAccept = offerBids?.filter((bid) => !bid.hasDeal);

  const handleAcceptBid = async (bidId: number | string) => {
    await postAcceptBid(OFF_SPEC.id, bidId);
    await getOfferDetails({ id, offerType: 'OFF_SPEC' });
  };

  const endedWithLowBids = status === 'ENDED' && !hasDeal && bidders > 0;
  const noBidSelectedFinish = status === 'ARCHIVED' && !hasDeal && bidders > 0;
  const isNoDEal = status === 'ARCHIVED' && !hasDeal && bidders === 0;
  const isNeedToChooseBid = status === 'ENDED';
  const gotDeals = deals?.length;
  const isFinalized = status === 'ARCHIVED' && hasDeal && bidders > 0;
  const archivedWithoutSelectingBid = status === 'ARCHIVED' && !hasDeal && bidders > 0;

  // need to add new conditions for auction
  // ? if splittable ended with...

  return (
    <>

      {status === 'ACTIVE' && (
      <div className="row">
        <div className="attention-message">{bidTexts.active}</div>
      </div>
      )}

      {endedWithLowBids && (
      <div className="row">
        <div className="attention-message">{bidTexts.endedWithLowBids}</div>
      </div>
      )}

      {isNoDEal && (
      <div className="row">
        <div className="attention-message">
          <span>{bidTexts.noDeal[0]}</span>
          <br />
          <span>{bidTexts.noDeal[1]}</span>
        </div>
      </div>
      )}

      {(isNeedToChooseBid || gotDeals || noBidSelectedFinish) && (
      <>
        <h2 className="row">The winning bids</h2>

        {noBidSelectedFinish && !gotDeals && <div className="attention-message">{bidTexts.noBidSelectedFinish}</div>}
        {isNeedToChooseBid && !gotDeals && (
        <div className="attention-message" style={{ marginBottom: 10 }}>{bidTexts.needChooseBid}</div>
        )}

        <div className="bids-list">
          {deals?.map((deal) => (
            <DealInfoRow deal={deal} askPrice={offerMinimumPrice} key={deal.id} />
          ))}
        </div>

        <br />
        <br />
      </>
      )}

      {status === 'CANCELLED' && (
      <div className="row">
        <div className="attention-message">
          {bidTexts.cancelled}
          <strong>{cancellationReason}</strong>
        </div>
      </div>
      )}

      {isFinalized && (
      <>
        <h2 className="row">Other bids</h2>

        <div className="bids-list">
          {bidsToAccept?.map((bid) => {
            if (bid.hasDeal) { return null; }
            return (
              <BidInfoRow country={ownerOrg.country} bid={bid} key={bid.id} offerMinimumPrice={offerMinimumPrice} />
            );
          })}
        </div>
      </>
      )}

      {archivedWithoutSelectingBid && !!bidsToAccept?.length && (
      <>
        <h2 className="row">Other bids</h2>

        <div className="bids-list">
          {bidsToAccept?.map((bid) => (
            <BidInfoRow country={ownerOrg.country} bid={bid} key={bid.id} offerMinimumPrice={offerMinimumPrice} />
          ))}
        </div>
      </>
      )}

      {/* If winner need to be chosen manually (separate table for better visuals) */}
      {isNeedToChooseBid && (
        <div className="bids-list">
          <h2 className="row">Other bids</h2>

          {bidsToAccept?.map((bid) => (
            <BidForAcceptRow
              bid={bid}
              handleAcceptBid={handleAcceptBid}
              key={bid.id}
              waitDecisionUntil={waitDecisionUntil}
            />
          ))}
        </div>
      )}
      <br />
    </>
  );
};

export default SellerBidTab;
