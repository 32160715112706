import React from 'react';
import { HandleRowClick, OfferListItem, OfferTableItem, PagesTypes } from 'SHARED/types/offerTypes';
import CardsView from 'SHARED/pages/OffersList/CardsView';
import TableView from 'SHARED/pages/OffersList/TableView';
import { OfferListTypes } from 'SHARED/pages/OffersList';
import { useOfferFields } from 'SHARED/pages/OffersList/fields/useOfferFields';
import 'SHARED/components/Table/table-styles.scss';

export interface IProps {
  data: OfferListItem[],
  handleRowClick: HandleRowClick,
  activeTab: OfferListTypes,
  pageType: PagesTypes,
}

export type FieldsList = (offer: any, activeTab: OfferListTypes, userTimezone?: string) => OfferTableItem[];

const OfferTable: React.FC<IProps> = ({
  data, handleRowClick, activeTab, pageType,
}) => {
  const fields = useOfferFields();

  return (
    <>
      <TableView
        handleRowClick={handleRowClick}
        offers={data}
        fields={fields[pageType]}
        activeTab={activeTab}
      />
      <CardsView
        handleRowClick={handleRowClick}
        offers={data}
        fields={fields[pageType]}
        activeTab={activeTab}
      />
    </>
  );
};

export default OfferTable;
