import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { offerTexts } from 'SHARED/helpers/texts';
import Checkboxes from 'SHARED/components/Checkboxes';
import RadioGroup from 'SHARED/components/RadioGroup';
import Select from 'SHARED/components/Select';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { Dictionary, OnspecOfferTypes } from 'SHARED/types/offerTypes';
import { departurePeriod as departurePeriods } from 'SHARED/helpers/OfferData';
import { isMobile } from 'react-device-detect';
import useUserTimezone from 'SHARED/hooks/useUserTimezone';
import { zonedMonthsDictionary } from 'SHARED/helpers/dates';
import ZonedDatePicker from 'SHARED/components/ZonedDatePicker/ZonedDatePicker';
import { filterLocationsByIncotermsLocationType } from 'SHARED/helpers/common';

interface Props {
  offerType: OnspecOfferTypes
}

const ShippingSection: React.FC<Props> = ({ offerType }) => {
  const offer = useTypedSelector((state) => state.offer.ON_SPEC);
  const { dictionaries, locations } = useTypedSelector((state) => state.dictionaries);

  const { userTimezone } = useUserTimezone();
  const methods = useFormContext();

  const {
    incoterms,
    departureFrom,
    departurePeriod,
    loadOptions,
    logisticLocation,
    departureTo,
    departureFromMonth,
  } = offer;

  useEffect(() => {
    setValue('departurePeriod', departurePeriod.value);
  }, [departurePeriod]);

  // 3 - watch form values section
  // for dynamic condition rendering and properly date validation
  const { watch, setValue, formState } = methods;
  const choosedDeparturePeriod = watch('departurePeriod');
  const choosedDepartureFrom = watch('departureFrom', departureFrom);
  const choosedDepartureTo = watch('departureTo', departureTo);
  const choosedIncoterms: Dictionary = watch('incoterms', incoterms);
  const { INCOTERM_SELLER, LOADING_DETAILS } = dictionaries;
  const [LOCATIONS_LIST_INCOTERMS, SET_LOCATIONS_LIST_INCOTERMS] = useState<any>(locations.LOGISTIC || []);

  const departureMonthPrefill = (choosedDeparturePeriod === 'MONTH' && departureFromMonth) ? departureFromMonth : zonedMonthsDictionary(userTimezone)[0];

  useEffect(() => {
    if (formState.dirtyFields?.incoterms) {
      setValue('logisticLocation', null);
    }

    if (choosedIncoterms) {
      SET_LOCATIONS_LIST_INCOTERMS(filterLocationsByIncotermsLocationType(locations.LOGISTIC, choosedIncoterms));
    }
  }, [choosedIncoterms]);

  // if user choosed date from late than date to
  // date to need to be updated
  useEffect(() => {
    if (choosedDepartureFrom > choosedDepartureTo) {
      setValue('departureTo', choosedDepartureFrom);
    }
  }, [choosedDepartureFrom]);

  return (
    <>

      {/* ********************************* Shipping *********************************** */}

      <h2 className="page-section-title">
        Shipping
      </h2>

      <Select
        label="INCOTERMS"
        name="incoterms"
        options={INCOTERM_SELLER}
        selected={incoterms}
      />

      {choosedIncoterms && LOCATIONS_LIST_INCOTERMS.length > 0 && (
        <Select
          label="SELLER INCOTERMS LOCATION"
          name="logisticLocation"
          options={LOCATIONS_LIST_INCOTERMS}
          selected={logisticLocation}
        />
      )}

      {/* if there is no any addrees related to choosed incoterms show message  */}
      {choosedIncoterms && LOCATIONS_LIST_INCOTERMS.length === 0 && (
        <div className="attention-message alert">
          {offerTexts.common.noAddressess}
        </div>
      )}

      <Checkboxes
        label="LOADING DETAILS"
        name="loadOptions"
        values={LOADING_DETAILS}
        selected={loadOptions}
      />

      <RadioGroup
        label="DEPARTURE"
        name="departurePeriod"
        defaultValue={departurePeriod}
        values={departurePeriods}
      />

      {choosedDeparturePeriod === 'MONTH' && (
        <Select
          label="Month"
          name="departureFromMonth"
          options={zonedMonthsDictionary(userTimezone)}
          selected={departureMonthPrefill}
        />
      )}

      {choosedDeparturePeriod === 'PERIOD' && (
        <div className="dates-range">
          <div className="dates-range-wrapper">
            <ZonedDatePicker
              inline={isMobile}
              name="departureFrom"
              value={departureFrom}
              showTimezone={false}
            />
            <ZonedDatePicker
              inline={isMobile}
              name="departureTo"
              minDate={choosedDepartureFrom}
              value={departureTo}
              showTimezone={false}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ShippingSection;
