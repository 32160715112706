import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { NumericFormat, NumberFormatValues } from 'react-number-format';
import './numeric-input.scoped.scss';

interface NumericInputProps {
  name: string,
  rules?: any,
  placeholder?: string,
  value?: string | number,
  disabled?: boolean,
  className?: string,
  isDecimal?: boolean,
  minValue?: number,
  maxValue?: number,
  decimalSeparator?: ',' | '.',
  decimalScale?: number,
}

const NumericInput: React.FC<NumericInputProps> = (props) => {
  const { control } = useFormContext();

  const {
    rules,
    placeholder,
    name,
    value,
    disabled = false,
    // className = '',
    // minValue = 0,
    maxValue = 0,
    decimalSeparator = ',',
    isDecimal = false,
    decimalScale = 2,
  } = props;

  type LimitType = (values: NumberFormatValues) => boolean;

  const valueLimit: LimitType = ({ floatValue }) => { // TODO handle case when this props not passed
    if (floatValue === undefined) return true;

    if (floatValue <= maxValue) {
      return true;
    }

    return false;
  };

  return (
    <div className="input-wrapper">
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={value}
        render={({ field, fieldState }) => (
          <>
            <NumericFormat
              isAllowed={valueLimit}
              value={field.value}
              onChange={field.onChange}
              displayType="input"
              disabled={disabled}
              allowNegative={false}
              allowedDecimalSeparators={[',', '.']}
              fixedDecimalScale
              decimalSeparator={decimalSeparator}
              decimalScale={isDecimal ? decimalScale : 0}
              placeholder={placeholder}
              getInputRef={field.ref}
            />

            {fieldState?.error && <div className="error-message">{fieldState?.error?.message}</div>}
          </>

        )}
      />
    </div>

  );
};

export default NumericInput;
