import React  from 'react';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import FormattedPrices from 'SHARED/components/Prices';
import { OfferPackage } from 'SHARED/types/offerTypes';

export interface IPackagePreviewItemProps {
  p: OfferPackage,
  index: number,
  isPreview?: boolean,
  showSpecifications: (id: string) => void
}

const PackagePreviewItem: React.FC<IPackagePreviewItemProps> = ({ p, index, showSpecifications, isPreview }) => (
  <div className="data-preview-row">
    <div className="page-section-title">
      <span>Product package </span>
      <span>{index + 1}</span>
    </div>

    <DataItemPreview item={p.productType.label} title="PRODUCT TYPE" />
    <div className="label-text">SELLER PRODUCT SPECIFICATION</div>
    <div>{p.productTitle}</div>
    {p.product && <div><a href="#open-spec-popup" onClick={(e) => showSpecifications(p.product.value)}>Specifications details</a></div>}
    {' '}
    <br />
    {isPreview && <FormattedPrices title="PREMIUM FOR THIS PACKAGE" values={[p.product.premiumPriceEur || 0, p.product.premiumPriceUsd || 0]} />}
    {!isPreview && <FormattedPrices title="PREMIUM FOR THIS PACKAGE" values={[p.premiumPriceEur || 0, p.premiumPriceUsd || 0]} />}
  </div>
);

export default PackagePreviewItem;
