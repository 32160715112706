import _                      from 'lodash';
import React                  from 'react';
import Preloader              from 'SHARED/components/ThePreloader';
import CharacteristicPreview  from 'SHARED/pages/ProductPreviewPage/ProductSpecificationPreview';
import { CharacteristicItem } from 'SHARED/types/specsTypes';
import                        './styles/ProductSpecsDetails.scoped.scss';

interface Props {
  handleClose: (value: React.SetStateAction<boolean>) => void,
  characteristics: CharacteristicItem[] | undefined | null,
  coaUsed?: boolean,
}

const ProductSpecsDetails: React.FC<Props> = ({ handleClose, characteristics, coaUsed = false }) => {
  if (!characteristics) {
    return (<Preloader isLoading />);
  }
  const sortedCharacteristics = _.sortBy(characteristics, 'displayGroup.order');
  const groupedCharacteristics = _.groupBy(sortedCharacteristics, 'displayGroup.value');

  return (
    <div className="popup">
      <div className="popup-overlay" />
      <div className="popup-body">
        <button type="button" className="popup-close link-button" onClick={(e) => { handleClose(false); }}>
          close
        </button>
        {coaUsed && <h1>COA</h1>}
        {!coaUsed && <h1>Product Specifications</h1>}

        <div className="popup-wrapper">

          {Object.keys(groupedCharacteristics).length === 0 && (
            <div className="attention-message">No product specifications available</div>
          )}

          {Object.keys(groupedCharacteristics).map((key) => (
            <div key={key}>
              <strong>
                {groupedCharacteristics[key][0].displayGroup.label}

              </strong>
              {' '}
              <br />
              <br />

              {groupedCharacteristics[key].map((ch) => (
                (
                  <CharacteristicPreview
                    key={ch.id}
                    characteristic={ch}
                  />
                )
              ))}
              <br />
              <br />
            </div>
          ))}

        </div>
      </div>
    </div>
  );
};
export default ProductSpecsDetails;
