import { AUCTION_ENDPOINTS } from 'SHARED/api/endpoints';
import { axios_Core } from 'SHARED/helpers/axios';

export const postAcceptBid = async (offerId: string | number, bidId: string | number) => {
  try {
    const response = await axios_Core.post(AUCTION_ENDPOINTS.postAcceptBid(offerId, bidId));

    return response;
  } catch (error:unknown) {
    console.log('postAcceptBid error', error);

    return error;
  }
};
