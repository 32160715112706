import axios, { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { Channel, Client, State } from 'twilio-chat';
import Chat from './Chat';
import './styles.scss';

export interface ChatProps {
  dealId: any,
  visible?: boolean,
}

export interface TwilioCredentials {
  token: string,
  channelSid: string,
  identity: string,
}

export default function TwilioChat(props: ChatProps) { // chat initializer
  const { dealId, visible = true } = props;
  const [credentials, setCredentials] = useState<TwilioCredentials>();
  const [client, setClient] = useState<Client>();
  const [channel, setChannel] = useState<Channel>();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  const loadCredentials = async () => {
    try {
      const res:AxiosResponse<TwilioCredentials> = await axios.get('/v1/chat/credentials', { params: { dealId } });
      setCredentials(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  // fetch Twilio token and chat identifier for current user
  useEffect(() => {
    loadCredentials();
  }, [dealId]);

  useEffect(() => () => { // cleanup client listeners on unmount
    client?.removeAllListeners();
  }, []);

  // initialize Twilio client
  useEffect(() => {
    if (credentials) {
      const CLIENT = new Client(credentials.token);
      CLIENT.on(Client.stateChanged, (state: State) => {
        if (state === 'initialized') {
          setClient(CLIENT);
          setLoading(false);
        }
      });
      CLIENT.on(Client.tokenAboutToExpire, loadCredentials);
      CLIENT.on(Client.tokenExpired, loadCredentials);
    }
  }, [credentials]);

  // join to requested channel
  useEffect(() => {
    if (client && credentials?.channelSid) {
      client.getChannelBySid(credentials.channelSid)
        .then(setChannel)
        .catch(() => {
          setLoading(false);
          setError('Failed to connect to requested channel');
        });
    }
  }, [credentials, client]);

  return (
    <div className={visible ? '' : 'hidden'}>

      {/* error on init fail */}
      {error && <div className="error">{error}</div> }
      {/* loading spinner */}
      { loading && <div className="loading-progress" /> }
      {/* chat component */}
      { (credentials && channel) && <Chat channel={channel} identity={credentials.identity} /> }
    </div>
  );
}
