import React, { FC, useState } from 'react';
import clsx from 'clsx';
import getDownloadDocumentFile from 'SHARED/api/documents/get/getDownloadDocumentFile';
import putDocumentUserAction from 'SHARED/api/documents/put/putDocumentUserAction';
import deleteFileFromDocument from 'SHARED/api/documents/delete/deleteFileFromDocument';
import TextCollapse from 'SHARED/components/TextCollapse';
import Preloader from 'SHARED/components/ThePreloader';
import renderMultiline from 'SHARED/helpers/renderMultiline';
import { useActions } from 'SHARED/redux/hooks/useActions';
import useTimezoneDate from 'SHARED/hooks/useTimezoneDate';
import { IDocumentThread } from 'SHARED/validators/documents/documentSchema';
import routes from 'SHARED/types/routes';
import { Link } from 'react-router-dom';
import RejectDocumentPopup from './RejectDocumentPopup';

interface IProps {
  dealId: string | number;
  document: IDocumentThread;
}

const DocumentCard: FC<IProps> = ({ dealId, document }) => {
  const { documentType, latestNode, status, userUploader, requested } = document;
  const { comment } = latestNode;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRejectPopupOpen, setIsRejectPopupOpen] = useState<boolean>(false);

  const { getDocuments } = useActions();
  const renderDate = useTimezoneDate();

  function prepareDocumentDisplayStatus() {
    switch (status) {
      case 'ON_REVIEW':
        return 'Uploaded';

      case 'APPROVED':
        return 'Approved';

      case 'REJECTED':
        return 'Update requested';

      // 'REQUESTED' by default, but it's not used for now
      default:
        return 'Requested';
    }
  }

  const creationDate = renderDate({ date: latestNode.createdAt, displayFormat: 'DD MMM YYYY HH:mm Z' });
  const statusString = `${prepareDocumentDisplayStatus()} ${creationDate}`;
  const personString = `By ${latestNode.createdBy}`;
  const uploadNewFileUrl = routes.onspec.goto_provideDocument_thread(dealId, document.id);

  // status flags
  const isApproved = status === 'APPROVED';
  const isRejected = status === 'REJECTED';
  // status flags === END

  // action permissions flags
  const isCanDeleteThread = !isApproved && (requested && userUploader);
  const isCanUpload = false;
  const isCanDownload = true;
  const isCanApprove = !isApproved && !isRejected && !userUploader;
  const isCanReject = !isApproved && !isRejected && !userUploader;
  const isCanUploadNew = userUploader && !isApproved;
  const isCanDeleteFile = !isApproved && userUploader;
  // action permissions flags === END

  // DOWNLOAD DOCUMENT
  function handleDownload() {
    setIsLoading(true);
    getDownloadDocumentFile({ dealId, threadId: document.id, originalFileName: latestNode.originalFileName })
      .finally(() => setIsLoading(false));
  }

  // APPROVE DOCUMENT
  function handleApproveDocument() {
    setIsLoading(true);
    putDocumentUserAction({
      actionType: 'approve',
      dealId,
      payload: {
        threadId: document.id,
        documentType: {
          code: documentType.code,
          title: documentType.title,
        },
      },
    })
      // if action was success - refetch fresh version of documents
      .then((result) => result === 'success' && getDocuments(dealId))
      .finally(() => setIsLoading(false));
  }

  // REQUEST UPDATE
  function handleRequestUpdate() {
    setIsRejectPopupOpen(true);
  }
  function handleCloseRejectPopup() {
    setIsRejectPopupOpen(false);
  }

  // DELETE FILE FROM DOCUMENT
  function handleDeleteFile() {
    setIsLoading(true);
    deleteFileFromDocument({ dealId, threadId: document.id })
      .then((result) => result === 'success' && getDocuments(dealId))
      .finally(() => setIsLoading(false));
  }

  return (
    <>
      <Preloader isLoading={isLoading} />

      <div className={clsx('document-card', status.toLowerCase())}>

        <div className="document-card__header">
          <div className="name">{documentType.title}</div>

          <div className="actions">
            {isCanDeleteThread && (
            <button type="button" className="btn-icon" title="Delete document">
              <i className="icon-delete" />
            </button>
            )}
          </div>
        </div>

        <div className="document-card__body">

          <div className="status">{statusString}</div>
          <div className="person">{personString}</div>

          {/* if description > 200 symbols -- render all the text, if more collapse */}
          <div className="description">
            {(comment && comment.length > 150) ? (
              <TextCollapse
                text={comment}
              />
            ) : (comment && renderMultiline(comment))}
          </div>

          <div className="actions buttons-wrapper row">
            {isCanUpload && (
            <button
              type="button"
              className="btn-primary btn--small btn--icon-start"
              title="Upload document"
            >
              <i className="icon-upload" />
              <span>Upload document</span>
            </button>
            )}

            {isCanDownload && (
            <button
              type="button"
              className="btn-icon--primary"
              title="Download document"
              onClick={handleDownload}
            >
              <i className="icon-download" />
            </button>
            )}

            {isCanUploadNew && (
            <Link
              to={uploadNewFileUrl}
              className="btn-secondary btn--small btn--icon-start"
              title="Upload new version"
            >
              <i className="icon-upload" />
              <span>Upload new</span>
            </Link>
            )}

            {isCanDeleteFile && (
              <button
                type="button"
                className="btn-icon"
                title="Delete file from document"
                onClick={handleDeleteFile}
              >
                <i className="icon-delete" />
              </button>
            )}

            {isCanApprove && (
            <button
              type="button"
              className="btn-secondary btn--small"
              onClick={handleApproveDocument}
            >
              Accept
            </button>
            )}

            {isCanReject && (
            <button
              type="button"
              className="btn-danger btn--small"
              onClick={handleRequestUpdate}
            >
              Request update
            </button>
            )}
          </div>

        </div>

      </div>

      {/* Popups */}
      {isRejectPopupOpen && (
        <RejectDocumentPopup
          dealId={dealId}
          document={document}
          handleClose={handleCloseRejectPopup}
        />
      )}
    </>
  );
};

export default DocumentCard;
