import React    from 'react';
// import { Link } from 'react-router-dom';
import routes   from 'SHARED/types/routes';
import          './dropdown.scoped.scss';

export interface IDropDownMenuProps {}

const DropDownMenu: React.FC<IDropDownMenuProps> = (props) => (
  <div className="dropdown-buttons-menu">
    <span className="btn-primary">
      <span>Create offer</span>
      <i className="icon-down" />
    </span>

    <div className="content">
      <p>
        All offer types allow you to negotiate with buyers one-on-one.
      </p>

      <h3>Advanced offer</h3>
      <p>
        Match supply and demand based on product specifications and offer logistics and finance services to buyers.
      </p>

      <ul>
        <li><a href={routes.onspec.createAP} className="btn-secondary">Already Produced</a></li>
        <li><a href={routes.onspec.createTP} className="btn-secondary">To Be Produced</a></li>
      </ul>

      <br />

      <h3>Simplified offer</h3>
      <p>
        No product specifications, logistics and financing services included. You can offer products without disclosing your identity.
      </p>

      <ul>
        <li><a href={routes.onspec.create_simple_offer} className="btn-secondary">Simple offer</a></li>
      </ul>
    </div>
  </div>
);

export default DropDownMenu;
