import { CORE_ENDPOINTS } from 'SHARED/api/endpoints';
import { axios_Core } from 'SHARED/helpers/axios';

export const cancelOffer = async (id: string | number, cancellationReason?: string) => {
  try {
    const response = await axios_Core.put(
      CORE_ENDPOINTS.offerById(id),
      {
        action: 'CANCEL',
        cancellationReason,
      },
    );
    return response.status;
  } catch (error: any) {
    console.error(error);
    return error?.status;
  }
};
