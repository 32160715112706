import { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { axios_Core } from 'SHARED/helpers/axios';
import { useActions } from 'SHARED/redux/hooks/useActions';
import { defaultOnspecOffer } from 'SHARED/initialState/offer';
import texts from 'SHARED/helpers/texts';
import useQueryItem from 'SHARED/hooks/useQueryItem';
import { ProductCategory } from 'SHARED/types/specsTypes';
import findCategoryPackaging from 'SHARED/helpers/findCategoryPackaging';
import { OnSpecOffer } from 'SHARED/types/offerTypes';

const useDuplicateAP = (setValue: UseFormSetValue<any>, watch: UseFormWatch<any>) => {
  const { saveOffer, getProducts, resetOffer } = useActions();

  const [duplicateLoading, setDuplicateLoading] = useState(false);
  const [offerState, setOfferState] = useState<any>(null);
  const [duplicateMessage, setDuplicateMessage] = useState<any>(null);
  const [duplicateError, setDuplicateError] = useState<any>(null);

  const [categories, setCategories] = useState<ProductCategory[]>([]);
  const [productValue, setProductValue] = useState<any>(null);

  const productSelect = watch('product');

  const duplicateId = useQueryItem('duplicate');

  // now we need to get offer data for duplication, but modify it before saving to redux
  const getDuplicatedOffer = async (offerId: number | string) => axios_Core.get(`/v1/offers/${offerId}`);
  useEffect(() => {
    if (duplicateId) {
      // enable preloader first
      // setOfferDuplicateLoading(true);
      setDuplicateLoading(true);
      resetOffer();
      getDuplicatedOffer(duplicateId)
        .then((res: AxiosResponse) => res.data)
        .then(async (offerData: any) => {
          if (offerData.type.value !== 'ALREADY_PRODUCED') {
            // need to set error message here as well
            setDuplicateError(texts.offerDuplicationTexts.onSpecTypeError);
            setDuplicateLoading(false);
            return;
          }

          getProducts({
            category: offerData.productCategory.value,
            type: offerData.productType.value,
          });
          const dictionaries = await axios_Core.get('/v1/dictionary/group?types=PAYMENT_TERMS&offerTypes=ALREADY_PRODUCED');
          const categoriesResponse: AxiosResponse<ProductCategory[]> = await axios_Core.get('v1/product/categories');

          if (dictionaries.status !== 200 || categoriesResponse.status !== 200) {
            setDuplicateError(texts.offerDuplicationTexts.onSpecNotFound);
            setDuplicateLoading(false);
            return;
          }
          setCategories(categoriesResponse.data);

          // PACKAGING
          const categoryPackagingOptions = findCategoryPackaging(categoriesResponse.data, offerData.productCategory.value);
          const packagingMatch = categoryPackagingOptions.find((opt: any) => opt.value === offerData.packaging.value);

          const {
            // fields that can cause problems when duplicating
            status,
            remainingVolume,
            // ====================
            ...offerFieldsToDuplicate
          } = offerData as OnSpecOffer;

          const offer = {
            ...offerFieldsToDuplicate,
            buyersFetchType: defaultOnspecOffer.buyersFetchType,

            // payment terms should be set to more explicit value
            paymentTerms: dictionaries.data.PAYMENT_TERMS.find((term: any) => term.value === offerData.paymentTerms.value) || null,
            paymentOffset: offerData.paymentOffset ? {
              value: offerData.paymentOffset.toString(),
              label: `${offerData.paymentOffset} days`,
            } : null,

            // reset some fields that are not needed for duplication
            productionLocation: defaultOnspecOffer.productionLocation,
            departureFrom: defaultOnspecOffer.departureFrom,
            departureTo: defaultOnspecOffer.departureTo,
            departurePeriod: defaultOnspecOffer.departurePeriod,
            endDate: defaultOnspecOffer.endDate,
            productionDate: defaultOnspecOffer.productionDate,
            expirationDate: defaultOnspecOffer.expirationDate,
            incoterms: defaultOnspecOffer.incoterms,
            buyers: defaultOnspecOffer.buyers,
            params: defaultOnspecOffer.params,
            // packaging: defaultOnspecOffer.packaging,
            packaging: packagingMatch || null,
            documents: {
              COA: offerData.documents?.COA?.map((doc: File) => ({ ...doc, duplicate: true })),
              SALES_TERMS: offerData.documents?.SALES_TERMS?.map((doc: File) => ({ ...doc, duplicate: true })),
            },
          };

          if (!offer.priceEur && offer.priceUsd) {
            offer.priceEur = null;
            offer.priceEurDisabled = true;
          }
          if (!offer.priceUsd && offer.priceEur) {
            offer.priceUsd = null;
            offer.priceUsdDisabled = true;
          }

          setOfferState(offer);
        })
        .catch((err: any) => {
          setDuplicateError(texts.offerDuplicationTexts.onSpecNotFound);
          setDuplicateLoading(false);
        });
    }
  }, [duplicateId]);

  useEffect(() => {
    if (duplicateId && offerState) {
      (async () => {
        try {
          const productsResponse: AxiosResponse = await axios_Core.get(`v1/product?category=${offerState?.productCategory?.value}&type=${offerState?.productType?.value}`);
          const products = productsResponse.data;

          if (productsResponse.status !== 200) {
            setDuplicateError(texts.offerDuplicationTexts.onSpecNotFound);
            throw new Error('Error');
          }

          setDuplicateMessage(texts.offerDuplicationTexts.alreadyProduced);
          saveOffer({
            offer: offerState,
            offerType: 'ON_SPEC',
          });

          // PRODUCT CATEGORY => pick from `categories`
          const categoryIndex = categories.findIndex((category) => category.value === offerState.productCategory.value);
          // setValue('productCategory', categories[categoryIndex]);
          categoryIndex >= 0 && setValue('productCategory', categories[categoryIndex]);

          // PRODUCT TYPE => pick from `categories[categoryIndex].productTypes` matched item
          const productTypeIndex = categories[categoryIndex]?.productTypes?.findIndex((type) => type.value === offerState.productType.value);
          (productTypeIndex >= 0) && setValue('productType', categories[categoryIndex].productTypes[productTypeIndex]);
          const productTypeMatch: any = categories[categoryIndex]?.productTypes[productTypeIndex];
          setValue('productType', {
            label: productTypeMatch?.label,
            value: productTypeMatch?.value,
            order: productTypeMatch?.order,
          });

          // PRODUCT (PUT PRODUCT SPECS) => pick from `products` matched item (before that convert to {value, label} obj)
          const convertedProducts = products.map((product: any) => ({ value: product.id.toString(), label: product.title }));
          const productIndex = convertedProducts.findIndex((product: any) => Number(product.value) === offerState.productId);
          (productIndex >= 0 && productTypeIndex >= 0) && setValue('product', convertedProducts[productIndex]);
          setProductValue(convertedProducts[productIndex]);
        } catch (error) {
          console.log(error);
        } finally {
          setDuplicateLoading(false);
        }
      })();
    }
  }, [offerState]);

  useEffect(() => {
    if (productSelect === null && productValue) {
      setValue('product', productValue);
    }

    return () => {
      setProductValue(null);
    };
  }, [productValue]);

  return { duplicateId, duplicateMessage, duplicateError, duplicateLoading };
};

export default useDuplicateAP;
