import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Preloader from 'SHARED/components/ThePreloader';
import { scrollToTop } from 'SHARED/helpers/common';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { useActions } from 'SHARED/redux/hooks/useActions';
import useIsForRfp from 'SHARED/hooks/useIsForRfp';
import PreventLossUnsavedData from 'SHARED/hooks/usePreventReload';
import routes from 'SHARED/types/routes';
import AlreadyProducedOfferSummary from '../OfferSummary';

const AlreadyProducedPreview: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { isForRfp, rfpID } = useIsForRfp();

  const { createOffer } = useActions();
  const { ON_SPEC } = useTypedSelector((state) => state.offer);

  PreventLossUnsavedData(true);

  const handleChangeOffer = () => {
    // history.goBack();
    if (isForRfp && rfpID) {
      history.push(routes.onspec.createAP_rfp(rfpID));
    } else {
      history.push(routes.onspec.createAP);
    }
  };

  useEffect(() => {
    if (!ON_SPEC.productCategory) {
      history.push(routes.onspec.createAP);
    }

    scrollToTop();
  }, []);

  const handlePlaceOrder = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();

    // console.log('ON_SPEC', ON_SPEC);
    // return false;

    setLoading(true);
    (async () => {
      if (await createOffer('ALREADY_PRODUCED')) {
        history.push('/');
        // setLoading(false);
      } else {
        setLoading(false);
      }
    })();
  };

  return (
    <>
      <Preloader isLoading={loading} />

      <div className="breadcrumbs">
        <a href={routes.onspec.listDefault}>&lt; All offers </a>
      </div>
      <div className="page-body is-logged-in preview-offer-page">

        <h1 className="page-title">Offer for already produced product</h1>

        <div className="attention-message inset">
          Great, you&apos;re almost done! Please review your offer before you continue.
        </div>

        <AlreadyProducedOfferSummary mode="preview" />

        <div className="attention-message success inset no-margin">
          <div className="page-section-title">Place this offer</div>
          <div>
            By placing this offer you agree to the OpenDairy platform terms.
            {' '}
            <br />
            If a buyer confirms your offer you have the legal obligation to sell.
            {' '}
            <br />
            Offers can be changed/cancelled at any time.
          </div>
          <div className="actions-section">
            <a href="##" className="btn-primary" onClick={(e) => handlePlaceOrder(e)}>Place offer</a>
            <button onClick={() => { handleChangeOffer(); }} type="button" className="btn-secondary">Change offer</button>
            <a href="/" className="btn-secondary">Discard</a>
          </div>
        </div>

      </div>
    </>
  );
};

export default AlreadyProducedPreview;
