/* eslint-disable react/jsx-props-no-spreading */
import React               from 'react';
import { Link }            from 'react-router-dom';
import routes              from 'SHARED/types/routes';
import { ProductListItem } from 'SHARED/types/specsTypes';
import texts               from 'SHARED/helpers/texts';
import SpecsTable          from './SpecificationsTable';

import                      './categories.scoped.scss';

interface SpecItemProps {
  name: string,
  category: string,
  products: ProductListItem[] | null
  openPopup: any,
}

const SpecItem: React.FC<SpecItemProps> = ({
  name, category, products, openPopup,
}) => (
  <div className="spec-item">

    <div className="spec-top">
      <h3>{name}</h3>
      <Link to={routes.common.createProduct(category)} className="btn-primary">{texts.specsTexts.common.addSpec}</Link>
    </div>

    {products && products.length > 0 && <SpecsTable products={products} openPopup={openPopup} />}
  </div>
);

export default SpecItem;
