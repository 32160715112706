import React, { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import putDocumentUserAction from 'SHARED/api/documents/put/putDocumentUserAction';
import FormInput from 'SHARED/components/FormInput';
import Popup from 'SHARED/components/Popup/Popup';
import Preloader from 'SHARED/components/ThePreloader';
import { useActions } from 'SHARED/redux/hooks/useActions';
import { IDocumentThread } from 'SHARED/validators/documents/documentSchema';

interface IProps {
  handleClose: () => void;
  dealId: string | number;
  document: IDocumentThread;
}

interface IFormValues {
  comment: string;
}

const RejectDocumentPopup: FC<IProps> = ({
  handleClose,
  dealId,
  document,
}) => {
  const { documentType } = document;
  const { getDocuments } = useActions();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const methods = useForm<IFormValues>();
  const { handleSubmit } = methods;

  function handleFormSubmit(values: IFormValues) {
    setIsLoading(true);

    const payload = {
      threadId: document.id,
      documentType: {
        code: documentType.code,
        title: documentType.title,
      },
      comment: values.comment,
    };

    putDocumentUserAction({ actionType: 'reject', dealId, payload })
      // if action was success - refetch fresh version of documents and close popup
      .then((result) => {
        setIsLoading(false);
        if (result === 'success') {
          getDocuments(dealId);
          handleClose();
        }
      });
  }

  return (
    <>
      <Preloader isLoading={isLoading} />

      <Popup handleClose={handleClose} isLoading={isLoading}>
        <FormProvider {...methods}>
          <h1>You are going to request changes to the document {documentType?.title}</h1>

          <p>
            The uploader will be notified and will be able to provide another version of the document
          </p>

          <form onSubmit={handleSubmit(handleFormSubmit)}>

            <FormInput
              name="comment"
              label="REASON FOR A NEW VERSION"
              tooltip="Please explain what needs to be changed in the document"
              rules={{ required: 'This field is required' }}
              textarea
            />

            <div className="btn-wrapper buttons-wrapper--mobile-full-width">
              <button type="submit" className="btn-primary">Request Update</button>
              <button type="button" className="btn-secondary" onClick={() => handleClose()}>Discard</button>
            </div>

          </form>
        </FormProvider>
      </Popup>
    </>
  );
};

export default RejectDocumentPopup;
