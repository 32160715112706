import { handleDate, handleDateRange, preparePrice } from 'SHARED/helpers/common';
import { OfferStatuses, OfferListItem, OfferTableItem } from 'SHARED/types/offerTypes';
import { OfferListTypes } from 'SHARED/pages/OffersList';

// to iterate trough it in table and cards
export const offspecBuyer = (offer: OfferListItem, activeTab: OfferListTypes) => {
  const {
    created = 0,
    title = '',
    totalVolume,
    price,
    loadDateFrom,
    loadDateTo,
    country,
    status,
    seller,
    ourLastBid,
    deal,
    currency,
    splittable,
  } = offer;

  const dateRange = () => handleDateRange({ from: loadDateFrom, to: loadDateTo });
  const volume = `${preparePrice(totalVolume)} mt`;
  const dealVolume = `${preparePrice(deal?.volume)} mt`;
  const statusLabel = OfferStatuses[status];

  // creating offer list item data
  // for table and cards
  const activeOffers: OfferTableItem[] = [
    { key: 'title', value: title, label: 'OFFER TITLE' },
    { key: 'volume', value: volume, label: 'VOLUME', isSplittable: splittable },
    { key: 'dateRange', value: dateRange(), label: 'PICK-UP DATES' },
    { key: 'seller', value: seller, label: 'SELLER' },
    { key: 'country', value: country, label: 'COUNTRY' },
    { key: 'price', value: price, prefix: currency, label: 'ASK PRICE' },
    { key: 'ourLastBid', value: ourLastBid, prefix: currency, label: 'OUR LAST BID' },
    { key: 'status', value: statusLabel, label: 'STATUS' },
  ];

  const deals: OfferTableItem[] = [
    { key: 'title', value: title, label: 'PRODUCT' },
    { key: 'volume', value: dealVolume, label: 'VOLUME', isSplittable: splittable },
    { key: 'dateRange', value: dateRange(), label: 'PICK-UP DATE RANGE' },
    { key: 'seller', value: seller, label: 'SELLER' },
    { key: 'placed', value: handleDate({ date: created }), label: 'DEAL DONE ON' },
    { key: 'price', value: deal?.price || 0, prefix: deal?.currency, label: 'DEAL PRICE' },
  ];

  if (activeTab === 'deals') {
    return deals;
  }

  return activeOffers;
};
