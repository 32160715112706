import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { tooltips } from 'SHARED/helpers/texts';
import Checkboxes from 'SHARED/components/Checkboxes';
import SingleCheckbox from 'SHARED/components/Checkbox';
import RadioGroup from 'SHARED/components/RadioGroup';
import Select from 'SHARED/components/Select';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import validationRules from 'SHARED/helpers/validation';
import { Dictionary, OnspecOfferTypes } from 'SHARED/types/offerTypes';
import { MpcDocLink } from 'SHARED/helpers/common';
import 'react-datepicker/dist/react-datepicker.css';
import { isMobile } from 'react-device-detect';
import ZonedDatePicker from 'SHARED/components/ZonedDatePicker/ZonedDatePicker';
import FormFileInput from 'SHARED/components/FileInput/FormFileInput';
import FormInput from 'SHARED/components/FormInput';
import OfferProductDetails from './OfferProductDetails';

interface Props {
  offerType: OnspecOfferTypes
  choosedProduct?: Dictionary,
}

const ProductSection: React.FC<Props> = ({ offerType, choosedProduct }) => {
  const offer = useTypedSelector((state) => state.offer.ON_SPEC);
  const { products } = useTypedSelector((state) => state.specs);
  const { dictionaries, locations } = useTypedSelector((state) => state.dictionaries);

  const methods = useFormContext();

  const {
    coaUsed,
    customLabelPossible,
    documentCapabilities,
    productionLocation,
    packaging,
    packages,
    salesTerms,
    documents,
    productionDate,
    expirationDate,
    productCharacteristics,
    endDate,
    description,
  } = offer;

  // 3 - watch form values section
  // for dynamic condition rendering and properly date validation
  const { watch } = methods;
  const productCategory = watch('productCategory');
  const choosedTermsType = watch('salesTerms', salesTerms);
  const choosedCoaUsed = watch('coaUsed', coaUsed);
  const offerEndDate = watch('endDate', endDate);

  const isOwnTermsChoosed = choosedTermsType === 'OWN';

  const { DOCUMENT_TYPE, SALES_TERMS } = dictionaries;

  // side effect to set default value (first packaging option from the list) when category is changed
  // without it select would be with prev value, even if it's not present in options
  useEffect(() => {
    if (productCategory && productCategory?.packagingOptions?.length > 0) {
      methods.setValue('packaging', productCategory?.packagingOptions[0]);
    }
  }, [productCategory]);

  return (
    <>
      <h2 className="page-section-title">Product details</h2>

      <FormInput
        name="description"
        textarea
        label="REMARKS FOR THE BUYER"
        value={description}
        tooltip={tooltips.offer_desc}
      />

      {offerType === 'ALREADY_PRODUCED' && (
        <>
          <SingleCheckbox
            title="DETAILED PRODUCT COA"
            label="Sell based on the Certificate of Analysis"
            name="coaUsed"
            defaultChecked={coaUsed}
            tooltip={tooltips.COA}
          />

          {choosedCoaUsed && (
          <FormFileInput
            name="coaDocument"
            label="PRODUCT COA DOCUMENT"
            defaultFiles={documents.COA}
            required={choosedCoaUsed}
            acceptedFileTypes={['application/pdf']}
          />
          )}

          {choosedProduct && products && (
            <div className="offer-right-side">
              <OfferProductDetails
                choosedCoaUsed={choosedCoaUsed}
                title={choosedProduct.label}
                productCharacteristics={productCharacteristics}
                characteristics={products.find((p) => p.title === choosedProduct.label)?.characteristics}
              />
            </div>
          )}
        </>
      )}

      <Checkboxes
        label="DOCUMENT CAPABILITIES"
        name="documentCapabilities"
        tooltip={tooltips.capabilities}
        values={DOCUMENT_TYPE}
        selected={documentCapabilities}
        className="two-columns"
      />

      <Select
        label="PRODUCTION LOCATION"
        name="productionLocation"
        options={locations.PRODUCTION}
        selected={productionLocation}
      />

      {productCategory && (
        <>
          {offerType === 'ALREADY_PRODUCED'
            ? (
              <Select
                label="PACKAGING"
                name="packaging"
                options={productCategory?.packagingOptions || []}
                selected={packaging}
                defaultValue={productCategory?.packagingOptions[0]?.value}
                // defaultValue={findCategoryPackaging(categories, productCategory.value)[0]?.value}
              />
            )
            : (
              <Checkboxes
                label="PACKAGING"
                name="packages"
                tooltip={tooltips.packaging}
                values={productCategory?.packagingOptions || []}
                selected={packages}
                className="two-columns"
              />
            )}
        </>
      )}

      <SingleCheckbox
        title="CUSTOM LABEL"
        label="Custom label possible"
        name="customLabelPossible"
        defaultChecked={customLabelPossible}
      />

      {offerType === 'ALREADY_PRODUCED' && (
        <div className="dates-range">
          <div className="dates-range-wrapper">
            <ZonedDatePicker
              label="PRODUCTION DATE"
              name="productionDate"
              minDate={null}
              maxDate={new Date()}
              value={productionDate}
              inline={isMobile}
              showTimezone={false}
            />
            <ZonedDatePicker
              label="EXPIRATION DATE"
              name="expirationDate"
              minDate={new Date()}
              rules={validationRules.endDate(offerEndDate, 'Must be later than offer expiration date')}
              value={expirationDate}
              inline={isMobile}
              showTimezone={false}
            />
          </div>
        </div>
      )}

      <RadioGroup
        label="SALES TERMS"
        name="salesTerms"
        defaultValue={salesTerms}
        values={SALES_TERMS}
      />

      <div className="form-input">
        <div className="label-text">SALES TERMS DOCUMENT *</div>

        {!isOwnTermsChoosed && (
          <a href={MpcDocLink} target="_blank" rel="noreferrer" className="document-link">
            <i className="icon-flag" />
            <span>MPC sales terms</span>
          </a>
        )}

      </div>

      {isOwnTermsChoosed && (
        <div>
          <FormFileInput
            name="termsDocument"
            defaultFiles={documents.SALES_TERMS}
            acceptedFileTypes={['application/pdf']}
            required={isOwnTermsChoosed}
          />
        </div>
      )}

    </>
  );
};

export default ProductSection;
