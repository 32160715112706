import React from 'react';

export interface IProps {
  products: string[],
}

const MatchedProducts: React.FC<IProps> = ({ products }) => (
  <>
    <div className="matched-products" title={`matched to: ${products.join(', ')}`}>
      <i className="icon-matched" />
      <span className="matched-products-list">
        <div>matched to:</div>
        {products.map((p) => <span key={p}>{`"${p}"`}</span>)}
      </span>
    </div>

  </>
);
export default MatchedProducts;
