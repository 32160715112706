import { axios_Core } from 'SHARED/helpers/axios';
import notification from 'SHARED/helpers/notifications';
import { OfferBid } from 'SHARED/types/bidTypes';
import { OfferEndpoints } from 'SHARED/types/routes';

interface IBidHistoryArgs {
  offerId: number | string
  buyerId: number
}

export const getBidHistory = async (args: IBidHistoryArgs) => {
  const { offerId, buyerId } = args;

  try {
    const response = await axios_Core.get<OfferBid[]>(OfferEndpoints.bidHistory, { params: { offerId, buyerId } });

    return {
      data: response.data,
      error: null,
    } as const;
  } catch (error) {
    console.log('bidHistory error', error);

    notification({
      type: 'danger',
      title: 'Error',
      message: 'Could not get bid history...',
    });

    return {
      data: null,
      error: 'Could not get bid history...',
    } as const;
  }
};
