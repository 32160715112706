import { axios_Documents } from 'SHARED/helpers/axios';
import notification from 'SHARED/helpers/notifications';
import { ICodeTitle } from 'SHARED/validators/common/codeTitleSchema';
import { AxiosResponse } from 'axios';

interface IParams {
  actionType: 'approve' | 'reject';
  dealId: string | number;
  payload: {
    threadId: number;
    documentType: ICodeTitle
    comment?: string;
  }
}

async function putDocumentUserAction(params: IParams) {
  const { actionType, dealId, payload } = params;
  const isApprove = actionType === 'approve';
  const message = isApprove ? 'Document accepted successfully' : 'Request to update document has been sent';

  try {
    const response: AxiosResponse<any> = await axios_Documents.put(`/v1/documents/${dealId}/${actionType}`, payload);

    if (response.status === 200) {
      notification({
        type: 'success',
        message,
      });

      return 'success';
    }

    throw new Error('Document put action error');
  } catch (error) {
    console.error(error);

    return 'error';
  }
}

export default putDocumentUserAction;
