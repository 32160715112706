import React from 'react';

export interface Props {
  setValue: any,
  value: boolean,
  label: string,
}

const OutOfFormCheckbox: React.FC<Props> = ({ setValue, value, label }) => (
  <div className="only-mathced-checkbox">
    <div className="custominput">
      <input
        type="checkbox"
        id="matched"
        checked={value}
        // onClick={() => { setValue(!value); }}
        onChange={() => { setValue(!value); }}
      />

      <label htmlFor="matched">
        {label}
        {/* Only show matching offers */}
      </label>
    </div>

  </div>
);

export default OutOfFormCheckbox;
