import { axios_Core } from 'SHARED/helpers/axios';
import { OfferEndpoints } from 'SHARED/types/routes';

async function postOffer(payload: any) {
  try {
    const response = await axios_Core.post(OfferEndpoints.create, payload);

    if (response.status === 200) {
      return 'success';
    }

    throw new Error('Post offer error');
  } catch (error) {
    console.error('Post offer error:', error);

    return 'error';
  }
}

export default postOffer;
