import { handleDateRange } from 'SHARED/helpers/common';
import { OfferListItem, OfferTableItem } from 'SHARED/types/offerTypes';
import { OfferListTypes } from 'SHARED/pages/OffersList';
import { dateWithTimezone } from 'SHARED/helpers/dates';

// to iterate trough it in table and cards
export const onspecSeller = (
  offer: OfferListItem,
  activeTab: OfferListTypes,
  userTimezone: string = 'Europe/Amsterdam',
) => {
  const {
    created,
    price,
    departurePeriod,
    remainingVolume,
    departureFrom,
    departureTo,
    productCategory,
    productType,
    splittable,
    statusLabel,
    productTitle,
    deal,
    type,
    currency,
    anonymous,
  } = offer;

  const listingFormat = 'DD MMM YYYY';
  const monthFormat = 'MMM YYYY';

  const volume = `${remainingVolume?.toString().replace('.', ',')} mt`;
  const departure = departurePeriod === 'MONTH' ? dateWithTimezone(departureFrom, userTimezone, monthFormat) : handleDateRange({ from: departureFrom, to: departureTo });
  const logistics = deal?.shipping?.logisticHandler?.label || deal?.shipping?.logisticHandlerType.label;
  const placed = dateWithTimezone(created, userTimezone, listingFormat);

  const productTitleValue = productTitle ? `(${productTitle})` : null;
  const fullProductTitle = [productCategory, productType, productTitleValue]
    .filter((part) => part)
    .join(' ');

  // creating offer list item data
  // for table and cards
  const activeOffers: OfferTableItem[] = [
    { key: 'placed', value: placed, label: 'PLACED' },
    { key: 'type', value: type, label: 'OFFER TYPE', isAnonymous: anonymous },
    { key: 'product', value: fullProductTitle, label: 'PRODUCT SPEC' },
    { key: 'volume', value: volume, label: 'REMAINING VOLUME', isSplittable: splittable },
    { key: 'dateRange', value: departure, label: 'DEPARTURE' },
    { key: 'price', value: price, label: 'Price excl. Premiums', prefix: currency },
    { key: 'status', value: statusLabel, label: 'STATUS' },
  ];
  const archivedOffers: OfferTableItem[] = [
    { key: 'placed', value: placed, label: 'PLACED' },
    { key: 'type', value: type, label: 'OFFER TYPE', isAnonymous: anonymous },
    { key: 'product', value: fullProductTitle, label: 'PRODUCT SPEC' },
    { key: 'volume', value: volume, label: 'REMAINING VOLUME', isSplittable: splittable },
    { key: 'dateRange', value: departure, label: 'DEPARTURE' },
    { key: 'price', value: price, label: 'Price excl. Premiums', prefix: currency },
    { key: 'status', value: statusLabel, label: 'STATUS' },
  ];
  const deals: OfferTableItem[] = [
    { key: 'placed', value: dateWithTimezone(deal?.created, userTimezone, listingFormat), label: 'DEAL DONE ON' },
    { key: 'type', value: type, label: 'OFFER TYPE', isAnonymous: anonymous },
    { key: 'title', value: fullProductTitle, label: 'PRODUCT' },
    { key: 'price', value: deal?.price, label: 'DEAL PRICE', prefix: deal?.currency },
    { key: 'volume', value: `${deal?.volume} mt`, label: 'VOLUME' },
    { key: 'buyer', value: deal?.buyerOrg.orgName, label: 'BUYER' },
    { key: 'logistics', value: logistics, label: 'LOGISTIC BY' },
    { key: 'dateRange', value: departure, label: 'DEPARTURE' },
  ];

  if (activeTab === 'archived-offers') {
    return archivedOffers;
  }
  if (activeTab === 'deals') {
    return deals;
  }

  return activeOffers;
};
