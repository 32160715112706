import React, { useEffect } from 'react';
import Profile from 'SHARED/pages/Profile';
import Oauth from 'SHARED/pages/Oauth';
import {
  Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { useActions } from 'SHARED/redux/hooks/useActions';
import routes from 'SHARED/types/routes';
import history from 'SHARED/helpers/history';
import texts from 'SHARED/helpers/texts';
import { devInfo } from 'SHARED/info';
import Preloader from 'SHARED/components/ThePreloader';

import OffersList from 'SHARED/pages/OffersList';
import OffSpecOfferCreate from 'pages/OffSpecOffer/CreateOffspec';
import OffspecOfferPage from 'pages/OffSpecOffer/OffspecOfferPage';
import OffspecDealPage from 'pages/OffSpecDeal/OffspecDealPage';
import PreviewOffspecOffer from 'pages/OffSpecOffer/PreviewOffspec';

import AlreadyProducedCreate from 'pages/Onspec/AlreadyProduced/Create';
import AlreadyProducedPreview from 'pages/Onspec/AlreadyProduced/OfferPreview';
import OnspecOfferDetails from 'pages/Onspec/OfferDetails';
import OnspecDealDetails from 'pages/Onspec/DealDetails';

import ToBeProducedCreate from 'pages/Onspec/ToBeProduced/Create';
import ToBeProducedPreview from 'pages/Onspec/ToBeProduced/OfferPreview';
import OnSpecEdit from 'pages/Onspec/OfferEdit';
import RfpDetailPage from 'pages/RFP/RfpDetailPage';

import ProductSpecifications from 'SHARED/pages/ProductCategoriesPage';
import BuyerGroups from 'pages/BuyerGroups/List';
import GroupDetails from 'pages/BuyerGroups/Details';
import { Toaster } from 'react-hot-toast';
import CreateProduct from 'SHARED/pages/ProductCreatePage';
import OfferProvideDocument from 'SHARED/pages/Documents/OfferProvideDocument';
import PreviewProduct from 'SHARED/pages/ProductPreviewPage';
import { SimpleOfferPreview } from 'SHARED/pages/simple-offer/preview';
import { SimpleOfferCreate } from 'SHARED/pages/simple-offer/create';
import ReactNotification from 'react-notifications-component';
import TheHeader from './SHARED/components/TheHeader';
import TheLogo from './SHARED/components/TheLogo';
import 'react-notifications-component/dist/theme.css';

const App = () => {
  const { me, loading } = useTypedSelector((state) => state.users);
  const { isLoggedIn, isLoading } = useTypedSelector((state) => state.oauth);

  const { getUser } = useActions();

  // it's impossible to use router hook in upper scope TODO: refactor needed
  // check if current page is not oauth app's page
  const url = window.location.href;
  const notOauthPage = url.indexOf(routes.auth.oauthLogin) === -1;

  useEffect(() => {
    // get user info if user has logged in
    if (isLoggedIn) {
      getUser();
    }

    // if not logged in and the page is not oauth page
    // redirect to oauth service
    if (!isLoggedIn && notOauthPage) {
      console.log('!isLoggedIn && notOauthPage');
      localStorage.setItem('route', window.location.pathname);

      if (window.location.hostname === 'localhost') {
        console.log('⚠️ [DEV] Set access token to local storage');
      } else {
        window.location.href = `${routes.auth.oauth}/login`;
      }
    }
  }, [isLoggedIn]);

  const userName = `${me.profile?.firstName} ${me.profile?.middleName || ''} ${me.profile?.lastName}`;

  return (
    <>
      <Preloader isLoading={isLoading || loading} />

      <Router history={history}>

        <Toaster
          position="bottom-right"
          toastOptions={{
            duration: 5000,
          }}
        />

        <ReactNotification id="1" />
        {isLoggedIn
          ? (
            <TheHeader
              orgName={me.organization?.orgName}
              userName={userName}
            />
          )
          : <TheLogo />}

        <div className={`container ${isLoggedIn ? 'is-logged-in' : ''}`}>

          <Switch>
            {isLoggedIn && <Redirect from="/" to={routes.onspec.listDefault} exact />}
            {isLoggedIn && <Redirect from={routes.auth.oauthLogin} to={routes.onspec.listDefault} exact />}

            <Route exact path={[routes.rfp.details, routes.rfp.archivedDetails]}>
              <RfpDetailPage />
            </Route>

            <Route exact path={routes.rfp.proposalDetails}>
              <OnspecOfferDetails />
            </Route>

            <Route exact path={routes.common.profile}>
              <Profile />
            </Route>

            <Route exact path={routes.auth.oauthLogin}>
              <Oauth />
            </Route>

            <Route exact path={routes.offspec.create}>
              <OffSpecOfferCreate isLoggedIn={isLoggedIn} />
            </Route>

            {/* ordering os important DEAL must be first */}
            <Route exact path={routes.offspec.dealDetail}>
              <OffspecDealPage isLoggedIn={isLoggedIn} />
            </Route>

            <Route
              exact
              path={[
                // regular offers
                routes.onspec.dealDetail,
                routes.onspec.dealDetail_documents,

                // RFPs
                routes.rfp.dealDetail,
                routes.rfp.dealDetail_documents,
              ]}
            >
              <OnspecDealDetails />
            </Route>

            <Route
              exact
              path={[
                routes.onspec.provideDocument,
                routes.rfp.provideDocument,
              ]}
            >
              <OfferProvideDocument />
            </Route>

            <Route exact path={routes.offspec.offerDetail}>
              <OffspecOfferPage isLoggedIn={isLoggedIn} />
            </Route>

            <Route exact path={routes.offspec.preview}>
              <PreviewOffspecOffer />
            </Route>

            <Route exact path={routes.common.specifications}>
              <ProductSpecifications
                title={texts.specsTexts.seller.title}
                description={texts.specsTexts.seller.description}
              />
            </Route>

            <Route exact path={routes.common.buyerGroups}>
              <BuyerGroups />
            </Route>

            <Route exact path={routes.common.createGroup}>
              <GroupDetails />
            </Route>

            <Route exact path={routes.common.groupDetails()}>
              <GroupDetails />
            </Route>

            <Route exact path={routes.common.createProduct()}>
              <CreateProduct />
            </Route>

            <Route exact path={routes.common.productDetails()}>
              <PreviewProduct />
            </Route>

            <Route exact path={routes.onspec.create_simple_offer}>
              <SimpleOfferCreate />
            </Route>

            <Route exact path={routes.onspec.preview_simple_offer}>
              <SimpleOfferPreview />
            </Route>

            <Route exact path={routes.onspec.createAP}>
              <AlreadyProducedCreate isLoggedIn={isLoggedIn} />
            </Route>

            <Route exact path={routes.onspec.previewAP}>
              <AlreadyProducedPreview />
            </Route>

            <Route exact path={routes.onspec.edit}>
              <OnSpecEdit />
            </Route>

            <Route exact path={routes.onspec.createTP}>
              <ToBeProducedCreate />
            </Route>

            <Route exact path={routes.onspec.previewTP}>
              <ToBeProducedPreview />
            </Route>

            <Route exact path={routes.onspec.details}>
              <OnspecOfferDetails />
            </Route>

            <Route exact path="/dev-info">
              <div>{`Library branch - ${devInfo}`}</div>
            </Route>

            {/* unclear/dynamic path's should be at the end */}
            <Route exact path={routes.offersList}>
              <OffersList isLoggedIn={isLoggedIn} portal="SELLER" />
            </Route>

          </Switch>

        </div>

      </Router>
    </>
  );
};

export default App;
