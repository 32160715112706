import React                  from 'react';
import { CharacteristicItem } from 'SHARED/types/specsTypes';
import                        _ from 'lodash';
import CharacteristicPreview  from 'SHARED/pages/ProductPreviewPage/ProductSpecificationPreview';
import CharacteristicInput    from 'SHARED/components/CharacteristicInput';

interface Props {
  characteristics: CharacteristicItem[] | undefined | null,
  productCharacteristics: any[] | null,
  title: string,
  choosedCoaUsed: boolean,
}

const OfferProductDetails: React.FC<Props> = ({
  characteristics, title, choosedCoaUsed, productCharacteristics,
}) => {
  const sortedCharacteristics = _.sortBy(characteristics, 'displayGroup.order');
  const groupedCharacteristics = _.groupBy(sortedCharacteristics, 'displayGroup.value');

  return (
    <>
      {choosedCoaUsed ? (
        <h2 className="page-section-title">
          Our Certificate of Analysis
        </h2>
      )
        : (
          <h2 className="page-section-title">
            {`Our product Specifications: ${title}`}
          </h2>
        )}

      {Object.keys(groupedCharacteristics).map((key) => (

        <div key={key} className="isCoaUsed">
          <strong>
            {groupedCharacteristics[key][0].displayGroup.label}
          </strong>
          {' '}
          <br />
          <br />

          {groupedCharacteristics[key].map((ch) => (
            <div key={ch.id}>
              {choosedCoaUsed ? (
                <CharacteristicInput
                  key={ch.id}
                  characteristic={ch}
                  isForBuyer={false}
                  isCoaUsed
                  inputsGroup="productCharacteristics"
                  prefilledItem={productCharacteristics?.find((c) => c?.characteristicSpecId === ch?.characteristicSpecId)}
                />
              ) : (
                <CharacteristicPreview
                  key={ch.id}
                  characteristic={ch}
                />
              )}

            </div>
          ))}
          <br />
          <br />
        </div>
      ))}
    </>
  );
};

// export default memo(OfferProductDetails, (prev, next) => prev.choosedCoaUsed === next.choosedCoaUsed && prev.title === next.title);
export default OfferProductDetails;
