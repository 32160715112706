import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import FormMultiSelect from 'SHARED/components/MultiSelect';
import validationRules from 'SHARED/helpers/validation';
import { BuyersFetchType, BuyersFetchTypes, OnspecOfferTypes } from 'SHARED/types/offerTypes';
import Radio from 'SHARED/components/Radio';
import { buyersFetchType } from 'SHARED/helpers/OfferData';
import { isMobile } from 'react-device-detect';
import ZonedDatePicker from 'SHARED/components/ZonedDatePicker/ZonedDatePicker';
import { Link } from 'react-router-dom';

interface Props {
  offerType: OnspecOfferTypes,
  isForRfp: boolean
}

const BottomSection: React.FC<Props> = ({ offerType, isForRfp }) => {
  // ********************* REDUX section *********************
  const offer = useTypedSelector((state) => state.offer.ON_SPEC);
  const { dictionaries } = useTypedSelector((state) => state.dictionaries);
  const { expirationDate, endDate, buyers, groups, departureTo } = offer;
  const { BUYERS_LIST, BUYERS_GROUPS_LIST } = dictionaries;

  const methods = useFormContext();
  const { watch, setValue, clearErrors } = methods;
  const choosedBuyersFetchType: BuyersFetchType = watch('buyersFetchType', offer.buyersFetchType.value);
  const choosedDeparturePeriod = watch('departurePeriod');
  const choosedDepartureFromMonth = watch('departureFromMonth');
  const choosedExpirationDate = watch('expirationDate', expirationDate);
  const choosedDepartureTo = watch('departureTo', departureTo);

  const currentDepartureDate = choosedDeparturePeriod === 'MONTH' ? choosedDepartureFromMonth.value : choosedDepartureTo;

  const isIncludeBuyerGroups = choosedBuyersFetchType === BuyersFetchTypes.includeGroup;
  const isExcludeBuyers = choosedBuyersFetchType === BuyersFetchTypes.exclude;
  const isHaveBuyerGroups = !!BUYERS_GROUPS_LIST?.length;

  useEffect(() => {
    setValue('buyersFetchType', offer.buyersFetchType.value); // TODO: investigate how to remove this so field must be updating automatically
  }, []);

  // in case user changed radio button back to include all
  // buyers list must be cleared
  useEffect(() => {
    clearBuyerLists();
  }, [choosedBuyersFetchType]);

  useEffect(() => {
    handleDatesValidation();
  }, [choosedDeparturePeriod]);

  const clearBuyerLists = () => {
    if (choosedBuyersFetchType !== offer.buyersFetchType.value) {
      setValue('buyers', []);
      setValue('groups', []);
    }
  };

  const handleDatesValidation = () => {
    if (choosedDeparturePeriod === 'MONTH') {
      const date = new Date(choosedDepartureTo || new Date());
      const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      setValue('departureTo', lastDayOfMonth);
      clearErrors('endDate');
    }
  };

  const buyersValidation = {
    required: (
      choosedBuyersFetchType === 'EXCLUDE'
        ? 'Select at least one buyer'
        : false
    ),
    validate: validationRules.notAllSelected(BUYERS_LIST?.length),
  };

  return (
    <>

      {/* *************************** bottom section *************************** */}

      {!isForRfp && (
        <>
          {' '}
          <h2 className="page-section-title">
            Buyers
          </h2>

          <div className="form-input"><label htmlFor="">BUYERS WHO SEE THIS OFFER *</label></div>

          <Radio
            selected={choosedBuyersFetchType}
            name="buyersFetchType"
            value={BuyersFetchTypes.includeAll}
            label={buyersFetchType[0].label}
          />
          <br />

          <Radio
            selected={choosedBuyersFetchType}
            name="buyersFetchType"
            value={BuyersFetchTypes.includeGroup}
            label={buyersFetchType[1].label}
          />
          <br />

          {isIncludeBuyerGroups && isHaveBuyerGroups && (
            <FormMultiSelect
              name="groups"
              options={BUYERS_GROUPS_LIST}
              value={groups}
            />
          )}
          {isIncludeBuyerGroups && !isHaveBuyerGroups && (
            <div className="attention-message">
              {'You have no Buyer Groups yet, '}
              <Link to="/create-buyers-group">
                create
              </Link>
              {' one now.'}
            </div>
          )}

          <Radio
            selected={choosedBuyersFetchType}
            name="buyersFetchType"
            value={BuyersFetchTypes.exclude}
            label={buyersFetchType[2].label}
          />
          <br />

          {isExcludeBuyers && (
            <FormMultiSelect
              name="buyers"
              rules={buyersValidation}
              options={BUYERS_LIST}
              value={buyers}
            />
          )}
        </>
      )}

      <h2 className="page-section-title">
        Offer expiration
      </h2>

      <ZonedDatePicker
        inline={isMobile}
        name="endDate"
        showTimeInput
        maxDate={choosedExpirationDate}
        rules={validationRules.startDate(currentDepartureDate, 'Must be earlier than departure end date')}
        value={endDate}
        dateFormat="dd MMMM yyyy HH:mm"
        showTimezone
      />
    </>
  );
};

export default BottomSection;
