import React, { FC, useState } from 'react';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import Popup from 'SHARED/components/Popup/Popup';
import FormattedPrice from 'SHARED/components/Price';
import { preparePriceInputValue } from 'SHARED/helpers/common';
import { FormProvider, useForm } from 'react-hook-form';
import { SingleCheckbox } from 'SHARED/components/Checkbox';
import postOffer from 'SHARED/api/offers/post/postOffer';
import notification from 'SHARED/helpers/notifications';
import Preloader from 'SHARED/components/ThePreloader';
import { IProposalPayload } from 'SHARED/types/offerTypes';

interface IProps {
  payload: IProposalPayload;
  handleClose: (isSuccess: boolean) => void;
  currency?: string | null;
}

interface IFormValues {
  acceptTerms: boolean;
}

const RfpSubmitPopup: FC<IProps> = ({
  payload,
  handleClose,
  currency = 'EUR',
}) => {
  const {
    totalVolume,
    priceEur,
    priceUsd,
  } = payload;

  const price = priceEur || priceUsd || 0;

  const totalPrice = preparePriceInputValue(price) * preparePriceInputValue(totalVolume);

  const methods = useForm<IFormValues>();
  const { handleSubmit } = methods;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function handleFormSubmit(values: IFormValues) {
    setIsLoading(true);

    await postOffer(payload)
      .then((result) => {
        if (result === 'success') {
          notification({
            type: 'success',
            title: 'RFP Proposal',
            message: 'RFP Proposal placed successfully',
          });

          handleClose(true);
        } else {
          notification({
            type: 'danger',
            title: 'RFP Proposal',
            message: 'Could not place RFP Proposal...',
          });

          setIsLoading(false);
          // handleClose(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
        notification({
          type: 'danger',
          title: 'RFP Proposal',
          message: 'Could not place RFP Proposal...',
        });

        handleClose(false);
      });
  }

  if (!payload) {
    return null;
  }

  return (
    <>
      <Preloader isLoading={isLoading} />

      <Popup handleClose={() => handleClose(false)} isLoading={isLoading}>
        <h1>You are about to place a proposal</h1>

        <DataItemPreview title="Volume" item={totalVolume} suffix="mt" />
        <FormattedPrice title="Price" value={price} prefix={currency} suffix="/mt" />
        <FormattedPrice title="Total proposal price" value={totalPrice} prefix={currency} />

        <br />

        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <SingleCheckbox
              label="I understand that my offer is firm until the validity date"
              name="acceptTerms"
              rules={{ required: 'This field is mandatory' }}
            />

            <button
              type="submit"
              className="btn-primary"
            >
              Place proposal
            </button>

            <button
              type="button"
              className="btn-secondary"
              onClick={() => handleClose(false)}
              disabled={isLoading}
            >
              Back to proposal
            </button>
          </form>
        </FormProvider>
      </Popup>
    </>
  );
};

export default RfpSubmitPopup;
