import clsx from 'clsx';
import React from 'react';
import prepareDataAttr from 'SHARED/helpers/prepareDataAttr';
import validationRules from 'SHARED/helpers/validation';
import NumericInput from '../NumericInput';

export interface Props {
  label?: string,
  value: number | undefined
  disabled?: boolean,
  name?: string,
  hasMaxValue: boolean,
  maxValue?: number,
  rules?: any,
  tooltipText?: string,
  testingName?: string,
  placeholder?: string,
  className?: string,
}

const VolumeInput: React.FC<Props> = ({
  label = 'Bid volume', // ? it was 'Bid volume' hardcoded before so it is a default now
  value,
  disabled = false,
  name = 'form.volume',
  hasMaxValue,
  maxValue,
  rules = validationRules.required,
  tooltipText,
  testingName,
  placeholder,
  className,
}) => (
  <>
    <div
      className={clsx('form-input numeric short', className)}
      data-testing={prepareDataAttr(testingName || name)}
    >
      <label htmlFor="acceptVolume">
        {label} *
        <div className="tooltip-wrapper">
          <i className="icon-question" />
          <div className="tooltip-text">{tooltipText || 'Depends per logistics options'}</div>
        </div>
      </label>

      <div className={clsx('form-input-wrapper', disabled && 'disabled')}>
        <NumericInput
          name={name}
          rules={rules}
          value={value}
          decimalSeparator="."
          decimalScale={2}
          isDecimal
          maxValue={
            hasMaxValue
              // legacy code coverage, `value` was used as `maxValue` before
              ? (maxValue || value)
              : 99999999999
          }
          placeholder={placeholder}
        />
        <div className="suffix">MT</div>
      </div>
    </div>
  </>
);

export default VolumeInput;
