import React, { useState } from 'react';
import CounterOfferModal from 'modals/CounterOfferModal';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { useActions } from 'SHARED/redux/hooks/useActions';
import { Dictionary } from 'SHARED/types/offerTypes';
import BidNotValidModal, { NonValidStatuses } from 'modals/BidNotValidModal';
import SuccessAcceptModal from 'modals/SuccessAcceptModal';
import errorCodes from 'SHARED/helpers/codes';
import AcceptBidSummaryModal from 'modals/AcceptBidSummaryModal';
import Preloader from 'SHARED/components/ThePreloader';
import BidHistoryModal from 'SHARED/modals/BidHistoryModal';
import notification from 'SHARED/helpers/notifications';
import axios from 'SHARED/helpers/axios';
import { OfferBid } from 'SHARED/types/bidTypes';
import { ICounterOffer } from 'SHARED/types/models/ICounterOffer';
import { getBidHistory } from 'SHARED/api/offers/get/getBidHistory';
import OfferBidItem from './OfferBidItem';

interface OfferProduct {
  offerBids: OfferBid[]
  offerId: number
  offerVersion: string
  priceEur: number
  priceUsd: number
  productCategory: Dictionary
  productCharacteristics: any
  productId: number
  productTitle: string
  productType: Dictionary
}

interface Props {
  bids: OfferBid[]
  products: OfferProduct[]
}

const OfferBids: React.FC<Props> = ({ bids, products }) => {
  const { ON_SPEC: { id, status, type } } = useTypedSelector((state) => state.offer);
  const { getOfferDetails } = useActions();

  const [loading, setLoading] = useState(false);
  const [bidDetails, setBidDetails] = useState<null | OfferBid>(null);
  const [history, setHistory] = useState<OfferBid[] | null>(null);
  const [isPopup, setIsPopup] = useState(false);
  const [acceptResult, setAcceptResult] = useState<{ dealId: number } | null>(null);
  const [isNotValidPopup, setIsNotValidPopup] = useState<null | NonValidStatuses>(null);
  const [isSuccessPopup, setIsSuccessPopup] = useState(false);
  const [isAcceptPopup, setIsAcceptPopup] = useState(false);

  // flags
  const isToBeProduced = type?.value === 'TO_BE_PRODUCED_BUNDLE';
  const isSimpleOffer = type?.value === 'SIMPLE_ALREADY_PRODUCED';
  // flags - end

  const showValidationMessage: (message: string) => void = (message) => {
    if (message === errorCodes.expiredOffer || message === errorCodes.outdatedOffer) {
      setIsNotValidPopup('EXPIRED');
    }
    if (message === errorCodes.notValidBid) {
      setIsNotValidPopup('NOT_VALID');
    }
    // TODO: handle default behavior
  };

  const showNotificationMessage: () => void = () => {
    notification({
      title: 'Error',
      message: 'Something went wrong',
    });
  };

  const handleNegotiationError = (error: any) => {
    if (error.status === 400) {
      showValidationMessage(error.data.message);
    } else {
      showNotificationMessage();
    }
  };

  const handleCounterOffer = (bid: OfferBid) => {
    const offerId = type?.value === 'TO_BE_PRODUCED_BUNDLE' ? bid.offerId : id;
    // @ts-expect-error
    setBidDetails({ ...bid, offerId });
    setIsPopup(true);
  };

  const AcceptOffer = async () => { // TODO: make it as in buyer portal
    setLoading(true);
    if (bidDetails) {
      const preparedBid = PrepareBid(bidDetails);

      try {
        const result = await axios.post('v1/negotiation/accept?commit=true', preparedBid);
        setAcceptResult(result.data);
        setIsAcceptPopup(false);
        setIsSuccessPopup(true);
      } catch (error: any) {
        handleNegotiationError(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const viewBidHistory = (offerId: number, buyerId: number) => {
    setLoading(true);
    (async () => {
      const result = await getBidHistory({ offerId, buyerId });
      setHistory(result.data);
      setLoading(false);
    })();
  };

  const PrepareBid = (bid: OfferBid) => {
    const offerId = isToBeProduced ? bid.offerId : id;

    const preparedBid: ICounterOffer = {
      volume: bid.volume,
      price: bid.totalPrice,
      message: bid.message,
      bidId: bid.id,
      offerId,
      offerVersion: bid.offerVersion || null,
    };
    return preparedBid;
  };

  const handleCloseSuccess = () => {
    setIsSuccessPopup(false);
    getOfferDetails({ id, offerType: 'ON_SPEC' });
  };

  const validateAcceptBid = async (bid: OfferBid) => {
    setBidDetails(bid);
    setLoading(true);

    const preparedBid = PrepareBid(bid);

    try {
      await axios.post('v1/negotiation/accept?commit=false', preparedBid);
      setIsAcceptPopup(true);
    } catch (error: any) {
      handleNegotiationError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Preloader isLoading={loading} />

      <div>
        {products.map((product, index: number) => (
          <div className="product-bundle" key={index}>
            {isToBeProduced && (
              <div className="page-section-title">
                {`Product package ${index + 1}. ${product.productTitle}`}
              </div>
            )}

            {status === 'ACTIVE' && product?.offerBids?.length === 0 && (
              <div className="attention-message">
                There are no bids yet
              </div>
            )}

            {status === 'ARCHIVED' && product?.offerBids?.length === 0 && (
              <div className="attention-message">
                Offer expired, no bids or confirmations
              </div>
            )}

            {status === 'CANCELLED' && product?.offerBids?.length === 0 && (
              <div className="attention-message">
                No bids or confirmations
              </div>
            )}

            <div className="offer-bids">
              {product?.offerBids?.map((bid) => (
                <OfferBidItem
                  key={bid.id}
                  bid={bid}
                  offerVersion={product.offerVersion}
                  offerStatus={status}
                  history={history}
                  validateAcceptBid={validateAcceptBid}
                  handleCounterOffer={handleCounterOffer}
                  viewBidHistory={viewBidHistory}
                  setHistory={setHistory}
                  isSimpleOffer={isSimpleOffer}
                />
              ))}
            </div>

          </div>
        ))}

      </div>

      {isPopup && bidDetails && (
        <CounterOfferModal
          setIsNotValidPopup={setIsNotValidPopup}
          handleClose={setIsPopup}
          bid={bidDetails}
        />
      )}

      {isAcceptPopup && bidDetails && (
        <AcceptBidSummaryModal
          offerVolume={bidDetails?.volume}
          offerPrice={bidDetails?.netPrice}
          extraCosts={0}
          currency={bidDetails?.currency}
          handleClose={setIsAcceptPopup}
          acceptBid={AcceptOffer}
        />
      )}

      {isNotValidPopup
        && <BidNotValidModal type={isNotValidPopup} handleClose={setIsNotValidPopup} />}

      {isSuccessPopup && (
        <SuccessAcceptModal
          handleClose={handleCloseSuccess}
          dealId={acceptResult?.dealId || null}
        />
      )}

      {history && !!history.length
        && (
          <BidHistoryModal
            handleClose={setHistory}
            history={history}
            buyer={history[0]?.buyerOrg}
          />
        )}
    </>
  );
};

export default OfferBids;
