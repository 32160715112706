import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, Link, useParams } from 'react-router-dom';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { useActions } from 'SHARED/redux/hooks/useActions';
import Preloader from 'SHARED/components/ThePreloader';
import routes from 'SHARED/types/routes';
import { sortBy } from 'lodash';
import RfpSummary from './partials/RfpSummary';
import RfpProposalCard from './partials/RfpProposalCard';
import RfpProposalForm from './partials/RfpProposalForm';

interface RouteParams {
  id: string,
}

const RfpDetailPage: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<RouteParams>();

  const { RFP, loading } = useTypedSelector((state) => state.offer);
  const { getOfferDetails, resetOffer } = useActions();

  const {
    productType,
    productCategory,
    proposals,
    minVolume,
    maxVolume,
    ownerOrg,
    isCanAddProposal,
    status,
    version,
  } = RFP;

  // ? all these manipulations with order are needed for correct sorting of proposals
  // ? and title with correct order number
  const proposalsToRender = useMemo(() => {
    const withOrder = [...proposals]
      .reverse()
      .map((item, index) => ({ ...item, order: index + 1 }));

    const sorted = sortBy(withOrder, (item) => {
      // ? if RFP is active, show active proposals first
      if (status === 'ACTIVE') {
        return !(item?.status === 'ACTIVE');
      }

      // ? if RFP is not active, show proposal with deal first
      return !item.deal;
    });

    return sorted;
  }, [version]);

  useEffect(() => {
    fetchOfferDetails();

    return () => {
      resetOffer();
    };
  }, []);

  const fetchOfferDetails = async () => {
    try {
      getOfferDetails({ id, offerType: 'RFP' });
    } catch (err) {
      history.push(routes.rfp.list);
    }
  };

  const RFP_TITLE_VOLUME = (minVolume === maxVolume) ? `${minVolume} mt` : `${minVolume} - ${maxVolume} mt`;

  const RFP_TITLE = `RFP from ${ownerOrg?.orgName}: ${productCategory?.label} ${productType?.label}, ${RFP_TITLE_VOLUME}`;

  const isActive = status === 'ACTIVE';
  const isHaveProposals = proposals && proposals.length > 0;

  return (
    <>
      <Helmet>
        <title>{RFP_TITLE}</title>
      </Helmet>

      <Preloader isLoading={loading} />

      <div className="breadcrumbs">
        <Link to={isActive ? routes.rfp.defaultList : routes.rfp.archivedList}>&lt; All rfps </Link>
      </div>
      <div className="page-body is-logged-in rfp-page">
        <h1 className="page-title defined">{RFP_TITLE}</h1>

        <RfpSummary />

        {/* PROPOSALS */}
        {isHaveProposals && (
          <>
            <div className="page-spacer defined mt-3 mb-3" />

            {
              proposalsToRender?.map((item) => (
                <RfpProposalCard
                  key={item?.id}
                  proposal={item}
                  rfp={RFP}
                  order={item?.order}
                  rfpStatus={status}
                />
              ))
            }
          </>
        )}
        {/* PROPOSALS === END */}

        {/* New Proposal form */}
        {/* `loading` is used to reset form after successful submit */}
        {isCanAddProposal && !loading && (
          <>
            <div className="page-spacer defined mt-3 mb-3" />
            <RfpProposalForm />
          </>
        )}

      </div>
    </>
  );
};

export default RfpDetailPage;
