import React, { FC } from 'react';
import useTimezoneDate from 'SHARED/hooks/useTimezoneDate';
import InfoBlock from '../InfoBlock';

interface IProps {
  cancellationDate: string | number;
  cancellationReason: string;
}

const OfferCancellationInfo: FC<IProps> = ({
  cancellationDate,
  cancellationReason,
}) => {
  const timezoneDate = useTimezoneDate();

  return (
    <InfoBlock>
      {`The offer has been cancelled ${timezoneDate({ date: cancellationDate })}. Reason: ${cancellationReason}`}
    </InfoBlock>
  );
};

export default OfferCancellationInfo;
